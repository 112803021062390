import React, { useEffect, useState } from 'react';
import { message, Select } from 'antd';
import { useQuery } from 'react-query';
import client from '../../../../common/utils/http';

const { Option } = Select;

const ComparisonSetLookup = ({ name, value, onChange }) => {
  const [query, setQuery] = useState('');

  const { isError, data: comparisonSets } = useQuery(
    ['entity-infos-selector', query],
    () => {
      const params = {
        $limit: 5,
        type: 'Brief',
        subType: 'Comparison Set',
        'name[$iLike]': `%${query}%`,
      };
      return client
        .get('/entity-infos', { params })
        .then((res) => res.data)
        .then(({ data }) => data);
    },
    { keepPreviousData: true },
  );

  const { data: selectedValue } = useQuery(
    ['entity-infos-selector-value', value],
    () => {
      const params = {
        $limit: 1,
        type: 'Brief',
        id: value,
      };
      return client
        .get('/entity-infos', { params })
        .then((res) => res.data)
        .then(({ data }) => data)
        .then((results) => results[0]);
    },
    { enabled: !!value },
  );

  useEffect(() => {
    if (isError) message.error('There was an error searching the comparison sets');
  }, [isError]);

  return (
    <Select
      name={name}
      value={value}
      onChange={onChange}
      onSearch={setQuery}
      style={{ width: '100%' }}
      optionFilterProp="children"
      showSearch
      allowClear
    >
      {selectedValue && (
        <Option value={selectedValue.id}>
          {selectedValue.name}
        </Option>
      )}
      {(comparisonSets || [])
        .filter((facet) => !(selectedValue && selectedValue.id === facet.id))
        .map((facet) => (
          <Option key={facet.id} value={facet.id}>
            {facet.name}
          </Option>
        ))}
    </Select>
  );
};

export default ComparisonSetLookup;
