import orderBy from 'lodash/orderBy';
import uniq from 'lodash/uniq';
import groupBy from 'lodash/groupBy';
import mt from 'moment-timezone';

import client from '../../common/utils/http';
import { catchError } from '../../saga/sagas';
import dateColumn from './components/columns/dateColumn';

const FORMAT = 'MM/DD/YYYY';

const getColumns = (data) => {
  const dates = uniq(data.map((d) => d.date));
  return dates.map((d) => dateColumn(d, FORMAT));
};

const getFormatedData = (data) => {
  const preparedData = data.map((el) => ({ ...el, date: mt(el.date).tz('UTC').format(FORMAT) }));
  const sortedData = orderBy(preparedData, ['date'], ['desc']);
  const columns = getColumns(sortedData);
  const rows = Object
    .values(groupBy(sortedData, 'entityId'))
    .map((cefs) => {
      const row = {};
      cefs.forEach((cef) => {
        row.entity = cef.entity;
        row.ceft = cef.type;
        row.entityId = cef.entityId;
        row[cef.date] = cef;
        row.date = cef.createdAt;
      });
      return row;
    });
  return { columns, data: orderBy(rows, ['date', 'entity.name'], ['desc', 'asc']) };
};

/**
 * @param {number} page - page
 * @param {object} search - params for search: { type, name, assignedToId, stage, orderField, orderDirection }
 * */
const getFacets = (id) => client.fetchAll('/cross-entity-facets', 10000, 0, [], id ? `crossEntityFacetTypeId=${id}&$sort[id]=-1` : '')
  .then((data) => getFormatedData(data))
  .catch(catchError);

const loadUsers = () => client
  .get('/users', { params: { $limit: 0 } })
  .then((response) => response.data.total)
  .then((total) => client.get('/users', { params: { $limit: total } }))
  .then((response) => response.data.data)
  .then((users) => orderBy(users, ['name'], ['asc']))
  .catch(catchError);

const updateMeta = (metaToUpdate) => client
  .patch('/facet-meta-data/', metaToUpdate)
  .catch(catchError);

export default {
  loadUsers,
  updateMeta,
  getFacets,
};
