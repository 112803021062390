const prepareLinkToSave = (link, sourceTypeDefault, sourceId) => {
  const preparedLink = {
    targetId: link.targetId,
    targetType: link.targetType,
    section: link.section,
    type: link.type,
    strength: link.strength,
    direction: link.direction,
    description: link.description,
    facetFieldTypeId: link.facetFieldTypeId,
    provenance: link.provenance,
    stage: link.stage,
  };
  if (link.id) {
    preparedLink.id = link.id;
    preparedLink.sourceType = link.sourceType;
  }
  preparedLink.sourceId = link.sourceId || sourceId;
  preparedLink.sourceType = link.sourceType || sourceTypeDefault;
  return preparedLink;
};

export default prepareLinkToSave;
