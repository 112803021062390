import { Alert } from '@axis/xyz.admin.alert';
import { Loading } from '@axis/xyz.admin.loading';
import { ButtonOutline } from '@axis/xyz.admin.buttons.button-outline';
import React from 'react';
import styles from './EndOfContent.module.css';

const EndOfContent = ({
  isError, isEnd, isLoading, onClick,
}) => {
  if (isError) {
    return (
      <Alert className={styles.alert} type="error">
        Seems the connection was lost!
        <br />
        Refresh the page or come back later
      </Alert>
    );
  }
  if (isLoading) return <Loading className={styles.loading} />;
  if (isEnd) {
    return (
      <Alert className={styles.alert} type="success">
        You got to the end of the list.
      </Alert>
    );
  }
  return <ButtonOutline type="button" label="Load more" onClick={onClick} />;
};

export default EndOfContent;
