import React from 'react';

const formatter = ({ row: { description } }) => (<div title={description}>{description}</div>);

const descriptionColumn = {
  key: 'description',
  name: 'Description',
  filterable: true,
  sortable: false,
  resizable: true,
  editable: true,
  width: 150,
  formatter,
  draggable: true,
};

export default descriptionColumn;
