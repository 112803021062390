export const seSendRequestForPasswordReset = 'Please check your email for a link to reset your password.';
export const seResetSuccess = 'Success'; // when pass was changed
export const seSaveEntitySuccess = 'Entity has been created';
export const seUpdateEntitySuccess = 'Entity has been updated';
export const seSaveEntityError = 'Save Entity';
export const seUpdateEntityError = 'Update Entity';
export const aErrorLargeFile = 'Large file';
export const aErrorUploadFailed = 'Upload failed';
export const dLeavePageUnsavedChanges = 'You have unsaved changes, are you sure you want to leave?';
export const dDeleteEntity = 'Are you sure delete';
export const dDeleteEntityForEditor = 'Are you sure you want to delete this database entry?';
export const noDataToShow = 'No data to show';
