import React, {
  useEffect, useState,
} from 'react';
import { Typography } from '@axis/xyz.admin.typography';
import { Button } from '@axis/xyz.admin.buttons.button';
import { DataTable } from '@axis/xyz.admin.tables.data-table';
import { Pagination } from '@axis/xyz.admin.pagination';
import { Icon } from '@axis/xyz.assets.icon';
import {
  useQuery, useMutation, useQueryClient,
} from 'react-query';
import { message } from 'antd';
import { Loading } from '@axis/xyz.admin.loading';
import { get } from 'lodash';
import css from './Landscapes.module.css';
import {
  fetchLandscapesPage, PAGE_SIZE, createLandscape, updateLandscape, deleteLandscape,
} from './utils';
import SearchSelect from '../../components/Form/SearchSelect/SearchSelect';
import ModalNewLandscape from '../../modals/ModalNewLandscape/ModalNewLandscape';
import ModalEditLandscape from '../../modals/ModalEditLandscape/ModalEditLandscape';
import ModalDeleteLandscape from '../../modals/ModalDeleteLandscape/ModalDeleteLandscape';

const LoadingIndicator = ({ visible }) => {
  if (!visible) return null;
  return (
    <div className={css.loadingIndicator}>
      <Loading className={css.spinner} />
      <p className={css.loading}>Refreshing countries</p>
    </div>
  );
};

const Landscapes = () => {
  const [filters, setFilters] = useState({
    landscapeName: null,
  });

  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({ field: '', order: '' });

  const [showNewLandscapeModal, setShowNewLandscapeModal] = useState(false);

  const [selectedLandscape, setSelectedLandscape] = useState({ landscape: null, action: '' });

  const queryClient = useQueryClient();

  const eventCreate = useMutation(createLandscape);
  const eventUpdate = useMutation(updateLandscape);
  const eventDelete = useMutation(deleteLandscape);

  const {
    data = { total: 0, landscapes: [] },
    isFetching,
    isLoading,
  } = useQuery(
    ['landscapes', filters, sort, page],
    () => fetchLandscapesPage({
      filters,
      orderByName: sort.order,
      pageParam: { page },
    }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    queryClient.invalidateQueries('landscapes');
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  const handleCreateLandscape = (landscape) => {
    eventCreate.mutate(landscape, {
      onSuccess: () => {
        queryClient.invalidateQueries(['landscapes', filters]);
        message.success('Landscape successfully created');
        setShowNewLandscapeModal(false);
      },
      onError: (err) => {
        const detail = get(err, 'response.data.message', err.message);
        message.error(detail);
      },
    });
  };

  const handleUpdateLandscape = ({ id, ...landscape }) => {
    eventUpdate.mutate({ id, landscape }, {
      onSuccess: () => {
        queryClient.invalidateQueries(['landscapes', filters]);
        message.success('Landscape successfully updated');
        setSelectedLandscape({ landscape: null, action: '' });
      },
      onError: (err) => {
        const detail = get(err, 'response.data.message', err.message);
        message.error(detail);
      },
    });
  };

  const handleDeleteConfirmed = (landscapeId) => {
    eventDelete.mutate(landscapeId, {
      onSuccess: () => {
        queryClient.invalidateQueries(['landscapes', filters]);
        message.success('Landscape successfully deleted');
      },
      onError: (err) => {
        const detail = get(err, 'response.data.message', err.message);
        message.error(detail);
      },
      onSettled: () => {
        setSelectedLandscape({ landscape: null, action: '' });
      },
    });
  };

  const handleFilterChange = (field) => (value) => {
    if (!value) {
      setFilters({ ...filters, [field]: null });
      setPage(1);
    } else {
      setFilters({ ...filters, [field]: value });
      setPage(1);
    }
  };

  const handleSortChange = (sortField) => {
    setSort(({ field, order }) => {
      if (field !== sortField) {
        return { field: sortField, order: 'asc' };
      }
      if (order === 'asc') {
        return { field, order: 'desc' };
      }
      if (order === 'desc') {
        return { field: '', order: '' };
      }
      return { field: '', order: '' };
    });
  };

  return (
    <div className={css.container}>
      <section>
        <div className={css.row}>
          <Typography
            className={css.title}
            tag="h1"
            type="title"
            size="xl"
          >
            Landscapes
            <LoadingIndicator
              visible={isFetching && !isLoading && data.length > 0}
            />
          </Typography>
          <Button
            className={css.buttonNew}
            color="primary"
            onClick={() => setShowNewLandscapeModal((m) => !m)}
            label="New Landscape"
          />
        </div>
        <hr className={css.titleSeparator} />
        <div className={css.filters}>
          <div className={css.filter}>
            <SearchSelect
              isClearable
              placeholder="Filter by Name"
              name="filter-name"
              value={filters.landscapeName || ''}
              onChange={handleFilterChange('landscapeName')}
              searchUrl="/landscapes?$limit=50&name="
            />
          </div>
        </div>
      </section>
      <section>
        <DataTable
          columns={[
            {
              label: 'Name', key: 'name', sortable: true, sortKey: 'name',
            },
            { label: 'Alternative Name', key: 'alternative_names', sortable: false },
            {
              label: 'Actions', key: 'actions', className: css.columnActions, sortable: false,
            },
          ]}
          rows={data.landscapes.map((landscape) => ({
            id: landscape.id,
            values: {
              name: landscape.name,
              alternative_names: landscape.alternative_names.join(', '),
              actions: (
                <div className={css.icons} data-cy="rss-sources-options">
                  <span className={css.iconContainer} data-cy="rss-sources-options-edit">
                    <Icon
                      className={css.icon}
                      type="edit"
                      onClick={() => setSelectedLandscape({ landscape: landscape.id, action: 'edit' })}
                    />
                  </span>
                  <span className={css.iconContainer} data-cy="rss-sources-options-delete">
                    <Icon
                      className={css.icon}
                      type="trash"
                      onClick={() => setSelectedLandscape({ landscape: landscape.id, action: 'delete' })}
                    />
                  </span>
                </div>
              ),
            },
          }))}
          sortKey={sort.field}
          sortOrder={sort.order}
          onSort={handleSortChange}
        />
        <div className={css.pagination}>
          <Pagination
            page={page}
            totalPages={Math.ceil(data.total / PAGE_SIZE)}
            onPageChange={setPage}
          />
        </div>
      </section>
      <ModalNewLandscape
        visible={showNewLandscapeModal}
        onCreate={handleCreateLandscape}
        onClose={() => setShowNewLandscapeModal(false)}
      />
      <ModalEditLandscape
        visible={selectedLandscape.landscape && selectedLandscape.action === 'edit'}
        id={selectedLandscape.landscape}
        onUpdate={handleUpdateLandscape}
        onClose={() => setSelectedLandscape({ landscape: null, action: '' })}
      />
      <ModalDeleteLandscape
        visible={selectedLandscape.landscape && selectedLandscape.action === 'delete'}
        onConfirm={() => handleDeleteConfirmed(selectedLandscape.landscape)}
        onCancel={() => setSelectedLandscape({ landscape: null, action: '' })}
      />
    </div>
  );
};

export default Landscapes;
