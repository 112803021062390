import flatMap from 'lodash/flatMap';
import { parseDateIgnoreTZ, transformDateTZ } from '../../common/utils/date';
import client from '../../common/utils/http';

const PAGE_SIZE = 25;

const getQueryParams = ({
  topicId, page, date, user, entity, title,
}) => {
  const params = {
    $limit: PAGE_SIZE,
    $skip: PAGE_SIZE * (page - 1),
  };
  if (topicId) params.topicId = topicId;
  if (date) {
    const dateWithOffset = transformDateTZ(date);
    params.updatedAt = dateWithOffset;
  }
  if (user) params.userId = user;
  if (entity) params.entityId = entity;
  if (title) params.title = title;
  return params;
};

const prepareDate = (event) => ({
  ...event,
  updatedAt: parseDateIgnoreTZ(event.updatedAt),
});

export const fetchEventsPage = ({
  topicId, pageParam = {}, date, user, entity, title,
}) => {
  const { page = 1 } = pageParam;
  const params = getQueryParams({
    topicId, page, date, user, entity, title,
  });
  return client.get('/events', { params })
    .then((res) => res.data)
    .then(({
      total: totalEvents,
      limit,
      skip,
      data: events,
    }) => ({
      hasMore: (limit + skip) < totalEvents,
      total: events.length,
      events: events.map(prepareDate),
    }));
};

export const processEvents = ({ pages }) => flatMap(pages, (page) => page.events);
