/* eslint-disable react/jsx-props-no-spreading */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import get from 'lodash/get';
import { Spin } from 'antd';
import Field from './lib';

import './index.css';

import {
  addFieldSet,
  changeFieldSet,
  dictionaryItemAdd,
  removeFieldSet,
  saveField,
} from '../../actions';

class FieldMapping extends PureComponent {
  render() {
    const {
      actions,
      fieldSet,
      fieldType,
      options,
      count,
      readonly,
      loading,
      entityId,
      isPreview,
      facetType,
    } = this.props;

    const props = {
      actions,
      fieldSet,
      fieldType,
      options,
      count,
      readonly,
      removeField: actions.removeFieldSet,
      facetType,
    };

    const fieldTemplateNames = {
      Checkbox: <Field.Checkbox {...props} />,
      Bullet: <Field.Bullet {...props} />,
      Date: <Field.Date {...props} />,
      Detail: <Field.Detail {...props} />,
      Importance: <Field.Importance {...props} />,
      Label: <Field.Label {...props} entityId={entityId} />,
      Location: <Field.Location {...props} />,
      Media: isPreview ? (
        <Field.Media {...props} readOnly={isPreview} />
      ) : (
        <Field.Media {...props} />
      ),
      Provenance: <Field.Provenance {...props} />,
      default: null,
    };
    const component = fieldTemplateNames[fieldSet.type.name] || fieldTemplateNames.default;

    return <Spin spinning={loading}>{component}</Spin>;
  }
}

const isLoading = (state, { fields }) => {
  const map = get(state, 'entityEditor.fieldLoadingStatusMap', {});
  return !!Object.values(fields)
    .map((field) => field.id)
    .reduce((acc, id) => acc || map[id], false);
};

const mapStateToProps = (state, { fieldSet }) => ({
  loading: isLoading(state, fieldSet),
  isPreview: state.entityEditor.viewMode,
});

const mapDispatchToProps = (dispatch, props) => {
  const {
    options: { entityKey, sectionKey, facetKey },
    fieldSet: { key: fieldSetKey },
    wrapperKey,
    fieldSet,
  } = props;
  return {
    actions: bindActionCreators(
      {
        addFieldSet: (fields) => addFieldSet({
          entityKey,
          sectionKey,
          facetKey,
          wrapperKey,
          fieldSetKey,
          fields,
        }),
        removeFieldSet: () => removeFieldSet(entityKey, sectionKey, facetKey, wrapperKey, fieldSet),
        changeFieldSet: (fields) => changeFieldSet({
          entityKey,
          sectionKey,
          facetKey,
          wrapperKey,
          fieldSetKey,
          fields,
        }),
        dictionaryItemAdd,
        saveField,
      },
      dispatch,
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FieldMapping);
