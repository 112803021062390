import flatMap from 'lodash/flatMap';
import { parseDateIgnoreTZ, transformDateTZ } from '../../common/utils/date';
import client from '../../common/utils/http';

const PAGE_SIZE = 25;

const getQueryParams = ({
  topicId, page, date, user, entity, title,
}) => {
  const params = {
    $limit: PAGE_SIZE,
    $skip: PAGE_SIZE * (page - 1),
  };
  if (topicId) params.topicId = topicId;
  if (date) {
    const dateWithOffset = transformDateTZ(date);
    params.updatedAt = dateWithOffset;
  }
  if (user) params.userId = user;
  if (entity) params.entityId = entity;
  if (title) params.title = title;
  return params;
};

const prepareDate = (regulation) => ({
  ...regulation,
  updatedAt: parseDateIgnoreTZ(regulation.updatedAt),
});

export const fetchRegulationsPage = ({
  topicId, pageParam = {}, date, user, entity, legalName,
}) => {
  const { page = 1 } = pageParam;
  const params = getQueryParams({
    topicId, page, date, user, entity, legalName,
  });
  return client.get('/regulation', { params })
    .then((res) => res.data)
    .then(({
      total: totalRegulations,
      limit,
      skip,
      data: regulations,
    }) => ({
      hasMore: (limit + skip) < totalRegulations,
      total: regulations.length,
      regulations: regulations.map(prepareDate),
    }));
};

export const processRegulations = ({ pages }) => flatMap(pages, (page) => page.regulations);
