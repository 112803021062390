import React from 'react';
import { Checkbox, Row } from 'antd';
import styled from 'styled-components';

import withRemoveButton from './HOC/withButtonRemove';
import Description from './common/Description';
import Info from './common/Info';

const checkValue = (value) => {
  if (typeof value === 'string') {
    return value === 'true';
  }

  return value;
};

const CheckBox = (props) => {
  const {
    fieldType: {
      rules: {
        Field: fieldTitle,
      },
      name: fieldName,
    },
    fieldSet,
    actions,
    options,
    readonly,
  } = props;
  const {
    rules: {
      Label: label,
      Info: info,
      Description: description,
    },
  } = fieldSet;
  const onChange = ({ target: { checked } }) => {
    if (fieldSet.fields.checked.value !== checked) {
      actions.saveField({
        ...fieldSet.fields.checked,
        value: checked,
      });
    }
    actions.changeFieldSet({ [fieldSet.fields.checked.name]: checked });
  };

  return (
    <>
      <FieldName>{fieldTitle || fieldName}</FieldName>

      <Row type="flex" justify="space-between">
        <Checkbox
          onChange={onChange}
          checked={checkValue(fieldSet.fields.checked.value)}
          disabled={readonly}
        >
          {label}
        </Checkbox>
        <Info info={info} />
      </Row>

      <Description
        value={description}
        entityKey={options.entityKey}
      />
    </>
  );
};

export default withRemoveButton(CheckBox);

const FieldName = styled.p`
  color: black;
  font-size: 18px;
  margin-bottom: 12px;
`;
