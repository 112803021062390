import moment from 'moment';
import uniq from 'lodash/uniq';
import uniqueId from 'lodash/uniqueId';
import uniqBy from 'lodash/uniqBy';
import orderBy from 'lodash/orderBy';
import get from 'lodash/get';

export const PAGE_SIZE = 50;
export const DATE_FORMAT = 'MM/DD/YYYY H:mm';

export const getQueryParams = ({ filter, sort, page }) => {
  const params = {
    $limit: PAGE_SIZE,
    $skip: PAGE_SIZE * (page - 1),
  };
  if (filter.key) {
    params['key[$iLike]'] = `%${filter.key}%`;
  }

  if (filter.name) {
    params['name[$iLike]'] = `%${filter.name}%`;
  }

  if (sort.field && sort.order) {
    params[`$sort[${sort.field}]`] = sort.order;
  }
  return params;
};

export const processDictionaries = (response) => {
  const { total, data } = response.data;
  const dictionary = data.map(({ items, ...element }) => ({
    ...element,
    createdAt: moment(element.createdAt).format(DATE_FORMAT),
    updatedAt: moment(element.updatedAt).format(DATE_FORMAT),
  }));

  return ({ total, dictionary });
};

export const prepareRawDictionaryData = ({ rows, delimiter }) => {
  const categories = {};
  rows.forEach((r) => {
    const key = r.category || ' ';
    if (!categories[key]) {
      categories[key] = [];
    }
    const values = r.value.split(delimiter).map((e) => e.trim());
    categories[key] = uniq([...categories[key], ...values]);
  });
  const keys = Object.keys(categories);
  return keys.map((e) => ({ key: e, items: categories[e] }));
};

export const validateDictionary = ({ name, key, categories }) => {
  const items = [];
  let emptyCategories = 0;
  if (!key || !key.trim()) {
    return {
      status: 'ko',
      error: 'Cannot create unnamed dictionary',
    };
  }
  const duplicatedCategory = categories
    .find((e, i, arr) => arr.find((c, j) => get(c, 'key', '').trim() === get(e, 'key', '').trim() && i !== j));
  if (duplicatedCategory) {
    return {
      status: 'ko',
      error: 'Cannot create dictionary with duplicate category names',
    };
  }
  categories.forEach((c) => {
    if (!c.key || c.key.trim() === '') {
      emptyCategories += 1;
    }
    c.items.forEach((i) => items.push({ value: i, category: c.key }));
  });
  if (emptyCategories > 1) {
    return {
      status: 'ko',
      error: 'Only one category can be empty',
    };
  }
  if (items.length === 0) {
    return {
      status: 'ko',
      error: 'Cannot create empty dictionary',
    };
  }
  if (items.find((i) => !i || i.value.trim() === '')) {
    return {
      status: 'ko',
      error: 'Cannot create dictionary with empty items',
    };
  }
  return {
    status: 'ok',
    dictionary: { key, name, items },
  };
};

export const mergeDictionaryCategories = ({ itemType, existingCategories, newCategories }) => {
  const type = { ...itemType };
  const merged = {};
  existingCategories.forEach((e) => {
    merged[e.key] = e;
  });
  newCategories.forEach((c) => {
    if (merged[c.key]) {
      merged[c.key].items = uniq([...merged[c.key].items, ...c.items]).filter((e) => !!e.trim());
    } else {
      const id = uniqueId();
      merged[c.key] = {
        id,
        items: c.items.filter((e) => !!e.trim()),
        key: c.key,
      };
      type[id] = '';
    }
  });
  return {
    mergedCategories: Object.values(merged),
    type,
  };
};

export const groupCategories = (categories) => {
  const items = [];
  categories.forEach((c) => c.items.forEach((i) => {
    items.push({
      category: c.key,
      value: i,
      id: uniqueId(),
    });
  }));
  const itemsSorted = orderBy(
    uniqBy(items, (e) => `${e.value}${e.category}`),
    ['category', 'value'],
    ['asc', 'asc'],
  );
  const itemsGrouped = {};
  itemsSorted.forEach((e) => {
    if (e.category === null || e.category === '') {
      if (!itemsGrouped['']) {
        itemsGrouped[''] = [];
      }
      itemsGrouped[''].push(e);
    } else {
      if (!itemsGrouped[e.category]) {
        itemsGrouped[e.category] = [];
      }
      itemsGrouped[e.category].push(e);
    }
  });

  return {
    categoryKeys: Object.keys(itemsGrouped),
    categoryValues: Object.values(itemsGrouped),
  };
};

export const processDictionary = (dictionary) => {
  const details = {};
  dictionary.items.forEach((item) => {
    if (!item.value || !item.value.trim()) return;
    if (!details[item.category]) {
      details[item.category] = {
        key: item.category,
        id: uniqueId(),
        items: [],
      };
    }
    details[item.category].items.push(item.value);
  });
  return {
    categories: Object.values(details) || [],
    name: dictionary.name,
    key: dictionary.key,
  };
};
