import React from 'react';
import { Collapse, Row } from 'antd';

import withRemoveButton from './HOC/withButtonRemove';
import Description from './common/Description';
import Info from './common/Info';
import PropertyType from './PropertyType';
import ElementState from '../ElementState';
import useDebounceSave from './useDebounceSave';

const { Panel } = Collapse;

function Importance({
  fieldSet, actions, options, fieldType,
}) {
  const {
    rules: {
      Field: fieldTitle,
    },
    name: fieldName,
  } = fieldType;
  const {
    rules: {
      Dictionary,
      Label: label,
      Info: info,
      Chars: chars,
      Description: description,
    },
  } = fieldSet;

  const debounceSave = useDebounceSave({ saveField: actions.saveField, fieldSet });

  return (
    <div className="facet-type-field">
      <Collapse
        bordered={false}
        defaultActiveKey={!fieldSet.filled ? fieldSet.key : null}
      >
        <Panel
          key={fieldSet.key}
          header={(
            <div>
              {fieldTitle || fieldName}
              <ElementState saved={fieldSet.fields.selectedValue.id} />
            </div>
          )}
        >
          <Row type="flex" justify="space-between">
            <div className="label-text">{label}</div>
            <Info info={info} />
          </Row>

          <PropertyType
            rules={{ type: Dictionary, chars }}
            onChange={(fields) => {
              debounceSave(
                fields,
                () => actions.changeFieldSet(fields),
              );
            }}
            fieldSelectedId={fieldSet.fields.selectedValue}
            fieldValue={fieldSet.fields.selectedLabel}
          />

          <Description
            value={description}
            entityKey={options.entityKey}
          />
        </Panel>
      </Collapse>
    </div>
  );
}

export default withRemoveButton(Importance);
