import React, { createRef, PureComponent } from 'react';
import debounce from 'lodash/debounce';
import orderBy from 'lodash/orderBy';
import { Select, Spin } from 'antd';
import { Filters } from 'react-data-grid-addons';
import { ENTITY_COLUMNS } from '../../../../common/Enums/Columns';
import styles from '../../../FacetsAssign/components/columns/styles.module.css';
import helpers from '../../helpers';

const { SingleSelectFilter } = Filters;

class CustomEditor extends PureComponent {
  refInput = createRef();

  debouncedCommit = debounce(() => this.props.onCommit(), 0);

  constructor(props) {
    super(props);
    this.state = {
      topics: [],
      value: [],
      fetching: false,
    };
  }

  componentDidMount() {
    const value = this.props.value || [];
    this.setState({ value: value.map((e) => ({ ...e, key: e.id, label: e.name })) });
    this.refInput.current.rcSelect.rootRef.click();
    document.addEventListener('mousedown', this.handleClick, false);
    document.addEventListener('scroll', this.handleScroll, false);
    document.addEventListener('wheel', this.handleScroll, false);
    this.fetchTopics = debounce(this.fetchTopics, 300);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
    document.removeEventListener('scroll', this.handleScroll, false);
    document.removeEventListener('wheel', this.handleScroll, false);
  }

  getValue() {
    return { topics: this.state.value };
  }

  getInputNode() {
    return this.refInput.current;
  }

  // eslint-disable-next-line react/sort-comp,class-methods-use-this
  disableContainerStyles() {}

  fetchTopics = (text) => {
    this.setState({ topics: [], fetching: true });
    helpers.loadTopics(text)
      .then(({ data }) => {
        this.setState({ fetching: false, topics: data.data });
      })
      .catch((err) => {
        console.error(err);
        this.setState({ fetching: false });
      });
  }

  onChange = (value) => {
    this.setState({
      value: value.map((e) => ({ id: e.key, name: e.label, ...e })),
      topics: [],
      fetching: false,
    });
  };

  handleClick = (e) => {
    if (this.refInput.current.rcSelect.rootRef.contains(e.target)) { return; }
    const popupNode = this.refInput.current.rcSelect.getPopupDOMNode();
    if (popupNode && popupNode.contains(e.target)) { return; }
    this.props.onCommit();
  };

  handleScroll = (e) => {
    const popupNode = this.refInput.current.rcSelect.getPopupDOMNode();
    if (popupNode && popupNode.contains(e.target)) { return; }
    this.props.onCommit();
  };

  render() {
    const { topics, value, fetching } = this.state;

    return (
      <Select
        showSearch
        labelInValue
        mode="multiple"
        ref={(node) => { this.refInput.current = node; }}
        onChange={this.onChange}
        value={value}
        className={styles.Select}
        onSearch={this.fetchTopics}
        filterOption={false}
        notFoundContent={fetching ? <Spin size="small" /> : null}
      >
        {topics && topics.length ? topics.map((e) => <Select.Option key={e.id}>{e.name}</Select.Option>) : null}
      </Select>
    );
  }
}

const formatter = ({ value }) => {
  const string = value && value.length > 0 ? orderBy(value, 'name', 'asc').map((e) => e.name).join(', ') : '';
  return (<div title={string}>{string}</div>);
};

const topicColumn = {
  key: ENTITY_COLUMNS.TOPIC,
  name: 'Topic',
  filterable: true,
  draggable: true,
  sortable: true,
  resizable: true,
  width: 280,
  formatter,
  editable: true,
  editor: CustomEditor,
  filterRenderer: SingleSelectFilter,
};

export default topicColumn;
