import client from '../../common/utils/http';
import { catchError } from '../../saga/sagas';
import store from '../../store';
import { editUser as editUserAction } from '../Auth/actions';

const editUser = (id, params) => {
  const promise = id ? client.patch(`/users/${id}`, params) : client.post('/users', params);
  return promise
    .then((response) => {
      store.dispatch(editUserAction({ id, ...params }));
      return Promise.resolve(response);
    })
    .catch(catchError);
};

const fetchTopics = async () => {
  const ENTITY_URL = '/countries?$limit=500&$sort[name]=1';
  const { data: { data: topics } } = await client.get(ENTITY_URL);
  return topics;
};

export default {
  editUser,
  fetchTopics,
};
