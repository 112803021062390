import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Pagination } from '@axis/xyz.admin.pagination';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import css from './ArticlesFeedCreation.module.css';
import { STORAGE_ADMIN_FEED_ARTICLES } from '../../common/utils/storage';
import Article from '../../components/Article/Article';
import EventForm from '../EventForm/EventForm';
import { fetchSelectedArticles } from '../ArticlesFeedReview/utils';
import { useToast } from '../../contexts/ToastManager/ToastManager';
import {
  createEvent,
  getActiveArticlePage,
  levenshteinDistance,
  transformArticleToEvent,
} from './utils';
import Loading from '../../components/Loading/Loading';
import { sendEvent } from '../../contexts/AnalyticsTrackingContext/AnalyticsTrackingContext';
import useLocalStorage from '../../hooks/useLocalStorage';

const EMPTY_EVENT_FORM = {
  topics: [],
  entities: [],
  briefs: [],
  locations: [],
  sources: [],
  date: null,
  attachments: [],
};

const ArticlesFeedCreation = ({ showArticles = false }) => {
  const [activeArticle, setActiveArticle] = useState(null);
  const [eventForm, setEventForm] = useState(EMPTY_EVENT_FORM);
  const [isEventFormEnabled, setEventFormEnabled] = useState(true);

  const history = useHistory();
  const toast = useToast();

  const [selectedArticles] = useLocalStorage(STORAGE_ADMIN_FEED_ARTICLES, []);

  useEffect(() => {
    if (showArticles && activeArticle) {
      const form = transformArticleToEvent(activeArticle);
      setEventForm(form);
    }
  }, [showArticles, activeArticle]);

  const {
    data: articles = [],
    isFetching: isFetchingArticles,
  } = useQuery(
    ['selectedArticles', selectedArticles],
    () => fetchSelectedArticles({ ids: selectedArticles }),
    {
      enabled: showArticles,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    if (articles && (articles.length > 0)) {
      setActiveArticle(articles[0]);
    }
  }, [articles]);

  const updateActiveArticle = (articleIndex) => {
    setActiveArticle(articles[articleIndex - 1]);
  };

  const handleEventFormChange = (field, value) => {
    setEventForm((currentEvent) => ({ ...currentEvent, [field]: value }));
  };

  const hasArticles = showArticles && (articles.length > 0);

  const handleSubmit = () => {
    setEventFormEnabled(false);
    eventForm.topics.map((t) => sendEvent('event_creation', {
      description: 'Creating a new event',
      eventTopic: t.name,
      eventTopicId: t.id,
    }));

    // Send event about ML changes
    const mlTitle = get(activeArticle, 'mlPredictions.summarization', '');
    if (mlTitle && eventForm.title !== mlTitle) {
      sendEvent('ml_event_title_change', {
        description: 'Event title change from ML prediction',
        eventTitle: eventForm.title,
        mlTitle,
        separation: levenshteinDistance(eventForm.title, mlTitle),
      });
    }

    const mlCategories = get(activeArticle, 'mlPredictions.categories', []);
    if (mlCategories.length > 0) {
      sendEvent('ml_event_briefs_change', {
        description: 'Briefs used from ML prediction',
        eventBriefs: eventForm.briefs,
        mlBriefs: mlCategories,
      });
    }

    const mlENREntities = get(activeArticle, 'mlPredictions.foundEnrEntities', []);
    if (mlENREntities.length > 0) {
      sendEvent('ml_event_enr_entities_change', {
        description: 'ENR Entities used from ML detection',
        eventEntities: eventForm.entities,
        mlEntities: mlENREntities.map((enr) => (enr.suggestedEntities || [])).flat(),
      });
    }

    createEvent(eventForm)
      .then(() => {
        toast.success({ content: 'Event successfully created!' });
        // If we don't have any articles, we reset the form
        if (!hasArticles) {
          setEventForm(EMPTY_EVENT_FORM);
        } else {
          // We move on automatically to the next article
          const currentPage = getActiveArticlePage({ activeArticle, articles });
          if (currentPage < articles.length) {
            updateActiveArticle(currentPage + 1);
            window.scrollTo(0, 0);
          } else {
            // We have nothing else to work on - we go back to the feed
            history.push('/analyst/feed/review');
          }
        }
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setEventFormEnabled(true);
      });
  };

  return (
    <Loading isLoading={isFetchingArticles}>
      <div className={css.main} data-content={hasArticles ? 'articles' : 'manual'}>
        {hasArticles && activeArticle && (
          <div className={css.articles}>
            <Article article={activeArticle} />
            <div className={css.pagination}>
              <Pagination
                page={getActiveArticlePage({ activeArticle, articles })}
                totalPages={articles.length}
                onPageChange={updateActiveArticle}
              />
            </div>
          </div>
        )}
        <div className={css.creation} data-cy="feed-creation-event-form">
          <EventForm
            title="Create Event"
            buttonLabel={isEventFormEnabled ? 'Publish' : 'Publishing...'}
            enableTopicSelection={!showArticles}
            event={eventForm}
            onChange={handleEventFormChange}
            submitEnabled={isEventFormEnabled}
            onSubmit={handleSubmit}
          />
        </div>
      </div>
    </Loading>
  );
};

export default ArticlesFeedCreation;
