import { put } from 'redux-saga/effects';
import uniqueId from 'lodash/uniqueId';
import {
  SET_ENTITY, ADD_LOAD_PROCESS, REMOVE_LOAD_PROCESS,
} from '../constants';
import { CREATE_ERROR } from '../../../constants';
import client from '../../../common/utils/http';
import * as strings from '../../../common/Strings';
import { formatTemplate } from '../../../common/utils/lib';
import { createSchema } from '../utils';
import loadDictionaries from './loadDictionaries';
import { catchError } from '../../../saga/sagas';

export default function* loadTemplate({ payload }) {
  const processId = uniqueId();
  let errors = false;
  const { id, customTemplate } = payload;
  try {
    yield put({ type: ADD_LOAD_PROCESS, payload: { processId } });
    let template;
    if (customTemplate) {
      template = formatTemplate(customTemplate);
    } else {
      const { data } = yield client
        .get(`/entity-templates/${id}`)
        .catch(catchError);
      template = formatTemplate(data);
    }

    const entity = createSchema(template, null);
    const dictionaries = yield loadDictionaries([entity]);
    yield put({
      type: SET_ENTITY,
      payload: {
        dictionaries,
        template,
        entity,
      },
    });
  } catch (e) {
    yield put({
      type: CREATE_ERROR,
      payload: `${strings.seLoadEntData}: ${e.message}.`,
    });
    errors = true;
    console.error(e);
  } finally {
    yield put({ type: REMOVE_LOAD_PROCESS, payload: { processId, errors } });
  }
}
