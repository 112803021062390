import React, { useMemo } from 'react';
import isNumber from 'lodash/isNumber';
import classnames from 'classnames';
import { extractStatistics } from './helper';
import css from './Statistic.module.css';
import Container from './Container';
import Text from './Text';

const YearStat = (props) => (
  <div>
    <Container spacing={{ outer: { bottom: 0.15 } }}>
      <TextTag>{props.year}</TextTag>
    </Container>
    <Text large={props.large} bold className={css.yearStatStat}>
      {isNumber(props.stat) ? props.stat.toLocaleString('en-US') : props.stat}
    </Text>
    {props.footer ? (
      <Text spacing={{ inner: { top: 0.1 } }} small>
        {props.footer}
      </Text>
    ) : null}
  </div>
);

const TextTag = (props) => (
  <Text
    small
    color={props.goal ? 'white' : undefined}
    className={classnames(css.textTag, { [css.goalTag]: props.goal })}
  >
    {props.children}
  </Text>
);

const Goal = (props) => (
  <div className={css.goal}>
    {props.goal && <TextTag goal>{props.text}</TextTag>}
    <Container spacing={{ outer: { bottom: 0.3 } }} />
    {props.difference !== undefined && Math.abs(props.difference) > 0.009 && props.difference !== 'Infinity' ? (
      <Text
        small
        className={classnames(css.difference, {
          [css.differencePositive]: props.difference > 0,
          [css.differenceNegative]: props.difference <= 0,
        })}
      >
        {props.difference > 0 ? '↑' : '↓'}
        {Math.abs(props.difference)}
        %
      </Text>
    ) : (
      <Text />
    )}
    {props.footer || null}
  </div>
);

const Statistics = ({ stats, ceft }) => {
  const [data] = useMemo(() => extractStatistics([{
    ...ceft,
    values: stats,
  }]), [stats, ceft]);
  return (
    <div>
      {ceft.name ? (
        <Text spacing={{ outer: { bottom: 0.4 } }} bold large>
          {ceft.name}
        </Text>
      ) : null}
      <div className={css.content}>
        <div className={classnames(css.left, { [css.italic]: data.goal })}>
          <div className={css.statGoalGroup}>
            <div className={css.futureStat}>
              <YearStat
                large
                year={data.currentDate}
                stat={data.currentStat}
                footer={data.currentStatFooter}
              />
            </div>
            <div className={css.futureGoal}>
              <Goal
                goal={data.goal}
                difference={data.goalDifference}
                text={data.goalText || 'Projection'}
              />
            </div>
          </div>
          {data.footerText && data.footerText.length ? (
            <Text small spacing={{ outer: { top: 0.3 } }} color="gray">
              {data.footerText.map((text) => (
                <>
                  {text}
                  <br />
                </>
              ))}
            </Text>
          ) : null}
        </div>
        <div className={css.right} />
      </div>
    </div>
  );
};

export default Statistics;
