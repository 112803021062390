import React from 'react';
import { Filters } from 'react-data-grid-addons';
import includes from 'lodash/includes';

const { SingleSelectFilter } = Filters;

const formatter = ({ row, value }) => {
  const users = row.columnsActions.getUsers();
  const editors = users.filter((user) => includes(value, user.id));
  const names = editors.map((editor) => editor.name).join(', ');

  return <div title={names}>{names}</div>;
};

const sectionColumn = {
  key: 'editors',
  name: 'Editors',
  filterable: true,
  sortable: false,
  resizable: true,
  width: 150,
  formatter,
  filterRenderer: SingleSelectFilter,
  draggable: true,
};

export default sectionColumn;
