import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import {
  Layout, Breadcrumb, message, Spin,
} from 'antd';
import capitalizeFirstLetter from 'lodash/upperFirst';
import classnames from 'classnames';
import authSelector from '../../selectors/users';
import SidePanel from '../SidePanel';
import Menu from './Menu/Menu';
import AppRoutes from '../../routes/AppRoutes';
import { withBreadcrumbs } from '../../constants';
import ModalContainer from './ModalContainer';
import './styles.css';

const { Content } = Layout;
const NEW = 'new';

const keyFromLocation = (location) => {
  const withoutGuid = location.pathname.replace(
    /\/(.{8}-.{4}-.{4}-.{4}-.{12}).*/,
    '',
  );
  return withoutGuid.endsWith(NEW)
    ? withoutGuid.substring(0, withoutGuid.length - NEW.length - 1)
    : withoutGuid;
};

const LayoutAxis = ({
  loading,
  location,
  location: { pathname },
}) => {
  const [state, setState] = useState({
    marginTop: 20,
    path: '',
  });
  const { marginTop, path } = state;
  const key = keyFromLocation(location);
  const showBreadcrumbs = withBreadcrumbs.includes(key);
  const [collapse, setCollapse] = useState(false);
  const toggleCollapse = useCallback(() => setCollapse((prev) => !prev), [setCollapse]);
  useEffect(() => {
    message.config({
      top: 12,
      duration: 2,
    });
    let top = 20;
    let appMode = pathname
      .split('/')
      .filter((e) => e && e !== 'admin' && e !== 'content');
    appMode = appMode.length > 0 ? appMode : ['Home'];
    if (
      appMode
      && appMode.length === 2
      && appMode[0] === 'entities'
      && appMode[1] === 'editor'
    ) {
      appMode = '';
      top = 0;
    }
    if (appMode.length > 3) {
      appMode.length = 3;
    }
    if (
      appMode.length > 2
      && (appMode[0] === 'entities' || appMode[0] === 'briefs')
      && appMode[1] === 'editor'
    ) {
      appMode.length = 2;
    }
    setState({ marginTop: top, path: appMode });
  }, [pathname]);

  return loading ? (
    <Spin className="FullScreenLoader" />
  ) : (
    <Layout>
      <SidePanel />
      <Layout>
        <Menu collapse={collapse} toggleCollapse={toggleCollapse} />
        <Layout className={classnames(
          'ContentLayout',
          {
            'main-layout': showBreadcrumbs,
            LayoutEditor: !showBreadcrumbs,
            collapse,
          },
        )}
        >
          {showBreadcrumbs && (
            <Breadcrumb>
              {path && path.map((e) => (
                <Breadcrumb.Item key={e}>
                  {capitalizeFirstLetter(e)}
                </Breadcrumb.Item>
              ))}
            </Breadcrumb>
          )}
          <Content
            id="content"
            overlay={null}
            style={{
              background: '#fff',
              padding: showBreadcrumbs ? 17 : 0,
              marginTop,
              minHeight: 500,
            }}
          >
            <AppRoutes />
          </Content>
        </Layout>
      </Layout>
      <ModalContainer />
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
  user: authSelector.getUser(state),
});

export default connect(mapStateToProps)(LayoutAxis);
