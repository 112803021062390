/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import startCase from 'lodash/startCase';
import styled from 'styled-components';
import {
  Select, Button, Row, Col, Spin, message,
} from 'antd';
import userSelector from '../../../../selectors/users';
import {
  UNASSIGNED, PUBLISHED, EXPIRED, REJECTED, NO_INFORMATION,
} from '../../../../common/Enums/Stages';

import SelectOption from '../../../../components/Avatar/SelectOption';
import chatIcon from '../../../../img/chat.svg';

import { handleSidePanel } from '../../../../effectorModel/sidePanel';
import ElementState from '../ElementState';
import StatusIcon from '../StatusIcon';
import { STATUSES } from '../../utils/isFacetFilled';

const statuses = [REJECTED];
const statusesWithoutAssigne = [UNASSIGNED, PUBLISHED, EXPIRED, REJECTED];

const allowChangeMeta = (prev, current, status) => {
  if (prev.stage === UNASSIGNED && current.stage !== UNASSIGNED) {
    return true;
  }
  if (prev.stage !== UNASSIGNED && current.stage === UNASSIGNED) {
    return true;
  }
  if (prev.stage === PUBLISHED && current.stage !== PUBLISHED) {
    return true;
  }
  if (prev.stage === REJECTED && current.stage !== REJECTED) {
    return true;
  }
  if (prev.stage !== REJECTED && current.stage === REJECTED) {
    return true;
  }
  if (prev.stage === EXPIRED && current.stage !== EXPIRED) {
    return true;
  }
  if (prev.stage !== EXPIRED && current.stage === EXPIRED) {
    return true;
  }
  if (prev.stage !== NO_INFORMATION && current.stage === NO_INFORMATION) {
    return true;
  }
  if (prev.stage === NO_INFORMATION && current.stage !== NO_INFORMATION) {
    return true;
  }
  if (!prev.user && current.user) {
    return true;
  }
  return status !== STATUSES.RED;
};

const ChatIcon = () => <img src={chatIcon} alt="chatIcon" />;

const ColWrapper = styled(Col)`
  font-size: 25px;
  line-height: 35px;
  word-wrap: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 600px;
`;

const FacetName = styled.span`
  :hover {
    border-bottom: 2px solid #502DB3;
  }
`;

const buttonPaddingStyles = {
  paddingLeft: '5px',
  paddingRight: '5px',
};

class Header extends PureComponent {
  componentWillUnmount() {
    handleSidePanel({ isOpen: false });
  }

  filterOption = (input, option) => {
    if (option.props.children && option.props.children.props && option.props.children.props.label) {
      return option.props.children.props.label
        && option.props.children.props.label.toLowerCase
        && (option.props.children.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.props.children === 'Reset Filter');
    }

    return option.props.children
      && option.props.children.toLowerCase
      && (option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.props.children === 'Reset Filter');
  };

  onDropdownChangezIndex = () => {
    const selects = document.querySelectorAll('.ant-select-dropdown');
    if (selects) {
      selects.forEach((select) => {
        select.style.zIndex = '0';
      });
    }
  };

  handleUser = (value) => {
    const prev = {
      stage: this.props.activeStage,
      user: this.props.activeUser,
    };
    const current = {
      ...prev,
      user: value,
    };
    const allow = allowChangeMeta(prev, current, this.props.status);
    if (!allow) {
      message.error('Required field is not complete', 3);
      return;
    }
    this.props.onChangeMetaField({ assignedToId: value });
  }

  handleStage = (value) => {
    const prev = {
      stage: this.props.activeStage,
      user: this.props.activeUser,
    };
    const current = {
      ...prev,
      stage: value,
    };
    const allow = allowChangeMeta(prev, current, this.props.status);
    if (!allow) {
      message.error('Required field is not complete', 3);
      return;
    }
    const {
      facetId, onChangeMetaField,
    } = this.props;

    onChangeMetaField({ assignedToId: null, stage: value });

    if (facetId && statuses.includes(value)) {
      this.openCommentComponent();
    }

    if (facetId && value === REJECTED) {
      this.openRejectionsComponent();
    }
  };

  openLogsComponent = () => {
    const { facetId, name } = this.props;

    handleSidePanel({
      isOpen: true,
      isLogsComponent: true,
      facetId,
      facetTitle: name,
    });
  };

  openCommentComponent = () => {
    const { facetId, name } = this.props;

    handleSidePanel({
      isOpen: true,
      facetId,
      facetTitle: name,
      isCommentComponent: true,
    });
  };

  openRejectionsComponent = () => {
    const { facetId, name } = this.props;

    handleSidePanel({
      isOpen: true,
      facetId,
      facetTitle: name,
      isRejectionsComponent: true,
    });
  };

  render() {
    const {
      activeStage,
      activeUser,
      label,
      name,
      onBlur,
      users,
      viewMode,
      count,
      facetId,
      logsCount,
      rejections = [],
      stages,
      amountComment,
      loading,
      openPreview,
      hasAccessToAssignFacetToUser,
      status,
    } = this.props;

    return (
      <Spin spinning={loading}>
        <Row type="flex" justify="space-between" align="middle">
          <ColWrapper
            span={12}
          >
            <StatusIcon status={status} />
            <FacetName>
              <span>{label ? `${name}: ` : name}</span>
              {label && <span title={label}>{label}</span>}
              {count !== false && <span><b>{`  ${count}`}</b></span>}
            </FacetName>
            <ElementState saved={facetId} />
          </ColWrapper>

          <Col span={12}>
            {
              !viewMode ? (
                <Row type="flex" align="middle" justify="end" gutter={8} onClick={(e) => e.stopPropagation()}>
                  {openPreview && (
                    <Col>
                      <Button icon="eye" onClick={openPreview} />
                    </Col>
                  )}
                  {rejections.length || activeStage === REJECTED ? (
                    <Col>
                      <Button
                        title={!facetId ? 'Please save this entity to enable rejections' : 'Rejections'}
                        className={!facetId ? 'comments-button' : ''}
                        onClick={this.openRejectionsComponent}
                        disabled={!facetId}
                        style={buttonPaddingStyles}
                      >
                        R
                      </Button>
                    </Col>
                  ) : null}

                  <Col>
                    <Button
                      icon="diff"
                      title={!facetId ? 'Please save this entity to enable log' : 'Log'}
                      className={!facetId ? 'comments-button' : ''}
                      onClick={this.openLogsComponent}
                      disabled={!facetId || !logsCount}
                      style={buttonPaddingStyles}
                    >
                      {logsCount > 0 ? logsCount : ''}
                    </Button>
                  </Col>

                  <Col>
                    <Button
                      title={!facetId ? 'Please save this entity to enable comments' : 'Comments'}
                      onClick={this.openCommentComponent}
                      disabled={!facetId}
                      className={!facetId || !amountComment ? 'comments-button' : ''}
                      style={buttonPaddingStyles}
                    >
                      <ChatIcon />
                      {amountComment > 0 ? amountComment : ''}
                    </Button>
                  </Col>

                  <Col data-testid="facetStatusSelect">
                    <Select
                      onChange={this.handleStage}
                      showSearch
                      style={{ width: 130 }}
                      placeholder="Stage"
                      dropdownClassName="dropdown-facet-header dropdown-zindex"
                      value={startCase(activeStage) || UNASSIGNED}
                      optionFilterProp="children"
                      filterOption={(input, option) => this.filterOption(input, option)}
                      onBlur={onBlur}
                    >
                      {stages.map((e) => <Select.Option key={e}>{startCase(e)}</Select.Option>)}
                    </Select>
                  </Col>

                  <Col data-testid="facetUserSelect">
                    <Select
                      onChange={this.handleUser}
                      showSearch
                      style={{ width: 130 }}
                      placeholder="User"
                      dropdownClassName="dropdown-facet-header dropdown-zindex"
                      value={activeUser || undefined}
                      optionFilterProp="children"
                      filterOption={(input, option) => this.filterOption(input, option)}
                      disabled={statusesWithoutAssigne.includes(activeStage) || !activeStage || !hasAccessToAssignFacetToUser}
                      onBlur={onBlur}
                    >
                      {users && users.map(
                        (e) => (
                          <Select.Option key={e.id}>
                            <SelectOption
                              avatar={e.avatar}
                              label={e.name || e.email || 'unnamed'}
                            />
                          </Select.Option>
                        ),
                      )}
                      {!userSelector.isUserExists(activeUser, users)
                        && (
                        <Select.Option
                          disabled
                          key={activeUser}
                        >
                          Removed User
                        </Select.Option>
                        )}
                    </Select>
                  </Col>
                </Row>
              ) : null
            }
          </Col>
        </Row>
      </Spin>
    );
  }
}

Header.defaultProps = {
  loading: false,
};

export default Header;
