import React from 'react';
import { ENTITY_COLUMNS } from '../../../../common/Enums/Columns';

const completenessColumn = {
  key: ENTITY_COLUMNS.COMPLETENNES,
  name: 'Completeness',
  resizable: true,
  width: 100,
  formatter: ({ row }) => (
    <div>{`${row[ENTITY_COLUMNS.COMPLETENNES] || 0}%`}</div>
  ),
};

export default completenessColumn;
