export const RELATIONSHIP = 'Relationship';
export const LABEL = 'Label';
export const DATE = 'Date';
export const LOCATION = 'Location';
export const DETAIL = 'Detail';
export const STATISTIC = 'Statistic';
export const CHECKBOX = 'Checkbox';
export const PROVENANCE = 'Provenance';
export const MEDIA = 'Media';
export const IMPORTANCE = 'Importance';
export const BULLET = 'Bullet';

export default {
  RELATIONSHIP,
  LABEL,
  DATE,
  LOCATION,
  DETAIL,
  STATISTIC,
  CHECKBOX,
  PROVENANCE,
  MEDIA,
  IMPORTANCE,
  BULLET,
};
