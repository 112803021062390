import uniqueId from 'lodash/uniqueId';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import parseRules from './parseRules';
import { UNASSIGNED } from '../../../common/Enums/Stages';

const swapLink = (link, sourceId) => {
  const newLink = { ...link, originalSourceId: link.targetId };
  if (link.targetId === sourceId) {
    newLink.direction = link.direction !== 'BILATERAL' ? 'FROM' : 'BILATERAL';
    newLink.sourceId = link.targetId;
    newLink.sourceType = link.targetType;
    newLink.targetId = link.sourceId;
    newLink.targetType = link.sourceType;
    newLink.targetEntityType = link.sourceEntityType;
    newLink.sourceEntityType = link.targetEntityType;
    newLink.targetFacet = link.sourceFacet;
    newLink.sourceFacet = link.targetFacet;
    newLink.targetEntity = link.sourceEntity;
    newLink.sourceEntity = link.targetEntity;
  }
  return newLink;
};

export const prepareLinkSet = (link, linkType, facetType) => {
  const newLink = {
    originalSourceId: link ? link.originalSourceId : null,
    description: link ? link.description : '',
    direction: get(link, 'direction'),
    facetFieldTypeId: link ? link.facetFieldTypeId : linkType.id,
    facetFieldType: link ? link.facetFieldType : linkType,
    id: link ? link.id : undefined,
    key: uniqueId('_linkSet'),
    section: link ? link.section : facetType.section,
    strength: link ? link.strength : null,
    source: link ? get(link, 'sourceFacet.entity') || get(link, 'sourceEntity') : null,
    sourceEntityType: link ? link.sourceEntityType : null,
    sourceId: link ? link.sourceId : null,
    sourceType: link ? link.sourceType : null,
    target: link ? get(link, 'targetFacet.entity') || get(link, 'targetEntity') : null,
    targetEntityType: link ? link.targetEntityType : null,
    targetId: link ? link.targetId : null,
    targetType: link ? link.targetType : null,
    type: link ? link.type : null,
    stage: link ? link.stage : UNASSIGNED,
    rules: link ? parseRules(link.facetFieldType) : parseRules(linkType),
    createdAt: link ? link.createdAt : Date.now(),
    provenance: get(link, 'provenance', ''),
    componentLogs: {
      updated: Date.now(),
      changed: false,
    },
  };
  if (!link) {
    const fullDirectionNames = {
      to: 'TO',
      from: 'FROM',
      bi: 'BILATERAL',
      TO: 'TO',
      FROM: 'FROM',
      BILATERAL: 'BILATERAL',
      BI: 'BILATERAL',
    };
    newLink.direction = fullDirectionNames[newLink.rules.Direction.fixedValue]
      || fullDirectionNames[newLink.rules.Direction.type]
      || fullDirectionNames.from;
    newLink.strength = newLink.rules.Significance.defaultValue;
  }
  return newLink;
};

/**
 * @param linkTypes {array}
 * @param links {array}
 * @param facetType {object}
 * @param sourceId {string}
 * @returns {object} linkSets
 */
export const getLinkSets = (linkTypes, links, facetType, sourceId) => {
  const linksMap = {};
  const linkSets = [];

  links.forEach((link) => {
    if (!Object.prototype.hasOwnProperty.call(linksMap, link.facetFieldTypeId)) {
      linksMap[link.facetFieldTypeId] = {
        facetFieldTypeId: link.facetFieldTypeId,
        facetFieldType: link.facetFieldType,
        links: [],
        typeExistsInTemplate: false,
      };
    }
    linksMap[link.facetFieldTypeId].links.push(swapLink(link, sourceId));
  });

  linkTypes.forEach(({ type: linkType }) => {
    if (Object.prototype.hasOwnProperty.call(linksMap, linkType.id)) {
      linksMap[linkType.id].typeExistsInTemplate = true;
      linksMap[linkType.id].links.forEach((link) => {
        const preparedLink = prepareLinkSet(link, linkType, facetType);
        if (preparedLink.target && preparedLink.source) {
          linkSets.push(preparedLink);
        }
      });
    } else if (linkType.create === true) {
      linkSets.push(prepareLinkSet(null, linkType, facetType));
    }
  });

  links.forEach((link) => {
    if (!linksMap[link.facetFieldTypeId].typeExistsInTemplate) {
      const reversedLink = linksMap[link.facetFieldTypeId].links.find((l) => l.id === link.id);
      const preparedLink = prepareLinkSet(reversedLink, null, facetType);
      if (preparedLink.target && preparedLink.source) {
        linkSets.push(preparedLink);
      }
    }
  });
  return orderBy(linkSets, ['createdAt'], ['asc']);
};

const createLink = (
  linkSet,
  log,
) => ({
  ...linkSet,
  key: uniqueId('_linkSet'),
  componentLogs: log,
});

export default createLink;
