import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { Typography } from '@axis/xyz.admin.typography';
import { Button } from '@axis/xyz.admin.buttons.button';
import { ButtonText } from '@axis/xyz.admin.buttons.button-text';
import {
  useInfiniteQuery, useMutation, useQueryClient,
} from 'react-query';
import { DateInput } from '@axis/xyz.admin.form.date-input';
import { message } from 'antd';
import { Loading } from '@axis/xyz.admin.loading';
import { TextInput } from '@axis/xyz.admin.form.text-input';
import { debounce } from 'lodash';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import styles from './Regulations.module.css';
import { STORAGE_ADMIN_ACTIVE_FILTER_REGULATIONS } from '../../common/utils/storage';
import client from '../../common/utils/http';
import Modal from '../../components/Modal/Modal';
import EndOfContent from '../../components/EndOfContent/EndOfContent';
import { fetchRegulationsPage, processRegulations } from './utils';
import Row, { Header } from './components/Row';
import SearchSelect from '../../components/Form/SearchSelect/SearchSelect';
import { sendEvent } from '../../contexts/AnalyticsTrackingContext/AnalyticsTrackingContext';

import getEntityOptionLabel from '../../common/utils/getEntityOptionLabel';
import useLocalStorage from '../../hooks/useLocalStorage';

const LoadingIndicator = ({ visible }) => {
  if (!visible) return null;
  return (
    <div className={styles.loadingIndicator}>
      <Loading className={styles.spinner} />
      <p className={styles.loading}>Refreshing data</p>
    </div>
  );
};

const Regulations = () => {
  const [removingRegulation, setRemovingRegulation] = useState(null);
  const loadNextRef = useRef();

  const [filters, setFilters] = useLocalStorage(STORAGE_ADMIN_ACTIVE_FILTER_REGULATIONS, {});

  const [legalName, setLegalName] = useState(filters.legalName || '');

  const queryClient = useQueryClient();

  const regulationDelete = useMutation((id) => client.delete(`/regulation/${id}`));

  const {
    data: regulationPages = {},
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ['regulations', filters],
    ({ pageParam }) => fetchRegulationsPage({
      date: filters.date,
      topicId: filters.topic ? filters.topic.id : '',
      user: filters.user ? filters.user.id : '',
      entity: filters.entity ? filters.entity.id : '',
      legalName: filters.legalName || '',
      pageParam,
    }),
    {
      getNextPageParam: (lastPage, pages) => (lastPage.hasMore ? ({ page: pages.length + 1 }) : undefined),
    },
  );

  useIntersectionObserver({
    target: loadNextRef,
    onIntersect: fetchNextPage,
    enabled: hasNextPage,
  });

  useEffect(() => {
    queryClient.invalidateQueries('regulations');
  }, []);

  const hasActiveFilters = () => {
    if (filters.date) return true;
    if (filters.topic && filters.topic.id) return true;
    if (filters.user && filters.user.id) return true;
    if (filters.entity && filters.entity.id) return true;
    if (filters.legalName) return true;
    return false;
  };

  const onRemove = (id) => {
    regulationDelete.mutate(id, {
      onSuccess: () => {
        queryClient.invalidateQueries(['regulations', filters]);
        message.success('Regulation successfully deleted');
      },
      onError: (err) => {
        console.error(err);
        message.error(err.message);
      },
      onSettled: () => {
        setRemovingRegulation(null);
      },
    });
  };

  const regulations = processRegulations(regulationPages);

  const handleFilterChange = (field) => (value) => {
    if (!value) setFilters({ ...filters, [field]: null });
    else setFilters({ ...filters, [field]: value });
  };

  const updateLegalNameFilter = useCallback(debounce((searchTitle) => setFilters((f) => ({ ...f, legalName: searchTitle })), 500), []);

  const handleTitleChange = (searchTitle) => {
    setLegalName(searchTitle);
    updateLegalNameFilter(searchTitle);
  };

  const handleDeleteConfirmed = (regulation) => {
    sendEvent('regulation_deletion', {
      description: 'Deleting a regulation',
      regulationId: regulation.id,
      regulationTopicId: regulation.topic,
      regulationName: regulation.name,
    });
    onRemove(regulation.id);
  };

  return (
    <div className={styles.container}>
      <section>
        <div className={styles.row}>
          <Typography
            className={styles.title}
            tag="h1"
            type="title"
            size="xl"
          >
            Regulations
            <LoadingIndicator
              visible={isFetching && regulations.length > 0}
            />
          </Typography>
          {(hasActiveFilters()) && (
            <ButtonText
              className={styles.clearFilter}
              color="primary"
              onClick={() => {
                setFilters({});
                setLegalName('');
              }}
              label="Clear filters"
            />
          )}
        </div>
        <hr className={styles.titleSeparator} />
        <div className={styles.filters}>
          <div className={styles.filter}>
            <TextInput
              placeholder="Filter by legal name"
              name="filter-legal-name"
              value={legalName || ''}
              onChange={(e) => handleTitleChange(e.target.value)}
            />
          </div>
          <div className={styles.filter}>
            <SearchSelect
              isClearable
              placeholder="Filter by country"
              name="filter-topic"
              value={filters.topic || ''}
              onChange={handleFilterChange('topic')}
              searchUrl="/countries?$limit=50&name[$iLike]="
            />
          </div>
          <div className={styles.filter}>
            <SearchSelect
              isClearable
              placeholder="Filter by entity"
              name="filter-entity"
              value={filters.entity || ''}
              onChange={handleFilterChange('entity')}
              getOptionLabel={getEntityOptionLabel}
              searchUrl="/entity-infos?type[$ne]=Brief&$limit=50&name[$iLike]="
            />
          </div>
          <div className={styles.filter}>
            <SearchSelect
              isClearable
              placeholder="Filter by user"
              name="filter-user"
              value={filters.user || ''}
              onChange={handleFilterChange('user')}
              searchUrl="/users?$limit=50&name[$iLike]="
            />
          </div>
          <div className={styles.filter}>
            <DateInput
              variant="block"
              valuePosition="right"
              placeholder="Filter by date"
              styles={{ right: 0 }}
              value={filters.date || ''}
              onClear={() => setFilters((f) => ({ ...f, date: null }))}
              onChange={(date) => setFilters((f) => ({ ...f, date }))}
              closeOnValueSelect
            />
          </div>
        </div>
      </section>
      <section>
        <Header />
        {regulations.map((regulation, index) => (
          <Row
            onRemove={setRemovingRegulation}
            index={index}
            id={regulation.id}
            key={regulation.id}
            title={regulation.legalName}
            issuerEntity={regulation.issuerEntity}
            topic={regulation.topic}
            date={regulation.updatedAt}
            createdBy={regulation.createdBy}
          />
        ))}
        <div className={styles.end} ref={loadNextRef}>
          <EndOfContent
            isError={false}
            isLoading={isFetching || isFetchingNextPage}
            isEnd={!hasNextPage}
            onClick={fetchNextPage}
          />
        </div>
      </section>
      <Modal
        className={styles.modal}
        width={500}
        open={removingRegulation}
        maskStyle={{ backgroundColor: 'var(--color-bg-modal)' }}
      >
        <div className={styles.content}>
          <Typography>Are you sure you want to delete</Typography>
          <Typography type="title" size="sm">
            {removingRegulation ? removingRegulation.legalName : null}
          </Typography>
          <div className={styles.buttons}>
            <ButtonText
              disabled={regulationDelete.isLoading}
              color="secondary"
              className={styles.buttonText}
              label="Delete"
              onClick={() => handleDeleteConfirmed(removingRegulation)}
            />
            <Button
              label="Cancel"
              disabled={regulationDelete.isLoading}
              onClick={() => setRemovingRegulation(null)}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Regulations;
