import get from 'lodash/get';
import client from '../../../../../common/utils/http';

export const searchComparisonSet = (value = '') => client
  .get(`/entity-infos?$limit=5&type=Brief&subType=Comparison+Set&name[$iLike]=%${value}%`)
  .then((response) => response.data.data);

export const getComparisonSetById = (id = '') => client
  .get(`/entity-infos?$limit=5&type=Brief&subType=Comparison+Set&id=${id}`)
  .then((response) => get(response, 'data.data[0]', {}));

export const getCrossEntityTypeByName = (name) => client
  .get(`/cross-entity-facet-types?name=${name}`);

export const createCrossEntityType = (data) => client
  .post('/cross-entity-facet-types', data);

export const editCrossEntityType = (id, data) => client
  .patch(`/cross-entity-facet-types/${id}`, data);

export const searchCrossEntityTypes = (value) => (value
  ? client
    .get(`/cross-entity-facet-types?name[$iLike]=%${(value || '').split(' ').join('%')}%`)
    .then((response) => response.data.data)
  : client
    .get('/cross-entity-facet-types')
    .then((response) => response.data.data));
