import {
  take, fork, call,
} from 'redux-saga/effects';
import get from 'lodash/get';
import client from '../common/utils/http';

// eslint-disable-next-line import/prefer-default-export
export const takeOnce = (pattern, saga, ...args) => fork(function* () {
  let firstLoad = true;
  while (firstLoad) {
    const action = yield take(pattern);
    firstLoad = false;
    yield call(saga, ...args.concat(action));
  }
});

export const catchError = (data, toReturn) => {
  if (get(data, 'response.data.code', null) === 401) {
    client.dispatch401();
  }
  if (toReturn) {
    return data;
  }
  return Promise.reject(data);
};
