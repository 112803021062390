import client from '../../common/utils/http';

export const saveEntityRole = async (role) => (
  role.id
    ? client.patch(`/roles/${role.id}`, role).then((response) => response.data)
    : client.post('/roles', role).then((response) => response.data));

export const getEntityRoles = async (entityId) => client
  .get('/roles', { params: { entityId } })
  .then((response) => response.data);

export const deleteEntityRole = async (role) => client.delete(`/roles/${role.id}`);
