import React from 'react';
import { Prompt } from 'react-router-dom';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import pick from 'lodash/pick';
import styled from 'styled-components';
import memoize from 'lodash/memoize';
import {
  Collapse, Button, Spin, Row,
} from 'antd';

import { Select } from '@axis/xyz.admin.form.select';
import { isEqual } from 'lodash';
import { FormItemWrapper } from '@axis/xyz.admin.form.form-item-wrapper';
import * as strings from '../../common/Strings';

import EntityEditorHeader from './components/EntityEditorHeader/EntityEditorHeader';
import ModalAddFacet from '../../components/ModalAddFacet';
import Facet from './components/Facet';
import './index.css';

import { ENTITY_COLUMNS } from '../../common/Enums/Columns';
import { sendEvent } from '../../contexts/AnalyticsTrackingContext/AnalyticsTrackingContext';

import SearchSelect from '../../components/Form/SearchSelect/SearchSelect';
import {
  fetchBriefRelationship,
  fetchCountriesRelationship,
  fetchLandscapeRelationship,
  saveBriefRelationship,
  saveCountriesRelationship,
  saveLandscapeRelationship,
} from '../../api/create-relationship';
import EntityToEntityRelationship from '../../components/EntityToEntityRelationship';
import EntityRole from '../../components/EntityRole';
import EntityEducation from '../../components/EntityEducation';

const hiddenProperty = ENTITY_COLUMNS.HIDDEN;

const memoizedOptions = memoize((obj) => obj, (obj) => JSON.stringify(obj));
const { Panel } = Collapse;
const buttonStyles = {
  marginTop: 64,
  marginBottom: 64,
  marginLeft: 47,
};

class EntityEditor extends React.Component {
  hash = null;

  constructor(props) {
    super(props);
    this.state = {
      modalAddFacet: false,
      entityId: null,
      additions: [],
      sectionKey: null,
      entityKey: null,
      collapseStatuses: {},
      countries: [],
      briefs: [],
      landscapes: [],
      primaryCountry: null,
    };
  }

  componentDidMount() {
    const id = window.location.pathname.slice(window.location.pathname.lastIndexOf('/') + 1);
    fetchCountriesRelationship(id)
      .then(({ data }) => this.setState({ countries: data, primaryCountry: data.find((country) => country.primary) || {} }));

    fetchBriefRelationship(id)
      .then(({ data }) => this.setState({ briefs: data }));

    fetchLandscapeRelationship(id)
      .then(({ data }) => this.setState({ landscapes: data }));

    if (window.location.hash) {
      this.hash = window.location.hash.slice(1);
    }
    document.leavingpage = false;
    if (!this.props.assignedMode) {
      this.props.actions.clearStore();
    }
    if (this.props.viewTemplate) {
      this.props.actions.useView(this.props.viewTemplate);
      return;
    }
    if (get(this.props.match, 'params.entityIdByUrl')) {
      this.setState({ entityId: this.props.match.params.entityIdByUrl });
      this.props.actions.loadEntity(this.props.match.params.entityIdByUrl);
    } else if (this.props.location.state) {
      const { entityId, templateId } = this.props.location.state;
      this.setState({ entityId });
      if (entityId) {
        this.props.actions.loadEntity(entityId);
      } else if (templateId) {
        this.props.actions.loadTemplate(templateId);
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const fields = ['facets', 'filteredFacets', 'name', 'viewTemplate', 'template', 'isLoading', 'users', 'meta',
      'entity', 'user', 'viewMode', 'filter', 'assignedMode', 'filled', 'collapsed', 'entityEditorState',
    ];
    return !isEqual(nextState, this.state) || !isEqual(pick(nextProps, fields), pick(this.props, fields));
  }

  componentDidUpdate(last) {
    let path = 'entities';
    if (this.props.isCollection) {
      path = 'briefs';
    }
    if (this.props.viewMode && this.props.viewTemplate !== last.viewTemplate) {
      this.props.actions.useView(this.props.viewTemplate);
      return;
    }
    if (last.entity !== this.props.entity
      && this.props.entity && last.entity
      && this.props.entity.id !== last.entity.id) {
      this.props.history.replace(`/analyst/${path}/editor/${this.props.entity.id}`);
    }
    if (this.props.entity && this.props.entity.sections && this.hash && !this.props.isLoading) {
      const e = document.querySelector(`div[data-id='${this.hash}']`);
      const top = e.getBoundingClientRect().top - 250;
      if (e) {
        window.scrollTo(0, top);
      }
      this.hash = null;
    }
  }

  componentWillUnmount() {
    this.props.actions.clearStore();
    document.leavingpage = false;
  }

  toggleModalAddFacet = (modalAddFacet, duplicates, entityKey = null, sectionKey = null) => {
    this.setState((state) => ({
      ...state,
      modalAddFacet,
      additions: duplicates || state.additions || [],
      entityKey,
      sectionKey,
    }));
  };

  addFacetFromModal = (facetType) => {
    const {
      entityKey,
      sectionKey,
    } = this.state;
    this.props.actions.facetAddToSection(entityKey, sectionKey, facetType);
    this.toggleModalAddFacet(false);
  };

  toggleModalSave = (modalSave) => this.setState({ modalSave });

  changeCollapse = (value) => {
    const { key } = this.props.entity;
    this.props.actions.sidebarSectionOpenSet({ entityKey: key, activeKeys: value });
  };

  facetCollapseChanged = (key, value) => {
    const { collapseStatuses } = this.state;
    collapseStatuses[key] = !!value.length;

    this.setState({ collapseStatuses: cloneDeep(collapseStatuses) });
  }

  reorderFacet = (entityKey, sectionKey, { destination, source }) => {
    if (destination && destination.droppableId === source.droppableId) {
      const { actions: { facetOrderChange } } = this.props;
      facetOrderChange({
        entityKey, sectionKey, newOrder: destination.index, prevOrder: source.index,
      });
    }
  }

  handleEntityStatusChange = (status) => {
    this.props.actions.setEntityStatus(status);
    sendEvent('entity_update', {
      description: 'Change entity published status',
      entityId: this.props.entity.id,
      entityStatus: status,
    });
  }

  handleEntityVisibilityChange = (value) => {
    this.props.actions.setEntityHidden({ entityKey: this.props.entity.key, value });
    sendEvent('entity_update', {
      description: 'Change entity visibility (hidden) status',
      entityId: this.props.entity.id,
      entityVisibility: value,
    });
  }

  handleSaveCountryRelation = () => {
    saveCountriesRelationship(
      window.location.pathname.slice(window.location.pathname.lastIndexOf('/') + 1),
      this.state.countries.map((country) => country.id),
    );
  }

  handleSaveBriefRelation = () => {
    saveBriefRelationship(
      window.location.pathname.slice(window.location.pathname.lastIndexOf('/') + 1),
      this.state.briefs.map((brief) => brief.id),
    );
  }

  handleSaveLandscapeRelation = () => {
    saveLandscapeRelationship(
      window.location.pathname.slice(window.location.pathname.lastIndexOf('/') + 1),
      this.state.landscapes.map((landscape) => landscape.id),
    );
  }

  handleSavePrimaryCountryRelation = () => {
    saveCountriesRelationship(
      window.location.pathname.slice(window.location.pathname.lastIndexOf('/') + 1),
      this.state.primaryCountry ? [this.state.primaryCountry.id] : [],
      true,
    );
  }

  render() {
    const {
      entity: {
        sections,
      },
      entity,
      viewMode,
      isLoading,
      actions,
      entityEditorState,
      userRole,
    } = this.props;

    const {
      additions,
      modalAddFacet,
    } = this.state;
    return (
      <>
        {
          isLoading
            ? (
              <Row
                type="flex"
                justify="center"
                align="middle"
                className="EditorLoading"
              >
                <Spin />
              </Row>
            )
            : (
              <div
                id="entity-editor-id"
                className="entity-editor ant_customs__facets_section"
                style={{ position: 'relative' }}
              >
                <Prompt
                  when={document.leavingpage}
                  message={strings.dLeavePageUnsavedChanges}
                />

                <EntityEditorHeader
                  userRole={userRole}
                  viewMode={viewMode}
                  name={entity.name}
                  topics={entity.topics}
                  template={entity.template}
                  status={(entity.meta || {}).stage}
                  hidden={entity[hiddenProperty]}
                  onStatusChange={this.handleEntityStatusChange}
                  onHiddenChange={this.handleEntityVisibilityChange}
                  onSave={() => actions.saveEntity(false)}
                  entityEditorState={entityEditorState}
                  publishingFailedReason={(entity.meta || {}).failedReason}
                />
                {entity && entity.id
                    && (
                      <>
                        <FormItemWrapper
                          label="Countries Related"
                          helpMessageTitle="Entities"
                        >
                          <div
                            className="select-country-wrapper"
                          >
                            <SearchSelect
                              name="entity-countries"
                              className="select-countries"
                              isMulti
                              value={this.state.countries || []}
                              onChange={(value) => this.setState({
                                countries: value,
                              })}
                              searchUrl="/countries?$limit=50&name[$iLike]="
                            />
                            <Button
                              onClick={() => this.handleSaveCountryRelation()}
                              style={{ marginTop: 10 }}
                            >
                              Save
                            </Button>
                          </div>
                        </FormItemWrapper>

                        <FormItemWrapper
                          label="Primary Countries"
                          helpMessageTitle="Entities"
                        >
                          <div
                            className="select-country-wrapper"
                          >
                            <Select
                              name="entity-primary-country"
                              value={this.state.primaryCountry || {}}
                              className="select-primary-country"
                              onChange={(value) => {
                                this.setState({
                                  primaryCountry: value,
                                });
                              }}
                              getOptionLabel={(country) => country.name}
                              options={this.state.countries}
                              isClearable
                            />
                            <Button
                              onClick={() => this.handleSavePrimaryCountryRelation()}
                              style={{ marginTop: 10 }}
                            >
                              Save
                            </Button>
                          </div>
                        </FormItemWrapper>

                        <FormItemWrapper
                          label="Related Briefs"
                          helpMessageTitle="Briefs"
                          className="select-briefs"
                        >
                          <div
                            className="select-country-wrapper"
                          >
                            <SearchSelect
                              name="entity-briefs"
                              className="select-countries"
                              isMulti
                              value={this.state.briefs || []}
                              onChange={(value) => this.setState({
                                briefs: value,
                              })}
                              searchUrl="/briefs?$limit=50&name="
                            />
                            <Button
                              onClick={() => this.handleSaveBriefRelation()}
                              style={{ marginTop: 10 }}
                            >
                              Save
                            </Button>
                          </div>
                        </FormItemWrapper>

                        <FormItemWrapper
                          label="Related Landscapes"
                          helpMessageTitle="Landscapes"
                        >
                          <div
                            className="select-country-wrapper"
                          >
                            <SearchSelect
                              name="entity-landscapes"
                              className="select-countries"
                              isMulti
                              value={this.state.landscapes || []}
                              onChange={(value) => this.setState({
                                landscapes: value,
                              })}
                              searchUrl="/landscapes?$limit=50&name="
                            />
                            <Button
                              onClick={() => this.handleSaveLandscapeRelation()}
                              style={{ marginTop: 10 }}
                            >
                              Save
                            </Button>
                          </div>
                        </FormItemWrapper>

                        <FormItemWrapper
                          label="Related Entities"
                          helpMessageTitle="Entities"
                        >
                          <EntityToEntityRelationship entityId={entity.id} />
                        </FormItemWrapper>

                        {entity.template && entity.template.subType === 'Person' && (
                          <FormItemWrapper
                            label="Entity Roles"
                            helpMessageTitle="Roles"
                            className="roles"
                          >
                            <EntityRole entityId={entity.id} />
                          </FormItemWrapper>
                        )}

                        {entity.template && entity.template.subType === 'Person' && (
                          <FormItemWrapper
                            label="Entity Educations"
                            helpMessageTitle="Educations"
                            className="education"
                          >
                            <EntityEducation entityId={entity.id} />
                          </FormItemWrapper>
                        )}
                      </>
                    )}
                <Collapse
                  bordered={false}
                  onChange={this.changeCollapse}
                  activeKey={entity.activeKeys}
                  defaultActiveKey={entity.activeKeys}
                >
                  {
                    sections && sections.map((section, i) => (
                      <Panel
                        key={section.key}
                        style={i === 0 ? { paddingTop: 40 } : {}}
                        header={<SectionName>{section.name}</SectionName>}
                      >
                        {
                            section.facets && section.facets.map((facet) => (

                              <Facet
                                key={facet.key}
                                options={memoizedOptions({
                                  sectionKey: section.key,
                                  entityKey: entity.key,
                                  facetKey: facet.key,
                                  viewMode,
                                })}
                                collapseChanged={this.facetCollapseChanged}
                                facet={facet}
                              />
                            ))
                          }
                        {
                            section.duplicates && section.duplicates.length > 1
                            && (
                              <Button
                                style={buttonStyles}
                                onClick={
                                  () => this.toggleModalAddFacet(true, section.duplicates, entity.key, section.key)
                                }
                              >
                                + Add Facet
                              </Button>
                            )
                          }
                        {
                            section.duplicates && section.duplicates.length === 1
                            && (
                              <Button
                                style={buttonStyles}
                                onClick={
                                  () => actions.facetAddToSection(entity.key, section.key, section.duplicates[0])
                                }
                              >
                                {`+ Add ${section.duplicates[0].name}`}
                              </Button>
                            )
                          }
                      </Panel>
                    ))
                  }
                </Collapse>

                <ModalAddFacet
                  onCancel={() => this.toggleModalAddFacet(false)}
                  visible={modalAddFacet}
                  additions={additions}
                  addFacet={this.addFacetFromModal}
                />
              </div>
            )
          }
      </>
    );
  }
}

export default EntityEditor;

const SectionName = styled.span`
  margin-left: 25px;
  font-size: 25px;
  font-weight: 400;
`;
