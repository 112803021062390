/* eslint-disable max-len */
/* eslint-disable react/no-danger */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import selectors from '../../../../../selectors/entityEditor';

const getNextId = (() => {
  let a = 0;
  return () => {
    a += 1;
    return `{{__${a}__}}`;
  };
})();

const prepareString = (str, name) => {
  let p = str;
  let matches = p.match(/({%(.*?)%})/);
  const toReplace = [];
  while (matches) {
    const params = matches[0];
    const linkName = params.match(/name={(.*?)}/);
    const href = params.match(/href={(.*?)}/);
    const withParam = !!params.match(/\bwithParam\b/);
    const id = getNextId();
    const obj = {
      target: id,
      data: `<a target="_blank" href="${href && href[1] ? href[1] : ''}${withParam && name ? name.replace(/ /gm, '+') : ''}">${linkName && linkName[1] ? linkName[1] : name}</a>`,
    };
    if (!href || !href[1]) {
      obj.data = name;
    }
    toReplace.push(obj);
    p = p ? p.replace(params, id) : p;
    matches = p.match(/({%(.*?)%})/);
  }
  toReplace.forEach((f) => {
    p = p ? p.replace(f.target, f.data) : p;
  });
  p = p ? p.replace(/\n/gm, '<br/>') : p;
  return p;
};

class Description extends PureComponent {
  render() {
    const {
      value, name, className,
    } = this.props;
    return value && (
      <div
        className={`description ${className}`}
        dangerouslySetInnerHTML={{ __html: prepareString(value, name) }}
      />
    );
  }
}

const mapStateToProps = (state, props) => ({
  name: selectors.getEntityNameByKey(state, props.entityKey),
});

export default connect(mapStateToProps)(Description);
