/* eslint-disable max-len */
/* eslint-disable prefer-destructuring */
import * as analyst from './analyst';
import * as admin from './admin';
import * as user from './user';

/* Validate Errors for Facet Fields in templates */

export const fftValidateErrorSection = '';
export const fftValidateErrorGroup = '';
export const fftValidateErrorAdditions = '';
export const fftValidateErrorLevel = '';
export const unknownProperty = 'Unknown property';
export const schemaError = 'Template has errors in structure';
export const fftValidateErrorLabel = "Allowed Facet Types Field : ['Label', 'Description', 'Info', 'Chars', 'Type']";
export const fftValidateErrorLink = "Allowed Facet Types Field : ['Validation', 'Relationship-Type', 'Description', 'Info', 'Date', 'Detail-Chars', 'Start', 'Validation']";
export const fftValidateErrorDetail = "Allowed Facet Types Field : ['Start', 'Description', 'Info']";
export const fftValidateErrorCheckbox = "Allowed Facet Types Field : ['Label', 'Description', 'Info']";
export const fftValidateErrorImportance = "Allowed Facet Types Field : ['Start', 'Description', 'Info']";
export const fftValidateErrorIcon = "Allowed Facet Types Field : ['Start']";
export const fftValidateErrorDate = "Allowed Facet Types Field : ['Start', 'Format', 'Date', 'Event-Type', 'Description', 'Info']";
export const fftValidateErrorMedia = "Allowed Facet Types Field : ['Chars', 'Detail-Chars', 'Start', 'Image-Type']";
export const fftValidateErrorProvenance = "Allowed Facet Types Field : ['Start']";
export const fftValidateErrorLocation = "Allowed Facet Types Field : ['Start', 'Location-Type', 'Description', 'Info']";
export const fftValidateErrorStatistic = "Allowed Facet Types Field : ['Chars', 'Detail-Chars', 'Start', 'Image-Type', 'Rank-Set']";

/* Validate Errors for Facet Fields Rules in templates */

export const ffrtValidateErrorName = "'Name' must be one of ['Instant','At a glance','Briefing','Detailed read','Others']. 'Name' must be text";
export const ffrtValidateErrorLabel = "'Label' must be text";
export const ffrtValidateErrorTag = "'Tag' must be text";
export const ffrtValidateErrorAllowedDirections = "Must be an array like: ['to', 'from', 'bi']";
export const ffrtValidateErrorTopicSearch = 'Must be true or false';
export const ffrtValidateErrorDescription = "'Description' must be text";
export const ffrtValidateErrorInfo = "'Info' must be atext";
export const ffrtValidateErrorProvenance = 'ImageDetail must be a boolean';
export const ffrtValidateErrorStart = "'Start' must be one of ['open','close']. 'Start' must be text";
export const ffrtValidateErrorChars = "'Chars' must be a number greater than or equal to 0 (0 = unlimited)";
export const ffrtValidateErrorType = "'Type' must be text. Allowed values: `text`, `fixed('value')`, `dictionary('dict_key'), param`, where param = free / add / fixed";
export const ffrtValidateErrorDetailChars = "'Detail-Chars' must be a number greater than or equal to 0 (0 = unlimited)";
export const ffrtValidateErrorDate = "'Date' must be one of ['range', 'start', 'end', 'single'].";
export const ffrtValidateErrorLinked = "'Linked' must be one of ['unlinked', 'entity type'].";
export const ffrtValidateErrorCompare = "'Compare' must be one of ['unlinked', 'topic', 'entity'].";
export const ffrtValidateErrorValue = "'Value' must be one of ['int', 'percent'].";
export const ffrtValidateErrorDictionary = "'Dictionary' must be text.";
export const ffrtValidateErrorUnits = "'Units' must be text. Allowed values: `fixed('value')`, `dictionary('Units'), param`, where param = free / add / fixed";
export const ffrtValidateErrorRankTotal = '';
export const ffrtValidateErrorRankSet = "'Rank-Set' must be \"dictionary('some dictionary name')\"";
export const ffrtValidateErrorRankFormat = "'Rank-Format' must be text. Allowed: ['number', 'percent']";
export const ffrtValidateErrorImageType = "'Image-Type' must be text.";
export const ffrtValidateErrorFormat = "'Format' must be one of ['select', 'mm/dd/yy', 'mm/yy', 'yy'].";
export const ffrtValidateErrorLinkType = "'Link-Type' must be one of ['dictionary', 'off'].";
export const ffrtValidateErrorValidation = "'Validation' must be text.";
export const ffrtValidateErrorLocationType = "'Location-Type' must be one of ['dictionary('some-dictionary')', 'off'].";
export const ffrtValidateErrorDirection = "'Direction' must be one of ['to', 'from', 'bi', 'fixed('to'), 'fixed('from'), 'fixed('bi')']";
export const ffrtValidateErrorDetailedDescription = "'Detailed-Description' must be one of ['on', 'off'].";
export const ffrtValidateErrorSignificance = "'Significance' must be one of ['off', on, default('1-5'].";
export const ffrtValidateErrorField = "'Field' must be a string.";

/* Validate Errors for Facet in templates */

export const ftValidateErrorNoType = admin.ftValidateErrorNoType;
export const ftValidateErrorNoFacets = admin.ftValidateErrorNoContent;
export const ftSuccessValidate = admin.ftSuccessValidate;
export const ftInvalidJSON = admin.ftInvalidJSON;
export const ftSaveError = admin.ftSaveError;
export const ftInvalidEntityFormat = admin.ftInvalidEntityFormat;
export const ftSaveTemplate = admin.ftSaveTemplate;
export const ftDictionaryIsNotFound = admin.ftDictionaryIsNotFound;

/* Dialog */

export const dLeavePageUnsavedChanges = analyst.dLeavePageUnsavedChanges;
export const dDeleteEntity = analyst.dDeleteEntity;
export const dDeleteEntityForEditor = analyst.dDeleteEntityForEditor;
export const noDataToShow = analyst.noDataToShow;

/* Analyst fields error */

export const aErrorLargeFile = analyst.aErrorLargeFile;
export const aErrorUploadFailed = analyst.aErrorUploadFailed;

/* Auth */

export const authErrorShortPassword = user.authErrorShortPassword;
export const authErrorDiffPassword = user.authErrorDiffPassword;
export const authErrorNoEmail = user.authErrorNoEmail;
export const authErrorNoPassword = user.authErrorNoPassword;
export const signupErrorNoName = user.signupErrorNoName;
export const signupErrorNoPassword = user.signupErrorNoPassword;
export const signupErrorDiffPassword = user.signupErrorDiffPassword;
export const signupErrorNoConfirmPassword = user.signupErrorNoConfirmPassword;

/* Invite User */

export const iuErrorInvalidEmail = admin.iuErrorInvalidEmail;
export const iuErrorNoName = admin.iuErrorNoName;
export const iuErrorNoEmail = admin.iuErrorNoEmail;
export const iuErrirNoPermissions = admin.iuErrorNoPermissions;
export const iuErrorNoPassword = admin.iuErrorNoPassword;
export const iuErrorNoProfile = admin.iuErrorNoProfile;

/* Errors from server-side
 *  message used as wrapper for error from server. '${Your message}: ${server-error message}'
 */

export const seLoadUsers = 'Load Users'; // admin / users
export const seDeleteUserError = admin.seDeleteUserError; // admin / users
export const seInviteUserError = admin.seInviteUserError; // admin / users
export const seLoadEntities = admin.seLoadEntities; // admin / templates
export const seRemoveEntities = admin.seRemoveEntities; // admin / templates
export const seLoadEntData = 'Load Entity Data'; // admin / editor
export const seCreateEntData = admin.seCreateEntData; // admin / editor
export const seUpdateEntData = admin.seUpdateEntData; // admin / editor
export const seSearchDict = 'Search Dictionary'; // admin / editor
export const dpLoadDictionaries = 'Search Dictionary'; // admin / dictionaries
export const dpCreateDictionaryError = 'Create Dictionary'; // admin / dictionaries
export const dpRemoveDictionary = 'Remove Dictionary'; // admin / dictionaries
export const dpUpdateDictionaryError = 'Remove Dictionary'; // admin / dictionaries
export const dpLoadItems = 'Load Dictionary Items'; // admin / dictionaries
export const seAuthFailed = 'Authentication error. Incorrect email or password.'; // auth
export const seSendRequestForPasswordResetError = 'Something went wrong...'; // request for reset password / send email
export const seResetError = admin.seResetError; // request for reset password / send new pass
export const seUserCreatedError = admin.seUserCreatedError; // request for reset password / send new pass
export const seLoadEntitiesList = 'Load Entities';
export const seDeleteEntity = 'Delete Entity';
export const seLoadEntityData = 'Load Entity';
export const seChangeMeta = 'Change Meta';
export const seSaveEntityError = analyst.seSaveEntityError;
export const seUpdateEntityError = analyst.seUpdateEntityError;
export const seSearchEntities = 'Search Entity';
export const seDictionaryNewItem = 'Add new item to the dictionary';

/* success server-side */
export const dpCreateDictionarySuccess = admin.dpCreateDictionarySuccess; // 'Dictionary has been created'; // admin / dictionaries
export const dpUpdateDictionarySuccess = admin.dpUpdateDictionarySuccess; // 'Dictionary has been updated'; // admin / dictionaries
export const seUpdateEntDataSuccess = admin.seUpdateEntDataSuccess; // 'Template has been updated'; // admin / editor
export const seSaveEntDataSuccess = admin.seSaveEntDataSuccess; // 'Template has been created'; // admin / editor
export const seInviteUserSuccess = admin.seInviteUserSuccess; // 'User has been invited'; // admin / users
export const seDeleteUserSuccess = admin.seDeleteUserSuccess; // 'User has been deleted'; // admin / users
export const seSendRequestForPasswordReset = analyst.seSendRequestForPasswordReset;
export const seResetSuccess = analyst.seResetSuccess; // when pass was changed
export const seUserCreatedSuccess = admin.seUserCreatedSuccess;
export const seSaveEntitySuccess = analyst.seSaveEntitySuccess;
export const seUpdateEntitySuccess = analyst.seUpdateEntitySuccess;

/* admin / dictionaries errors */
export const oneEmptyCategoryError = 'Available only one empty category';
export const emptyDictionaryError = 'Cannot create empty dictionary';
export const emptyDictionaryName = 'Cannot create unnamed dictionary';
export const emptyDictionaryItems = 'Cannot create dictionary with empty items';
export const theSameCategories = 'Cannot create dictionary with several categories of one name';

export const errValue255chars = 'Value too long';
export const errValueInteger = 'Value must be a Integer';
