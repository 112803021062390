import React from 'react';
import { Typography } from '@axis/xyz.admin.typography';
import { HelpMessage } from '@axis/xyz.admin.help-message';
import styles from './EntitySection.module.css';

const EntitySection = ({
  className = '',
  label,
  helpTitle,
  helpContent,
  children,
}) => (
  <section className={`${className} ${styles.section}`}>
    <div className={styles.header}>
      <Typography size="md" type="title" tag="h2" className={styles.title}>{label}</Typography>
      {helpTitle && (
        <HelpMessage
          title={helpTitle}
          content={helpContent}
        />
      )}
    </div>
    {children}
  </section>
);

export default EntitySection;
