import React, { PureComponent } from 'react';
import styled from 'styled-components';
import {
  Affix, Row, Col, Checkbox, Tag, Popover,
} from 'antd';
import permissions from '../../../../selectors/featurePermissions';
import {
  ENTITY_EDITOR_STATE_CLEAN,
  ENTITY_EDITOR_STATE_DIRTY,
  ENTITY_STATUSES_FOR_DISPLAY,
  SAVE_BUTTON_TEXT,
  getEntityStage,
} from '../../constants';
import { ENTITY_COLUMNS } from '../../../../common/Enums/Columns';
import styles from './EntityEditorHeader.module.css';

const checkHiddenDirty = (entityEditorState, hidden) => {
  if (entityEditorState === ENTITY_EDITOR_STATE_DIRTY) {
    return 'unsaved';
  }
  if (hidden) {
    return 'hidden';
  }
  return null;
};

const Tooltip = ({ reason }) => (
  <div>
    <p>{reason}</p>
  </div>
);

class EntityEditorHeader extends PureComponent {
  render() {
    const {
      name,
      status,
      onHiddenChange,
      onSave,
      template,
      viewMode,
      entityEditorState,
      userRole,
      hidden,
      topics,
      publishingFailedReason = null,
    } = this.props;

    const topicsText = topics ? topics.map((e) => e.name).join(', ') : null;

    return (
      <Affix>
        <RowWrapper className="EntityEditor_Header">
          <Col>
            <NameTitle>
              {name ? (
                <span title={name || 'Unnamed'}>{name}</span>
              ) : (
                <UnnamedTitle>Unnamed</UnnamedTitle>
              )}
            </NameTitle>
          </Col>

          <Col>
            {topicsText ? (
              <Row type="flex" justify="space-between" align="middle">
                <Col>
                  <TypeTitle>
                    <Span title={topicsText}>
                      {topicsText}
                    </Span>
                  </TypeTitle>
                </Col>
              </Row>
            ) : null}
            <Row type="flex" justify="space-between" align="middle">

              <Col>
                <TypeTitle>
                  {template && template.subType && (
                    <span title={`${template.type} : ${template.subType}`}>
                      {`${template.type} : ${template.subType}`}
                    </span>
                  )}
                  {template && !template.subType && (
                    <span title={template.type}>{template.type}</span>
                  )}
                </TypeTitle>
              </Col>

              <Col>
                {!viewMode && (
                  <Row type="flex" justify="end" gutter={10}>
                    <ColStyled>
                      <Checkbox
                        disabled={!permissions.hasAccessToChangeEntityProperty(ENTITY_COLUMNS.HIDDEN, userRole)}
                        checked={hidden}
                        onChange={({ target: { checked } }) => onHiddenChange(checked)}
                      >
                        Hidden
                      </Checkbox>
                    </ColStyled>
                    <Col id="entity-header-select">
                      <Popover
                        placement="leftTop"
                        content={<Tooltip reason={publishingFailedReason} />}
                        title="Reasons"
                        trigger={publishingFailedReason ? 'hover' : ''}
                      >
                        <Tag
                          className={styles.tag}
                          data-status={getEntityStage(checkHiddenDirty(entityEditorState, hidden) || status)}
                        >
                          {ENTITY_STATUSES_FOR_DISPLAY[checkHiddenDirty(entityEditorState, hidden) || status]}
                        </Tag>
                      </Popover>
                    </Col>

                    <Col>
                      <button
                        data-cy="entity-editor-header-save-button"
                        type="button"
                        onClick={onSave}
                        disabled={entityEditorState === ENTITY_EDITOR_STATE_CLEAN}
                        className={styles.save}
                      >
                        {SAVE_BUTTON_TEXT[entityEditorState]}
                      </button>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </Col>
        </RowWrapper>
      </Affix>
    );
  }
}

export default EntityEditorHeader;

const Span = styled.span`
  display: block;
  white-space: nowrap;
  max-width: 40vw;
  overflow: hidden;
  text-overflow: ellipsis; `;

const ColStyled = styled(Col)`
  align-items: center;
  display: flex;
`;

const RowWrapper = styled(Row)`
  padding: 20px 0;
  background-color: white;
`;

const NameTitle = styled.div`
  font-size: 47px;
  word-wrap: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 800px;

  span {
    font-size: 47px;
    font-weight: 700;
    letter-spacing: -1.5px;
  }
`;

const TypeTitle = styled.div`
  font-size: 28px;
  word-wrap: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #231f20ff;

  span {
    font-weight: 300;
  }
`;

const UnnamedTitle = styled.span`
  color: gray;
`;
