/* eslint-disable import/prefer-default-export */
const ENTITY_COLUMNS = {
  HIDDEN: 'hidden',
  STAGE: 'stage',
  ASSIGNEDTOID: 'assignedToId',
  NAME: 'name',
  CREATED: 'createdAt',
  UPDATED: 'updatedAt',
  TYPE: 'type',
  COMPLETENNES: 'completeness',
  TOPIC: 'topics',
};

export {
  ENTITY_COLUMNS,
};
