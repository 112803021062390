import jwt from 'jsonwebtoken';
import { all, put, takeEvery } from 'redux-saga/effects';
import { message } from 'antd';
import sortBy from 'lodash/sortBy';
import { loggOut, setAuthToken } from '../../../common/utils/auth';
import {
  AUTH, SET_INITIAL_LOAD, AUTH_FAIL, SET_LOADING, INITIAL_LOAD, RELOGIN, CLOSE_MODAL,
} from '../constants';
import * as strings from '../../../common/Strings';
import client from '../../../common/utils/http';
import { takeOnce } from '../../../saga/sagas';

function* initialLoad({ payload: { userId } }) {
  try {
    yield put({
      type: SET_LOADING,
      payload: true,
    });
    const {
      data,
    } = yield client
      .get(`/users/${userId}`)
      .catch(({ response: { data: d } }) => {
        loggOut();
        throw new Error(`${d.name}: ${d.message}`);
      });
    const users = yield client.fetchAll('/users');
    yield put({
      type: SET_INITIAL_LOAD,
      payload: {
        user: data,
        users: sortBy(users, 'name'),
      },
    });
  } catch (e) {
    console.error(e);
    message.error(e.message);
  } finally {
    yield put({
      type: SET_LOADING,
      payload: false,
    });
  }
}

function* watchInitialLoad() {
  yield takeOnce(INITIAL_LOAD, initialLoad);
}

function* authoriztion({ payload: { email, password } }) {
  try {
    yield put({
      type: SET_LOADING,
      payload: true,
    });
    const { data: { accessToken } } = yield client.post('/authentication', { email, password, strategy: 'local' });
    setAuthToken(accessToken);
    const decoded = jwt.decode(accessToken);
    yield put({
      type: INITIAL_LOAD,
      payload: {
        userId: decoded.userId,
      },
    });
  } catch (e) {
    yield put({
      type: AUTH_FAIL,
      payload: { message: strings.seAuthFailed },
    });
  }
}
function* watchAuth() {
  yield takeEvery(AUTH, authoriztion);
}

function* relogin({ payload: { email, password } }) {
  try {
    const { data: { accessToken } } = yield client.post(
      '/authentication', { email, password, strategy: 'local' },
    );
    setAuthToken(accessToken);
    yield put({
      type: CLOSE_MODAL,
    });
  } catch (e) {
    console.error(e);
    message.error(strings.seAuthFailed);
  }
}
function* watchRelogin() {
  yield takeEvery(RELOGIN, relogin);
}

export default function* rootSaga() {
  yield all([
    watchAuth(),
    watchInitialLoad(),
    watchRelogin(),
  ]);
}
