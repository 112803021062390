import {
  ROUTE_ANALYST,
  ROUTE_ANALYST_ELEMENTS_STATENTITY,
  ROUTE_ADMIN_ELEMENTS_DICTIONARIES,
  ROUTE_ADMIN_ELEMENTS_DICTIONARIES_EDITOR,
  ROUTE_ADMIN_ASSIGN_FACETS,
  ROUTE_ADMIN,
  ROUTE_ADMIN_BRIEFS_EDITOR,
  ROUTE_ADMIN_ELEMENTS_BRIEFS,
  ROUTE_ADMIN_ELEMENTS_UNTYPED,
  ROUTE_ADMIN_ENTITIES,
  ROUTE_ADMIN_ENTITIES_EDITOR,
  ROUTE_ADMIN_ENTITIES_FACET_EDITOR,
  ROUTE_ADMIN_USERS,
  ROUTE_ANALYST_ENTITIES,
  ROUTE_ANALYST_ENTITIES_ALL,
  ROUTE_ANALYST_ENTITIES_ACTORS,
  ROUTE_ANALYST_ENTITIES_PLOTS,
  ROUTE_ANALYST_ENTITIES_CONTEXTS,
  ROUTE_ANALYST_ENTITIES_EDITOR,
  ROUTE_ANALYST_ELEMENTS_BRIEFS,
  ROUTE_ANALYST_BRIEFS_EDITOR,
  ROUTE_ADMIN_ELEMENTS,
  ROUTE_ANALYST_ELEMENTS,
  ROUTE_ADMIN_ELEMENTS_CEFS,
  ROUTE_ANALYST_ELEMENTS_CEFS,
  ROUTE_ANALYST_ELEMENTS_IMPORT_STATS,
  ROUTE_ANALYST_ELEMENTS_IMPORT_ENTITIES,
  ROUTE_ANALYST_ELEMENTS_IMPORT_CHALLENGES,
  ROUTE_ANALYST_ELEMENTS_IMPORT_BRIEFS,
  ROUTE_ANALYST_ELEMENTS_CEFT,
  ROUTE_ANALYST_ELEMENTS_UNTYPED,
  ROUTE_ADMIN_EXPORT_ANALYST_STATUS,
  ROUTE_ADMIN_EXPORT,
  ROUTE_ANALYST_SOURCES_ARTICLES,
  ROUTE_ANALYST_SOURCES_REGULATIONS,
  ROUTE_ANALYST_SOURCES_REGULATION_ARTICLES,
  ROUTE_ANALYST_FEED_ARTICLES,
  ROUTE_ANALYST_FEED_REGULATION_ARTICLES,
  ROUTE_ANALYST_FEED_REVIEW,
  ROUTE_ANALYST_FEED_CREATE_MANUAL,
  ROUTE_ANALYST_FEED_CREATE,
  ROUTE_ANALYST_REGULATIONS_FEED,
  ROUTE_ANALYST_REGULATIONS_REVIEW,
  ROUTE_ANALYST_REGULATIONS_CREATE,
  ROUTE_ANALYST_REGULATIONS_CREATE_MANUAL,
  ROUTE_ANALYST_EVENTS_ALL,
  ROUTE_ANALYST_EVENT_EDITOR,
  ROUTE_ANALYST_REGULATIONS,
  ROUTE_ANALYST_REGULATION_EDITOR,
  ROUTE_AUTH,
  ROUTE_ANALYST_COUNTRIES_ALL,
  ROUTE_ANALYST_LANDSCAPES_ALL,
} from '../../constants';
import authSelectors from '../../selectors/users';
import {
  ROLE_ADMIN,
  ROLE_ANALYST,
} from '../Enums/Roles';

const basicAccess = [
  ROUTE_ANALYST,
  ROUTE_ANALYST_ELEMENTS_STATENTITY,
  ROUTE_ANALYST_BRIEFS_EDITOR,
  ROUTE_ANALYST_ELEMENTS,
  ROUTE_ANALYST_ELEMENTS_BRIEFS,
  ROUTE_ANALYST_ENTITIES,
  ROUTE_ANALYST_ENTITIES_ACTORS,
  ROUTE_ANALYST_ENTITIES_ALL,
  ROUTE_ANALYST_ENTITIES_CONTEXTS,
  ROUTE_ANALYST_ENTITIES_EDITOR,
  ROUTE_ANALYST_ENTITIES_PLOTS,
  ROUTE_ANALYST_ELEMENTS_CEFS,
  ROUTE_ANALYST_ELEMENTS_IMPORT_STATS,
  ROUTE_ANALYST_ELEMENTS_IMPORT_ENTITIES,
  ROUTE_ANALYST_ELEMENTS_IMPORT_CHALLENGES,
  ROUTE_ANALYST_ELEMENTS_IMPORT_BRIEFS,
  ROUTE_ANALYST_ELEMENTS_CEFT,
  ROUTE_ANALYST_ELEMENTS_UNTYPED,
  ROUTE_ANALYST_SOURCES_ARTICLES,
  ROUTE_ANALYST_SOURCES_REGULATIONS,
  ROUTE_ANALYST_SOURCES_REGULATION_ARTICLES,
  ROUTE_ANALYST_FEED_ARTICLES,
  ROUTE_ANALYST_FEED_REGULATION_ARTICLES,
  ROUTE_ANALYST_FEED_REVIEW,
  ROUTE_ANALYST_FEED_CREATE,
  ROUTE_ANALYST_FEED_CREATE_MANUAL,
  ROUTE_ANALYST_REGULATIONS_FEED,
  ROUTE_ANALYST_REGULATIONS_REVIEW,
  ROUTE_ANALYST_REGULATIONS_CREATE,
  ROUTE_ANALYST_REGULATIONS_CREATE_MANUAL,
  ROUTE_ANALYST_EVENTS_ALL,
  ROUTE_ANALYST_EVENT_EDITOR,
  ROUTE_ANALYST_REGULATIONS,
  ROUTE_ANALYST_REGULATION_EDITOR,
  ROUTE_ADMIN_ASSIGN_FACETS,
  ROUTE_ANALYST_COUNTRIES_ALL,
  ROUTE_ANALYST_LANDSCAPES_ALL,
];

const roleRoutes = {
  [ROLE_ADMIN]: {
    default: ROUTE_ADMIN,
    routes: [
      ROUTE_ADMIN_EXPORT_ANALYST_STATUS,
      ROUTE_ADMIN_EXPORT,
      ROUTE_ADMIN,
      ROUTE_ADMIN_BRIEFS_EDITOR,
      ROUTE_ADMIN_ELEMENTS,
      ROUTE_ADMIN_ELEMENTS_BRIEFS,
      ROUTE_ADMIN_ELEMENTS_UNTYPED,
      ROUTE_ADMIN_ELEMENTS_DICTIONARIES,
      ROUTE_ADMIN_ELEMENTS_DICTIONARIES_EDITOR,
      ROUTE_ADMIN_ELEMENTS_CEFS,
      ROUTE_ADMIN_ENTITIES,
      ROUTE_ADMIN_ENTITIES_EDITOR,
      ROUTE_ADMIN_ENTITIES_FACET_EDITOR,
      ROUTE_ADMIN_USERS,
      ...basicAccess,
    ],
  },
  [ROLE_ANALYST]: {
    default: ROUTE_ANALYST_ENTITIES,
    routes: basicAccess,
  },
};

/**
 * @param user {string || object} - user role, or user object
 * @param path {string} - route path
 * */
const checkRoleForRoute = (user, path) => {
  const role = typeof user === 'string' ? user : authSelectors.getRole(user);
  if (roleRoutes[role] && roleRoutes[role].routes.includes(path)) {
    return { status: true, path };
  }
  if (roleRoutes[role] && roleRoutes[role].default) {
    return { status: false, path: roleRoutes[role] ? roleRoutes[role].default : '' };
  }
  return { status: false, path: ROUTE_AUTH };
};

export default checkRoleForRoute;
