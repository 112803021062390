import React from 'react';
import moment from 'moment';
import {
  Select, Collapse, Row, Col,
} from 'antd';
import PropertyType from './PropertyType';
import withRemoveButton from './HOC/withButtonRemove';

import Info from './common/Info';
import Description from './common/Description';
import DatePickerWrapper from './common/DatePickerWrapper';
import ElementState from '../ElementState';
import useDebounceSave from './useDebounceSave';

const { Option } = Select;
const { Panel } = Collapse;
const selectStyles = {
  width: '100%',
};
const ranges = {
  RANGE: 'range',
  DATE_SINGLE: 'single',
  DATE_START: 'start',
  DATE_END: 'end',
};
const rangeModes = {
  YYYY: 'year',
  'MM/YYYY': 'month',
  'MM/DD/YYYY': 'date',
};

function Date(props) {
  const {
    fieldType: {
      rules: {
        Field: fieldTitle,
      },
      name: fieldName,
    },
    fieldSet,
    actions,
    options,
    readonly,
  } = props;

  const {
    fields: {
      dateEnd,
      dateSingle,
      dateStart,
      dateType,
      format,
      selectedLabel,
      selectedValue,
    },
    rules: {
      'Event-Type': type,
      Label: label,
      Info: info,
      Format: formatTemplate,
      Date: dateTemplate,
      Description: description,
    },
  } = fieldSet;

  const rangeMode = rangeModes[format.value] || ['date', 'date'];

  const debounceSave = useDebounceSave({ saveField: actions.saveField, fieldSet });

  const onChangeField = (value, name) => {
    const data = { [name]: value };
    if (name === 'dateType') {
      if (value === 'start') {
        data.dateEnd = null;
        data.dateSingle = null;
      }
      if (value === 'end') {
        data.dateStart = null;
        data.dateSingle = null;
      }
      if (value === 'single') {
        data.dateStart = null;
        data.dateEnd = null;
      }
      if (value === 'range') {
        data.dateSingle = null;
      }
    }
    const keys = Object.keys(data);
    keys.forEach((key) => {
      if (fieldSet.fields[key].value !== data[key]) {
        actions.saveField({
          ...fieldSet.fields[key],
          value: data[key],
        });
      }
    });
    actions.changeFieldSet(data);
  };

  let valueDate = null;
  let titleDate = '';
  let dateId = null;

  const dateTypes = {
    [ranges.DATE_START]: () => {
      valueDate = dateStart.value;
      titleDate = 'Start Date';
      dateId = dateStart.name;
    },
    [ranges.DATE_END]: () => {
      valueDate = dateEnd.value;
      titleDate = 'End Date';
      dateId = dateEnd.name;
    },
    [ranges.DATE_SINGLE]: () => {
      valueDate = dateSingle.value;
      titleDate = 'Single Date';
      dateId = dateSingle.name;
    },
  };

  if (dateTypes[dateType.value]) {
    dateTypes[dateType.value]();
  }

  return (
    <div className="facet-type-field">
      <Collapse bordered={false} defaultActiveKey={!fieldSet.filled ? fieldSet.key : null}>
        <Panel
          key={fieldSet.key}
          header={fieldTitle || fieldName}
        >
          <Row type="flex" justify="space-between">
            <div className="label-text">
              {label}
              <ElementState saved={selectedValue.id} />
            </div>
            <Info info={info} />
          </Row>

          <Row type="flex" justify="start" gutter={16}>
            <Col>
              <span className="label-text">Date Type</span>
              <Select
                dropdownClassName="dropdown-zindex"
                disabled={dateTemplate !== 'select' || readonly}
                getPopupContainer={() => document.getElementById('entity-editor-id')}
                onChange={(value) => onChangeField(value, dateType.name)}
                style={selectStyles}
                value={dateType.value}
              >
                <Option value="range">range</Option>
                <Option value="start">start</Option>
                <Option value="end">end</Option>
                <Option value="single">single</Option>
              </Select>
            </Col>

            <Col>
              <span className="label-text">Date Format</span>
              <Select
                dropdownClassName="dropdown-zindex"
                disabled={formatTemplate !== 'select' || readonly}
                getPopupContainer={() => document.getElementById('entity-editor-id')}
                onChange={(value) => onChangeField(value, format.name)}
                style={selectStyles}
                value={format.value}
              >
                <Option value="MM/DD/YYYY">mm/dd/yyyy</Option>
                <Option value="MM/YYYY">mm/yyyy</Option>
                <Option value="YYYY">yyyy</Option>
              </Select>
            </Col>

            <Col>
              {
                dateType.value === ranges.RANGE
                  ? (
                    <>
                      <span className="label-text">Date Range</span>
                      <Row type="flex" justify="start" gutter={16}>
                        <Col>
                          <DatePickerWrapper
                            mode={rangeMode}
                            placeholder={format.value}
                            format={format.value}
                            dateId={dateStart.name}
                            onChangeField={onChangeField}
                            valueDate={
                              dateStart.value && moment(dateStart.value).isValid()
                                ? moment(dateStart.value)
                                : null
                            }
                            disabled={readonly}
                          />
                        </Col>
                        <Col>
                          <DatePickerWrapper
                            mode={rangeMode}
                            placeholder={format.value}
                            format={format.value}
                            dateId={dateEnd.name}
                            onChangeField={onChangeField}
                            valueDate={
                              dateEnd.value && moment(dateEnd.value).isValid()
                                ? moment(dateEnd.value)
                                : null
                            }
                            disabled={readonly}
                          />
                        </Col>
                      </Row>
                    </>
                  )
                  : (
                    <>
                      <div className="label-text">{titleDate || 'Select Date'}</div>
                      <DatePickerWrapper
                        mode={rangeMode}
                        placeholder={format.value}
                        format={format.value}
                        dateId={dateId}
                        onChangeField={onChangeField}
                        valueDate={
                          moment(valueDate).isValid()
                            ? moment(valueDate)
                            : null
                        }
                        disabled={readonly}
                      />
                    </>
                  )
              }
            </Col>
          </Row>

          <Description
            value={description}
            entityKey={options.entityKey}
          />

          {
            type.type && type.type !== 'off'
            && (
              <div className="column mrg-top-10" style={{ width: 250 }}>
                <span className="label-text">Event Type</span>
                <PropertyType
                  rules={{ type }}
                  onChange={(fields) => {
                    debounceSave(
                      fields,
                      () => actions.changeFieldSet(fields),
                    );
                  }}
                  fieldSelectedId={selectedValue}
                  fieldValue={selectedLabel}
                  readonly={readonly}
                />
              </div>
            )
          }
        </Panel>
      </Collapse>
    </div>
  );
}

export default withRemoveButton(Date);
