import { createSelector } from 'reselect';
import {
  UNASSIGNED,
  EDIT,
  REVIEW,
  ANALYST,
  IN_PROGRESS,
  PUBLISHED,
  EXPIRED,
  REJECTED,
  NO_INFORMATION,
} from '../common/Enums/Stages';
import {
  ROLE_ANALYST,
  ROLE_ADMIN,
} from '../common/Enums/Roles';

const getFacetStatuses = createSelector((role) => role, (role) => {
  if (role === ROLE_ANALYST) {
    return [
      UNASSIGNED,
      ANALYST,
      REVIEW,
      NO_INFORMATION,
      EDIT,
      PUBLISHED,
      REJECTED,
    ];
  }
  if (role === ROLE_ADMIN) {
    return [
      UNASSIGNED,
      ANALYST,
      REVIEW,
      NO_INFORMATION,
      EDIT,
      PUBLISHED,
      EXPIRED,
      REJECTED,
    ];
  }
  return [
    UNASSIGNED,
    ANALYST,
    REVIEW,
    NO_INFORMATION,
    EDIT,
    PUBLISHED,
    EXPIRED,
    REJECTED,
  ];
});

const getEntityStatuses = createSelector(
  (role) => role,
  () => [
    IN_PROGRESS,
    PUBLISHED,
  ],
);

export default {
  getFacetStatuses,
  getEntityStatuses,
};
