/* eslint-disable no-param-reassign */
import React, { useRef, useEffect } from 'react';
import get from 'lodash/get';
import icon from '../../img/marker.png';
import styles from './Map.module.css';

const DEFAULT_ZOOM = 1;

const getLatLng = (location) => {
  const lat = (location && location.lat) || 0;
  const lng = (location && location.lng) || 0;
  return new window.google.maps.LatLng(lat, lng);
};

const setMarker = (store, latLng, onMarkerPositionChange) => {
  if (store.marker) {
    store.marker.setPosition(latLng);
  } else {
    store.marker = new window.google.maps.Marker({
      position: latLng,
      map: store.map,
      icon,
      draggable: true,
    });
    window.google.maps.event.addListener(store.marker, 'dragend', onMarkerPositionChange);
  }
  store.map.panTo(latLng);
};

const Map = ({
  setLocation,
  location,
  storeZoom,
}) => {
  const locationText = get(location, 'address', '');
  const { current: store } = useRef({
    map: null,
    marker: null,
    geocoder: new window.google.maps.Geocoder(),
    address: locationText,
  });
  const ref = useRef(null);

  const onMarkerPositionChange = (event) => {
    store.geocoder.geocode(
      { latLng: event.latLng },
      (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            setLocation({
              address: results[0].formatted_address,
              lat: event.latLng.lat(),
              lng: event.latLng.lng(),
            });
          }
        }
      },
    );
  };

  useEffect(() => {
    if (!store.map) {
      const latLng = getLatLng(location);
      store.map = new window.google.maps.Map(ref.current, {
        center: latLng,
        zoom: (location && location.zoom) || DEFAULT_ZOOM,
      });
      store.map.addListener('click', (event) => {
        store.map.setCenter(event.latLng);
        store.geocoder.geocode(
          { latLng: event.latLng },
          (results, status) => {
            if (status === window.google.maps.GeocoderStatus.OK) {
              if (results[0]) {
                setLocation({
                  address: results[0].formatted_address,
                  lat: event.latLng.lat(),
                  lng: event.latLng.lng(),
                });
              }
            }
          },
        );
        setMarker(store, event.latLng, onMarkerPositionChange);
      });
      store.map.addListener('zoom_changed', () => {
        storeZoom.zoom = store.map.getZoom();
      });
    }
    if (store.marker) {
      store.marker.setMap(null);
      store.marker = null;
    }
    if (!store.marker && location) {
      const latLng = getLatLng(location);
      setMarker(store, latLng, onMarkerPositionChange);
    }
  }, []);

  useEffect(() => {
    if (locationText && locationText !== store.address) {
      const latLng = getLatLng(location);
      setMarker(store, latLng, onMarkerPositionChange);
    }
  }, [locationText]);

  return (
    <div ref={ref} className={styles.map} />
  );
};

export default Map;
