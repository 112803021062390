import React, {
  useState, useEffect, useRef, memo, useCallback,
} from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import debounce from 'lodash/debounce';
import uniqBy from 'lodash/uniqBy';
import styled from 'styled-components';
import ReactDataGrid from 'react-data-grid';

import {
  Row, Col, Spin, message, Pagination, Button, Select,
} from 'antd';
import {
  EDIT,
  REVIEW,
  ANALYST,
  IN_PROGRESS,
  UNASSIGNED,
  PUBLISHED,
  EXPIRED,
  REJECTED,
  NO_INFORMATION,
} from '../../common/Enums/Stages';

import { PAGE_SIZE, ROW_HEIGHT } from './utils';
import helpers from './helpers';
import columns from './components/columns';

import usersSelector from '../../selectors/users';
import './styles.css';

const {
  DraggableHeader: { DraggableContainer },
} = require('react-data-grid-addons');

const CONTROLS_ROW_ID = 'CONTROLS_ROW_ID';

const stagesWithStartCase = [
  EDIT,
  REVIEW,
  NO_INFORMATION,
  ANALYST,
  IN_PROGRESS,
  UNASSIGNED,
  PUBLISHED,
  EXPIRED,
  REJECTED,
].map((e) => startCase(e));

const sortRows = (sortColumn, sortDirection, setState) => {
  let orderField = sortColumn;

  if (sortColumn === 'name') {
    orderField = 'entityName';
  }
  if (sortColumn === 'facet') {
    orderField = 'typeName';
  }
  if (sortColumn === 'assignedToId') {
    orderField = 'assignedToName';
  }

  setState((prev) => ({
    ...prev,
    search: {
      ...prev.search,
      orderField,
      orderDirection: sortDirection,
    },
  }));
};

const setFilters = (filter, setState, getUserByName) => {
  let value = get(filter, 'filterTerm.value') || get(filter, 'filterTerm');

  if (filter.column.key === 'assignedToId' || filter.column.key === 'editors') {
    value = get(getUserByName(get(filter, 'filterTerm.value')), 'id', '');
  }

  if (filter.column.key === 'stage') {
    value = get(filter, 'filterTerm.value', '').toLowerCase();
  }

  if (['updatedAt', 'createdAt'].includes(filter.column.key)) {
    value = moment(get(filter, 'filterTerm', '').replace(/\D+/g, ''), 'MMDDYYYY').valueOf();
    if (!value) {
      value = undefined;
    }
  }

  setState((prev) => ({
    ...prev,
    page: 1,
    search: {
      ...prev.search,
      [filter.column.key]: value,
    },
  }));
};

const changePage = (page, state, setState) => {
  setState((prev) => ({ ...prev, page }));
};

const updateRow = (props, state, setState) => {
  const { rowIds, updated, cellKey } = props;
  const update = [];
  const data = [];
  const rows = state.facets.map((row) => {
    if (rowIds.includes(row.id)) {
      const newRow = { ...row };
      if (cellKey === 'stage') {
        const stage = (updated.stage || '').toLowerCase() || null;
        newRow.stage = stage;
        newRow.meta.stage = stage;
        const users = usersSelector.getUsersByStatus(state.users, stage);
        if (!users.find((user) => user.id === row.assignedToId)) {
          newRow.assignedToId = null;
          newRow.meta.assignedToId = null;
        }
      }
      if (cellKey === 'assignedToId') {
        newRow.assignedToId = updated.assignedToId;
        newRow.meta.assignedToId = updated.assignedToId;
        const stage = (row.stage || '').toLowerCase() || null;
        const users = usersSelector.getUsersByStatus(state.users, stage);
        if (!users.find((user) => user.id === updated.assignedToId)) {
          newRow.assignedToId = row.assignedToId;
          newRow.meta.assignedToId = row.assignedToId;
        }
      }
      if (cellKey === 'label' || cellKey === 'description' || cellKey === 'sources') {
        Object.assign(newRow, updated);
        data.push(newRow);
      }
      update.push(newRow.meta);
      return newRow;
    }
    return row;
  });
  const metaToUpdate = uniqBy(
    [...update, ...state.metaToUpdate],
    (meta) => meta.id,
  );
  const dataToUpdate = uniqBy(
    [...data, ...state.dataToUpdate],
    (row) => row.id,
  );
  setState((prev) => ({
    ...prev, facets: rows, metaToUpdate, dataToUpdate,
  }));
};

const updateMeta = (meta, setState, data) => {
  helpers
    .updateMeta(meta)
    .then(() => helpers.saveFields(data))
    .then(() => {
      message.success('Saved');
      setState((prev) => ({ ...prev, metaToUpdate: [], loading: false }));
    })
    .catch((err) => {
      console.error(err);
      const errMessage = get(err, 'response.data.message', 'Cannot save changes.');
      message.error(errMessage);
      setState((prev) => ({ ...prev, loading: false }));
    });
};

const getValidFilterValues = (columnKey, state) => {
  if (columnKey === 'stage') {
    return stagesWithStartCase;
  }
  if (columnKey === 'assignedToId' || columnKey === 'editors') {
    return state.users.map((user) => user.name);
  }
  if (columnKey === 'facet') {
    return state.nameList;
  }
  if (columnKey === 'level') {
    return state.levelList;
  }
  if (columnKey === 'section') {
    return state.sectionList;
  }
  return [];
};

const FacetsAssign = ({ user, role, users }) => {
  const refRDG = useRef();
  const [columnsFormatted, setColumns] = useState(columns);
  const [state, setState] = useState({
    loading: true,
    facets: [],
    topics: [],
    activeTopic: null,
    users,
    page: 1,
    total: 0,
    sectionList: [],
    nameList: [],
    levelList: [],
    metaToUpdate: [],
    dataToUpdate: [],
    limit: PAGE_SIZE,
    search: {
      type: '',
      name: '',
      section: '',
      editors: '',
      facet: '',
      level: '',
      stage: '',
      assignedToId: '',
      orderField: '',
      orderDirection: '',
    },
  });
  const setStateOld = useCallback((obj) => setState((prev) => ({ ...prev, ...obj })), [setState]);
  const { current: debounceLoadFacets } = useRef(
    debounce((page, search, limit) => {
      setState((prev) => ({ ...prev, loading: true }));

      helpers
        .getFacets(page, search, limit)
        .then((data) => setState((prev) => ({
          ...prev,
          facets: data.data,
          total: data.total,
          page: data.page,
          loading: false,
          sectionList: data.sectionList,
          nameList: data.nameList,
          levelList: data.levelList,
        })))
        .then(() => {
          if (refRDG.current && !refRDG.current.firstToggle) {
            refRDG.current.onToggleFilter();
            refRDG.current.firstToggle = true;
          }
        })
        .catch((err) => {
          console.error(err);
          message.error('Cannot load facets');
          setState((prev) => ({ ...prev, loading: false }));
        });
    }, 500),
  );
  const debouncedFilters = useCallback(
    debounce(
      (filter) => setFilters(filter, setState, (userName) => usersSelector.getUserByName(userName, state.users)),
      500,
    ),
    [state, setState],
  );
  useEffect(() => {
    helpers.getTopics('', setState);
  }, [setState]);

  useEffect(() => {
    debounceLoadFacets(state.page, state.search, state.limit);
  }, [state.search, state.page, state.limit]);

  const onHeaderDrop = (source, target) => {
    const columnsCopy = columns.slice();
    const columnSourceIndex = columns.findIndex((i) => i.key === source);
    const columnTargetIndex = columns.findIndex((i) => i.key === target);

    columnsCopy.splice(columnTargetIndex, 0, columnsCopy.splice(columnSourceIndex, 1)[0]);
    setColumns([]);
    setColumns(columnsCopy);
  };

  const setTopic = useCallback((e) => {
    setState((prev) => ({ ...prev, page: 1, search: { ...prev.search, topic: e } }));
  }, [setState]);

  const debouncedSearchTopic = useCallback(debounce((name) => helpers.getTopics(name, setState), 300), [setState]);
  return (
    <Spin spinning={state.loading}>
      <MarginBottomRowWrapper type="flex" justify="space-between">
        <Col>
          <Pagination
            onChange={(page) => changePage(page, state, setState)}
            current={state.page}
            total={Math.ceil(state.total / state.limit) * 10}
            pageSize={state.limit}
            showSizeChanger
            pageSizeOptions={[25, 50, 100, 250, 500, 1000, state.total]}
            onShowSizeChange={(current, size) => setStateOld({ limit: size })}
          />
        </Col>
        <Col>
          <Row id={CONTROLS_ROW_ID}>
            <span>Topic</span>
            <Select
              allowClear
              showSearch
              value={state.search.topic}
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={debouncedSearchTopic}
              notFoundContent={null}
              style={{ width: 220, marginLeft: 20, marginRight: 20 }}
              onChange={setTopic}
              dropdownStyle={{ zIndex: 1 }}
              getPopupContainer={() => document.getElementById(CONTROLS_ROW_ID)}
              data-testid="facetTopicSelect"
            >
              <Select.Option key="none" value="none">None</Select.Option>
              {state.topics.map((e) => <Select.Option key={e.id} value={e.id}>{e.name}</Select.Option>)}
            </Select>
            <Button
              disabled
              title="Coming soon"
              type="default"
              onClick={() => helpers.exportCSV()}
            >
              Export CSV
            </Button>
            <Button
              disabled={state.metaToUpdate.length === 0}
              type="primary"
              onClick={() => updateMeta(state.metaToUpdate, setState, state.dataToUpdate)}
            >
              Save Changes
            </Button>
          </Row>
        </Col>
      </MarginBottomRowWrapper>

      <Row className="FacetAssignTable__RDG">
        <DraggableContainer onHeaderDrop={onHeaderDrop}>
          <ReactDataGrid
            ref={refRDG}
            columns={columnsFormatted}
            rowGetter={(index) => ({
              ...state.facets[index],
              columnsActions: {
                getUserById: (userId) => usersSelector.getUserById(userId, state.users),
                getUsers: () => state.users,
                getRole: () => role,
                getUser: () => user,
              },
            })}
            rowsCount={state.facets.length}
            minHeight={ROW_HEIGHT * (state.limit + 2)}
            enableCellSelect
            onGridRowsUpdated={(props) => updateRow(props, state, setState)}
            onGridSort={(cell, direction) => sortRows(cell, direction, setState)}
            onAddFilter={debouncedFilters}
            getValidFilterValues={(columnKey) => getValidFilterValues(columnKey, state)}
          />
        </DraggableContainer>
      </Row>

      <MarginTopRowWrapper type="flex" justify="end">
        <Pagination
          onChange={(page) => changePage(page, state, setState)}
          current={state.page}
          total={Math.ceil(state.total / state.limit) * 10}
          pageSize={state.limit}
          showSizeChanger
          pageSizeOptions={[25, 50, 100, 250, 500, 1000, state.total]}
          onShowSizeChange={(current, size) => setStateOld({ limit: size })}
        />
      </MarginTopRowWrapper>
    </Spin>
  );
};

const mapStateToProps = (state) => {
  const user = usersSelector.getUser(state);
  return {
    user,
    users: usersSelector.getUsers(state),
    role: usersSelector.getRole(user) || '',
  };
};

export default connect(mapStateToProps)(memo(FacetsAssign));

const MarginTopRowWrapper = styled(Row)`
  margin-top: 20px;
`;

const MarginBottomRowWrapper = styled(Row)`
  margin-bottom: 20px;
`;
