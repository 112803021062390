import get from 'lodash/get';
import { RELATIONSHIP } from '../../../common/Enums/fieldTypeNames';
import isLinkSetFilled from './isLinkSetFilled';

export const getFieldCreateStatus = (wrapper) => {
  const { fieldType, fieldSets } = wrapper;
  return fieldType.required ? !!fieldSets.length : true;
};

const isFacetFilled = (wrappers) => {
  const filled = {
    filledRequired: true,
    filledAll: true,
    emptyRequired: false,
  };
  wrappers.forEach((wrapper) => {
    if (filled.emptyRequired) {
      return;
    }
    const created = getFieldCreateStatus(wrapper);
    if (!created) {
      filled.emptyRequired = true;
      filled.filledAll = false;
      filled.filledRequired = false;
      return;
    }
    if (wrapper.fieldType.name === RELATIONSHIP) {
      wrapper.fieldSets.forEach((linkSet) => {
        if (filled.filledRequired && wrapper.fieldType.required && !isLinkSetFilled(linkSet)) {
          filled.filledRequired = false;
        }
        if (filled.filledAll && !isLinkSetFilled(linkSet)) {
          filled.filledAll = false;
        }
      });
    } else {
      wrapper.fieldSets.forEach((fieldSet) => {
        if (filled.filledRequired && fieldSet.type.required && !fieldSet.filled) {
          filled.filledRequired = false;
        }
        if (filled.filledAll && !fieldSet.filled) {
          filled.filledAll = false;
        }
      });
    }
  });
  delete filled.emptyRequired;
  return filled;
};

export const isDataFilled = (facet) => {
  const data = facet.crossEntityFacets || [];
  const dataIsFilled = !!data.length && !data.some((d) => !d.date || (!d.value && d.value !== 0));
  let dataIsRequired = false;
  const ceftIsFilled = !!get(facet, 'crossEntityFacetType.id');
  const statisticFactFieldType = facet.type.fields.find((f) => f.fieldType === 'Statistic');
  if (statisticFactFieldType) {
    dataIsRequired = statisticFactFieldType.required;
  }
  const filledRequired = dataIsRequired ? dataIsFilled && ceftIsFilled : ceftIsFilled;
  const filledAll = data.length ? dataIsFilled && ceftIsFilled : ceftIsFilled;
  return {
    filledRequired,
    filledAll,
  };
};

export const STATUSES = {
  BLUE: 'blue',
  BLUE_EMPTY: 'blue_empty',
  RED: 'red',
};

export const getFillStatus = (facet) => {
  // true && false
  if (facet.type.required && !facet.filledRequired) {
    return STATUSES.RED;
  }
  // any && true
  if (facet.filledRequired) {
    return STATUSES.BLUE;
  }
  // false && false // !facet.type.required && !facet.filledRequired
  return STATUSES.BLUE_EMPTY;
};

export default isFacetFilled;
