import moment from 'moment';
import get from 'lodash/get';
import uniq from 'lodash/uniq';

export const PAGE_SIZE = 50;
export const DATE_FORMAT = 'MM/DD/YYYY H:mm';

export const getQueryParams = ({ filter, sort, page }) => {
  const params = {
    $limit: PAGE_SIZE,
    $skip: PAGE_SIZE * (page - 1),
  };

  if (filter.name) {
    params['name[$iLike]'] = `%${filter.name}%`;
  }

  if (filter.email) {
    params['email[$iLike]'] = `%${filter.email}%`;
  }

  if (filter.inviter) {
    params['inviter[$iLike]'] = `%${filter.inviter}%`;
  }

  if (sort.field && sort.order) {
    if (sort.field === 'role') {
      params['$sort[role]'] = sort.order;
    } else {
      params[`$sort[${sort.field}]`] = sort.order;
    }
  }
  return params;
};

export const processUsers = (response) => {
  const { total, data } = response.data;
  const users = data.map((user) => ({
    ...user,
    createdAt: moment(user.createdAt).format(DATE_FORMAT),
    updatedAt: moment(user.updatedAt).format(DATE_FORMAT),
  }));
  return { total, users };
};

export const getUniqueDataTopics = ({ data, topics }) => {
  const dataTopics = data
    .map((topicId) => {
      const topic = topics.find((t) => t.id === topicId);
      return get(topic, 'name', null);
    })
    .filter((topicId) => topicId);
  return uniq(dataTopics);
};
