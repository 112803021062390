/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */

import React, { useEffect, useState } from 'react';
import get from 'lodash';
import qs from 'query-string-parser';
import {
  Input,
  Button,
  Form,
  Modal,
  Row,
  Col,
  message as showMessage,
} from 'antd';

import { getUserInfo, verifyUser } from './helper';
import * as strings from '../../common/Strings';
import { ROLE_ANALYST } from '../../common/Enums/Roles';
import './index.css';
import { getAxisAppLocation } from '../../config/config';

const FormItem = Form.Item;

const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14, offset: 5 },
};

const SignUp = (props) => {
  const [state, setState] = useState({
    email: '',
    name: '',
    password: '',
    repassword: '',
    role: ROLE_ANALYST,
    confirmDirty: false,
  });

  const [errors, setErrors] = useState({});

  const {
    email,
    name,
    role,
    password,
    repassword,
    verifyToken,
  } = state;

  let params = null;

  useEffect(() => {
    params = qs.fromQuery(props.location.search);
    if (params && params.verifyToken) {
      getUserInfo(params.verifyToken).then((res) => {
        const { data } = res;
        setState({
          ...state,
          email: data.email,
          verifyToken: params.verifyToken,
          name: data.name || '',
          role: data.role || '',
        });
      }).catch((err) => {
        const msg = get(err, 'response.data.message', err.message);
        showMessage.error(msg);
      });
    }
  }, []);

  const handleInputChange = (EO, fieldName) => {
    const {
      target: { value },
    } = EO;
    setState({
      ...state,
      [fieldName]: value,
    });
  };

  const verify = () => {
    const verificationErrors = {};
    if (!name) {
      verificationErrors.name = strings.signupErrorNoName;
    }
    if (!password) {
      verificationErrors.password = strings.signupErrorNoPassword;
    }

    if (!repassword) {
      verificationErrors.repassword = strings.signupErrorNoPassword;
    }

    if (password && repassword && password !== repassword) {
      verificationErrors.repassword = strings.signupErrorDiffPassword;
      verificationErrors.password = strings.signupErrorDiffPassword;
    }

    setErrors({ ...verificationErrors });
    if (Object.keys(verificationErrors).length > 0) {
      return null;
    }
    verifyUser({
      name,
      password,
      verifyToken,
    }).then(() => {
      showMessage.success(strings.seUserCreatedSuccess);
      setTimeout(() => {
        if (!['user', 'trial'].includes(role)) {
          window.location.assign('/auth');
        } else {
          const url = getAxisAppLocation();
          window.location.assign(url);
        }
      }, 200);
    }).catch((err) => {
      const msg = get(err, 'response.data.message', err.message);
      showMessage.error(msg);
    });
    return null;
  };

  return (
    <Modal
      style={{ top: '45%', marginTop: -160 }}
      visible
      footer={null}
      closable={false}
    >
      <Form
        className="login-form"
        style={{ width: '100%', margin: '20px auto 0' }}
        autocomplete="off"
      >
        <Row type="flex" justify="start">
          <Col span={24}>
            <label htmlFor="email">Email</label>
            <Form.Item style={{ marginBottom: 0 }}>
              <Input name="email" disabled value={email} />
            </Form.Item>
          </Col>
        </Row>

        <Row type="flex" justify="start">
          <Col span={24}>
            <label htmlFor="name">Name</label>
            <Form.Item
              hasFeedback={!!errors.name}
              validateStatus={errors.name ? 'error' : null}
              help={errors.name}
              style={{ marginBottom: 0 }}
            >
              <Input
                name="name"
                maxLength={254}
                onChange={(EO) => handleInputChange(EO, 'name')}
                value={state.name}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row type="flex" justify="start">
          <Col span={24}>
            <label htmlFor="password">Password</label>
            <Form.Item
              hasFeedback={!!errors.password}
              validateStatus={errors.password ? 'error' : null}
              help={errors.password}
              style={{ marginBottom: 0 }}
            >
              <Input
                name="password"
                maxLength={254}
                type="password"
                onChange={(EO) => handleInputChange(EO, 'password')}
                value={password}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row type="flex" justify="start">
          <Col span={24}>
            <label htmlFor="repassword">Repeat Password</label>
            <Form.Item
              hasFeedback={!!errors.repassword}
              validateStatus={errors.repassword ? 'error' : null}
              help={errors.repassword}
              style={{ marginBottom: 0 }}
            >
              <Input
                name="repassword"
                type="password"
                maxLength={254}
                onChange={(EO) => handleInputChange(EO, 'repassword')}
                value={repassword}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row type="flex" justify="start">
          <Col span={24}>
            <label htmlFor="role">Role</label>
            <Form.Item style={{ marginBottom: 0 }}>
              <span style={{ fontWeight: 'bold' }}>
                {role}
              </span>
            </Form.Item>
          </Col>
        </Row>

        <FormItem {...formTailLayout}>
          <Button type="primary" onClick={verify} block>
            Sign Up
          </Button>
        </FormItem>
      </Form>
    </Modal>
  );
};

export default SignUp;
