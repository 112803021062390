import { createSelector } from 'reselect';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import usersSelector from './users';
import store from '../store';
import {
  REQUIRED,
  ASSIGNED_TO_ME,
  ASSIGNED_REQUIRED,
} from '../containers/EntityEditor/constants';
import { ENTITY_COLUMNS } from '../common/Enums/Columns';

const getEntity = (state) => (state.entityEditor.entities ? state.entityEditor.entities[0] : null);
const getEntities = (state) => state.entityEditor.entities;
const getFacets = (state) => state.entityEditor.facets;
const getFacetCount = (state, entityKey, facetTypeId) => {
  const entity = state.entityEditor.entities.find((ent) => ent.key === entityKey);
  if (entity) {
    return entity.facetInspector[facetTypeId].count || 0;
  }
  return 0;
};
const getFilled = (state) => state.entityEditor.filled;
const getFilteredFacets = (state) => state.entityEditor.filteredFacets;
const getFilter = (state) => state.entityEditor.filter;
const getFilterByUser = (state) => state.entityEditor.filterByUser;
const getIsLoading = (state) => state.entityEditor.isLoading;
const getMapping = (state) => state.entityEditor.mapping;
const getMeta = (state) => state.entityEditor.meta;
const getName = (state) => state.entityEditor.name;
const getPage = (state) => state.entityEditor.page;
const getTotal = (state) => state.entityEditor.total;
const getViewMode = (state) => state.entityEditor.viewMode;
const getTemplate = (state) => state.entityEditor.template;
const getEntityEditorState = (state) => state.entityEditor.entityEditorState;
const getTemplateProperty = (state, property) => get(state.entityEditor.template, property, null);
const getDictionaryByKey = (property) => get(store.getState().entityEditor.dictionaries, property, null);
const getEntityNameByKey = (state, property) => {
  const result = state.entityEditor.entities.find((entity) => entity.key === property);
  if (result) {
    return result.name;
  }
  return null;
};
const getEntityIdByKey = (state, property) => {
  const result = state.entityEditor.entities.find((entity) => entity.key === property);
  if (result) {
    return result.id;
  }
  return null;
};

const getFacetIdByKey = (state, entityKey, sectionKey, facetKey) => {
  const entity = state.entityEditor.entities.find((e) => e.key === entityKey);
  if (!entity) {
    return null;
  }
  const section = entity.sections.find((s) => s.key === sectionKey);
  if (!section) {
    return null;
  }
  const facet = section.facets.find((f) => f.key === facetKey);
  return facet ? facet.id : null;
};

// This selectors for EntityEditor
const getEntityHeader = createSelector(
  getEntity,
  (entity) => {
    const type = get(entity, 'template.type', '');
    const subType = get(entity, 'template.subType', '');

    return {
      name: get(entity, 'name', ''),
      label: `${type}${subType ? ' : ' : ''}${subType}`,
    };
  },
);

const getUserProperty = (state) => get(state.auth.user, 'id', null);

const getFilteredEntity = createSelector(
  [getEntity, getFilter, getUserProperty],
  (entity, filter, userId) => {
    if (entity) {
      const sections = entity.sections
        .map((section) => {
          let facets = [...section.facets];

          if (filter === REQUIRED) {
            facets = section.facets.filter((facet) => facet.type.required);
          } else if (filter === ASSIGNED_TO_ME) {
            facets = section.facets.filter((facet) => facet.meta.assignedToId === userId);
          } else if (filter === ASSIGNED_REQUIRED) {
            facets = section.facets.filter((facet) => facet.meta.assignedToId === userId && facet.type.required);
          }

          return {
            key: section.key,
            name: section.name,
            facets,
            duplicates: section.duplicates,
          };
        })
        .filter((section) => !isEmpty(section.facets));

      const filteredSectionsKeys = sections.map((section) => section.key);

      return {
        id: entity.id,
        key: entity.key,
        topics: entity.topics,
        isTopic: entity.isTopic,
        activeKeys: entity.activeKeys,
        sectionKeys: filteredSectionsKeys,
        sections,
        name: entity.name,
        template: entity.template,
        meta: entity.meta,
        [ENTITY_COLUMNS.HIDDEN]: entity[ENTITY_COLUMNS.HIDDEN],
      };
    }

    return {};
  },
);

const getLastUsersThatReassignmentFacet = createSelector(
  [getEntities, usersSelector.getUsers, getFilterByUser],
  (entities, users, selectedUser) => {
    let lastUsersThatReassignmentFacet = [];

    entities.forEach((entity) => {
      entity.sections.forEach((section) => {
        section.facets.forEach((facet) => lastUsersThatReassignmentFacet.push(facet.lastUserIdThatReassignmentFacet));
      });
    });

    lastUsersThatReassignmentFacet = uniq(lastUsersThatReassignmentFacet);

    return users.filter((user) => lastUsersThatReassignmentFacet.includes(user.id) || user.id === selectedUser);
  },
);

const isExistsInFacetType = createSelector(
  (facetType) => facetType,
  (facetType, fieldTypeId) => fieldTypeId,
  (facetType, fieldTypeId) => (facetType.fields || facetType.facetFieldTypes)
    .find((fieldType) => fieldType.id === fieldTypeId),
);

export default {
  getEntity,
  getEntities,
  getFacets,
  getFacetCount,
  getFilled,
  getFilteredFacets,
  getFilter,
  getFilterByUser,
  getIsLoading,
  getMapping,
  getMeta,
  getName,
  getPage,
  getLastUsersThatReassignmentFacet,
  getTotal,
  getViewMode,
  getTemplate,
  getTemplateProperty,
  getDictionaryByKey,
  getEntityNameByKey,
  getEntityIdByKey,
  getEntityEditorState,
  getFacetIdByKey,
  getEntityHeader,
  getFilteredEntity,
  isExistsInFacetType,
};
