import React from 'react';
import { ButtonIcon } from '@axis/xyz.admin.buttons.button-icon';
import { Typography } from '@axis/xyz.admin.typography';
import uploadFile, { uploadImage } from '../../../common/utils/uploadFileHandler';
import css from './AttachmentField.module.css';

export const AttachmentDetails = ({
  source, onClick, fileKey, name,
}) => (
  <div className={css.source}>
    {source
      ? (
        <a href={source} target="_blank" rel="noreferrer">
          <Typography className={css.sourceText} help={source}>{name}</Typography>
        </a>
      )
      : <Typography className={css.sourceText} help={source}>{name}</Typography>}
    <ButtonIcon
      icon="close"
      help="Remove attachment"
      className={css.buttonRemove}
      onClick={() => onClick(fileKey)}
    />
  </div>
);

const AttachmentField = ({
  values = [],
  label = 'Upload document',
  fileType = 'any',
  limitFiles = 10,
  onChange = () => {},
}) => {
  const removeAttachment = (key) => {
    const newAttachments = values.filter((value) => value.key !== key);
    onChange(newAttachments);
  };

  const onClickUpload = () => {
    const handleSelectedFile = ({ filename, formData }) => {
      const newAttachments = [...values, { key: filename, name: filename, formData }];
      onChange(newAttachments);
    };
    if (fileType === 'image') uploadImage(handleSelectedFile);
    else uploadFile(handleSelectedFile);
  };

  return (
    <div className={css.container}>
      <ButtonIcon
        data-cy="event-upload-document"
        className={css.buttonUpload}
        icon="file"
        text={label}
        disabled={values.length >= limitFiles}
        onClick={onClickUpload}
      />
      {values.map((attachment) => (
        <AttachmentDetails
          key={attachment.key}
          name={attachment.name}
          fileKey={attachment.key}
          source={attachment.source}
          onClick={removeAttachment}
        />
      ))}
    </div>
  );
};

export default AttachmentField;
