import client from '../../common/utils/http';

export const saveEntityEducation = async (education) => (
  education.id
    ? client.patch(`/educations/${education.id}`, education).then((response) => response.data)
    : client.post('/educations', education).then((response) => response.data));

export const getEntityEducations = async (entityId) => client
  .get('/educations', { params: { entityId } })
  .then((response) => response.data);

export const deleteEntityEducation = async (education) => client.delete(`/educations/${education.id}`);
