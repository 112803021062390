import React, { useEffect } from 'react';
import { useStore } from 'effector-react';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import {
  Input, Button, Row, Spin,
} from 'antd';

import CommentsLog from './CommentsLog';

import {
  $details,
  $comments,
  $isLoadingComments,
  changeDetails,
  createComment,
  getComments,
  resetComments,
  resetForm,
  removeComment,
} from './model';

const { TextArea } = Input;

const autosize = {
  minRows: 2,
  maxRows: 5,
};

const FacetRejections = ({ facetId }) => {
  const comment = useStore($details);
  const comments = useStore($comments);
  const isLoading = useStore($isLoadingComments);

  useEffect(() => {
    getComments(facetId);

    return () => {
      resetComments();
      resetForm();
    };
  }, [facetId]);

  const params = {
    facetId,
    comment,
  };

  return (
    <Spin spinning={isLoading}>
      {isEmpty(comments) ? (
        <>
          <Wrapper id="FacetStatusForm">

            <Label>Comment:</Label>
            <TextArea
              type="text"
              autoComplete="off"
              value={comment}
              onChange={({ target: { value: v } }) => changeDetails(v)}
              autosize={autosize}
            />
          </Wrapper>

          <Row type="flex" justify="end">
            <Button
              type="primary"
              onClick={() => createComment(params)}
              disabled={!comment}
            >
              Add comment
            </Button>
          </Row>
        </>
      ) : null}

      {
        !isEmpty(comments) ? (
          <CommentsLog
            comments={comments}
            removeComment={removeComment}
          />
        ) : null
      }
    </Spin>
  );
};

export default FacetRejections;

const Label = styled.div`
  margin-top: 10px;
  font-size: 18px;
`;

const Wrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;
