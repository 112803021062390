import uniqueId from 'lodash/uniqueId';
import createComponentLog from './createCompomnentLog';
import { createFields, prepareFields } from './fieldUtils';

const createFieldSet = ({
  type, rules, fields, innerOrder = 0, createdFields = false,
}) => ({
  componentLogs: createComponentLog(),
  fields: fields ? prepareFields({ type, fields, innerOrder }) : createFields({ type, innerOrder }),
  filled: false,
  innerOrder,
  key: uniqueId('_fieldSet'),
  order: type.order,
  rules,
  type,
  createdFields,
});

export default createFieldSet;
