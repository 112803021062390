/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {
  useState, useRef, useEffect, useCallback,
} from 'react';
import debounce from 'lodash/debounce';
import { ButtonIcon } from '@axis/xyz.admin.buttons.button-icon';
import { Button } from '@axis/xyz.admin.buttons.button';
import { Icon } from '@axis/xyz.assets.icon';
import { Typography } from '@axis/xyz.admin.typography';
import { Select } from '@axis/xyz.admin.form.select';
import styles from './Location.module.css';
import Modal from '../../Modal/Modal';
import Map from '../../Map/Map';

const Location = ({
  onChange = () => {},
  values = [],
  placeholder = 'Add Geo-Location',
}) => {
  const [modal, setModal] = useState(false);

  const onClick = () => {
    setModal(true);
  };
  const onOk = (locationObj) => {
    onChange([...values, locationObj]);
    setModal(false);
  };
  const onCancel = () => {
    setModal(false);
  };
  const onRemoveLocation = (event, loc) => {
    event.stopPropagation();
    const newLocations = values.filter((value) => value.lat !== loc.lat);
    onChange(newLocations);
  };

  // const isLocation = value && value.address;

  return (
    <div>
      {values.length ? values.map((value) => (
        <div className={styles.wrapper}>
          <div className={styles.container} onClick={onClick}>
            <Icon type="geolocation" className={styles.icon} />
            <Typography className={styles.text} size="sm">
              {value.address}
            </Typography>
          </div>
          <ButtonIcon
            help="Remove location"
            className={styles.remove}
            onClick={(e) => onRemoveLocation(e, value)}
            icon="close"
          />
        </div>
      )) : null}

      <div className={styles.container} onClick={onClick}>
        <Icon type="geolocation" className={styles.icon} />
        <Typography className={styles.text} size="sm">
          {placeholder}
        </Typography>
      </div>

      <Modal
        width={1000}
        open={modal}
        onOk={onOk}
        onCancel={onCancel}
        className={styles.modal}
        maskStyle={{ backgroundColor: 'rgba(0,0,0,0.4)' }}
      >
        {modal ? (
          <LocationModal
            onOk={onOk}
            open={modal}
            onCancel={onCancel}
            defaultLocation={{}}
          />
        ) : null}
      </Modal>
    </div>
  );
};

const AutocompleteLocation = ({ location, setLocation, className }) => {
  const {
    current: {
      autocomplete,
      geocoder,
    },
  } = useRef({
    autocomplete: new window.google.maps.places.AutocompleteService(),
    geocoder: new window.google.maps.Geocoder(),
  });

  const [input, setInput] = useState();
  const [places, setPlaces] = useState([]);

  const onChange = (place) => {
    if (place && place.id) {
      const placeId = place.id;
      geocoder.geocode({ placeId }).then(({ results }) => {
        setLocation({
          id: placeId,
          address: place.address,
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
        });
      });
    }
  };
  const onInputChange = useCallback(
    debounce(setInput, 300),
    [input],
  );

  useEffect(() => {
    if (input && input.length > 3) {
      autocomplete.getPlacePredictions({ input }, (data, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          const placesFormatted = data.map((place) => ({ id: place.place_id, address: place.description }));
          setPlaces(placesFormatted);
        }
      });
    }
  }, [input]);

  return (
    <Select
      name="event-address"
      placeholder="Add an address"
      value={location}
      className={className}
      onChange={onChange}
      getOptionLabel={(place) => place.address}
      onInputChange={onInputChange}
      options={places}
    />
  );
};

const LocationModal = ({
  onOk, onCancel, defaultLocation,
}) => {
  const [location, setLocation] = useState(defaultLocation);
  const { current: storeZoom } = useRef({ zoom: defaultLocation ? defaultLocation.zoom : 1 });
  const onSubmit = () => {
    onOk({
      address: location.address,
      lat: location.lat,
      lng: location.lng,
      zoom: storeZoom.zoom,
    });
  };

  return (
    <div className={styles.content}>
      <div className={styles.row}>
        <AutocompleteLocation
          location={location}
          setLocation={setLocation}
          className={styles.select}
        />
        <ButtonIcon icon="close" onClick={onCancel} />
      </div>
      <div className={styles.row}>
        <Map
          storeZoom={storeZoom}
          location={location}
          setLocation={setLocation}
        />
      </div>
      <Button
        data-cy="event-geolocation"
        className={styles.button}
        label="Add Geo-Location"
        onClick={onSubmit}
      />
    </div>
  );
};

export default Location;
