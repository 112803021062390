import React from 'react';

const formatter = ({ value }) => <div>{(value && value.value ? value.value : null)}</div>;

const column = (name) => ({
  key: name,
  name,
  resizable: true,
  width: 150,
  editable: false,
  sortable: false,
  formatter,
});

export default column;
