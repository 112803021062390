import React, { useState } from 'react'; import {
  Input, Button, message, Spin, Form,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import styles from './RequestResetPassword.module.css';
import client from '../../common/utils/http';

const isValidEmail = (email) => email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
const isErrorVisible = (email) => email && !isValidEmail(email);

const RequestResetPassword = () => {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [isLoading, setLoading] = useState(false);

  const passwordReset = useMutation(({ email: recoverEmail }) => {
    // This endpoint always returns a 201 - but it doesn't mean success
    const params = { email: recoverEmail };
    return client.post('/send-pwd-reset/', params);
  });

  const handleResetPassword = () => {
    setLoading(true);
    return passwordReset.mutate({ email }, {
      onSuccess: (res) => {
        const {
          code = 200,
          info: infoMessage,
          message: errorMessage,
        } = res.data;
        if (code >= 200 && code <= 299) {
          message.success(infoMessage);
          setTimeout(() => history.push('/auth'), 3000);
        } else {
          message.error(errorMessage);
        }
      },
      onError: () => {
        message.error('There was an error reseting the password');
      },
      onSettled: () => setLoading(false),
    });
  };

  const isValid = isValidEmail(email);
  const showEmailError = isErrorVisible(email);

  return (
    <div className={styles.main}>
      <Spin className={styles.spin} size="large" spinning={isLoading}>
        <div className={styles.form}>
          <div className={styles.formColumn}>
            <Form style={{ width: '100%', margin: '0 auto' }}>
              <Form.Item
                label="Please input E-mail: "
                validateStatus={showEmailError ? 'error' : null}
                help={showEmailError ? 'Invalid Email' : null}
              >
                <Input
                  autoComplete="off"
                  name="email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </Form.Item>
            </Form>

          </div>
          <div className={styles.actions}>
            <Button
              type="default"
              onClick={() => history.goBack()}
            >
              Back
            </Button>
            <Button
              type="primary"
              disabled={!isValid}
              onClick={handleResetPassword}
            >
              Send Request
            </Button>
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default RequestResetPassword;
