/* eslint-disable no-underscore-dangle */
export const getTopicName = (topics = []) => topics.map((topic) => topic.name).join(', ');

export default (entity) => {
  if (entity.topics && entity.topics.length) {
    return `${entity.name} - ${getTopicName(entity.topics)}`;
  }
  if (entity.topicName) {
    return `${entity.name} - ${entity.topicName}`;
  }
  return entity.name;
};

export const getAutocompleteEntityOptionLabel = (entity) => {
  if (entity.__isNew__) return entity.label;
  if (entity.topics && entity.topics.length) {
    return `${entity.name} - ${getTopicName(entity.topics)}`;
  }
  if (entity.topicName) {
    return `${entity.name} - ${entity.topicName}`;
  }
  return entity.name;
};
