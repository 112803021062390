import React, { useCallback, useState } from 'react';
import { useQueryClient } from 'react-query';
import debounce from 'lodash/debounce';
import { Select } from '@axis/xyz.admin.form.select';
import client from '../../../common/utils/http';

const PERCENT_SYMBOL = encodeURIComponent('%');

const SearchSelect = ({
  className,
  placeholder,
  name,
  value,
  onChange,
  isClearable = false,
  isMulti = false,
  isDisabled = false,
  getOptionLabel,
  searchUrl,
}) => {
  const queryClient = useQueryClient();
  const [options, setOptions] = useState([]);
  const onInputChange = useCallback(debounce((key) => {
    if (key.length >= 3) {
      queryClient.fetchQuery(
        [searchUrl, key],
        () => client
          .get(`${searchUrl}${PERCENT_SYMBOL}${key}${PERCENT_SYMBOL}`)
          .then(({ data }) => {
            setOptions(data.data);
          }),
      );
    }
  }, 300), [queryClient]);
  return (
    <Select
      className={className}
      placeholder={placeholder}
      name={name}
      isClearable={isClearable}
      isMulti={isMulti}
      isDisabled={isDisabled}
      getOptionLabel={getOptionLabel}
      onChange={onChange}
      onInputChange={onInputChange}
      value={value}
      options={options}
    />
  );
};

export default SearchSelect;
