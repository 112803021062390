import { useEffect } from 'react';
import { connect } from 'react-redux';
import authSelector from '../../selectors/users';

const FullStory = ({ user }) => {
  const { id, name, email } = (user || {});

  useEffect(() => {
    if (id && name && email && window.FS && window.FS.identify) {
      window.FS.identify(id, { displayName: name, email });
    }
  }, [id, name, email]);

  return null;
};

const mapStateToProps = (state) => ({
  user: authSelector.getUser(state),
});

export default connect(mapStateToProps)(FullStory);
