import React, {
  useEffect, useState,
} from 'react';
import { Typography } from '@axis/xyz.admin.typography';
import { Button } from '@axis/xyz.admin.buttons.button';
import { DataTable } from '@axis/xyz.admin.tables.data-table';
import { Pagination } from '@axis/xyz.admin.pagination';
import { Icon } from '@axis/xyz.assets.icon';
import {
  useQuery, useMutation, useQueryClient,
} from 'react-query';
import { message } from 'antd';
import { Loading } from '@axis/xyz.admin.loading';
import { get } from 'lodash';
import css from './Briefs.module.css';
import {
  fetchBriefsPage, PAGE_SIZE, createBrief, updateBrief, deleteBrief,
} from './utils';
import SearchSelect from '../../components/Form/SearchSelect/SearchSelect';
import ModalCreateBrief from '../../modals/ModalCreateBrief/ModalCreateBrief';
import ModalDeleteBrief from '../../modals/ModalDeleteBrief/ModalDeleteBrief';
import ModalEditBrief from '../../modals/ModalEditBrief/ModalEditBrief';
import SelectBriefType from '../../components/Form/SelectBriefType/SelectBriefType';

const LoadingIndicator = ({ visible }) => {
  if (!visible) return null;
  return (
    <div className={css.loadingIndicator}>
      <Loading className={css.spinner} />
      <p className={css.loading}>Refreshing briefs</p>
    </div>
  );
};

const Briefs = () => {
  const [filters, setFilters] = useState({
    briefName: null,
    briefType: null,
  });

  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({ field: '', order: '' });
  const [loading, setLoading] = useState(false);

  const [showNewBriefModal, setShowNewBriefModal] = useState(false);

  const [selectedBrief, setSelectedBrief] = useState({ source: null, action: '' });

  const queryClient = useQueryClient();

  const briefCreate = useMutation(createBrief);
  const briefUpdate = useMutation(updateBrief);
  const briefDelete = useMutation(deleteBrief);

  const {
    data = { total: 0, briefs: [] },
    isFetching,
    isLoading,
  } = useQuery(
    ['briefs', filters, sort, page],
    () => fetchBriefsPage({
      filters,
      orderByName: sort.order,
      pageParam: { page },
    }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    queryClient.invalidateQueries('briefs');
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  const handleCreateBrief = (brief) => {
    setLoading(true);
    briefCreate.mutate(brief, {
      onSuccess: () => {
        queryClient.invalidateQueries(['briefs', filters]);
        message.success('Brief successfully created');
        setLoading(false);
        setShowNewBriefModal(false);
      },
      onError: (err) => {
        const detail = get(err, 'response.data.message', err.message);
        message.error(detail);
        setLoading(false);
      },
    });
  };

  const handleUpdateBrief = ({ id, ...brief }) => {
    setLoading(true);
    briefUpdate.mutate({ id, brief }, {
      onSuccess: () => {
        queryClient.invalidateQueries(['briefs', filters]);
        message.success('Brief successfully updated');
        setLoading(false);
        setSelectedBrief({ brief: null, action: '' });
      },
      onError: (err) => {
        const detail = get(err, 'response.data.message', err.message);
        message.error(detail);
        setLoading(false);
      },
    });
  };

  const handleDeleteConfirmed = (briefId) => {
    setLoading(true);
    briefDelete.mutate(briefId, {
      onSuccess: () => {
        queryClient.invalidateQueries(['briefs', filters]);
        message.success('Brief successfully deleted');
      },
      onError: (err) => {
        const detail = get(err, 'response.data.message', err.message);
        message.error(detail);
      },
      onSettled: () => {
        setSelectedBrief({ brief: null, action: '' });
        setLoading(false);
      },
    });
  };

  const handleFilterChange = (field) => (value) => {
    if (!value || value === 'All') {
      setFilters({ ...filters, [field]: null });
      setPage(1);
    } else {
      setFilters({ ...filters, [field]: value });
      setPage(1);
    }
  };

  const handleSortChange = (sortField) => {
    setSort(({ field, order }) => {
      if (field !== sortField) {
        return { field: sortField, order: 'asc' };
      }
      if (order === 'asc') {
        return { field, order: 'desc' };
      }
      if (order === 'desc') {
        return { field: '', order: '' };
      }
      return { field: '', order: '' };
    });
  };

  return (
    <div className={css.container}>
      <section>
        <div className={css.row}>
          <Typography
            className={css.title}
            tag="h1"
            type="title"
            size="xl"
          >
            Axis Briefs
            <LoadingIndicator
              visible={isFetching && !isLoading && data.briefs.length > 0}
            />
          </Typography>
          <Button
            className={css.buttonNew}
            color="primary"
            onClick={() => setShowNewBriefModal((m) => !m)}
            label="New Brief"
          />
        </div>
        <hr className={css.titleSeparator} />
        <div className={css.filters}>
          <div className={css.filter}>
            <SearchSelect
              isClearable
              placeholder="Filter by Name"
              name="filter-name"
              value={filters.briefName || ''}
              onChange={handleFilterChange('briefName')}
              searchUrl="/briefs?$limit=50&name="
            />
          </div>
          <div className={css.filter}>
            <SelectBriefType
              value={filters.briefType}
              onChange={handleFilterChange('briefType')}
              filter
            />
          </div>
        </div>
      </section>
      <section>
        <DataTable
          columns={[
            {
              label: 'Name', key: 'name', sortable: true, sortKey: 'name', className: css.name,
            },
            {
              label: 'Type', key: 'type', sortable: true, sortKey: 'type',
            },
            {
              label: 'Parents', key: 'parents', sortable: false, sortKey: '',
            },
            {
              label: 'Actions', key: 'actions', className: css.columnActions, sortable: false,
            },
          ]}
          rows={data.briefs.map((brief) => ({
            id: brief.id,
            values: {
              name: brief.name,
              type: brief.type,
              parents: brief.parents ? brief.parents.map((parent) => parent.name).join(', ') : '',
              actions: (
                <div className={css.icons} data-cy="rss-sources-options">
                  <span className={css.iconContainer} data-cy="rss-sources-options-edit">
                    <Icon
                      className={css.icon}
                      type="edit"
                      onClick={() => setSelectedBrief({ brief: brief.id, action: 'edit' })}
                    />
                  </span>
                  <span className={css.iconContainer} data-cy="rss-sources-options-delete">
                    <Icon
                      className={css.icon}
                      type="trash"
                      onClick={() => setSelectedBrief({ brief: brief.id, action: 'delete' })}
                    />
                  </span>
                </div>
              ),
            },
          }))}
          sortKey={sort.field}
          sortOrder={sort.order}
          onSort={handleSortChange}
        />
        <div className={css.pagination}>
          <Pagination
            page={page}
            totalPages={Math.ceil(data.total / PAGE_SIZE)}
            onPageChange={setPage}
          />
        </div>
      </section>
      <ModalCreateBrief
        visible={showNewBriefModal}
        onCreate={handleCreateBrief}
        onClose={() => setShowNewBriefModal(false)}
        loading={loading}
      />
      <ModalEditBrief
        visible={selectedBrief.brief && selectedBrief.action === 'edit'}
        id={selectedBrief.brief}
        onUpdate={handleUpdateBrief}
        onClose={() => setSelectedBrief({ country: null, action: '' })}
        loading={loading}
      />
      <ModalDeleteBrief
        visible={selectedBrief.brief && selectedBrief.action === 'delete'}
        onConfirm={() => handleDeleteConfirmed(selectedBrief.brief)}
        onCancel={() => setSelectedBrief({ brief: null, action: '' })}
        loading={loading}
      />
    </div>
  );
};

export default Briefs;
