import React, { useCallback, useEffect } from 'react';
import debounce from 'lodash/debounce';
import { Select } from 'antd';
import linkHelpers from '../linkHelpers';
import styles from '../styles.module.css';

const { Option } = Select;

const SelectLink = ({
  disabled, selectLink, linkSet, name, placeholder, defaultTopics, useTopicSelector,
}) => {
  const { target } = linkSet;
  const [searches, setSearches] = React.useState([]);
  const [searchesTopic, setSearchesTopics] = React.useState([]);
  const [topic, setTopic] = React.useState(null);
  useEffect(() => {
    if (useTopicSelector && defaultTopics) {
      setTopic(defaultTopics[0]);
    }
  }, [defaultTopics, useTopicSelector]);

  const search = React.useCallback(debounce(async (string = '') => {
    const {
      rules: { 'Allow-Link-To': allowed },
    } = linkSet;
    const results = await linkHelpers.searchEntities(
      string.trim().replace(' ', '%20'),
      allowed.entityType,
      allowed.briefValue,
      topic && useTopicSelector ? topic.id || topic : null,
    );
    setSearches(results);
  }, 300), [linkSet, topic]);

  const searchTopics = React.useCallback(debounce(async (string = '') => {
    const results = await linkHelpers.searchEntities(
      string.trim().replace(' ', '%20'), 'Brief', 'Country',
    );
    setSearchesTopics(results);
  }, 300), [linkSet]);

  let link = null;
  if (target && target.type) {
    link = (
      <span>
        <span className={styles.type}>{target.type}</span>
        {!useTopicSelector && target.subType ? <span className={styles.subType}>{target.subType}</span> : null}
        {!useTopicSelector && target.briefType ? <span className={styles.briefType}>{target.briefType}</span> : null}
        {!useTopicSelector && (target.topics?.length || defaultTopics?.length)
          ? (
            <span className={styles.topics}>
              [
              {target.topics && target.topics.length
                ? target.topics.map((e) => e.name).join(', ')
                : defaultTopics.map((e) => e.name).join(', ')}
              ]
            </span>
          )
          : null}
        <span>{target.name}</span>
      </span>
    );
  }

  let topicSelected = null;
  if (topic) {
    topicSelected = (
      <span>
        <span>{topic.name}</span>
      </span>
    );
  }

  const onChangeLink = useCallback((value, element) => {
    selectLink(value, element);
  }, [selectLink]);

  return (
    <>
      {useTopicSelector
        ? (
          <Select
            allowClear
            name="topic-select"
            disabled={disabled}
            showSearch
            dropdownClassName="dropdown-zindex"
            onSearch={searchTopics}
            notFoundContent={null}
            filterOption={false}
            getPopupContainer={() => document.getElementById('entity-editor-id')}
            placeholder="Topic (optional)"
            onChange={(e, ev) => setTopic(e ? ev.props.obj : null)}
            value={topicSelected}
            onFocus={() => searchTopics('')}
            style={{ width: '40%', marginRight: '5%' }}
          >
            {searchesTopic
              ? searchesTopic.map((entity) => (
                <Option
                  key={entity.id}
                  value={entity.id}
                  obj={entity}
                >
                  <span className={styles.type}>{entity.type}</span>
                  <span>{entity.name}</span>
                </Option>
              )) : null}
          </Select>
        )
        : null}
      <Select
        name={name}
        disabled={disabled}
        showSearch
        dropdownClassName="dropdown-zindex"
        onSearch={search}
        notFoundContent={null}
        filterOption={false}
        getPopupContainer={() => document.getElementById('entity-editor-id')}
        placeholder={placeholder}
        onChange={onChangeLink}
        value={link}
        onFocus={() => search('')}
        style={{ width: useTopicSelector ? '55%' : '100%' }}
        data-testid="coreBriefTagSelect"
      >
        {searches
          ? searches.map((entity) => (
            <Option
              key={entity.id}
              value={entity.id}
              obj={entity}
              data-testid="coreBriefTagSelectOption"
            >
              <span className={styles.type}>{entity.type}</span>
              {!useTopicSelector && entity.subType ? <span className={styles.subType}>{entity.subType}</span> : null}
              {!useTopicSelector && entity.briefType
                ? <span className={styles.briefType}>{entity.briefType}</span>
                : null}
              {!useTopicSelector && (Array.isArray(entity.topics) && entity.topics.length)
                ? (
                  <span className={styles.topics}>
                    [
                    {entity.topics.map((e) => e.name).join(', ')}
                    ]
                  </span>
                )
                : null}
              <span>{entity.name}</span>
            </Option>
          )) : null}
      </Select>
    </>
  );
};

export default SelectLink;
