import React from 'react';
import moment from 'moment';

import { Filters } from 'react-data-grid-addons';
import { Link } from 'react-router-dom';
import { DATE_FORMAT } from '../../../../constants/common';

import editorsColumn from './editorsColumn';
import nameColumn from './nameColumn';
import typeColumn from './typeColumn';
import stageColumn from './stageColumn';
import assignColumn from './assignColumn';
import sectionColumn from './sectionColumn';
import sourceColumn from './sourceColumn';
import descriptionColumn from './descriptionColumn';
import labelColumn from './labelColumn';
import { ROUTE_ANALYST_ENTITIES_EDITOR } from '../../../../constants';

const { SingleSelectFilter } = Filters;

const formatter = ({ row: { facet, ...rest } }) => (
  <div
    title={facet}
    data-testid="facetsItemFacet"
  >
    <Link to={`${ROUTE_ANALYST_ENTITIES_EDITOR}/${rest.entityId}#${rest.facetId}`} target="_blank">{facet}</Link>
  </div>
);

export default [
  nameColumn,
  typeColumn,
  sectionColumn,
  {
    key: 'facet',
    name: 'Facet',
    width: 80,
    sortable: false,
    resizable: true,
    filterable: true,
    formatter,
    filterRenderer: SingleSelectFilter,
    draggable: true,
  },
  {
    key: 'level',
    name: 'Level',
    width: 90,
    sortable: false,
    resizable: true,
    filterable: true,
    filterRenderer: SingleSelectFilter,
    draggable: true,
  },
  stageColumn,
  assignColumn,
  {
    key: 'createdAt',
    name: 'Created',
    sortable: true,
    resizable: true,
    filterable: true,
    width: 86,
    formatter: ({ row: { createdAt } }) => moment(createdAt.replace('Z', '')).format(DATE_FORMAT),
    draggable: true,
  },
  {
    key: 'updatedAt',
    name: 'Modified',
    sortable: true,
    resizable: true,
    filterable: true,
    width: 120,
    formatter: ({ row: { updatedAt } }) => moment(updatedAt.replace('Z', '')).format(DATE_FORMAT),
    draggable: true,
  },
  editorsColumn,
  labelColumn,
  descriptionColumn,
  sourceColumn,
];
