import React, {
  createContext, useState, useContext, useEffect,
} from 'react';
import { Alert } from '@axis/xyz.admin.alert';
import styles from './ToastManager.module.css';

const Context = createContext();

const Toast = ({ type, content }) => (
  <div className={styles.toast}>
    <Alert
      className={styles.alert}
      type={type}
      data-cy="notification-toast"
    >
      {content}
    </Alert>
  </div>
);

export const ToastManager = ({ children }) => {
  const [toast, setToast] = useState({});

  const success = ({ content, timeout = 4000 }) => {
    setToast({ type: 'success', content, timeout });
  };

  const error = ({ content, timeout = 4000 }) => {
    setToast({ type: 'error', content, timeout });
  };

  useEffect(() => {
    if (toast.timeout && toast.timeout > 0) {
      const timeout = setTimeout(() => {
        setToast({});
      }, toast.timeout);
      return () => clearTimeout(timeout);
    }
    return () => {};
  }, [toast.timeout]);

  return (
    <Context.Provider value={{ success, error }}>
      {children}
      {toast.content && (
        <Toast
          type={toast.type}
          content={toast.content}
        />
      )}
    </Context.Provider>
  );
};

export const useToast = () => useContext(Context);
