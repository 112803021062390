import React, { useEffect } from 'react';
import { useStore } from 'effector-react';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import {
  Input, Select, Button, Row, Spin,
} from 'antd';

import CommentsLog from './CommentsLog';

import {
  $assessment,
  $details,
  $comments,
  $isLoadingComments,
  changeAssessment,
  changeDetails,
  createComment,
  getComments,
  resetComments,
  resetForm,
  removeComment,
} from './model';

const { TextArea } = Input;
const { Option } = Select;

const autosize = {
  minRows: 2,
  maxRows: 5,
};
const options = [1, 2, 3, 4, 5];

const FacetComments = ({ facetId }) => {
  const assessment = useStore($assessment);
  const details = useStore($details);
  const comments = useStore($comments);
  const isLoading = useStore($isLoadingComments);

  useEffect(() => {
    getComments(facetId);

    return () => {
      resetComments();
      resetForm();
    };
  }, [facetId]);

  const params = {
    sourceId: facetId,
    details,
    assessment,
  };

  return (
    <Spin spinning={isLoading}>
      <Wrapper id="FacetStatusForm">
        <Label>Assessment:</Label>
        <SelectWrapper
          value={assessment}
          onChange={changeAssessment}
          getPopupContainer={() => document.getElementById('FacetStatusForm')}
        >
          {
            options.map((option) => <Option key={option} value={option}>{option}</Option>)
          }
        </SelectWrapper>

        <Label>Comment:</Label>
        <TextArea
          type="text"
          autoComplete="off"
          value={details}
          onChange={({ target: { value: v } }) => changeDetails(v)}
          autosize={autosize}
        />
      </Wrapper>

      <Row type="flex" justify="end">
        <Button
          type="primary"
          onClick={() => createComment(params)}
          disabled={!details}
        >
          Add comment
        </Button>
      </Row>

      {
        !isEmpty(comments) && (
          <CommentsLog
            comments={comments}
            removeComment={removeComment}
          />
        )
      }
    </Spin>
  );
};

export default FacetComments;

const Label = styled.div`
  margin-top: 10px;
  font-size: 18px;
`;

const Wrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const SelectWrapper = styled(Select)`
  width: 100%;
`;
