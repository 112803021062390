import React, {
  useCallback, useEffect, useState, memo,
} from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { connect } from 'react-redux';
import { message } from 'antd';
import get from 'lodash/get';
import { Loading } from '@axis/xyz.admin.loading';
import { getRegulation, updateRegulation } from './utils';
import styles from './RegulationPage.module.css';
import { sendEvent } from '../../contexts/AnalyticsTrackingContext/AnalyticsTrackingContext';
import usersSelector from '../../selectors/users';
import RegulationForm from '../RegulationForm/RegulationForm';

const REGULATION_TEMPLATE = {
  issuerEntity: { name: '', id: '' },
  topic: { name: '', id: '' },
  summarizedName: '',
  legalName: '',
  dateStart: null,
  dateEnd: null,
  industryBriefs: [],
  source: '',
  attachment: '',
};

const RegulationPage = (props) => {
  const { match: { params: { id } } } = props;
  const [regulation, setRegulation] = useState(REGULATION_TEMPLATE);
  const [isRegulationFormEnabled, setRegulationFormEnabled] = useState(true);
  const [loading, setLoading] = useState(true);
  const queryClient = useQueryClient();
  const mutateRegulation = useMutation(updateRegulation);

  const handleRegulationFormChange = useCallback((field, value) => {
    setRegulation((currentRegulation) => ({ ...currentRegulation, [field]: value }));
  }, [setRegulation]);

  const onSubmit = useCallback(() => {
    sendEvent('regulation_update', {
      description: 'Updating a regulation',
      regulationId: regulation,
      regulationTopic: regulation.topic.name,
      regulationTopicId: regulation.topic.id,
    });
    setLoading(true);
    setRegulationFormEnabled(false);
    mutateRegulation
      .mutateAsync(regulation)
      .then((data) => {
        setRegulation(data);
        queryClient.invalidateQueries(['regulation', id]);
        message.success('Regulation was saved');
      })
      .catch((error) => {
        console.log('ERROR', error);
        const text = get(error, 'response.data.message', 'Can\'t update regulation');
        message.error(text, 3);
      })
      .finally(() => {
        setRegulationFormEnabled(true);
        setLoading(false);
      });
  }, [mutateRegulation, regulation]);

  useEffect(() => {
    setLoading(true);
    queryClient
      .fetchQuery(['regulation', id], () => getRegulation(id))
      .then(setRegulation)
      .catch((error) => {
        console.log({ error });
        const text = get(error, 'response.data.message', 'Can\'t load regulation');
        message.error(text, 3);
      })
      .finally(() => setLoading(false));
    return () => {};
  }, [queryClient, id]);

  return (
    <div className={styles.page}>
      <div className={styles.container}>
        {loading
          ? <Loading className={styles.loader} />
          : (
            <RegulationForm
              title="Edit Regulation"
              regulation={regulation}
              buttonLabel={isRegulationFormEnabled ? 'Save changes' : 'Saving...'}
              onChange={handleRegulationFormChange}
              submitEnabled={isRegulationFormEnabled}
              onSubmit={onSubmit}
            />
          )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const user = usersSelector.getUser(state);
  return {
    userRole: usersSelector.getRole(user) || '',
  };
};

export default connect(mapStateToProps)(memo(RegulationPage));
