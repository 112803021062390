import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import {
  Table, Button, Pagination, Spin, Row, Col, message,
} from 'antd';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import client from '../../common/utils/http';
import SearchColumn from '../../components/SearchColumn/SearchColumn';
import { PAGE_SIZE, getQueryParams, processDictionaries } from './utils';
import styles from './Dictionaries.module.css';
import DictionaryActions from './components/DictionaryActions/DictionaryActions';

const Dictionaries = () => {
  const [query, setQuery] = useState({
    filter: { key: '', name: '' },
    sort: { field: '', order: 'asc' },
    page: 1,
  });

  const queryClient = useQueryClient();

  const { isLoading, isError, data: result } = useQuery(
    ['dictionaries', query],
    () => {
      const params = getQueryParams(query);
      return client
        .get('/dictionaries', { params })
        .then(processDictionaries);
    },
    { keepPreviousData: true },
  );

  const dictionaryDelete = useMutation((id) => client.delete(`/dictionaries/${id}`));

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  useEffect(() => {
    if (!isError) return;
    message.error('There was an error fetching the dictionaries');
  }, [isError]);

  const handleDictionaryDelete = (id) => {
    dictionaryDelete.mutate(id, {
      onSuccess: () => {
        queryClient.invalidateQueries('dictionaries');
      },
      onError: () => {
        message.error('There was an error deleting the dictionary');
      },
    });
  };

  const changePage = (newPage) => {
    setQuery((current) => ({
      ...current,
      page: newPage,
    }));
  };

  const onChangeTable = (pagination, filters, sorter) => {
    if (isEmpty(sorter)) return;
    setQuery((current) => ({
      ...current,
      sort: {
        field: sorter.field,
        order: sorter.order === 'ascend' ? 1 : -1,
      },
    }));
  };

  const handleFilterUpdate = (field) => (value) => {
    setQuery((current) => ({
      ...current,
      filter: { ...current.filter, [field]: value },
    }));
  };

  const columns = [
    {
      title: (
        <SearchColumn
          onClickSearch={handleFilterUpdate('key')}
          label="Name"
          style={{ width: '100%' }}
        />
      ),
      dataIndex: 'key',
      key: 'key',
      render: (value) => <div title={value}>{value}</div>,
      sorter: true,
    },
    {
      title: (
        <SearchColumn
          onClickSearch={handleFilterUpdate('name')}
          label="Description"
          style={{ width: '100%' }}
        />
      ),
      dataIndex: 'name',
      key: 'name',
      render: (value) => <div title={value}>{value}</div>,
      sorter: true,
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date) => <div title={date}>{date}</div>,
      sorter: true,
    },
    {
      title: 'Modified',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (date) => <div title={date}>{date}</div>,
      sorter: true,
    },
    {
      title: 'Actions',
      width: 70,
      render: (dictionaryElement) => (
        <DictionaryActions
          element={dictionaryElement}
          onDelete={() => handleDictionaryDelete(dictionaryElement.id)}
        />
      ),
    },
  ];

  return (
    <Spin spinning={isLoading}>
      <Row className={styles.rowTop} type="flex" justify="space-between">
        <Col>
          <Link to="/admin/elements/dictionaries/editor">
            <Button type="primary">Create Dictionary</Button>
          </Link>
        </Col>

        <Col>
          <Pagination
            onChange={changePage}
            current={query.page}
            total={result && result.total}
            pageSize={PAGE_SIZE}
          />
        </Col>
      </Row>

      <Table
        id="DictionariesTable"
        rowKey="id"
        size="small"
        columns={columns}
        pagination={false}
        dataSource={result && result.dictionary}
        onChange={onChangeTable}
        bordered
      />

      <Row className={styles.rowBottom} type="flex" justify="end">
        <Pagination
          onChange={changePage}
          current={query.page}
          total={result && result.total}
          pageSize={PAGE_SIZE}
        />
      </Row>
    </Spin>
  );
};

export default Dictionaries;
