import React from 'react';
import { Table, Button, Spin } from 'antd';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import {
  ROUTE_ANALYST_BRIEFS_EDITOR,
  ROUTE_ANALYST_ENTITIES_EDITOR,
} from '../../../../constants';
import './index.css';
import { sendEvent } from '../../../../contexts/AnalyticsTrackingContext/AnalyticsTrackingContext';

const getPath = (type) => {
  switch (type) {
    case 'Brief': return ROUTE_ANALYST_BRIEFS_EDITOR;
    default: return ROUTE_ANALYST_ENTITIES_EDITOR;
  }
};

const Templates = ({
  templates, onCancel, type, history, isLoading,
}) => {
  const onTemplateClick = (template) => {
    sendEvent('entity_creation', {
      description: 'Creating a new entity',
      entityType: template.type,
      entityTemplateId: template.id,
    });
    history.push({
      pathname: getPath(type),
      state: { templateId: template.id },
    });
  };

  return (
    <Spin spinning={isLoading}>
      <Table
        className="ModalCreateEntity__Table"
        rowKey="id"
        dataSource={templates}
        columns={[
          {
            title: 'Type',
            dataIndex: 'type',
            sorter: (a, b) => {
              const at = `${a.type}`.toLowerCase();
              const bt = `${b.type}`.toLowerCase();
              if (at > bt) return 1;
              if (at < bt) return -1;
              return 0;
            },
          }, {
            title: 'Sub Type',
            dataIndex: 'subType',
            sorter: (a, b) => {
              const at = `${a.subType}`.toLowerCase();
              const bt = `${b.subType}`.toLowerCase();
              if (at > bt) return 1;
              if (at < bt) return -1;
              return 0;
            },
          }, {
            title: 'Modified',
            dataIndex: 'updatedAt',
            render: (date) => moment(date).format('YYYY/M/D H:mm'),
            sorter: (a, b) => (moment(a.updatedAt).valueOf() - moment(b.updatedAt).valueOf()),
          }, {
            title: 'Actions',
            render: (template) => (
              <Button onClick={() => onTemplateClick(template)}>
                Create
              </Button>
            ),
          },
        ]}
        pagination={{ pageSize: 50 }}
        size="small"
      />
      <Button className="ModalCreateEntity__Button" type="cancel" onClick={onCancel}>Cancel</Button>
    </Spin>
  );
};

export default withRouter(Templates);
