import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import get from 'lodash/get';
import Link from './Link';
import entityEditorSelectors from '../../../../selectors/entityEditor';
import { changeFieldLink, saveLink } from '../../actions';
import withRemoveButton from '../Field/HOC/withButtonRemove';
import statusesSelector from '../../../../selectors/statuses';
import authSelector from '../../../../selectors/users';

const mapStateToProps = (state, ownProps) => {
  const { entityKey, sectionKey, facetKey } = ownProps.options;
  return ({
    entityName: entityEditorSelectors.getEntityNameByKey(state, entityKey),
    entityId: entityEditorSelectors.getEntityIdByKey(state, entityKey),
    facetId: entityEditorSelectors.getFacetIdByKey(state, entityKey, sectionKey, facetKey),
    editable: entityEditorSelectors.isExistsInFacetType(ownProps.facetType, ownProps.link.facetFieldTypeId),
    loading: !!get(state, 'entityEditor.linkLoadingStatusMap', {})[ownProps.link.id],
    stages: statusesSelector.getFacetStatuses(
      authSelector.getRole(authSelector.getUser(state)),
    ),
  });
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ changeFieldLink, saveLink }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRemoveButton(Link));
