import Label from './Label';
import Detail from './Detail';
import Importance from './Importance';
import Location from './Location';
import Media from './Media';
import Date from './Date';
import Checkbox from './Checkbox';
import Provenance from './Provenance';
import Bullet from './Bullet';

export default {
  Label,
  Detail,
  Importance,
  Media,
  Date,
  Location,
  Checkbox,
  Provenance,
  Bullet,
};
