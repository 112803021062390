import React from 'react';
import {
  Icon,
  Popconfirm,
  Row,
  Col,
} from 'antd';

const UserActions = ({
  user,
  onEditClick,
  onDeleteClick,
}) => (
  <Row type="flex" justify="start" align="middle" gutter={8}>
    <Col>
      {!user.isVerified && (
        <Icon type="warning" title="Account not activated" />
      )}
    </Col>
    <Col>
      <Icon
        className="cursor-pointer"
        type="edit"
        onClick={onEditClick}
      />
    </Col>
    <Col>
      <Popconfirm
        id={user.id}
        placement="left"
        title={`Are you sure you want to delete '${user.email}'?`}
        onConfirm={onDeleteClick}
        okText="Confirm"
        cancelText="Cancel"
      >
        <Icon
          className="cursor-pointer"
          type="delete"
        />
      </Popconfirm>
    </Col>
  </Row>
);

export default UserActions;
