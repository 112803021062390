import React from 'react';
import classnames from 'classnames';
import css from './Container.module.css';
import background from './backgroundColor.module.css';

export const SPACING_SIZE = 16;

export const spacingStyle = (spacing) => {
  if (!spacing) {
    return {};
  }

  let outer = spacing.outer || {};
  let inner = spacing.inner || {};

  if (typeof outer === 'number') {
    outer = {
      top: outer,
      right: outer,
      bottom: outer,
      left: outer,
    };
  }

  if (typeof inner === 'number') {
    inner = {
      top: inner,
      right: inner,
      bottom: inner,
      left: inner,
    };
  }

  return [
    [outer.top, 'marginTop'],
    [outer.right, 'marginRight'],
    [outer.bottom, 'marginBottom'],
    [outer.left, 'marginLeft'],
    [inner.top, 'paddingTop'],
    [inner.right, 'paddingRight'],
    [inner.bottom, 'paddingBottom'],
    [inner.left, 'paddingLeft'],
    [spacing.width, 'width'],
    [spacing.height, 'height'],
  ].reduce((attrs, [unit, name]) => {
    if (typeof unit === 'number') {
      // eslint-disable-next-line no-param-reassign
      attrs[name] = unit * SPACING_SIZE;
    }
    return attrs;
  }, {});
};

const Container = React.forwardRef(
  (
    {
      spacing,
      Component,
      children,
      className,
      style,
      flex,
      inlineFlex,
      flexWrap,
      alignCenter,
      justifyCenter,
      backgroundColor,
      inline,
      inlineBlock,
      ...props
    },
    ref,
  ) => (
    <Component
      {...props}
      ref={ref}
      style={{
        ...style,
        ...spacingStyle(spacing),
      }}
      className={classnames(css.root, className, {
        [css.flex]: flex,
        [css.inlineFlex]: inlineFlex,
        [css.flexWrap]: flexWrap,
        [css.alignCenter]: alignCenter,
        [css.justifyCenter]: justifyCenter,
        [css.inline]: inline,
        [css.inlineBlock]: inlineBlock,
        [background[backgroundColor]]: backgroundColor,
      })}
    >
      {children}
    </Component>
  ),
);

Container.defaultProps = {
  Component: 'div',
};

export default Container;
