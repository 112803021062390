import React, { useEffect, useState } from 'react';
import { FormItemWrapper } from '@axis/xyz.admin.form.form-item-wrapper';
import { TextInput } from '@axis/xyz.admin.form.text-input';
import { useMutation, useQueryClient } from 'react-query';
import { Typography } from '@axis/xyz.admin.typography';
import { Checkbox, notification } from 'antd';
import { get } from 'lodash';
import { Button } from '@axis/xyz.admin.buttons.button';
import { DateInput } from '@axis/xyz.admin.form.date-input';
import { Icon } from '@axis/xyz.assets.icon';
import moment from 'moment';
import { ButtonText } from '@axis/xyz.admin.buttons.button-text';
import { Loading } from '@axis/xyz.admin.loading';
import { Select } from '@axis/xyz.admin.form.select';
import css from './EntityRole.module.css';
import { deleteEntityRole, getEntityRoles, saveEntityRole } from './utils';
import Modal from '../Modal/Modal';
import SearchSelect from '../Form/SearchSelect/SearchSelect';

const emptyRole = {
  id: null,
  title: '',
  type: '',
  organization: '',
  department: '',
  isPrimary: false,
  source: '',
  startDate: null,
  endDate: null,
  employerEntity: null,
};

const organizationSources = ['Axis', 'Non-Axis'];
const searchEntitiesQuery = '/entity-infos?type[$ne]=Brief&$limit=50&name[$iLike]=';

const EntityRole = ({ entityId }) => {
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [roleObject, setRoleObject] = useState(emptyRole);
  const [roleToDelete, setRoleToDelete] = useState(null);
  const [organizationSource, setOrganizationSource] = useState(organizationSources[0]);
  const [removeRoleModalOpen, setRemoveRoleModalOpen] = useState(false);
  const queryClient = useQueryClient();
  const mutateRole = useMutation(saveEntityRole);
  const deleteRole = useMutation(deleteEntityRole);

  const requiredFields = ['title', 'source', ...(organizationSource === 'Axis' ? ['employerEntity'] : ['organization'])];

  useEffect(() => {
    setLoading(true);
    queryClient
      .fetchQuery(['entity-roles', entityId], () => getEntityRoles(entityId))
      .then(setRoles)
      .catch((error) => {
        const text = get(error, 'response.data.message', 'Could not fetch entity roles');
        notification.error({ message: text });
        setRoles([]);
      })
      .finally(() => {
        setLoading(false);
      });
    return () => {};
  }, []);

  const handleSaveRole = () => {
    const errors = [];
    Object.keys(roleObject).forEach((key) => {
      if (requiredFields.includes(key) && !roleObject[key]) {
        errors.push(key);
      }
    });

    if (errors.length) {
      notification.error(`The following fields are empty: ${errors.join(', ')}`);
      return;
    }

    roleObject.entityId = entityId;
    roleObject.employerEntityId = roleObject.employerEntity ? roleObject.employerEntity.id : null;
    roleObject.employerEntity = null;

    setLoading(true);
    mutateRole
      .mutateAsync(roleObject)
      .then((data) => {
        setRoles([...roles, ...[data]]);
        setRoleObject(emptyRole);
        queryClient.invalidateQueries(['entity-roles', entityId]);
        notification.success({ message: 'Entity role saved successfully!' });
      })
      .catch((error) => {
        const text = get(error, 'response.data.message', 'Could not save entity roles');
        notification.error({ message: text });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleInputChange = (field, value) => setRoleObject({ ...roleObject, [field]: value });

  const handleEditRole = (role) => {
    setRoles(roles.filter((r) => r.id !== role.id));
    setRoleObject({
      ...role,
      startDate: role.startDate ? new Date(role.startDate) : null,
      endDate: role.endDate ? new Date(role.endDate) : null,
    });
    setOrganizationSource(role.employerEntity ? 'Axis' : 'Non-Axis');
  };

  const handleDeleteRole = (role) => {
    setRoleToDelete(role);
    setRemoveRoleModalOpen(true);
  };

  const handleDeleteConfirmed = () => {
    setRemoveRoleModalOpen(false);
    setLoading(true);
    deleteRole
      .mutateAsync(roleToDelete)
      .then(() => {
        setRoles(roles.filter((r) => r.id !== roleToDelete.id));
        queryClient.invalidateQueries(['entity-roles', entityId]);
        notification.success({ message: 'Entity role deleted successfully!' });
      })
      .catch((error) => {
        const text = get(error, 'response.data.message', 'Could not delete entity role');
        notification.error({ message: text });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDeleteCancelled = () => {
    setRoleToDelete(null);
    setRemoveRoleModalOpen(false);
  };

  return (
    <>
      <div className={css.card}>
        {roles.map((role) => (
          <div className={css.card} key={role.id}>
            <div className={css.icons}>
              <Icon
                className={css.icon}
                type="edit"
                onClick={() => handleEditRole(role)}
              />
              <Icon
                className={css.icon}
                type="trash"
                onClick={() => handleDeleteRole(role)}
              />
            </div>
            <div className={css.row}>
              <div className={css.entry}>
                <Typography size="sm" type="title" className={css.subtitle}>Role Title</Typography>
                <Typography>{role.title || '<Empty>'}</Typography>
              </div>
              <div className={css.entry}>
                <Typography size="sm" type="title" className={css.subtitle}>Role Type</Typography>
                <Typography>{role.type || '<Empty>'}</Typography>
              </div>
              <div className={css.entry}>
                <Typography size="sm" type="title" className={css.subtitle}>Department</Typography>
                <Typography>{role.department || '<Empty>'}</Typography>
              </div>
            </div>
            <div className={css.row}>
              <div className={css.entry}>
                <Typography size="sm" type="title" className={css.subtitle}>Axis Organization</Typography>
                <Typography>{role.employerEntity ? 'Yes' : 'No'}</Typography>
              </div>
              <div className={css.entry}>
                <Typography size="sm" type="title" className={css.subtitle}>Organization</Typography>
                <Typography>{role.employerEntity ? role.employerEntity.name : role.organization || '<Empty>'}</Typography>
              </div>
              <div className={css.entry}>
                <Typography size="sm" type="title" className={css.subtitle}>Is Primary</Typography>
                <Typography>{role.isPrimary ? 'Yes' : 'No'}</Typography>
              </div>
            </div>
            <div className={css.row}>
              <div className={css.entry}>
                <Typography size="sm" type="title" className={css.subtitle}>Start Date</Typography>
                <Typography>{role.startDate ? moment(role.startDate).format('DD MMM, YYYY') : '<Empty>'}</Typography>
              </div>
              <div className={css.entry}>
                <Typography size="sm" type="title" className={css.subtitle}>End Date</Typography>
                <Typography>{role.endDate ? moment(role.endDate).format('DD MMM, YYYY') : '<Empty>'}</Typography>
              </div>
              <div className={css.entry}>
                <Typography size="sm" type="title" className={css.subtitle}>Source</Typography>
                <Typography>{role.source || '<Empty>'}</Typography>
              </div>
            </div>
          </div>
        ))}
        <div>
          <div>
            <div className={css.row}>
              <FormItemWrapper
                className={css.input}
                isRequired
                label="Role Title"
                helpMessage=""
                helpMessageTitle="Role Title"
                errorMessage={roleObject.title ? '' : 'Role title is required'}
                maxLength={`${300 - (roleObject.title || '').length}`}
              >
                <TextInput
                  name="role-title"
                  value={roleObject.title || ''}
                  onChange={(e) => handleInputChange('title', e.target.value)}
                  maxLength={300}
                />
              </FormItemWrapper>
              <div style={{ marginLeft: '10px', marginRight: '10px' }} />
              <FormItemWrapper
                className={css.input}
                label="Role Type"
                helpMessage=""
                helpMessageTitle="Role Type"
                maxLength={`${300 - (roleObject.type || '').length}`}
              >
                <TextInput
                  name="role-type"
                  value={roleObject.type || ''}
                  onChange={(e) => handleInputChange('type', e.target.value)}
                  maxLength={300}
                />
              </FormItemWrapper>
            </div>
          </div>
          <div>
            <div className={css.row}>
              <FormItemWrapper
                className={css.input}
                label="Organization Source"
                helpMessage=""
                helpMessageTitle="Organization Source"
              >
                <Select
                  className={css.select}
                  placeholder="Select Organization Source"
                  name="organization-source"
                  getOptionLabel={(option) => option}
                  getOptionValue={(option) => option}
                  filterOption={(options) => options}
                  onChange={(value) => setOrganizationSource(value)}
                  value={organizationSource}
                  options={organizationSources}
                />
              </FormItemWrapper>
              <div style={{ marginLeft: '10px', marginRight: '10px' }} />
              {organizationSource === 'Axis' ? (
                <FormItemWrapper
                  className={css.input}
                  isRequired={organizationSource === 'Axis'}
                  label="Axis Entity"
                  helpMessageTitle="Entity"
                  errorMessage={(organizationSource === 'Axis' && !roleObject.employerEntity) ? 'An Axis entity is required' : ''}
                >
                  <SearchSelect
                    name="role-organization"
                    value={roleObject.employerEntity}
                    onChange={(value) => handleInputChange('employerEntity', value)}
                    getOptionLabel={(entity) => (entity ? entity.name : null)}
                    searchUrl={searchEntitiesQuery}
                  />
                </FormItemWrapper>
              ) : (
                <FormItemWrapper
                  className={css.input}
                  label="Non-Axis Organization"
                  isRequired={organizationSource === 'Non-Axis'}
                  helpMessage=""
                  helpMessageTitle="Non-Axis Organization"
                  errorMessage={(organizationSource === 'Non-Axis' && !roleObject.organization) ? 'An organization is required' : ''}
                >
                  <TextInput
                    name="non-axis-organization"
                    value={roleObject.organization || ''}
                    onChange={(e) => handleInputChange('organization', e.target.value)}
                    maxLength={300}
                  />
                </FormItemWrapper>
              )}
            </div>
          </div>
          <div>
            <div className={css.row}>
              <FormItemWrapper
                className={css.input}
                label="Department"
                helpMessage=""
                helpMessageTitle="Department"
                maxLength={`${300 - (roleObject.department || '').length}`}
              >
                <TextInput
                  name="department"
                  value={roleObject.department || ''}
                  onChange={(e) => handleInputChange('department', e.target.value)}
                  maxLength={300}
                />
              </FormItemWrapper>

              <div style={{ marginLeft: '10px', marginRight: '10px' }} />
              <FormItemWrapper
                className={css.input}
                isRequired
                label="Source"
                helpMessage=""
                helpMessageTitle="Source"
                errorMessage={roleObject.source ? '' : 'Role source is required'}
                maxLength={`${300 - (roleObject.source || '').length}`}
              >
                <TextInput
                  name="source"
                  value={roleObject.source || ''}
                  onChange={(e) => handleInputChange('source', e.target.value)}
                  maxLength={500}
                />
              </FormItemWrapper>
            </div>
          </div>
          <div>
            <div className={css.row}>
              <FormItemWrapper
                className={css.input}
                label="Start Date"
                helpMessage=""
                helpMessageTitle="Start Date"
              >
                <DateInput
                  name="role-start-date"
                  placeholder="Start date"
                  value={roleObject.startDate}
                  onChange={(date) => handleInputChange('startDate', date)}
                  onClear={() => handleInputChange('startDate', null)}
                  valuePosition="right"
                  closeOnValueSelect
                />
              </FormItemWrapper>
              <div style={{ marginLeft: '10px', marginRight: '10px' }} />
              <FormItemWrapper
                className={css.input}
                label="End Date"
                helpMessage=""
                helpMessageTitle="End Date"
              >
                <DateInput
                  name="role-end-date"
                  placeholder="End date"
                  value={roleObject.endDate}
                  onChange={(date) => handleInputChange('endDate', date)}
                  onClear={() => handleInputChange('endDate', null)}
                  valuePosition="right"
                  closeOnValueSelect
                />
              </FormItemWrapper>
              <div style={{ marginLeft: '10px', marginRight: '10px' }} />
              <FormItemWrapper
                className={css.input}
                label="Primary Role Selection"
                helpMessage=""
                helpMessageTitle="Primary Role Selection"
              >
                <Checkbox
                  value={roleObject.isPrimary}
                  onChange={(e) => handleInputChange('isPrimary', e.target.checked)}
                />
              </FormItemWrapper>
            </div>
          </div>
          <Button
            className={css.save}
            disabled={loading || !roleObject.title || !roleObject.source}
            onClick={handleSaveRole}
            label="Save Role"
            type="button"
          />
        </div>
        <Modal
          className={css.modal}
          width={500}
          open={removeRoleModalOpen}
          maskStyle={{ backgroundColor: 'var(--color-bg-modal)' }}
        >
          <div className={css.content}>
            <Typography>Are you sure you want to delete the role</Typography>
            <Typography type="title" size="sm">
              {removeRoleModalOpen ? roleToDelete.title : null}
            </Typography>
            <div className={css.buttons}>
              <ButtonText
                disabled={loading}
                color="secondary"
                className={css.buttonText}
                label="Delete"
                onClick={() => handleDeleteConfirmed()}
              />
              <Button
                label="Cancel"
                disabled={loading}
                onClick={() => handleDeleteCancelled()}
              />
            </div>
          </div>
        </Modal>
        <div className={css.loaderContainer}>
          {loading && (<Loading className={css.loader} />)}
        </div>
      </div>
    </>
  );
};

export default EntityRole;
