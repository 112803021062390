import React from 'react';
import get from 'lodash/get';
import {
  Row, Col, Input, Button, message, Spin,
} from 'antd';
import helper from './helper';
import css from './Import.module.css';

const Import = ({ type }) => {
  const [url, setUrl] = React.useState('');
  const [loading, setLoader] = React.useState(false);
  const [result, setResult] = React.useState({ status: false, errors: [] });
  const [themesResult, setThemesResult] = React.useState({ status: false, errors: [] });
  const [currentThemeFile, setCurrentThemeFile] = React.useState();
  const [isFileSelected, setFileSelected] = React.useState(false);

  const onClick = () => {
    setLoader(true);
    helper
      .importData(url, type)
      .then((data) => {
        setResult(data);
      })
      .catch((err) => {
        message.error(err.message);
        setResult((prev) => ({
          status: false,
          errors: [
            ...prev.errors,
            {
              message: get(err, 'response.data.message', err.message),
              data: '',
            },
          ],
        }));
      })
      .finally(() => setLoader(false));
  };

  const changeHandler = (event) => {
    setCurrentThemeFile(event.target.files[0]);
    setFileSelected(true);
  };

  const onUploadClick = () => {
    setThemesResult({ status: false, errors: [] });
    setLoader(true);
    const formData = new FormData();
    formData.append('file', currentThemeFile);

    helper
      .uploadTheme(formData)
      .then((data) => {
        setThemesResult(data);
      })
      .catch((err) => {
        message.error(err.message);
        setThemesResult((prev) => ({
          status: false,
          errors: [
            ...prev.errors,
            {
              message: get(err, 'response.data.message', err.message),
              data: '',
            },
          ],
        }));
      })
      .finally(() => setLoader(false));
  };

  return (
    <Spin spinning={loading}>
      <Row className={css.container}>
        <Row className={css.row}>Insert link to google sheet</Row>
        <Row className={css.row}>
          <Input
            className={css.input}
            value={url}
            onChange={({ target: { value: v } }) => setUrl(v)}
          />
          <Button type="primary" className={css.button} onClick={onClick}>
            Import
          </Button>
        </Row>
        <Col className={css.column}>
          <Row>{result.status ? 'Imported' : 'Not Imported'}</Row>
          <Row>Errors:</Row>
          {result.errors.map((err, ind) => (
            // eslint-disable-next-line react/no-array-index-key
            <Row key={ind}>
              Message:
              <span style={{ color: 'red' }}>{` ${err.message} `}</span>
            </Row>
          ))}
        </Col>
        <h4 className={css.section}>Upload sheet for Current Themes facet</h4>
        <Row className={css.row}>
          <Col className={css.column}>
            <Input
              type="file"
              name="file"
              onChange={changeHandler}
            />
          </Col>
        </Row>
        {isFileSelected ? (
          <Button type="primary" className={css.button} onClick={onUploadClick}>
            Upload
          </Button>
        ) : (
          <p>Select file for upload.</p>
        )}
        <Col className={css.column}>
          <Row>{themesResult.status ? 'Import' : 'Not Imported'}</Row>
          <Row>Errors:</Row>
          {themesResult.errors.map((err, ind) => (
            // eslint-disable-next-line react/no-array-index-key
            <Row key={ind}>
              Message:
              <span style={{ color: 'red' }}>{` ${err.message} `}</span>
            </Row>
          ))}
        </Col>
      </Row>
    </Spin>
  );
};

export default Import;
