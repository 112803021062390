import client from '../../common/utils/http';
import {
  formatDateRelative, isSameDay, parseDate, startOfDay, parseDateIgnoreTZ, transformDateTZ, convertDate,
} from '../../common/utils/date';

const PAGE_SIZE = 25;

export const getArticlesInfo = (articlesTotal) => {
  if (articlesTotal === 1) return '1 article';
  return `${articlesTotal} articles`;
};

const getQueryParams = ({
  topicId, page, date, since, briefIds,
}) => {
  const params = {
    $limit: PAGE_SIZE,
    $skip: PAGE_SIZE * (page - 1),
  };
  if (topicId) params.topicId = topicId;
  if (since) params.since = since;
  if (briefIds) params.briefIds = briefIds;
  if (date) {
    const dateWithOffset = transformDateTZ(date);
    params.date = dateWithOffset;
  }
  return params;
};

export const fetchArticlesPage = ({
  topicId, pageParam = {}, briefIds, since, date,
}) => {
  const { page = 1 } = pageParam;
  const params = getQueryParams({
    topicId, page, briefIds, since, date,
  });
  return client.get('/articles', { params })
    .then((res) => res.data)
    .then(({
      total: totalArticles,
      limit,
      skip,
      data: articles,
    }) => ({
      hasMore: (limit + skip) < totalArticles,
      total: articles.length,
      articles: articles.map((article) => ({
        ...article,
        date: parseDateIgnoreTZ(article.date),
      })),
    }));
};

export const processArticles = (pages) => {
  const groupedPages = pages.reduce((groupedArticles, currentPage) => {
    const groupedPage = currentPage.articles.reduce((articles, article) => {
      const articleDate = parseDate(article.date);
      const EMPTY_DATE = { date: startOfDay(parseDate(articleDate)), articles: [] };
      const value = articles.find((e) => isSameDay(e.date, articleDate)) || EMPTY_DATE;
      value.articles = [
        ...(value.articles || []),
        article,
      ];
      return [
        ...articles.filter((e) => !isSameDay(e.date, articleDate)),
        value,
      ];
    }, groupedArticles);
    return groupedPage;
  }, []);
  const pagesList = groupedPages
    .sort((page1, page2) => page2.date - page1.date)
    .map((page) => ({ ...page, date: formatDateRelative(parseDate(page.date)) }));
  return pagesList;
};

export const getArticleTags = (article) => {
  const mergedEntities = [
    ...(article.actors || []).map((a) => a.name),
    ...(article.plots || []).map((p) => p.entityName),
    ...(article.contexts || []).map((c) => c.entityName),
  ];
  return [...new Set(mergedEntities)];
};

export const getFilterCount = ({
  date, topicId, topicName, sectorBriefs, subsectorBriefs, industryBriefs,
}) => {
  let total = 0;
  if (date) total += 1;
  if (topicId && topicName) total += 1;
  if (sectorBriefs.length > 0) total += 1;
  if (subsectorBriefs.length > 0) total += 1;
  if (industryBriefs.length > 0) total += 1;
  return total;
};

export const getFilterDescription = ({
  date, topicId, topicName, sectorBriefs, subsectorBriefs, industryBriefs,
}) => {
  let description = 'Viewing all articles ';
  const fragments = [];
  if (date) fragments.push(`from ${formatDateRelative(convertDate(date))}`.toLowerCase());
  if (topicId && topicName) fragments.push(`of ${topicName}`);
  const briefList = [...sectorBriefs, ...subsectorBriefs, ...industryBriefs]
    .map((brief) => brief.briefName)
    .join(', ');
  if (briefList) fragments.push(`tagged to ${briefList}`);
  description += fragments.join(' ');
  return description;
};
