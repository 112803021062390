import React, { useEffect } from 'react';
import { Row, Col, Button } from 'antd';
import { useStore } from 'effector-react';
import styled from 'styled-components';
import { slide as Menu } from 'react-burger-menu';

import {
  $sidePanel,
  handleSidePanel,
} from '../../effectorModel/sidePanel';

import FacetComments from './FacetComments';
import FacetLogs from './FacetLogs';
import FacetRejections from './FacetRejections';

const SidePanel = () => {
  const { isOpen, options } = useStore($sidePanel);

  useEffect(() => () => handleSidePanel({ isOpen: false }), []);

  return (
    <Menu
      width="465px"
      isOpen={isOpen}
      pageWrapId="root"
      outerContainerId="root"
      noOverlay
      disableCloseOnEsc
      disableOverlayClick
      disableAutoFocus
      customBurgerIcon={false}
      customCrossIcon={false}
    >
      {
        isOpen ? (
          <Wrapper className="scroll-block">
            <Row type="flex" justify="space-between">
              <Title>{options.facetTitle}</Title>
              <Button
                icon="close"
                onClick={() => handleSidePanel({ isOpen: false })}
              />
            </Row>
            <Row>
              <Col>
                {options.isCommentComponent ? <FacetComments facetId={options.facetId} /> : null}
                {options.isLogsComponent ? <FacetLogs facetId={options.facetId} /> : null}
                {options.isRejectionsComponent ? <FacetRejections facetId={options.facetId} /> : null}
              </Col>
            </Row>
          </Wrapper>
        ) : null
      }
    </Menu>
  );
};

export default SidePanel;

const Wrapper = styled.div`
  height: 100vh;
  padding: 20px;
  background-color: white;
  border-right: 1px solid gray;
  overflow-y: auto;
`;

const Title = styled.h1`
  width: 85%;
  font-size: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
  white-space: nowrap;
`;
