import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import entityEditorSelector from '../../selectors/entityEditor';
import authSelector from '../../selectors/users';

import {
  loadEntity,
  loadTemplate,
  facetAdd,
  saveEntity,
  clearStore,
  changeMeta,
  useView,
  setOpenedSections,
  saveAssignedEntity,
  facetAddToSection,
  facetRemove,
  sidebarSectionOpenSet,
  setEntityStatus,
  setEntityHidden,
  facetOrderChange,
} from './actions';

import EntityEditor from './EntityEditor';

const mapSateToProps = (state) => ({
  isLoading: entityEditorSelector.getIsLoading(state),
  viewMode: entityEditorSelector.getViewMode(state),
  entity: entityEditorSelector.getFilteredEntity(state),
  users: authSelector.getUsers(state),
  userRole: authSelector.getRole(authSelector.getUser(state)),
  entityEditorState: entityEditorSelector.getEntityEditorState(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    loadEntity,
    loadTemplate,
    facetAdd,
    facetAddToSection,
    saveEntity,
    clearStore,
    changeMeta,
    facetOrderChange,
    useView,
    setOpenedSections,
    saveAssignedEntity,
    facetRemove,
    sidebarSectionOpenSet,
    setEntityStatus,
    setEntityHidden,
  }, dispatch),
});

export default connect(mapSateToProps, mapDispatchToProps)(EntityEditor);
