import React, { useState, useRef, useEffect } from 'react';
import delay from 'lodash/delay';
import isEmpty from 'lodash/isEmpty';
import {
  Button, Collapse, Row, Col,
} from 'antd';
import GeoSuggest from 'antd-geosuggest';

import Description from './common/Description';
import Info from './common/Info';
import PropertyType from './PropertyType';
import withRemoveButton from './HOC/withButtonRemove';
import ElementState from '../ElementState';
import useDebounceSave from './useDebounceSave';

const { Panel } = Collapse;

const DEFAULT_ZOOM = 3;

function LocationType({
  options,
  fieldSet,
  actions,
  fieldType,
}) {
  const {
    rules: {
      Field: fieldTitle,
    },
    name: fieldName,
  } = fieldType;
  const {
    fields: {
      address,
      lat,
      lng,
      selectedLocationName,
      selectedLocationValue,
      zoom,
    },
    rules: {
      'Location-Type': type,
      Label: label,
      Info: info,
      Chars: chars,
      Description: description,
    },
  } = fieldSet;
  const { current: mutable } = useRef({
    autocomplete: null,
    googlemap: null,
    marker: null,
  });
  const [mapVisible, setMapVisible] = useState(false);
  const mapRef = useRef(null);
  const geoSuggestRef = useRef(null);

  const debounceSave = useDebounceSave({ saveField: actions.saveField, fieldSet });

  const setMapData = () => {
    const latValue = Number(lat.value) || 0;
    const lngValue = Number(lng.value) || 0;
    if (!mutable.marker && (latValue && lngValue)) {
      const myLatlng = new window.google.maps.LatLng(latValue, lngValue);
      mutable.marker = new window.google.maps.Marker({
        position: myLatlng,
        map: mutable.googlemap,
        draggable: false,
      });
      mutable.googlemap.setCenter(myLatlng, DEFAULT_ZOOM);
    }
  };

  useEffect(() => {
    mutable.googlemap = new window.google.maps.Map(mapRef.current, {
      center: { lat: 0, lng: 0 },
      zoom: parseInt(zoom.value, 10) || DEFAULT_ZOOM,
    });
    window.google.maps.event.addListener(mutable.googlemap, 'zoom_changed', () => {
      const zoomValue = mutable.googlemap.getZoom() || DEFAULT_ZOOM;
      if (zoomValue !== parseInt(zoom.value, 10)) {
        const changes = {
          [zoom.name]: zoomValue,
        };
        debounceSave(changes);
        actions.changeFieldSet(changes);
      }
    });
    window.google.maps.event.addListener(mutable.googlemap, 'click', (event) => {
      const clickedLocation = event.latLng;
      const geocoder = new window.google.maps.Geocoder();
      const zoomValue = mutable.googlemap.getZoom() || DEFAULT_ZOOM;
      const changes = {
        [zoom.name]: zoomValue,
        [lat.name]: `${clickedLocation.lat()}`,
        [lng.name]: `${clickedLocation.lng()}`,
      };
      geocoder.geocode({
        latLng: event.latLng,
      }, (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            changes[address.name] = results[0].formatted_address;

            const key = results[0].place_id;
            const value = results[0].formatted_address;
            geoSuggestRef.current.state.value = [{ key, label: value }];
          }
        }
        debounceSave(changes, () => actions.changeFieldSet(changes));
      });
      if (!mutable.marker) {
        mutable.marker = new window.google.maps.Marker({
          position: clickedLocation,
          map: mutable.googlemap,
          draggable: false,
        });
      } else {
        mutable.marker.setPosition(clickedLocation);
      }
    });
    delay(setMapData, 1000);
  }, []);

  useEffect(() => {
    if (!address.value && geoSuggestRef.current.state.value.length) {
      geoSuggestRef.current.clearValue();
    }
  }, [address.value]);

  const onGeoSuggestChange = (value) => {
    let changes = null;
    if (isEmpty(value)) {
      if (mutable.marker) {
        mutable.marker.setMap(null);
        mutable.marker = null;
      }

      changes = {
        [address.name]: '',
        [zoom.name]: DEFAULT_ZOOM,
        [lat.name]: '',
        [lng.name]: '',
      };
    } else {
      const latValue = value[0].lat;
      const lngValue = value[0].lng;
      const myLatlng = new window.google.maps.LatLng(latValue, lngValue);

      changes = {
        [address.name]: value[0].address,
        [zoom.name]: DEFAULT_ZOOM,
        [lat.name]: latValue,
        [lng.name]: lngValue,
      };
      mutable.googlemap.setCenter(myLatlng, DEFAULT_ZOOM);
      if (!mutable.marker) {
        mutable.marker = new window.google.maps.Marker({
          position: myLatlng,
          map: mutable.googlemap,
          draggable: false,
        });
        mutable.googlemap.setCenter(myLatlng, DEFAULT_ZOOM);
      }
    }
    debounceSave(changes, () => actions.changeFieldSet(changes));
  };

  return (
    <div className="facet-type-field">
      <Collapse
        bordered={false}
        defaultActiveKey={!fieldSet.filled ? fieldSet.key : null}
      >
        <Panel
          key={fieldSet.key}
          forceRender
          header={(
            <div>
              {fieldTitle || fieldName}
              <ElementState saved={address.id} />
            </div>
          )}
        >
          <Row type="flex" justify="space-between">
            <div className="label-text">{label}</div>
            <Info info={info} />
          </Row>

          <Row type="flex" justify="space-between" gutter={16}>
            <Col span={3}>
              <Button
                onClick={() => setMapVisible(!mapVisible)}
                style={{ width: '100%' }}
                icon="compass"
              />
            </Col>

            <Col span={21}>
              <GeoSuggest
                ref={geoSuggestRef}
                defaultValue={
                  address.value
                    ? [{ key: address.id, label: address.value }]
                    : null
                }
                onChange={onGeoSuggestChange}
              />
            </Col>
          </Row>

          <Description
            value={description}
            entityKey={options.entityKey}
          />

          <div
            id="map"
            className="mrg-top-10"
            ref={mapRef}
            style={!mapVisible
              ? { visibility: 'hidden', height: 0 }
              : { height: 300 }}
          />

          {
            type.type && type.type !== 'off' && (
              <div className="column mrg-top-10" style={{ width: 250 }}>
                <span className="label-text">Location Type</span>
                <PropertyType
                  rules={{ type, chars }}
                  onChange={(fields) => {
                    debounceSave(
                      fields,
                      () => actions.changeFieldSet(fields),
                    );
                  }}
                  fieldSelectedId={selectedLocationValue}
                  fieldValue={selectedLocationName}
                />
              </div>
            )
          }
        </Panel>
      </Collapse>
    </div>
  );
}

export default withRemoveButton(LocationType);
