import { put, select } from 'redux-saga/effects';
import {
  saveField, removeFields, removeLinks, removeCEFs,
} from '../actions';

const extractFieldObjects = (fs) => (fs.fields ? Object.values(fs.fields) : []);

export default function* clearFacet({ payload: { entityKey, sectionKey, facetKey } }) {
  const state = yield select();
  const facet = state.entityEditor.entities.find((e) => e.key === entityKey)
    .sections.find((s) => s.key === sectionKey)
    .facets.find((f) => f.key === facetKey);
  const fields = facet.wrappers.flatMap((w) => w.fieldSets.flatMap(extractFieldObjects))
    .map((f) => ({ ...f, value: '' }));
  yield put(removeFields(facet.fieldsToRemove));
  yield put(removeLinks(facet.linksToRemove));
  yield put(removeCEFs(facet.cefsToRemove));
  delete facet.fieldsToRemove;
  delete facet.linksToRemove;
  delete facet.cefsToRemove;
  // eslint-disable-next-line no-restricted-syntax
  for (const field of fields) {
    yield put(saveField(field));
  }
}
