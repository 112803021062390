import React from 'react';
import { Input } from 'antd';

const SearchColumn = ({
  label,
  style,
  marginRight = 30,
  onClickSearch,
}) => (
  <div
    className="ant-row-flex ant-row-flex-space-between"
    style={{ alignItems: 'center', ...style }}
  >
    <Input
      autoComplete="off"
      placeholder={label}
      onChange={(event) => onClickSearch(event.target.value)}
      onClick={(ev) => ev.stopPropagation()}
      style={{ width: '100%', marginRight }}
    />
  </div>
);

export default SearchColumn;
