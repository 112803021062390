import React, { useEffect, useState } from 'react';
import { Modal } from '@axis/xyz.admin.modal';
import { Typography } from '@axis/xyz.admin.typography';
import { FormItemWrapper } from '@axis/xyz.admin.form.form-item-wrapper';
import { TextInput } from '@axis/xyz.admin.form.text-input';
import { Button } from '@axis/xyz.admin.buttons.button';
import { Loading } from '@axis/xyz.admin.loading';
import css from './ModalCreateBrief.module.css';
import SelectBriefType from '../../components/Form/SelectBriefType/SelectBriefType';
import SearchSelect from '../../components/Form/SearchSelect/SearchSelect';

const ModalCreateBrief = ({
  visible, onCreate, onClose, loading,
}) => {
  const [brief, setBrief] = useState({
    name: '',
    type: '',
    parents: [],
  });
  const [searchType, setSearchType] = useState('');

  useEffect(() => {
    setBrief({
      name: '',
      type: '',
    });
  }, [visible]);

  const handleInputChange = (field, value) => {
    setBrief({ ...brief, [field]: value });

    if (field === 'type') {
      if (value === 'industry') {
        setSearchType('sub-sector');
      }
      if (value === 'sub-sector') {
        setSearchType('sector');
      }
    }
  };

  const submitEnabled = !!brief.name && !!brief.type;

  const handleCreate = async () => {
    onCreate(brief);
  };

  return (
    <Modal
      name="new-brief"
      className={css.modal}
      visible={visible}
      onClose={onClose}
    >
      <div className={css.content}>
        <Typography
          className={css.title}
          type="title"
          tag="h2"
          size="lg"
        >
          Create a new brief
        </Typography>
        <div className={css.form}>
          <FormItemWrapper
            className={css.input}
            isRequired
            label="Name"
          >
            <TextInput
              name="brief-name"
              value={brief.name || ''}
              onChange={(e) => handleInputChange('name', e.target.value)}
            />
          </FormItemWrapper>
          <FormItemWrapper
            className={css.input}
            label="Type"
          >
            <SelectBriefType
              value={brief.type}
              onChange={(selectedBriefType) => handleInputChange('type', selectedBriefType)}
            />
          </FormItemWrapper>
          {brief.type === 'industry' && (
          <FormItemWrapper
            className={css.input}
            label="Parent Sub-Sector Briefs"
            errorMessage={!searchType && brief.type ? `The brief of type "${brief.type}" cannot have a parent` : null}
          >
            <SearchSelect
              isClearable
              isDisabled={!searchType}
              placeholder="Enter parent briefs"
              name="filter-name"
              isMulti
              value={brief.parents}
              onChange={(values) => handleInputChange('parents', values)}
              searchUrl="/briefs?$limit=50&$type=sub-sector&name="
              getOptionLabel={(option) => (option ? option.name : '')}
            />
          </FormItemWrapper>
          )}
          {brief.type === 'sub-sector' && (
          <FormItemWrapper
            className={css.input}
            label="Parent Sector Briefs"
            errorMessage={!searchType && brief.type ? `The brief of type "${brief.type}" cannot have a parent` : null}
          >
            <SearchSelect
              isClearable
              isDisabled={!searchType}
              placeholder="Enter parent briefs"
              name="filter-name"
              isMulti
              value={brief.parents}
              onChange={(values) => handleInputChange('parents', values)}
              searchUrl="/briefs?$limit=50&$type=sector&name="
              getOptionLabel={(option) => (option ? option.name : '')}
            />
          </FormItemWrapper>
          )}

          {loading && (
          <div className={css.loadingContainer}>
            <Loading className={css.loader} />
          </div>
          )}

          <Button
            className={css.submit}
            disabled={!submitEnabled}
            onClick={handleCreate}
            label="Create"
            type="button"
          />
        </div>
      </div>
    </Modal>
  );
};

export default ModalCreateBrief;
