import sortBy from 'lodash/sortBy';
import orderBy from 'lodash/orderBy';
import upperFirst from 'lodash/upperFirst';
import get from 'lodash/get';

import moment from 'moment';
import client from '../../common/utils/http';
import { catchError } from '../../saga/sagas';

/**
 * @param {number} page - page
 * @param {object} search - params for search: { type, name, assignedToId, stage, orderField, orderDirection }
 * */
const getFacets = (page = 1, search = {}, limit) => {
  const params = {
    sectionList: true,
    levelList: true,
    nameList: true,
    $limit: limit,
    $skip: limit * (page - 1),
  };

  const isNotEmptySearch = Object.keys(search).some((key) => search[key]);
  if (isNotEmptySearch) {
    if (search.topic) {
      params.topic = search.topic;
    }

    if (search.updatedAt) {
      const date = moment(search.updatedAt);
      date.set({
        hour: 0, minute: 0, second: 0, millisecond: 0,
      });
      params['$and[0][updatedAt][$gte]'] = date.format('YYYY-MM-DDTHH:mm:ss.100[Z]');
      params['$and[1][updatedAt][$lt]'] = date.add(1, 'd').format('YYYY-MM-DDTHH:mm:ss.100[Z]');
      // params[`updatedAt[0][$lt]`] = date.add(1, 'd').valueOf();
    }

    if (search.createdAt) {
      const date = moment(search.createdAt);
      date.set({
        hour: 0, minute: 0, second: 0, millisecond: 0,
      });
      params['$and[0][createdAt][$gte]'] = date.format('YYYY-MM-DDTHH:mm:ss.100[Z]');
      params['$and[1][createdAt][$lt]'] = date.add(1, 'd').format('YYYY-MM-DDTHH:mm:ss.100[Z]');
      // params[`updatedAt[0][$lt]`] = date.add(1, 'd').valueOf();
    }

    if (search.type) {
      let { type } = search;
      let subType = '';
      if (search.type.indexOf(':') !== -1) {
        const typeAndSubType = search.type.split(':');
        [type, subType] = typeAndSubType;
      }

      params['type[$iLike]'] = `%${type}%`;
      if (subType) {
        params['subType[$iLike]'] = `%${subType}%`;
      }
    }

    if (search.label) {
      params.label = search.label;
    }

    if (search.description) {
      params.description = search.description;
    }

    if (search.sources) {
      params.description = search.sources;
    }

    if (search.name) {
      params['entityName[$iLike]'] = `%${search.name.split(' ').join('%')}%`;
    }

    if (search.orderField) {
      params[`$sort[${search.orderField}]`] = (search.orderDirection === 'ASC' ? -1 : 1);
    }

    if (search.section) {
      params.section = search.section;
    }

    if (search.facet) {
      params.typeName = search.facet;
    }

    if (search.level) {
      params.level = search.level;
    }

    if (search.stage) {
      params.stage = search.stage;
    }

    if (search.assignedToId) {
      params.assignedToId = search.assignedToId;
    }

    if (search.editors) {
      params.editorId = search.editors;
    }
  }

  return client
    .get('/facets', { params })
    .then((response) => {
      const {
        data: {
          total, data, sectionList, nameList, levelList,
        },
      } = response;
      return {
        data: data.map((e) => {
          const fields = {
            label: '',
            sources: [],
            sourcesId: [],
            description: '',
          };
          orderBy(e.fields, ['order'], ['desc']).forEach((f) => {
            if (f.type.name === 'Label' && f.name === 'value') {
              fields.label = f.value;
              fields.labelId = f.id;
            }
            if (f.type.name === 'Detail' && f.name === 'value') {
              fields.description = f.value;
              fields.descriptionId = f.id;
            }
            if (f.type.name === 'Provenance' && f.name === 'urlInput') {
              fields.sources.push(f.value);
              fields.sourcesId.push(f.id);
            }
          });
          fields.sources = fields.sources.join(', ');

          return (
            {
              ...e,
              assignedToId: get(e, 'meta.assignedToId', ''),
              stage: get(e, 'meta.stage', null),
              section: get(e, 'type.section', null),
              level: get(e, 'type.level', null),
              facet: get(e, 'type.name', null),
              facetId: get(e, 'id'),
              name: get(e, 'entity.name', null),
              id: get(e, 'meta.id', ''),
              ...fields,
            });
        }),
        total,
        page,
        sectionList,
        nameList,
        levelList,
      };
    })
    .catch(catchError);
};

const loadUsers = () => client
  .get('/users', { params: { $limit: 0 } })
  .then((response) => response.data.total)
  .then((total) => client.get('/users', { params: { $limit: total } }))
  .then((response) => response.data.data)
  .then((users) => sortBy(users, 'name'))
  .catch(catchError);

const updateMeta = (metaToUpdate) => client
  .patch('/facet-meta-data/', metaToUpdate)
  .catch(catchError);

const saveFields = (data) => {
  const toUpdate = [];
  const toCreate = [];
  data.forEach((row, i) => {
    if (row.labelId && row.label) {
      toUpdate.push({ id: row.labelId, value: row.label });
    } else if (row.label) {
      toCreate.push({
        facetId: row.facetId,
        name: 'value',
        order: i,
        facetTypeId: row.facetTypeId,
        value: row.label,
        typeName: 'Label',
      }); // any value for order
    }
    if (row.descriptionId && row.description) {
      toUpdate.push({ id: row.descriptionId, value: row.description });
    } else if (row.description) {
      toCreate.push({
        facetId: row.facetId,
        name: 'value',
        order: i,
        facetTypeId: row.facetTypeId,
        value: row.description,
        typeName: 'Detail',
      }); // any value for order
    }
    const sources = row.sources.split(',').map((s) => s.trim()).filter((s) => s);
    sources.forEach((s, j) => {
      if (row.sourcesId[j] && s) {
        toUpdate.push({ id: row.sourcesId[j], value: s });
      } else if (s) {
        toCreate.push({
          facetId: row.facetId,
          name: 'urlInput',
          order: i,
          facetTypeId: row.facetTypeId,
          value: s,
          typeName: 'Provenance',
        });
      }
    });
  });
  return Promise
    .all([
      client.patch('/facet-fields', toUpdate),
      client.post('/facet-fields?fromMassAssign=true', toCreate),
    ]);
};

const getHost = (url) => {
  try {
    const { hostname } = new URL(url);
    if (!hostname) return '';
    const arr = hostname.split('.');
    return upperFirst(arr[arr.length - 2]);
  } catch (e) {
    return 'link';
  }
};

const exportCSV = () => {
  client.get('/facets?export=true').then(() => {
  }).catch((err) => console.error(err));
};

const getTopics = async (name, setState) => {
  const encodedPercent = encodeURIComponent('%');
  const data = await client.get(`/countries?name[$iLike]=${encodedPercent}${name}${encodedPercent}&$limit=1000&$sort[name]=1`);
  setState((prev) => ({ ...prev, topics: orderBy(data.data.data, 'name', 'asc') }));
};

export default {
  getTopics,
  getHost,
  loadUsers,
  updateMeta,
  getFacets,
  saveFields,
  exportCSV,
};
