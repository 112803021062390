import client from '../../common/utils/http';

export const PAGE_SIZE = 100;

const getQueryParams = ({
  filters, page, orderByName,
}) => {
  const params = {
    $limit: PAGE_SIZE,
    $skip: PAGE_SIZE * (page - 1),
  };

  const {
    landscapeName,
  } = filters;
  if (landscapeName) params.name = landscapeName.name;
  if (orderByName) {
    params['$sort[name]'] = orderByName === 'asc' ? -1 : 1;
  } else {
    params['$sort[updated_at]'] = -1;
    params['$sort[created_at]'] = -1;
  }
  return params;
};

export const fetchLandscapesPage = ({
  filters, orderByName = '', pageParam = {}, type,
}) => {
  const { page = 1 } = pageParam;
  const params = getQueryParams({
    filters, orderByName, page, type,
  });
  return client.get('/landscapes', { params })
    .then((res) => res.data)
    .then(({ total, data }) => ({
      total,
      landscapes: data,
    }));
};

export const deleteLandscape = (id) => client.delete(`/landscapes/${id}`);

export const createLandscape = (landscape) => client.post('/landscapes', landscape);

export const updateLandscape = ({ id, landscape }) => client.patch(`/landscapes/${id}`, landscape);
