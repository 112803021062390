import { Select } from '@axis/xyz.admin.form.select';
import React from 'react';
import { useQuery } from 'react-query';
import css from './SelectBrief.module.css';
import client from '../../../common/utils/http';

const SelectBrief = ({
  topicId = '', briefType = 'sector', value, onChange,
}) => {
  const { isLoading, data: briefs = [] } = useQuery(
    ['brief-tree', briefType, topicId],
    async () => client.get(`/brief-tree?topicId=${topicId}`)
      .then(({ data }) => data)
      .then((briefTypes) => briefTypes[briefType] || [])
      .then((briefList) => briefList.reduce((groups, brief) => {
        const group = groups.find((briefGroup) => briefGroup.briefName === brief.briefName);
        if (group) {
          group.briefIds.push(brief.briefId);
        } else {
          groups.push({
            briefName: brief.briefName,
            briefIds: [brief.briefId],
          });
        }
        return groups;
      }, []))
      .catch(console.error),
    {
      refetchOnWindowFocus: false,
    },
  );

  return (
    <Select
      className={css.select}
      placeholder={`Select ${briefType} briefs...`}
      name="brief-tree"
      isMulti
      isDisabled={isLoading}
      getOptionLabel={(option) => option.briefName}
      getOptionValue={(option) => option.briefName}
      filterOption={(option, input) => option.value.toLowerCase().includes(input.toLowerCase())}
      onChange={onChange}
      value={value}
      options={briefs}
    />
  );
};

export default SelectBrief;
