import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { relogin } from '../../Auth/actions';
import Form from '../../../components/Auth/AuthForm';

const ModalContainer = (props) => (
  <>
    {props.modalAuth && <Form authorization={props.actions.relogin} />}
  </>
);

const mapStateToProps = (state) => ({
  modalAuth: state.auth.modal,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ relogin }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer);
