/* eslint-disable linebreak-style */
import {
  AUTH, SET_LOADING, SET_USER, RELOGIN, REMOVE_USER, EDIT_USER,
} from './constants';

export function authorization({ email, password }) {
  return {
    type: AUTH,
    payload: { email, password },
  };
}
export function relogin({ email, password }) {
  return {
    type: RELOGIN,
    payload: { email, password },
  };
}
export function setLoading(param) {
  return {
    type: SET_LOADING,
    payload: param,
  };
}
export function removeUser(id) {
  return {
    type: REMOVE_USER,
    payload: id,
  };
}
export function editUser(user) {
  return {
    type: EDIT_USER,
    payload: user,
  };
}

export const setUserDataAction = (data) => ({ type: SET_USER, payload: data });
