const ROLE_USER = 'user';
const ROLE_ADMIN = 'admin';
const ROLE_ANALYST = 'analyst';
const ROLE_TRIAL = 'trial';

const ROLES = [
  ROLE_USER,
  ROLE_ANALYST,
  ROLE_ADMIN,
  ROLE_TRIAL,
];

export {
  ROLE_USER,
  ROLE_ADMIN,
  ROLE_ANALYST,
  ROLE_TRIAL,
  ROLES,
};
