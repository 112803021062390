import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { Icon } from '@axis/xyz.assets.icon';
import { Typography } from '@axis/xyz.admin.typography';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import EntitySection from './components/EntitySection/EntitySection';
import SuggestedUpdateItem from './components/SuggestedUpdateItem/SuggestedUpdateItem';
import { formatDateTime, parseDate } from '../../common/utils/date';
import styles from './Article.module.css';
import Hint from './components/Hint/Hint';

const Entity = ({
  id, name, topic, keywords,
}) => (
  <div className={styles.entity}>
    <Link className={styles.name} to={`/analyst/entities/editor/${id}`} target="_blank">
      {name}
    </Link>
    {topic && (
      <Typography className={styles.topic}>
        {topic}
      </Typography>
    )}
    <div className={styles.tags}>
      {keywords.map((word) => (
        <Typography type="text" size="sm" className={styles.tag} key={word}>{word}</Typography>
      ))}
    </div>
  </div>
);

const Brief = ({ entityId, entityName, topicName }) => (
  <div className={styles.brief}>
    <Icon className={styles.briefIcon} type="folder" />
    <Typography>
      <Link className={styles.briefLink} to={`/analyst/entities/editor/${entityId}`} target="_blank">
        {entityName}
      </Link>
      {topicName ? ` - ${topicName}` : null}
    </Typography>
  </div>
);

const EntityList = ({ entities }) => (
  entities.map((e) => (
    <Entity
      key={e.entityId}
      id={e.entityId}
      name={e.entityName}
      topic={e.topicName}
      keywords={e.keywords}
    />
  ))
);

const Article = ({ article }) => {
  const {
    actors,
    plots,
    contexts,
    briefs,
    suggestedUpdates,
    highlightedSummary,
    isTranslated,
    source,
    favicon,
    image,
    title,
    date,
    language,
    mlPredictions = {},
  } = article;

  const mlCategories = get(mlPredictions, 'categories', []);
  const mlENREntities = get(mlPredictions, 'foundEnrEntities', [])
    .map((enr) => (enr.suggestedEntities || []).map((entity) => ({
      ...entity,
      word: enr.word,
    })))
    .flat();

  return (
    <div className={styles.container}>
      {image && (
        <div className={styles.imageContainer}>
          <img className={styles.image} src={image} alt={title} />
          {favicon ? <img className={styles.favicon} src={favicon} alt={source} /> : null}
        </div>
      )}
      <div className={styles.content}>
        <Typography size="xl" type="title" tag="h1" className={styles.title}>{title}</Typography>
        <Typography size="sm" type="text" className={styles.date}>{formatDateTime(parseDate(date), ' - ')}</Typography>

        {!isEmpty(actors) && (
          <EntitySection
            className={styles.section}
            label="Actors"
            helpTitle="Actors"
            helpContent={<Hint.Actors />}
          >
            <EntityList entities={actors} />
          </EntitySection>
        )}
        {!isEmpty(plots) && (
          <EntitySection
            className={styles.section}
            label="Plots"
            helpTitle="Plots"
            helpContent={<Hint.Plots />}
          >
            <EntityList entities={plots} />
          </EntitySection>
        )}
        {!isEmpty(contexts) && (
          <EntitySection
            className={styles.section}
            label="Contexts"
            helpTitle="Contexts"
            helpContent={<Hint.Contexts />}
          >
            <EntityList entities={contexts} />
          </EntitySection>
        )}
        {!isEmpty(suggestedUpdates) && (
          <EntitySection
            className={styles.section}
            label="Suggested Updates"
            helpTitle="Suggested Updates"
            helpContent={<Hint.SuggestedUpdates />}
          >
            {suggestedUpdates.map((item) => (
              <SuggestedUpdateItem
                key={item.entityId}
                entityId={item.entityId}
                entityName={item.entityName}
                facets={item.facets}
              />
            ))}
          </EntitySection>
        )}
        {!isEmpty(mlCategories) && (
          <EntitySection
            className={styles.section}
            label="Machine Learning Suggestons"
            helpTitle="Machine Learning Suggestons"
            helpContent={<Hint.MachineLearningSuggestions />}
          >
            <EntityList
              entities={mlCategories.map((category) => ({
                entityId: category.id,
                entityName: category.name,
                topicName: category.topicName,
                keywords: [category.type],
              }))}
            />
          </EntitySection>
        )}
        {!isEmpty(mlENREntities) && (
          <EntitySection
            className={styles.section}
            label="Machine Learning Entities"
            helpTitle="Machine Learning Entities"
            helpContent={<Hint.MachineLearningENRSuggestions />}
          >
            <EntityList
              entities={mlENREntities.map((entity) => ({
                entityId: entity.id,
                entityName: entity.name,
                topicName: entity.topicName,
                keywords: [entity.word],
              }))}
            />
          </EntitySection>
        )}
        <div className={styles.row}>
          {!isEmpty(briefs) && (
            <EntitySection
              label="Briefs"
              helpTitle="Briefs"
              helpContent={<Hint.Briefs />}
            >
              {briefs.map((brief) => (
                <Brief
                  key={brief.entityId}
                  entityId={brief.entityId}
                  entityName={brief.entityName}
                  topicName={brief.topicName}
                />
              ))}
            </EntitySection>
          )}
        </div>

        <EntitySection label="Article" className={styles.body}>
          <span
            className={styles.article}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: highlightedSummary }}
          />
        </EntitySection>

        <EntitySection label="Source" className={styles.source}>
          <div className={styles.link}>
            {isTranslated && (
              <div title={`This article was translated from ${language}`}>
                <Icon className={styles.translate} type="translate" />
              </div>
            )}
            <a
              className={styles.textLink}
              href={source}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Typography>{source}</Typography>
            </a>
          </div>
        </EntitySection>
      </div>
    </div>
  );
};

export default Article;
