import {
  CHANGE_FIELDS,
  CHANGE_FILTER,
  CHANGE_LINK,
  CHANGE_META,
  CLEAR_FACET,
  CLEAR_STORE,
  DICTIONARY_ITEM_ADD,
  FACET_ADD,
  FACET_ADD_TO_SECTION,
  FACET_LOADING_DISABLE,
  FACET_LOADING_ENABLE,
  FACET_META_CHANGE,
  FACET_ORDER_CHANGE,
  FACET_REMOVE,
  FACET_SAVE,
  FIELD_ADD,
  FIELD_LOADING_DISABLE,
  FIELD_LOADING_ENABLE,
  FIELD_REMOVE,
  FIELD_SAVE,
  LINK_ADD,
  LINK_LOADING_DISABLE,
  LINK_LOADING_ENABLE,
  LINK_SAVE,
  LOAD_ENTITY,
  LOAD_TEMPLATE,
  REG_DICT_ITEM,
  REMOVE_LINK,
  SAVE,
  SAVE_ASSIGNED_ENTITY,
  SEARCH,
  SET_OPENED_SECTIONS,
  SET_STATUS,
  SIDEBAR_SECTION_OPEN_SET,
  USE_VIEW_MODE,
  CROSS_ENTITY_FACET_BLUR,
  CROSS_FACET_ADD,
  CROSS_FACET_REMOVE,
  CROSS_FACET_CHANGE,
  ACTION_TOGGLE_HIDDEN,
  REMOVE_CEFS,
  REMOVE_LINKS,
  REMOVE_FIELDS, FETCH_FACET_LOG_COUNT,
} from './constants';

/** @param {string} dictionaryKey
 * @param {string} item - value */
export function dictionaryItemAdd(dictionaryKey, item) {
  return { type: DICTIONARY_ITEM_ADD, payload: { dictionaryKey, item } };
}

export function useView(template) {
  return { type: USE_VIEW_MODE, payload: template };
}

export function changeFieldSet({
  entityKey, sectionKey, facetKey, fieldSetKey, fields, wrapperKey,
}) {
  return {
    type: CHANGE_FIELDS,
    payload: {
      entityKey, sectionKey, facetKey, fieldSetKey, fields, wrapperKey,
    },
  };
}

export function changeFieldLink({
  entityKey, sectionKey, facetKey, key, changes, wrapperKey,
}) {
  return {
    type: CHANGE_LINK,
    payload: {
      entityKey, sectionKey, facetKey, key, changes, wrapperKey,
    },
  };
}

export function facetAdd({ entityKey, sectionKey, facetKey }) {
  return { type: FACET_ADD, payload: { entityKey, sectionKey, facetKey } };
}

/** @param entityKey {string}
 * @param sectionKey {string}
 * @param facetType {object} - facet template obj
 * */
export function facetAddToSection(entityKey, sectionKey, facetType) {
  return { type: FACET_ADD_TO_SECTION, payload: { entityKey, sectionKey, facetType } };
}

export function facetRemove({ entityKey, sectionKey, facetKey }) {
  return { type: FACET_REMOVE, payload: { entityKey, sectionKey, facetKey } };
}

export function facetMetaChange({
  entityKey, sectionKey, facetKey, stage, assignedToId, values,
}) {
  return {
    type: FACET_META_CHANGE,
    payload: {
      entityKey, sectionKey, facetKey, stage, assignedToId, values,
    },
  };
}

export function facetOrderChange({
  entityKey, sectionKey, prevOrder, newOrder,
}) {
  return {
    type: FACET_ORDER_CHANGE,
    payload: {
      entityKey, sectionKey, prevOrder, newOrder,
    },
  };
}

export function sidebarSectionOpenSet({ entityKey, activeKeys }) {
  return { type: SIDEBAR_SECTION_OPEN_SET, payload: { activeKeys, entityKey } };
}

export function addLink({
  linkType, facetKey, sectionKey, entityKey, wrapperKey,
}) {
  return {
    type: LINK_ADD,
    payload: {
      linkType, facetKey, sectionKey, entityKey, wrapperKey,
    },
  };
}

export function removeLink(entityKey, sectionKey, facetKey, wrapperKey, key) {
  return {
    type: REMOVE_LINK,
    payload: {
      facetKey, sectionKey, entityKey, wrapperKey, key,
    },
  };
}

export function addDictionaryItem() {
}

export function addFieldSet(
  entityKey, sectionKey, facetKey, wrapperKey, fieldSet,
) {
  return {
    type: FIELD_ADD,
    payload: {
      entityKey, sectionKey, facetKey, wrapperKey, fieldSet,
    },
  };
}

export function removeFieldSet(
  entityKey, sectionKey, facetKey, wrapperKey, fieldSet,
) {
  return {
    type: FIELD_REMOVE,
    payload: {
      entityKey, sectionKey, facetKey, wrapperKey, fieldSet,
    },
  };
}

export function setOpenedSections(values) {
  return { type: SET_OPENED_SECTIONS, payload: values };
}

export function changeFilter(filter) {
  return { type: CHANGE_FILTER, payload: filter };
}

export function clearFacet({ entityKey, sectionKey, facetKey }) {
  return { type: CLEAR_FACET, payload: { entityKey, sectionKey, facetKey } };
}

export function saveEntity(close = false, airtableRow = null) {
  return { type: SAVE, payload: { close, airtableRow } };
}

export function saveAssignedEntity() {
  return { type: SAVE_ASSIGNED_ENTITY };
}

export function registredDictItem(key, fieldId, value) {
  return { type: REG_DICT_ITEM, payload: { key, fieldId, value } };
}

export function clearStore() {
  return { type: CLEAR_STORE };
}

export function loadTemplate(id, airtableRow, customTemplate) {
  return { type: LOAD_TEMPLATE, payload: { id, airtableRow, customTemplate } };
}

export function loadEntity(id) {
  return { type: LOAD_ENTITY, payload: id };
}

export function changeMeta(value, type, facetId, massAssign = false) {
  return {
    type: CHANGE_META,
    payload: {
      value, type, facetId, massAssign,
    },
  };
}

export function search(value, id, types) {
  return { type: SEARCH, payload: { value, id, types } };
}

export function setEntityStatus(status) {
  return { type: SET_STATUS, payload: status };
}

export function saveField(field) {
  return { type: FIELD_SAVE, payload: field };
}

export function enableFieldLoading(id) {
  return { type: FIELD_LOADING_ENABLE, payload: id };
}

export function disableFieldLoading(id) {
  return { type: FIELD_LOADING_DISABLE, payload: id };
}

export function saveLink(link, facetId) {
  return { type: LINK_SAVE, payload: { link, facetId } };
}

export function enableLinkLoading(id) {
  return { type: LINK_LOADING_ENABLE, payload: id };
}

export function disableLinkLoading(id) {
  return { type: LINK_LOADING_DISABLE, payload: id };
}

export function saveFacet(facet, options) {
  return { type: FACET_SAVE, payload: facet, options };
}

export function enableFacetLoading(id) {
  return { type: FACET_LOADING_ENABLE, payload: id };
}

export function disableFacetLoading(id) {
  return { type: FACET_LOADING_DISABLE, payload: id };
}

export function crossEntityFacetBlur(cef) {
  return {
    type: CROSS_ENTITY_FACET_BLUR,
    payload: cef,
  };
}

export function crossFacetAdd({
  entityKey, sectionKey, facetKey,
}) {
  return {
    type: CROSS_FACET_ADD,
    payload: {
      entityKey, sectionKey, facetKey,
    },
  };
}

export function crossFacetRemove({
  entityKey, sectionKey, facetKey, setKey, setId,
}) {
  return {
    type: CROSS_FACET_REMOVE,
    payload: {
      entityKey, sectionKey, facetKey, setKey, setId,
    },
  };
}

export function crossFacetChange({
  entityKey, sectionKey, facetKey, crossFacetKey, values,
}) {
  return {
    type: CROSS_FACET_CHANGE,
    payload: {
      entityKey, sectionKey, facetKey, crossFacetKey, values,
    },
  };
}

export function setEntityHidden({ entityKey, value }) {
  return {
    type: ACTION_TOGGLE_HIDDEN,
    payload: { entityKey, value },
  };
}

export function removeLinks(ids) {
  return { type: REMOVE_LINKS, payload: ids.filter((id) => id) };
}

export function removeCEFs(ids) {
  return { type: REMOVE_CEFS, payload: ids.filter((id) => id) };
}

export function removeFields(ids) {
  return { type: REMOVE_FIELDS, payload: ids.filter((id) => id) };
}

export function fetchFacetLogCount(facetId) {
  return {
    type: FETCH_FACET_LOG_COUNT,
    payload: { facetId },
  };
}
