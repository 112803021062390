import { Select } from '@axis/xyz.admin.form.select';
import React from 'react';
import { useQuery } from 'react-query';
import css from './SelectFeedTopic.module.css';
import client from '../../../common/utils/http';

const SelectFeedTopic = ({ value, onChange }) => {
  const { isLoading, data: topics = [] } = useQuery(
    ['articlesCount'],
    () => client
      .get('/articles-count')
      .then((response) => response.data)
      .then((topicList) => [
        {
          topicId: '',
          topicName: 'All',
        },
        ...topicList,
      ])
      .catch(console.error),
    {
      refetchOnWindowFocus: false,
    },
  );

  const selectedOption = typeof value === 'string' ? topics.find((topic) => topic.topicId === value) : value;

  return (
    <Select
      className={css.select}
      placeholder="Select topic"
      name="feed-topics"
      isDisabled={isLoading}
      filterOption={() => true}
      getOptionLabel={(option) => option.topicName}
      getOptionValue={(option) => option.topicId}
      onChange={onChange}
      value={selectedOption || {}}
      options={topics}
    />
  );
};

export default SelectFeedTopic;
