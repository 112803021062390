import React from 'react';
import styles from './ErrorDetail.module.css';
import logo from '../../img/logo.svg';

const ErrorScreen = () => {
  const handleHomeClick = () => {
    // Reset the state of the application
    localStorage.clear();
    window.location.pathname = '/';
  };

  return (
    <div className={styles.main}>
      <img className={styles.logo} src={logo} alt="Axis" />
      <h1 className={styles.title}>
        Something went wrong
      </h1>
      <h2 className={styles.subtitle}>
        There was an unexpected error in the admin.
        <br />
        If the error persists report it to the Axis team.
      </h2>
      <div className={styles.actions}>
        <button
          type="button"
          className={styles.button}
          onClick={handleHomeClick}
        >
          Go home
        </button>
      </div>
    </div>
  );
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error);
    console.error(errorInfo);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <ErrorScreen />;
    }
    return children;
  }
}

export default ErrorBoundary;
