import React from 'react';
import DatePicker from '../common/DatePickerWrapper';

const format = {
  ANNUAL: 'MM/DD/YYYY',
  YEAR: 'MM/DD/YYYY',
  DAILY: 'MM/DD/YYYY',
  DAY: 'MM/DD/YYYY',
  MONTHLY: 'MM/DD/YYYY',
  MONTH: 'MM/DD/YYYY',
  QUARTERLY: 'MM/DD/YYYY',
  QUARTER: 'MM/DD/YYYY',
};
const mode = {
  'MM/DD/YYYY': 'date',
  'MM/YYYY': 'month',
  YYYY: 'year',
};

const DatePickerCustom = (props) => {
  const {
    onChange, value, periodicity, disabled,
  } = props;
  return (
    <div style={{ width: '100%' }}>
      <DatePicker
        mode={mode[format[periodicity]]}
        disabled={disabled}
        format={format[periodicity] || 'MM/DD/YYYY'}
        valueDate={value}
        onChangeField={onChange}
      />
    </div>
  );
};

export default DatePickerCustom;
