import React, { PureComponent } from 'react';
import {
  Select, Menu, Spin, Row,
} from 'antd';
import StatusIcon from '../StatusIcon';
import { FILTERS_SIDEBAR, ALL } from '../../constants';

import iconDiamond from '../../../../img/diamond.svg';
import '../../../Layout/editor.sidebar.css';

import {
  selectStyles,
  menuStyles,
  subMenuStyles,
} from './constantSidebar';

const { Option } = Select;
const { SubMenu, Item } = Menu;

class Sidebar extends PureComponent {
  componentWillUnmount() {
    this.props.changeFilterAction(ALL);
  }

  navigateToFacet = ({ entityKey, sectionKey, facetKey }) => {
    const scrollToFacet = (id) => {
      const el = document.getElementById(id);
      if (el) {
        el.scrollIntoView({
          alignToTop: true,
          behavior: 'smooth',
          block: 'center',
        });
      }
    };
    if (sectionKey) {
      const { activeKeys } = this.props.entity;
      if (!activeKeys.includes(sectionKey)) {
        this.props.openSectionAction({ entityKey, activeKeys: [...activeKeys, sectionKey] });
      }
      setTimeout(() => scrollToFacet(facetKey), 350);
    } else {
      scrollToFacet(facetKey);
    }
  };

  render() {
    const {
      filter,
      userId,
      changeFilterAction,
      entity,
      header,
      isLoading,
    } = this.props;

    return (
      <div className="EditorSidebarWrapper">
        {
          isLoading
            ? (
              <Row
                className="EditorSidebar"
                type="flex"
                justify="center"
                align="middle"
                style={{ height: '100vh' }}
              >
                <Spin />
              </Row>
            )
            : (
              <div className="EditorSidebar EditorSidebar__Entity">
                <div
                  id="assignedFiltersContainer"
                  className="EditorSidebar__Container"
                >
                  <Select
                    onChange={changeFilterAction}
                    value={filter}
                    style={selectStyles}
                    getPopupContainer={() => document.getElementById('assignedFiltersContainer')}
                  >
                    {
                      FILTERS_SIDEBAR.map((item) => <Option key={item} value={item}>{item}</Option>)
                    }
                  </Select>
                </div>
                <div className="EditorSidebar__EntityList scroll-block">
                  <div className="EditorSidebar__AssignedEntity">
                    <div
                      className={header.name ? 'EditorSidebar__Name' : 'EditorSidebar__Name unnamed'}
                      title={header.name}
                    >
                      {header.name || 'Unnamed'}
                    </div>
                    <div
                      className="EditorSidebar__Type"
                      title={header.label}
                    >
                      {header.label}
                    </div>

                    <Menu
                      mode="inline"
                      defaultOpenKeys={entity.sectionKeys}
                      style={menuStyles}
                    >
                      {
                        entity.sections && entity.sections.map((section) => (
                          <SubMenu
                            key={section.key}
                            title={section.name}
                            style={subMenuStyles}
                          >
                            {
                              section.facets && section.facets.map((facet) => (
                                <Item
                                  key={facet.key}
                                  onClick={() => this.navigateToFacet({
                                    entityKey: entity.key,
                                    sectionKey: section.key,
                                    facetKey: facet.key,
                                  })}
                                >
                                  <div className="sidebar-item">
                                    {
                                      facet.meta.assignedToId === userId
                                        ? (
                                          <img
                                            src={iconDiamond}
                                            alt="Assigned to me"
                                            className="iconDiamond"
                                          />
                                        )
                                        : <span />
                                    }
                                    <span className="EditorSidebar__MenuItem">{facet.type.name}</span>
                                    <StatusIcon status={facet.status} />
                                  </div>
                                </Item>
                              ))
                            }
                          </SubMenu>
                        ))
                      }
                    </Menu>
                  </div>
                </div>
              </div>
            )
        }
      </div>
    );
  }
}

export default Sidebar;
