import React, { useState, useEffect, useRef } from 'react';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import set from 'lodash/set';
import {
  Input, Select, message,
} from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TextArea from 'antd/lib/input/TextArea';
import { dictionaryItemAdd } from '../../actions';
import selectors from '../../../../selectors/entityEditor';
import * as strings from '../../../../common/Strings';

const notFoundText = {
  add: 'Use your text, and add it to the global Axis list',
  fixed: 'Not found',
  free: 'Use your text instead of an existing Axis item',
};

const Type = (props) => {
  const {
    rules,
    onChange,
    fieldSelectedId,
    fieldValue,
    itemAdd,
    options = {},
    readonly,
    onBlur = () => {},
    name,
  } = props;
  const { style, className, allowType } = options;
  const {
    type: {
      fixedValue,
      type,
      mode,
      defaultValue,
      key,
    },
    chars,
  } = rules;
  const ref = useRef(null);
  const { current: debounceInput } = useRef(debounce((v) => onChange({ [fieldValue.name]: v }), 500));
  const [freeItem, setFreeItem] = useState('');
  const [dictionary, setDictionary] = useState();
  const [valueToAdd, setValueToAdd] = useState('');

  useEffect(() => {
    if (type === 'dictionary' && key) {
      setDictionary(selectors.getDictionaryByKey(key));
    }
  }, []);

  const handleChange = (v, component) => {
    if (`${v}`.length > 254) {
      return message.error(strings.errValue255chars);
    }
    const label = get(component, 'props.children') || get(v, '[0]') || '';
    if (allowType === 'Integer' && !label.match(/^\d*$/)) {
      return message.error(strings.errValueInteger);
    }
    const fields = {};
    let changed = false;
    if (fieldSelectedId.name) {
      fields[fieldSelectedId.name] = v;
      changed = true;
    }
    if (fieldValue.name) {
      fields[fieldValue.name] = label;
      changed = true;
    }
    if (changed) {
      onChange(fields);
    }
    setValueToAdd('');
    return null;
  };

  const addNewItem = () => {
    if (`${valueToAdd}`.length > 254 || (chars > 0 && `${valueToAdd}`.length > chars)) {
      return message.error(strings.errValue255chars);
    }
    if (rules.type.mode === 'add' && dictionary && dictionary.items
      && !dictionary.items.find((item) => item.value.toUpperCase().startsWith(valueToAdd.toUpperCase()))
      && !dictionary.items.find((item) => item.value.toUpperCase() === valueToAdd.toUpperCase())) {
      itemAdd(rules.type.key, valueToAdd);
    }
    if (allowType === 'Integer' && !valueToAdd.match(/^\d*$/)) {
      return message.error(strings.errValueInteger);
    }
    if (ref && ref.current) {
      ref.current.focus();
      ref.current.blur();
    }
    setFreeItem(valueToAdd);
    setValueToAdd('');
    return null;
  };

  const onSearch = (v) => {
    if (rules.type.type === 'dictionary' && rules.type.mode !== 'fixed') {
      setValueToAdd(v);
    }
  };

  const onInputKeyDown = (e) => {
    if (rules.type.type === 'dictionary' && rules.type.mode !== 'fixed' && e.keyCode === 13) {
      addNewItem();
    }
  };

  const onChangeWrapper = ({ target: { value: v } }) => {
    if (`${v}`.length > 254) return message.error(strings.errValue255chars);
    if (allowType === 'Integer' && !v.match(/^\d*$/)) return message.error(strings.errValueInteger);
    debounceInput(v);
    return null;
  };

  const onTextareaChangeWrapper = ({ target: { value: v } }) => {
    debounceInput(v);
    return null;
  };

  let valItems = [];
  let keyItems = [];
  let itemsMap = {};
  let isValueId = false;
  if (dictionary) {
    valItems = dictionary.values;
    keyItems = dictionary.keys;
    isValueId = dictionary.items.some((item) => item.id === fieldSelectedId.value);
    itemsMap = valItems.flatMap((vi) => vi).reduce((map, item) => {
      set(map, item.id, item.value);
      return map;
    }, itemsMap);
  }

  const onSelectBlur = (val) => {
    onBlur({ target: { value: itemsMap[val], selectedValue: val } });
  };

  return (
    <div style={style} className={className}>
      {
        type === 'text'
        && (
          <Input
            name={name}
            autoComplete="off"
            type="text"
            defaultValue={fieldValue.value || ''}
            onChange={onChangeWrapper}
            maxLength={chars || 254}
            disabled={readonly}
            onBlur={onBlur}
            spellCheck
            data-testid="facetLabelInput"
          />
        )
      }
      {
        type === 'textarea'
        && (
          <TextArea
            name={name}
            rows={6}
            autoComplete="off"
            type="text"
            defaultValue={fieldValue.value || ''}
            onChange={onTextareaChangeWrapper}
            disabled={readonly}
            onBlur={onBlur}
            spellCheck
            data-testid="facetLabelTextarea"
          />
        )
      }
      {
        type === 'fixed'
        && (
          <Input
            name={name}
            autoComplete="off"
            type="text"
            disabled
            value={fixedValue || ''}
            maxLength={chars || 254}
            onBlur={onBlur}
            spellCheck
            data-testid="facetBriefTagType"
          />
        )
      }
      {
        type === 'dictionary'
        && (
          <Select
            name={name}
            allowClear
            defaultValue={isValueId ? fieldSelectedId.value : fieldValue.value}
            placeholder="Please select item"
            dropdownClassName="dropdown-zindex"
            getPopupContainer={() => document.getElementById('entity-editor-id')}
            style={{ width: '100%' }}
            ref={(select) => {
              ref.current = select;
            }}
            onChange={handleChange}
            onSearch={onSearch}
            onInputKeyDown={onInputKeyDown}
            showSearch
            optionFilterProp="children"
            notFoundContent={notFoundText[mode]}
            filterOption={(input, option) => option.props.children
              && option.props.children.toLowerCase
              && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            disabled={readonly}
            onBlur={onSelectBlur}
            data-testid="facetTypeSelect"
          >
            {/* <editor-folder labele="input-value"> */}
            {valItems && valItems.map((v, i) => (keyItems[i] !== ' '
              ? (
                <Select.OptGroup label={keyItems[i]} key={keyItems[i]}>
                  {v && v.map((o) => (
                    <Select.Option key={o.id} title={o.value.length > 20 ? o.value : ''}>
                      {o.value}
                    </Select.Option>
                  ))}
                </Select.OptGroup>
              )
              : v && v.map((o) => o.value && (
              <Select.Option
                key={o.id}
                title={o.value.length > 20 ? o.value : ''}
              >
                {o.value}
              </Select.Option>
              ))))}
            {
              freeItem
              && (
                <Select.Option
                  key={freeItem}
                  title={freeItem.length > 20 ? freeItem : ''}
                >
                  {freeItem}
                </Select.Option>
              )
            }
            {
              defaultValue
              && (
                <Select.Option
                  key={defaultValue}
                  title={defaultValue.length > 20 ? defaultValue : ''}
                >
                  {defaultValue}
                </Select.Option>
              )
            }
            {/* </editor-folder> */}
          </Select>
        )
      }
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  itemAdd: bindActionCreators(dictionaryItemAdd, dispatch),
});
export default connect(null, mapDispatchToProps)(Type);
