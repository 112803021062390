import React from 'react';
import moment from 'moment/moment';
import {
  Col,
  Input,
  Row,
  Button,
} from 'antd';
import CustomDatePicker from './DatePicker';

const ValueSet = (props) => {
  const {
    id: setId,
    setKey,
    value,
    date,
    onChange,
    rank,
    total,
    percent,
    readonly,
    periodicity,
    removeValueSet,
  } = props;

  return (
    <>
      <Row gutter={18} style={{ marginBottom: '32px', marginTop: '32px' }}>
        <Col span={12}>
          <div style={{ fontSize: '18px' }}>Number</div>
          <Input
            type="number"
            style={{ width: '100%' }}
            placeholder="Number"
            defaultValue={value}
            onChange={({ target: { value: v } }) => onChange({ value: v })}
            disabled={readonly}
          />
        </Col>

        <Col span={12}>
          <div style={{ fontSize: '18px' }}>Date</div>
          <CustomDatePicker
            disabled={readonly}
            periodicity={periodicity}
            value={moment(date).isValid() ? moment(date) : null}
            onChange={(d) => onChange({ date: d })}
          />
        </Col>
      </Row>

      <Row gutter={18} style={{ marginBottom: '16px' }}>
        <Col span={8}>
          <div style={{ fontSize: '18px' }}># Rank</div>
          <Input
            disabled={readonly}
            style={{ width: '100%' }}
            placeholder="# Rank"
            defaultValue={rank}
            onChange={({ target: { value: v } }) => onChange({ rank: v })}
          />
        </Col>

        <Col span={8}>
          <div style={{ fontSize: '18px' }}>of Total</div>
          <Input
            disabled={readonly}
            style={{ width: '100%' }}
            placeholder="of Total"
            defaultValue={total}
            onChange={({ target: { value: v } }) => onChange({ total: v })}
          />
        </Col>

        <Col span={8}>
          <div style={{ fontSize: '18px' }}>% Percentile</div>
          <Input
            disabled={readonly}
            style={{ width: '100%' }}
            placeholder="% Percentile"
            defaultValue={percent}
            onChange={({ target: { value: v } }) => onChange({ percent: v })}
          />
        </Col>
      </Row>

      <Row type="flex" justify="end">
        <Button
          type="link"
          className="button-like-link"
          onClick={() => removeValueSet(setKey, setId)}
        >
          × Remove
        </Button>
      </Row>

      <Row gutter={18}>
        <Col span={24}>
          <hr />
        </Col>
      </Row>
    </>
  );
};

export default ValueSet;
