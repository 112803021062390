export const CREATE_ERROR = 'COMMON:CREATE_ERROR';
export const LOAD_DICTIONARIES = 'COMMON:LOAD_DICTIONARIES';
export const SET_DICTIONARIES = 'COMMON:SET_DICTIONARIES';
export const LIMIT = 50;

export const ROUTE_ADMIN = '/admin';
export const ROUTE_ADMIN_ASSIGN_FACETS = '/analyst/assign-facets';
export const ROUTE_ADMIN_BRIEFS_EDITOR = '/admin/briefs/editor';
export const ROUTE_ADMIN_ELEMENTS_CEFS = '/admin/elements/cefs';
export const ROUTE_ADMIN_EXPORT = '/admin/export';
export const ROUTE_ADMIN_EXPORT_ANALYST_STATUS = '/admin/export/analyst-status';
export const ROUTE_ADMIN_ELEMENTS_DICTIONARIES = '/admin/elements/dictionaries';
export const ROUTE_ADMIN_ELEMENTS_DICTIONARIES_EDITOR = '/admin/elements/dictionaries/editor';
export const ROUTE_ADMIN_ELEMENTS_BRIEFS = '/admin/elements/briefs';
export const ROUTE_ADMIN_ELEMENTS_UNTYPED = '/admin/elements/untyped';
export const ROUTE_ADMIN_ENTITIES = '/admin/entities';
export const ROUTE_ADMIN_ENTITIES_EDITOR = '/admin/entities/editor';
export const ROUTE_ADMIN_ENTITIES_FACET_EDITOR = '/admin/entities/facet-editor';
export const ROUTE_ADMIN_USERS = '/admin/users';
export const ROUTE_ANALYST = '/analyst';
export const ROUTE_ANALYST_ENTITIES = '/analyst/entities';
export const ROUTE_ANALYST_ENTITIES_ALL = '/analyst/entities';
export const ROUTE_ANALYST_ENTITIES_ACTORS = '/analyst/entities/actors';
export const ROUTE_ANALYST_ENTITIES_PLOTS = '/analyst/entities/plots';
export const ROUTE_ANALYST_ENTITIES_CONTEXTS = '/analyst/entities/contexts';
export const ROUTE_ANALYST_ENTITIES_EDITOR = '/analyst/entities/editor';
export const ROUTE_ANALYST_ELEMENTS_BRIEFS = '/analyst/elements/briefs';
export const ROUTE_ANALYST_ELEMENTS_STATENTITY = '/analyst/elements/statentity';
export const ROUTE_ANALYST_ELEMENTS_UNTYPED = '/analyst/elements/untyped';
export const ROUTE_ANALYST_BRIEFS_EDITOR = '/analyst/briefs/editor';
export const ROUTE_ANALYST_STATENTITY_EDITOR = '/analyst/statentity/editor';
export const ROUTE_ANALYST_ELEMENTS_CEFS = '/analyst/elements/cefs';
export const ROUTE_ANALYST_ELEMENTS_CEFT = '/analyst/elements/ceft';
export const ROUTE_ANALYST_ELEMENTS_IMPORT_STATS = '/analyst/elements/import-stats';
export const ROUTE_ANALYST_ELEMENTS_IMPORT_ENTITIES = '/analyst/elements/import-entities';
export const ROUTE_ANALYST_ELEMENTS_IMPORT_CHALLENGES = '/analyst/elements/import-challenges';
export const ROUTE_ANALYST_ELEMENTS_IMPORT_BRIEFS = '/analyst/elements/import-briefs';

export const ROUTE_ANALYST_SOURCES_ARTICLES = '/analyst/sources/articles';
export const ROUTE_ANALYST_SOURCES_REGULATIONS = '/analyst/sources/regulations';
export const ROUTE_ANALYST_SOURCES_REGULATION_ARTICLES = '/analyst/sources/regulation-articles';

export const ROUTE_ANALYST_FEED_ARTICLES = '/analyst/feed/articles';
export const ROUTE_ANALYST_FEED_REGULATION_ARTICLES = '/analyst/feed/regulation-articles';
export const ROUTE_ANALYST_FEED_REVIEW = '/analyst/feed/review';
export const ROUTE_ANALYST_FEED_CREATE = '/analyst/feed/create';
export const ROUTE_ANALYST_FEED_CREATE_MANUAL = '/analyst/feed/manually-create';

export const ROUTE_ANALYST_REGULATIONS_FEED = '/analyst/feed/regulations';
export const ROUTE_ANALYST_REGULATIONS_REVIEW = '/analyst/feed/regulations/review/:type';
export const ROUTE_ANALYST_REGULATIONS_CREATE = '/analyst/feed/regulations/create/:type';
export const ROUTE_ANALYST_REGULATIONS_CREATE_MANUAL = '/analyst/feed/regulations/manually-create';

export const ROUTE_ANALYST_EVENTS_ALL = '/analyst/events';
export const ROUTE_ANALYST_EVENT_EDITOR = '/analyst/events/:id';

export const ROUTE_ANALYST_COUNTRIES_ALL = '/analyst/countries';
export const ROUTE_ANALYST_LANDSCAPES_ALL = '/analyst/landscapes';

export const ROUTE_ANALYST_REGULATIONS = '/analyst/regulations';
export const ROUTE_ANALYST_REGULATION_EDITOR = '/analyst/regulations/:id';

export const ROUTE_AUTH = '/auth';
export const PARAM_CEFT_EDITOR = 'id';
export const PARAM_CEFT_ID = 'id';
export const PARAM_DICTIONARY_EDITOR = 'dictionaryIdByUrl';
export const PARAM_TEMPLATES_EDITOR = 'templateIdByUrl';
export const PARAM_FACET_EDITOR = 'id';
export const PARAM_ENTITIES_EDITOR = 'entityIdByUrl';
export const ROUTE_USER_SIGNUP = '/user';
export const ROUTE_RESET_REQUEST = '/reset-request';
export const ROUTE_RESET_PASSWORD = '/password-reset';
export const ROUTE_ANALYST_ELEMENTS = '/analyst/elements';
export const ROUTE_ADMIN_ELEMENTS = '/admin/elements';

export const withBreadcrumbs = [
  ROUTE_ADMIN_ELEMENTS_UNTYPED,
  ROUTE_ADMIN_ELEMENTS_DICTIONARIES_EDITOR,
  ROUTE_ADMIN_ENTITIES_FACET_EDITOR,
  ROUTE_ADMIN_BRIEFS_EDITOR,
  ROUTE_ADMIN_ENTITIES_EDITOR,
  ROUTE_ADMIN_ELEMENTS_DICTIONARIES,
  ROUTE_ADMIN_USERS,
  ROUTE_ADMIN_ENTITIES,
  ROUTE_ADMIN_ASSIGN_FACETS,
  ROUTE_ADMIN_ELEMENTS_CEFS,
  ROUTE_ADMIN_ELEMENTS_BRIEFS,
  ROUTE_ANALYST_ENTITIES_ALL,
  ROUTE_ANALYST_ENTITIES_ACTORS,
  ROUTE_ANALYST_ENTITIES_PLOTS,
  ROUTE_ANALYST_ENTITIES_CONTEXTS,
  ROUTE_ANALYST_ELEMENTS_BRIEFS,
  ROUTE_ANALYST_ELEMENTS_STATENTITY,
  ROUTE_ANALYST_ELEMENTS_CEFT,
  ROUTE_ANALYST_ELEMENTS_CEFS,
  ROUTE_ANALYST_ELEMENTS_UNTYPED,
];

export const RSS_SOURCE_TYPES = {
  ARTICLE: 'article',
  REGULATION: 'pdf',
  REGULATION_ARTICLE: 'reg_article',
};

export const REGULATION_FEED_TYPES = {
  ARTICLE: 'article',
  REGULATION: 'regulation',
};
