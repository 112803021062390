/* eslint-disable linebreak-style */
import {
  all, put, takeEvery, select,
} from 'redux-saga/effects';
import auth from '../containers/Auth/sagas';
import entityEditor from '../containers/EntityEditor/saga';
import client from '../common/utils/http';
import { group } from '../common/utils/lib';
import {
  LOAD_DICTIONARIES, SET_DICTIONARIES, CREATE_ERROR,
} from '../constants';
import * as strings from '../common/Strings';
import { catchError } from './sagas';

function* loadDictionaries({ payload: keys }) {
  try {
    const store = yield select();
    const dicts = {};
    for (let i = 0; i < keys.length; i += 1) {
      if (!store.common.dictionaries[keys[i]]) {
        const { data: { data } } = yield client.get(
          `/dictionaries?key=${keys[i]}`,
        ).catch(catchError);
        if (data && data[0]) {
          const { data: { items } } = yield client.get(
            `/dictionaries/${data[0].id}`,
          ).catch(catchError);
          dicts[keys[i]] = {
            ...group(items, ['category', 'value'], ['asc', 'asc']),
            items,
          };
        }
        yield put({
          type: SET_DICTIONARIES,
          payload: dicts,
        });
      }
    }
  } catch (e) {
    yield put({
      type: CREATE_ERROR,
      payload: `${strings.dpLoadDictionaries}: ${e.message}.`,
    });
    console.error(e);
  }
}
function* watchLoadDictionaries() {
  yield takeEvery(LOAD_DICTIONARIES, loadDictionaries);
}

export default function* saga() {
  yield all([
    auth(),
    entityEditor(),
    watchLoadDictionaries(),
  ]);
}
