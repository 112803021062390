/* eslint-disable no-param-reassign,linebreak-style,no-plusplus */
import { put, select } from 'redux-saga/effects';
import uniqueId from 'lodash/uniqueId';
import { message } from 'antd';
import get from 'lodash/get';

import client from '../../../common/utils/http';
import * as strings from '../../../common/Strings';
import { RELATIONSHIPS_FACETS } from '../../../constants/facetTypes';
import { createSchema, prepareLinkToSave } from '../utils';
import {
  ADD_LOAD_PROCESS, REMOVE_LOAD_PROCESS, SET_ENTITY, SET_STATUS,
} from '../constants';

import dictionaryAddItems from './dictionaryAddItems';
import { RELATIONSHIP } from '../../../common/Enums/fieldTypeNames';
import { ENTITY_COLUMNS } from '../../../common/Enums/Columns';
import { catchError } from '../../../saga/sagas';
import { IN_PROGRESS } from '../../../common/Enums/Stages';

const PUBLISHING_ERROR = '[Publishing Error]';

export default function* save() {
  const processId = uniqueId();
  let errors = false;
  try {
    yield put({ type: ADD_LOAD_PROCESS, payload: { processId } });
    const state = (yield select()).entityEditor;
    const {
      entities: [entity],
      dataToDelete: { facets: deletedFacets },
      dictionariesItemNew,
    } = state;
    const entityToSave = {
      entityTemplateId: entity.template.id,
      type: entity.template.type,
      subType: entity.template.subType,
      name: entity.name,
      facets: [],
      meta: {
        assignedToId: entity.meta.assignedToId,
        stage: entity.meta.stage,
      },
      links: [],
      linksToDestroy: state.dataToDelete.links,
      cefsToDestroy: state.dataToDelete.cefs,
      facetFieldsToDestroy: state.dataToDelete.fields,
      [ENTITY_COLUMNS.HIDDEN]: entity[ENTITY_COLUMNS.HIDDEN],
    };
    entity.sections.forEach((section) => {
      section.facets.forEach((facet) => {
        if (!facet.componentLogs.changed) {
          return false;
        }
        const facetToSave = {
          id: facet.id,
          facetTypeId: facet.type.id,
          order: facet.order,
          meta: facet.meta,
          fields: [],
          links: [],
          crossEntityFacetTypeId: get(facet, 'crossEntityFacetType.id'),
          crossEntityFacets: facet.crossEntityFacets.map((cef) => {
            cef.crossEntityFacetTypeId = get(cef, 'crossEntityFacetType.id') || get(facet, 'crossEntityFacetType.id');
            delete cef.key;
            delete cef.crossEntityFacetType;
            if (!cef.value && cef.value !== 0) {
              cef.value = null;
            }
            return cef;
          }).filter((cef) => cef.crossEntityFacetTypeId),
        };
        facet.wrappers.forEach((wrapper) => {
          if (wrapper.fieldType.name === RELATIONSHIP) {
            let links = wrapper.fieldSets.filter((link) => link.componentLogs && link.componentLogs.changed && link.targetId && link.targetType);
            if (RELATIONSHIPS_FACETS.includes(facet.type.name)) {
              links.forEach((link) => entityToSave.links.push(prepareLinkToSave(link, 'entity', entity.id)));
              links = [];
            }
            facetToSave.links.push(...links.map((link) => prepareLinkToSave(link, 'facet', facet.id)));
          } else {
            wrapper.fieldSets.forEach((fieldSet) => fieldSet.componentLogs.changed
              && facetToSave.fields.push(...Object.values(fieldSet.fields)));
          }
        });
        if (facet.id === null) {
          delete facet.id;
        }
        entityToSave.facets.push(facetToSave);
        return true;
      });
    });
    const method = entity.id ? 'patch' : 'post';
    const url = entity.id ? `/entities/${entity.id}` : '/entities';
    if (deletedFacets.length > 0) {
      for (let i = 0; i < deletedFacets.length; i++) {
        yield client.delete(`/facets/${deletedFacets[i]}`).catch(catchError);
      }
    }
    const dictionaries = yield dictionaryAddItems(dictionariesItemNew, state.dictionaries);
    const { data } = yield client[method](url, entityToSave).catch(catchError);
    const preparedEntity = createSchema(data.entityTemplate, data);
    yield put({
      type: SET_ENTITY,
      payload: {
        entity: preparedEntity,
        dictionaries: dictionaries || {},
      },
    });
  } catch (e) {
    console.error(e);
    const text = get(e, 'response.data.message', e.message);
    message.error(`${strings.seSaveEntityError}: ${text}.`, 5);
    if (text.startsWith(PUBLISHING_ERROR)) {
      yield put({ type: SET_STATUS, payload: IN_PROGRESS });
    }
    errors = true;
  } finally {
    yield put({ type: REMOVE_LOAD_PROCESS, payload: { processId, errors } });
  }
}
