import React, {
  useEffect, useState,
} from 'react';
import { Typography } from '@axis/xyz.admin.typography';
import { Button } from '@axis/xyz.admin.buttons.button';
import { DataTable } from '@axis/xyz.admin.tables.data-table';
import { Pagination } from '@axis/xyz.admin.pagination';
import { Icon } from '@axis/xyz.assets.icon';
import {
  useQuery, useMutation, useQueryClient,
} from 'react-query';
import { message } from 'antd';
import { Loading } from '@axis/xyz.admin.loading';
import { get } from 'lodash';
import css from './RSSSources.module.css';
import {
  createRSSSource, deleteRSSSource, fetchRSSSourcesPage, getRSSSourceStatus, PAGE_SIZE, updateRSSSource,
} from './utils';
import SearchSelect from '../../components/Form/SearchSelect/SearchSelect';
import { sendEvent } from '../../contexts/AnalyticsTrackingContext/AnalyticsTrackingContext';
import ModalNewRSSSource from '../../modals/ModalNewRSSSource/ModalNewRSSSource';
import ModalEditRSSSource from '../../modals/ModalEditRSSSource/ModalEditRSSSource';
import ModalDeleteRSSSource from '../../modals/ModalDeleteRSSSource/ModalDeleteRSSSource';
import { RSS_SOURCE_TYPES } from '../../constants';

const LoadingIndicator = ({ visible }) => {
  if (!visible) return null;
  return (
    <div className={css.loadingIndicator}>
      <Loading className={css.spinner} />
      <p className={css.loading}>Refreshing sources</p>
    </div>
  );
};

const RSSSources = ({ type: sourceType }) => {
  const [filters, setFilters] = useState({
    sourceName: null,
    topic: null,
  });

  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({ field: '', order: '' });

  const [showNewSourceModal, setShowNewSourceModal] = useState(false);

  const [selectedSource, setSelectedSource] = useState({ source: null, action: '' });

  const queryClient = useQueryClient();

  const eventCreate = useMutation(createRSSSource);
  const eventUpdate = useMutation(updateRSSSource);
  const eventDelete = useMutation(deleteRSSSource);

  const {
    data: sources = { total: 0, sources: [] },
    isFetching,
    isLoading,
  } = useQuery(
    ['rss-sources', filters, sort, page],
    () => fetchRSSSourcesPage({
      filters,
      orderByName: sort.order,
      pageParam: { page },
      type: sourceType,
    }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    queryClient.invalidateQueries('rss-sources');
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  const handleCreateRSSSource = (source) => {
    eventCreate.mutate(source, {
      onSuccess: () => {
        queryClient.invalidateQueries(['rss-sources', filters]);
        message.success('Source successfully created');
        setShowNewSourceModal(false);
      },
      onError: (err) => {
        const detail = get(err, 'response.data.message', err.message);
        message.error(detail);
      },
    });
  };

  const handleUpdateRSSSource = ({ id, ...source }) => {
    eventUpdate.mutate({ id, source }, {
      onSuccess: () => {
        queryClient.invalidateQueries(['rss-sources', filters]);
        message.success('Source successfully updated');
        setSelectedSource({ source: null, action: '' });
      },
      onError: (err) => {
        const detail = get(err, 'response.data.message', err.message);
        message.error(detail);
      },
    });
  };

  const handleDeleteConfirmed = (source) => {
    sendEvent('rss_source_deletion', {
      description: 'Deleting a RSS Source',
      sourceId: source,
    });
    eventDelete.mutate(source, {
      onSuccess: () => {
        queryClient.invalidateQueries(['rss-sources', filters]);
        message.success('Source successfully deleted');
      },
      onError: (err) => {
        const detail = get(err, 'response.data.message', err.message);
        message.error(detail);
      },
      onSettled: () => {
        setSelectedSource({ source: null, action: '' });
      },
    });
  };

  const handleFilterChange = (field) => (value) => {
    if (!value) {
      setFilters({ ...filters, [field]: null });
      setPage(1);
    } else {
      setFilters({ ...filters, [field]: value });
      setPage(1);
    }
  };

  const handleSortChange = (sortField) => {
    setSort(({ field, order }) => {
      if (field !== sortField) {
        return { field: sortField, order: 'asc' };
      }
      if (order === 'asc') {
        return { field, order: 'desc' };
      }
      if (order === 'desc') {
        return { field: '', order: '' };
      }
      return { field: '', order: '' };
    });
  };

  const pageTitle = () => {
    switch (sourceType) {
      case RSS_SOURCE_TYPES.ARTICLE:
        return 'RSS Article Sources';
      case RSS_SOURCE_TYPES.REGULATION:
        return 'RSS Regulation Sources';
      case RSS_SOURCE_TYPES.REGULATION_ARTICLE:
        return 'RSS Regulation Article Sources';
      default:
        return '';
    }
  };

  return (
    <div className={css.container}>
      <section>
        <div className={css.row}>
          <Typography
            className={css.title}
            tag="h1"
            type="title"
            size="xl"
          >
            {pageTitle()}
            <LoadingIndicator
              visible={isFetching && !isLoading && sources.length > 0}
            />
          </Typography>
          <Button
            className={css.buttonNew}
            color="primary"
            onClick={() => setShowNewSourceModal((m) => !m)}
            label="New source"
          />
        </div>
        <hr className={css.titleSeparator} />
        <div className={css.filters}>
          <div className={css.filter}>
            <SearchSelect
              isClearable
              placeholder="Filter by topic"
              name="filter-topic"
              value={filters.topic || ''}
              onChange={handleFilterChange('topic')}
              searchUrl="/countries?$limit=50&name[$iLike]="
            />
          </div>
          <div className={css.filter}>
            <SearchSelect
              isClearable
              placeholder="Filter by Name"
              name="filter-name"
              value={filters.sourceName || ''}
              onChange={handleFilterChange('sourceName')}
              searchUrl="/rss-sources?$limit=50&name[$iLike]="
            />
          </div>
        </div>
      </section>
      <section>
        <DataTable
          columns={[
            {
              label: 'Name', key: 'name', sortable: true, sortKey: 'name',
            },
            { label: 'WebsiteURL', key: 'websiteUrl', sortable: false },
            { label: 'Language', key: 'language', sortable: false },
            { label: 'Topics', key: 'topics', sortable: false },
            {
              label: 'Actions', key: 'actions', className: css.columnActions, sortable: false,
            },
          ]}
          rows={sources.sources.map((source) => ({
            id: source.id,
            rowInfo: {
              className: css[`table-row-${getRSSSourceStatus(source)}`],
            },
            values: {
              name: source.name,
              websiteUrl: (
                <a className={css.link} href={source.websiteUrl} target="_blank" rel="noopener noreferrer">
                  {source.websiteUrl}
                </a>
              ),
              language: source.language,
              topics: source.topics,
              actions: (
                <div className={css.icons} data-cy="rss-sources-options">
                  <span className={css.iconContainer} data-cy="rss-sources-options-edit">
                    <Icon
                      className={css.icon}
                      type="edit"
                      onClick={() => setSelectedSource({ source: source.id, action: 'edit' })}
                    />
                  </span>
                  <span className={css.iconContainer} data-cy="rss-sources-options-delete">
                    <Icon
                      className={css.icon}
                      type="trash"
                      onClick={() => setSelectedSource({ source: source.id, action: 'delete' })}
                    />
                  </span>
                </div>
              ),
            },
          }))}
          sortKey={sort.field}
          sortOrder={sort.order}
          onSort={handleSortChange}
        />
        <div className={css.pagination}>
          <Pagination
            page={page}
            totalPages={Math.ceil(sources.total / PAGE_SIZE)}
            onPageChange={setPage}
          />
        </div>
      </section>
      <ModalNewRSSSource
        sourceType={sourceType}
        visible={showNewSourceModal}
        onCreate={handleCreateRSSSource}
        onClose={() => setShowNewSourceModal(false)}
      />
      <ModalEditRSSSource
        sourceType={sourceType}
        visible={selectedSource.source && selectedSource.action === 'edit'}
        id={selectedSource.source}
        onUpdate={handleUpdateRSSSource}
        onClose={() => setSelectedSource({ source: null, action: '' })}
      />
      <ModalDeleteRSSSource
        visible={selectedSource.source && selectedSource.action === 'delete'}
        onConfirm={() => handleDeleteConfirmed(selectedSource.source)}
        onCancel={() => setSelectedSource({ source: null, action: '' })}
      />
    </div>
  );
};

export default RSSSources;
