import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import {
  Select, Row, Col, Button, Input, Modal, Collapse,
} from 'antd';
import debounce from 'lodash/debounce';
import ValueSet from './Value';
import CrossEntityFacetType, { NUMBERS } from './CrossEntityFacetType';
import { searchCrossEntityTypes, getComparisonSetById } from './helper';
import css from './Statistic.module.css';
import './index.css';

const { Option } = Select;
const { Panel } = Collapse;

const periodicityDict = {
  YEAR: 'Annual',
  QUARTER: 'Quarterly',
  MONTH: 'Monthly',
  DAY: 'Daily',
};

const Statistic = ({
  cefs,
  options,
  actions,
  readonly,
  crossEntityFacetType,
}) => {
  const [modal, setModal] = useState(false);

  const [comparisonSets, setComparisonSets] = useState([]);

  const currentCompressionSetId = get(crossEntityFacetType, 'comparisonSetId')
    || get(crossEntityFacetType, 'comparisonSet.id');

  const [comparisonSet, setComparisonSet] = useState();

  const [crossEntityFacetTypes, setCrossEntityFacetTypes] = useState([]);
  useEffect(() => {
    searchCrossEntityTypes().then((data) => {
      setCrossEntityFacetTypes(data);
    });
  }, []);

  useEffect(() => {
    if (currentCompressionSetId) {
      getComparisonSetById(currentCompressionSetId).then((data) => setComparisonSet({ id: data.id, name: data.name }));
    } else {
      setComparisonSet({});
    }
  }, [currentCompressionSetId, setComparisonSet]);

  const onChangeName = (id) => {
    const facet = crossEntityFacetTypes.find((f) => f.id === id);
    actions.crossFacetChange({
      ...options,
      crossFacetKey: 'all',
      values: { crossEntityFacetType: facet || {}, crossEntityFacetTypeId: '' },
    });
  };

  const debouncedChange = debounce((values, set) => {
    actions.crossFacetChange({
      ...options,
      crossFacetKey: set.key,
      values,
    });
    const newValue = { ...set, ...values };
    const hasUpdates = (newValue.date !== set.date)
      || (newValue.value !== set.value)
      || (newValue.crossEntityFacetTypeId !== set.crossEntityFacetTypeId);
    if (hasUpdates) {
      actions.crossEntityFacetSave({
        id: newValue.id,
        date: newValue.date,
        entityId: newValue.entityId,
        facetId: newValue.facetId,
        crossEntityFacetTypeId: newValue.crossEntityFacetTypeId,
        value: newValue.value,
      });
    }
  }, 300);

  const addValueSets = () => {
    actions.crossFacetAdd({
      ...options,
    });
  };

  const removeValueSet = (setKey, setId) => {
    actions.crossFacetRemove({
      ...options,
      setKey,
      setId,
    });
  };

  const searchCEFType = (value) => searchCrossEntityTypes(value).then((data) => setCrossEntityFacetTypes(data));

  return (
    <div className="Statistic_Ceft-Pannel">
      <Collapse bordered={false} defaultActiveKey={false}>
        <Panel
          key="key"
          header={<div className="Statistic_Ceft-Pannel">CEFT</div>}
        >
          <div>
            <Row gutter={18} style={{ marginBottom: 16 }}>
              <Col span={24}>
                <div style={{ fontSize: '18px' }}>Name</div>
                <Row gutter={18}>
                  <Col span={21}>
                    <Select
                      dropdownClassName="dropdown-zindex"
                      disabled={readonly}
                      value={crossEntityFacetType.name}
                      onChange={onChangeName}
                      onSearch={searchCEFType}
                      style={{ width: '100%' }}
                      placeholder="Name"
                      optionFilterProp="children"
                      showSearch
                    >
                      {crossEntityFacetTypes.map((facet) => (
                        <Option key={facet.id} value={facet.id}>
                          {facet.name}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                  <Col span={3}>
                    <Button
                      disabled={readonly}
                      className={css.ButtonCreateCEFT}
                      onClick={() => setModal(true)}
                    >
                      {crossEntityFacetType && crossEntityFacetType.id
                        ? 'Edit CEFT'
                        : 'Create CEFT'}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={18} style={{ marginBottom: '16px' }}>
              <Col span={24}>
                <div style={{ fontSize: '18px' }}>Comparison Set</div>
                <Input
                  style={{ width: '100%' }}
                  value={
                    comparisonSet && comparisonSet.name
                      ? comparisonSet.name
                      : null
                  }
                  disabled
                />
              </Col>
            </Row>

            <Row gutter={18} style={{ marginBottom: '16px' }}>
              <Col span={8}>
                <div style={{ fontSize: '18px' }}>Currency</div>
                <Input
                  style={{ width: '100%' }}
                  value={crossEntityFacetType.currency}
                  disabled
                />
              </Col>

              <Col span={8}>
                <div style={{ fontSize: '18px' }}>Units</div>
                <Input
                  style={{ width: '100%' }}
                  value={crossEntityFacetType.units}
                  disabled
                />
              </Col>

              <Col span={8}>
                <div style={{ fontSize: '18px' }}>Periodicity</div>
                <Select
                  value={periodicityDict[crossEntityFacetType.periodicity]}
                  style={{ width: '100%' }}
                  disabled
                >
                  <Option value="YEAR">Annual</Option>
                  <Option value="QUARTER">Quarterly</Option>
                  <Option value="MONTH">Monthly</Option>
                  <Option value="DAY">Daily</Option>
                </Select>
              </Col>
            </Row>

            <Row gutter={18} style={{ marginBottom: '16px' }}>
              <Col span={12}>
                <div style={{ fontSize: '18px' }}>Future Dates</div>
                <Select
                  style={{ width: '100%' }}
                  value={crossEntityFacetType.futureDates}
                  disabled
                >
                  <Option value="PROJECTION">Projection</Option>
                  <Option value="GOAL">Goal</Option>
                </Select>
              </Col>

              <Col span={12}>
                <div style={{ fontSize: '18px' }}>Multiplier</div>
                <Select
                  disabled
                  style={{ width: '100%' }}
                  value={crossEntityFacetType.multiplier}
                >
                  {NUMBERS.map((n) => <Option key={n.value} value={n.value}>{n.key}</Option>)}
                </Select>
              </Col>
            </Row>

            <Row gutter={24} style={{ marginBottom: '16px' }}>
              <Col span={24}>
                <div style={{ fontSize: '18px' }}>Subtitle</div>
                <Input
                  disabled
                  type="text"
                  name="subtitle"
                  value={crossEntityFacetType.subtitle}
                />
              </Col>
            </Row>

            <Row gutter={24} style={{ marginBottom: '60px' }}>
              <Col span={24}>
                <div style={{ fontSize: '18px' }}>Footnote</div>
                <Input.TextArea
                  disabled
                  name="footnote"
                  rows={4}
                  value={crossEntityFacetType.footnote}
                />
              </Col>
            </Row>

            {cefs
              && crossEntityFacetType
              && crossEntityFacetType.name
              && cefs.map((set) => (
                <ValueSet
                  id={set.id}
                  key={set.key}
                  value={set.value}
                  date={set.date}
                  setKey={set.key}
                  periodicity={crossEntityFacetType.periodicity}
                  readonly={readonly}
                  removeValueSet={removeValueSet}
                  onChange={(values) => debouncedChange(
                    { ...values, crossEntityFacetType },
                    set,
                  )}
                />
              ))}

            <Row style={{ marginBottom: '32px', marginTop: '32px' }}>
              <Col>
                <Button
                  className="btn-like-link"
                  disabled={readonly}
                  onClick={addValueSets}
                >
                  + Add another value
                </Button>
              </Col>
            </Row>

            <Modal
              visible={modal}
              onCancel={() => setModal(false)}
              footer={null}
              destroyOnClose
              centered
            >
              <CrossEntityFacetType
                ceft={crossEntityFacetType}
                onCancel={() => setModal(false)}
                options={options}
                crossFacetChange={actions.crossFacetChange}
                comparisonSets={comparisonSets}
                setComparisonSets={setComparisonSets}
                currentCompressionSetId={currentCompressionSetId}
                comparisonSet={comparisonSet}
                setComparisonSet={setComparisonSet}
              />
            </Modal>
          </div>
        </Panel>
      </Collapse>
    </div>
  );
};

export default Statistic;
