import React, { createRef } from 'react';
import debounce from 'lodash/debounce';
import { Checkbox } from 'antd';
import { ENTITY_COLUMNS } from '../../../../common/Enums/Columns';

const property = ENTITY_COLUMNS.HIDDEN;

class CustomEditor extends React.PureComponent {
  state = {
    [property]: false,
  };

  refInput = createRef();

  debouncedCommit = debounce(() => this.props.onCommit(), 0);

  componentDidMount() {
    this.setState({ [property]: !this.props.value });
    this.debouncedCommit();
  }

  getValue() {
    return { [property]: this.state[property] };
  }

  getInputNode() {
    return this.refInput.current;
  }

  // eslint-disable-next-line class-methods-use-this,react/sort-comp
  disableContainerStyles() {}

  onChange = ({ target: { checked } }) => {
    this.setState({ [property]: checked }, this.debouncedCommit);
  };

  render() {
    return (
      <Checkbox
        style={{ marginTop: 8, marginLeft: 8 }}
        ref={(ref) => { this.refInput.current = ref; }}
        onChange={this.onChange}
        value={this.state[property]}
      />
    );
  }
}

const formatter = ({ row }) => (<Checkbox checked={row[property]} />);

const hiddenStatusColumn = {
  key: property,
  name: 'Hidden',
  editable: true,
  sortable: true,
  resizable: true,
  width: 70,
  editor: CustomEditor,
  formatter,
};

export default hiddenStatusColumn;
