import moment from 'moment/moment';
import uniqueId from 'lodash/uniqueId';

// eslint-disable-next-line import/prefer-default-export
export const getCrossEntityFacets = (facetType, cefs = [], statisticTemplate) => {
  if (cefs && cefs.length) {
    return cefs.map((f) => ({
      ...f,
      date: f.date ? moment(f.date) : null,
      key: uniqueId('cef'),
    }));
  }
  if (statisticTemplate) {
    return [{
      date: moment().format('YYYY-MM-DD[T]HH:mm:ss'),
      value: 0,
      key: uniqueId('cef'),
    }];
  }
  return [];
};
