import React from 'react';
import './index.css';

const SelectOption = ({
  label, avatar, small, isShowLabel = true, title, isExists = null, assigned = null,
}) => {
  if (isExists || (isExists === null && assigned === null)) {
    return (
      <div className="select-option-avatar">
        <div
          className="avatar"
          title={title}
        >
          <div
            className="avatar-icon"
            style={label
              ? { backgroundColor: avatar || '#CCC', width: small ? 20 : 22, height: small ? 20 : 22 }
              : {}}
          >
            {label.charAt(0)}
          </div>
        </div>
        {
          isShowLabel && (
            <div className="short-name">
              <span className="short-name-content" title={label}>{label}</span>
            </div>
          )
        }
      </div>
    );
  }
  if (assigned) {
    return <span>Removed User</span>;
  }
  return null;
};

export default SelectOption;
