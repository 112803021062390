import orderBy from 'lodash/orderBy';

export function group(array, fields, order) {
  const itemsSorted = orderBy(array, fields, order);
  const itemsGrouped = {};
  itemsSorted.forEach((e) => {
    if (!e[fields[0]] || e[fields[0]] === null || e[fields[0]] === '' || e[fields[0]] === ' ') {
      if (!itemsGrouped[' ']) {
        itemsGrouped[' '] = [];
      }
      itemsGrouped[' '].push(e);
    } else {
      if (!itemsGrouped[e[fields[0]]]) {
        itemsGrouped[e[fields[0]]] = [];
      }
      itemsGrouped[e[fields[0]]].push(e);
    }
  });
  const valItemsG = Object.values(itemsGrouped);
  const keyItemsG = Object.keys(itemsGrouped);
  return {
    values: valItemsG,
    keys: keyItemsG,
  };
}

export function formatTemplate(template) {
  const prepared = {
    ...template,
  };
  if (!prepared.facets) {
    prepared.facets = prepared.facetTypes;
  }
  delete prepared.facetTypes;
  prepared.facets = prepared.facets.map((f) => {
    const facet = { ...f };
    if (!facet.fields) {
      facet.fields = facet.facetFieldTypes;
    }
    delete facet.facetFieldTypes;
    return facet;
  });

  if (!prepared.layout) {
    prepared.layout = { rows: [] };
  }
  return prepared;
}

export function simulateClick(elem) {
  const evt = new MouseEvent('click', {
    bubbles: true,
    cancelable: true,
    view: window,
  });
  elem.dispatchEvent(evt);
}
