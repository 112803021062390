import groupBy from 'lodash/groupBy';
import uniqueId from 'lodash/uniqueId';
import orderBy from 'lodash/orderBy';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import createFacet from './createFacet';
import {
  RELATIONSHIPS_FACETS,
} from '../../../constants/facetTypes';
import { ENTITY_COLUMNS } from '../../../common/Enums/Columns';

const HIDE_FACETS = ['Role', 'Education', 'English Keywords', 'Origin Language Keywords'];

const createFacets = ({
  facetType = null, facets = [], links, entityId,
}, facetInspector) => {
  const preparedFacets = facets && facets.length > 0
    ? facets
    : [{
      type: facetType,
      crossEntityFacet: facetType.crossEntityFacetTypeId
        ? [{
          date: null,
          value: '',
          crossEntityFacetTypeId: facetType.crossEntityFacetTypeId,
        }]
        : [],
    }];
  return preparedFacets.map((facet) => createFacet(facet, facetType, links[facetType.name], facetInspector, entityId));
};

const getFacets = (template) => {
  const facets = template.facets || template.facetTypes || [];
  const result = facets.length > 0 ? facets : [];
  return orderBy(result, [(e) => parseInt(e.order, 10), 'section'], ['asc', 'asc'])
    .filter((facet) => !facet.deleted && !facet.archived);
};

const getSections = ({ facets = [] }) => {
  const sectionList = uniq(facets.map((facet) => facet.type.section));
  const sectionObj = groupBy(facets, (facet) => facet.type.section);
  delete sectionObj.Relationships;

  const sections = Object
    .keys(sectionObj)
    .map((section) => ({
      key: uniqueId('_section'),
      name: section,
      facets: orderBy(
        sectionObj[section].filter((facet) => (!!facet.id || facet.type.display) && !HIDE_FACETS.includes(facet.type.name)),
        [(facet) => Number.parseInt(facet.type.order, 10), 'order'],
        ['asc', 'asc'],
      ),
      order: sectionList.indexOf(section),
      componentLogs: {
        updated: Date.now(),
        event: 'init',
        changed: false,
      },
      duplicates: sectionObj[section]
        .map((facet) => (facet.type.duplicate === 'section' ? facet.type : null))
        .filter((type, i, arr) => !!type
          && !RELATIONSHIPS_FACETS.includes(type.name)
          && !arr.find((a, j) => !!a && a.id === type.id && i < j)),
    }))
    .sort((a, b) => a.order - b.order);
  return sections;
};

const createSchema = (template, entity = {}) => {
  const preparedEntity = {
    key: uniqueId('_entityId'),
    id: get(entity, 'id') || undefined,
    facetLoadingStatusMap: {},
    fieldLoadingStatusMap: {},
    linkLoadingStatusMap: {},
    links: [],
    template,
    facetInspector: {},
    componentLogs: {
      updated: Date.now(),
      changed: false,
      event: 'init',
    },
    sectionsOpenByDefault: [],
    activeKeys: [],
    sectionsKeys: [],
    meta: {
      assignedToId: null,
      stage: 'in progress',
      failedReason: null,
    },
    topic: null,
    isTopic: false,
  };
  const facetTypes = getFacets(template || {});
  const dataFacets = getFacets(entity || {});
  const facets = [];
  facetTypes.forEach((facetType) => {
    facets.push(
      ...createFacets({
        facetType,
        facets: dataFacets
          .filter((facet) => facet.facetTypeId === facetType.id),
        links: [],
        entityId: preparedEntity.id,
      },
      (type) => {
        if (!Object.prototype.hasOwnProperty.call(preparedEntity.facetInspector, type.id)) {
          preparedEntity.facetInspector[type.id] = {
            count: 0,
          };
        }
        if (facetType.display) {
          preparedEntity.facetInspector[type.id].count += 1;
        }
      }),
    );
  });
  const facetName = facets.find((facet) => facet.type.name === 'Name' && facet.type.required);
  preparedEntity.name = facetName ? facetName.label : '';
  preparedEntity.sections = getSections({ facets });
  preparedEntity.sections.forEach((section) => {
    preparedEntity.sectionsKeys.push(section.key);
    const unfilledFacet = section.facets.find((facet) => !facet.filledAll);
    if (unfilledFacet) {
      preparedEntity.sectionsOpenByDefault.push(section.key);
      preparedEntity.activeKeys.push(section.key);
    }
  });
  if (entity) {
    preparedEntity.topics = entity.topics;
    preparedEntity.isTopic = entity.isTopic;
    preparedEntity.meta = {
      assignedToId: get(entity, 'meta.assignedToId') || null,
      stage: get(entity, 'meta.stage') || null,
      failedReason: get(entity, 'meta.failedReason') || null,
    };
    preparedEntity.name = entity.name;
    preparedEntity[ENTITY_COLUMNS.HIDDEN] = entity[ENTITY_COLUMNS.HIDDEN];
  }
  return preparedEntity;
};

export default createSchema;
