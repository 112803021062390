import React from 'react';
import { ENTITY_COLUMNS } from '../../../../common/Enums/Columns';

const formatter = ({ row }) => (<div title={row[ENTITY_COLUMNS.NAME]} data-testid="entityItemName">{row[ENTITY_COLUMNS.NAME]}</div>);

const nameColumn = {
  key: ENTITY_COLUMNS.NAME,
  name: 'Name',
  filterable: true,
  sortable: true,
  resizable: true,
  width: 280,
  formatter,
};

export default nameColumn;
