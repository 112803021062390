import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import {
  Row, Col, Select, Input, Button, message, Form,
} from 'antd';
import {
  searchComparisonSet,
  createCrossEntityType,
  editCrossEntityType,
} from './helper';

export const NUMBERS = [
  { key: 'Hundred', value: '0.01' },
  { key: 'Thousand', value: '0.001' },
  { key: 'Million', value: '0.000001' },
  { key: 'Billion', value: '0.1e-9' },
  { key: 'Trillion', value: '0.1e-12' },
];

export const getMultiplierByValue = (value) => (NUMBERS.find((f) => f.value === value) || {}).key || null;

const { Option } = Select;

const periodicityDict = {
  YEAR: 'Annual',
  QUARTER: 'Quarterly',
  MONTH: 'Monthly',
  DAY: 'Daily',
};

const CrossEntityFacetType = ({
  onCancel,
  options,
  crossFacetChange,
  crossFacetTypeKey,
  ceft,
  comparisonSet,
  setComparisonSet,
  comparisonSets,
  setComparisonSets,
}) => {
  const [name, setName] = useState(ceft ? ceft.name : '');
  const [errors, setError] = useState({});
  const [multiplier, setMultiplier] = useState(ceft ? ceft.multiplier : null);
  const [units, setUnits] = useState(ceft ? ceft.units : getMultiplierByValue(ceft.multiplier));
  const [currency, setCurrency] = useState(ceft ? ceft.currency : '');
  const [subtitle, setSubtitle] = useState(ceft ? ceft.subtitle : '');
  const [footnote, setFootnote] = useState(ceft ? ceft.footnote : '');
  const [futureDates, setFutureDates] = useState(
    ceft ? ceft.futureDates : 'GOAL',
  );
  const [periodicity, setPeriodicity] = useState(
    ceft && ceft.periodicity ? ceft.periodicity : 'YEAR',
  );

  const searchComparison = (value) => searchComparisonSet(value).then((data) => {
    setComparisonSets(data);
  });
  const onChangeComparisonSet = (id) => {
    const facet = comparisonSets.find((f) => f.id === id);
    setComparisonSet(facet);
  };
  const createFacetType = () => createCrossEntityType({
    name,
    currency,
    periodicity,
    units,
    comparisonSetId: !comparisonSet ? undefined : comparisonSet.id,
    multiplier,
    futureDates,
    footnote,
    subtitle,
  })
    .then(({ data }) => {
      crossFacetChange({
        ...options,
        crossFacetTypeKey,
        crossFacetKey: 'all',
        values: {
          crossEntityFacetTypeId: data.id,
          crossEntityFacetType: data,
          comparisonSet,
        },
      });
      onCancel();
    })
    .catch((err) => {
      setError(err.response.data.data);
      message.error(err.toString());
    });

  const editFacetType = () => {
    const body = {
      ...ceft,
      name: name || '',
      currency: currency || '',
      periodicity,
      units: units || '',
      multiplier: multiplier || '',
      futureDates: futureDates || '',
      footnote: footnote || '',
      subtitle: subtitle || '',
      comparisonSetId: !comparisonSet ? null : comparisonSet.id,
    };

    editCrossEntityType(ceft.id, body)
      .then(({ data }) => {
        crossFacetChange({
          ...options,
          crossFacetTypeKey,
          crossFacetKey: 'all',
          values: {
            crossEntityFacetTypeId: data.id,
            crossEntityFacetType: data,
            comparisonSet,
          },
        });
        onCancel();
      })
      .catch((err) => {
        console.error(err.response.data);
        setError(err.response.data.data);
        message.error(err.toString());
      });
  };

  useEffect(() => {
    searchComparisonSet().then((data) => setComparisonSets(data));
  }, [searchComparisonSet, setComparisonSets]);

  useEffect(() => {
    if (!units && multiplier) {
      setUnits(getMultiplierByValue(multiplier));
    }
  }, [multiplier]);

  return (
    <>
      <Row gutter={24} style={{ marginBottom: '16px' }}>
        <Col span={24}>
          <div style={{ fontSize: '18px' }}>Name</div>
          <Form.Item
            hasFeedback={!!errors.name}
            validateStatus={errors.name ? 'error' : null}
            help={errors.name}
            style={{ margin: 0 }}
          >
            <Input
              value={name}
              onChange={({ target: { value } }) => setName(value.slice(0, 32))}
              style={{ width: '100%' }}
              placeholder="Name"
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24} style={{ marginBottom: '16px' }}>
        <Col span={24}>
          <div style={{ fontSize: '18px' }}>Comparison Set</div>
          <Select
            value={get(comparisonSet, 'id')}
            onChange={onChangeComparisonSet}
            onSearch={searchComparison}
            style={{ width: '100%' }}
            optionFilterProp="children"
            showSearch
            allowClear
          >
            {comparisonSets.map((facet) => (
              <Option key={facet.id} value={facet.id}>
                {facet.name}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>

      <Row gutter={24} v>
        <Col span={8}>
          <div style={{ fontSize: '18px' }}>Currency</div>
          <Input
            style={{ width: '100%' }}
            value={currency}
            onChange={({ target: { value } }) => setCurrency(value)}
          />
        </Col>

        <Col span={8}>
          <div style={{ fontSize: '18px' }}>Units</div>
          <Input
            style={{ width: '100%' }}
            value={units}
            onChange={({ target: { value } }) => setUnits(value.slice(0, 20))}
          />
        </Col>

        <Col span={8}>
          <div style={{ fontSize: '18px' }}>Periodicity</div>
          <Select
            value={periodicityDict[periodicity]}
            onChange={setPeriodicity}
            style={{ width: '100%' }}
          >
            <Option value="YEAR">Annual</Option>
            <Option value="QUARTER">Quarterly</Option>
            <Option value="MONTH">Monthly</Option>
            <Option value="DAY">Daily</Option>
          </Select>
        </Col>
      </Row>

      <Row gutter={24} style={{ marginBottom: '16px' }}>
        <Col span={12}>
          <div style={{ fontSize: '18px' }}>Future Dates</div>
          <Select
            style={{ width: '100%' }}
            value={futureDates}
            onChange={setFutureDates}
          >
            <Option value="PROJECTION">Projection</Option>
            <Option value="GOAL">Goal</Option>
          </Select>
        </Col>

        <Col span={12}>
          <div style={{ fontSize: '18px' }}>Multiplier</div>
          <Select
            style={{ width: '100%' }}
            value={multiplier}
            onChange={setMultiplier}
            allowClear
          >
            {NUMBERS.map((n) => (
              <Option key={n.value} value={n.value}>
                {n.key}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>

      <Row gutter={24} style={{ marginBottom: '16px' }}>
        <Col span={24}>
          <div style={{ fontSize: '18px' }}>Subtitle</div>
          <Input
            type="text"
            name="subtitle"
            value={subtitle}
            onChange={({ target: { value = '' } }) => setSubtitle(value)}
          />
        </Col>
      </Row>

      <Row gutter={24} style={{ marginBottom: '60px' }}>
        <Col span={24}>
          <div style={{ fontSize: '18px' }}>Footnote</div>
          <Input.TextArea
            name="footnote"
            rows={4}
            value={footnote}
            onChange={({ target: { value = '' } }) => setFootnote(value)}
          />
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={9} />
        <Col span={4}>
          <Button onClick={onCancel}>Cancel</Button>
        </Col>
        <Col span={11}>
          <Button
            type="primary"
            onClick={() => (ceft && ceft.id ? editFacetType() : createFacetType())}
          >
            {ceft && ceft.id
              ? 'Update Cross Entity Facet Type'
              : 'Create Cross Entity Facet Type'}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default CrossEntityFacetType;
