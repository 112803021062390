/* eslint-disable no-param-reassign */
import { get, uniqBy } from 'lodash';
import { parseDate } from '../../common/utils/date';
import client from '../../common/utils/http';
import { processEventData } from '../EventPage/utils';

export const getActiveArticlePage = ({ activeArticle, articles }) => {
  const index = articles.findIndex((article) => article.id === activeArticle.id);
  return index + 1;
};

export const createEvent = async (data) => {
  const event = await processEventData(data);
  return client.post('/events', event);
};

export const splitArticleIntoSections = ({ article, sectionWidth = 300 }) => {
  const words = article.split(' ');
  const totalSections = Math.ceil(words.length / sectionWidth);
  const wordsPerSection = Math.ceil(words.length / totalSections);
  const sections = [];
  for (let i = 0; i < totalSections; i += 1) {
    sections.push(words.slice(i * wordsPerSection, (i + 1) * wordsPerSection).join(' '));
  }
  return sections;
};

export const mergeBriefSuggestions = (briefSuggestions) => {
  const options = briefSuggestions.flat();
  const recommendations = options.reduce((suggestions, option) => {
    const existingOption = suggestions.some((s) => s.label === option.label);
    if (!existingOption) return [...suggestions, option];
    return suggestions.map((suggestion) => {
      if (suggestion.label === option.label) {
        return {
          ...suggestion,
          score: Math.max(suggestion.score, option.score),
        };
      }
      return suggestion;
    });
  }, []);
  return recommendations.sort((a, b) => b.score - a.score);
};

export const getRelevantBriefs = (briefSuggestions) => {
  const goodQualityThreshold = 0.8;
  const minimumThreshold = 0.6;
  const relevantBriefs = briefSuggestions
    .filter((brief) => brief.score > minimumThreshold)
    .map(({ label, score }) => ({
      label,
      score: (score * 100).toFixed(2),
      quality: score > goodQualityThreshold ? 'good' : 'medium',
    }));
  return relevantBriefs;
};

export const transformArticleToEvent = (article) => {
  // Get the data from the Machine Learning Algorithms
  const mlTitle = get(article, 'mlPredictions.summarization', '');
  const mlBriefs = get(article, 'mlPredictions.categories', []);

  const mlENREntities = get(article, 'mlPredictions.foundEnrEntities', [])
    .map((enr) => enr.suggestedEntities || [])
    .flat();

  article.actors.forEach((a) => {
    a.entityId = a.id;
    a.entityName = a.name;
  });

  const keywordEntities = [
    ...article.plots,
    ...article.contexts,
    ...article.actors,
  ]
    .sort((a, b) => a.frequency - b.frequency)
    .map((e) => ({
      id: e.entityId,
      name: e.entityName,
      topicName: e.topicName,
      topicId: e.topicId,
    }));

  return {
    title: (mlTitle || article.title || '').substring(0, 150),
    articleId: article.id,
    topics: article.topics,
    entities: uniqBy([
      ...mlENREntities,
      ...keywordEntities,
    ], 'id'),
    briefs: uniqBy([
      ...article.briefs
        .sort((a, b) => a.frequency - b.frequency)
        .map((b) => ({
          id: b.entityId,
          name: b.entityName,
          topicName: b.topicName,
          topicId: b.topicId,
        })),
      ...mlBriefs.map((b) => ({
        id: b.id,
        name: b.name,
        topicName: b.topicName,
        topicId: b.topicId,
      })),
    ], 'id'),
    location: {},
    sources: [article.source],
    date: parseDate(article.date).toJSDate(),
  };
};

export const levenshteinDistance = (str1 = '', str2 = '') => {
  const track = Array(str2.length + 1).fill(null).map(() => Array(str1.length + 1).fill(null));
  for (let i = 0; i <= str1.length; i += 1) {
    track[0][i] = i;
  }
  for (let j = 0; j <= str2.length; j += 1) {
    track[j][0] = j;
  }
  for (let j = 1; j <= str2.length; j += 1) {
    for (let i = 1; i <= str1.length; i += 1) {
      const indicator = str1[i - 1] === str2[j - 1] ? 0 : 1;
      track[j][i] = Math.min(
        track[j][i - 1] + 1, // Deletion
        track[j - 1][i] + 1, // Insertion
        track[j - 1][i - 1] + indicator, // Substitution
      );
    }
  }
  return track[str2.length][str1.length];
};
