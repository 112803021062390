import axios from 'axios';
import get from 'lodash/get';

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

/**
 * @param {string} apiUrl
 * @param {number} limit
 * @param {number} skip
 * @param {array} data - results, default = []
 * @param {string} urlParams - query params as string
 * @return {Promise} array in promise
 * */
const fetchAll = async (apiUrl, limit = 50, skip = 0, data = [], urlParams = '') => {
  if (apiUrl) {
    const url = `${apiUrl}?$skip=${skip}&$limit=${limit}&${urlParams}`;
    const response = await client.get(url);
    const res = get(response, 'data.data', response.data);
    data.push(...res);
    if (res.length === limit) {
      return fetchAll(apiUrl, limit, skip + limit, data, urlParams);
    }
    return data;
  }
  return data;
};

client.fetchAll = fetchAll;

export default client;
