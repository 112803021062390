import React from 'react';
import { Link } from 'react-router-dom';
import {
  Icon, Popconfirm, Row, Col,
} from 'antd';
import styles from './DictionaryActions.module.css';

const DictionaryActions = ({
  element,
  onDelete,
}) => (
  <Row type="flex" justify="center" align="middle" gutter={8}>
    <Col>
      <Link
        className={styles.link}
        to={`/admin/elements/dictionaries/editor/${element.id}`}
      >
        <Icon type="edit" />
      </Link>
    </Col>

    <Col>
      <Popconfirm
        id={element.id}
        placement="topRight"
        title={`Are you sure you want to delete '${element.key}'?`}
        onConfirm={onDelete}
        getPopupContainer={() => document.getElementById('DictionariesTable')}
        okText="Yes"
        cancelText="Cancel"
      >
        <Icon
          className="cursor-pointer mrg-left-10"
          type="delete"
        />
      </Popconfirm>
    </Col>
  </Row>
);

export default DictionaryActions;
