import get from 'lodash/get';
import uniqueId from 'lodash/uniqueId';
import _set from 'lodash/set';
import moment from 'moment';
import { message } from 'antd';
import {
  ADD_LOAD_PROCESS,
  ALL,
  CHANGE_FIELDS,
  CHANGE_FILTER,
  CHANGE_LINK,
  CLEAR_FACET,
  CLEAR_STORE,
  DICTIONARY_ITEM_ADD,
  ENTITY_EDITOR_STATE_CLEAN,
  ENTITY_EDITOR_STATE_DIRTY,
  ENTITY_EDITOR_STATE_SAVING,
  FACET_ADD,
  FACET_ADD_TO_SECTION,
  FACET_LOADING_DISABLE,
  FACET_LOADING_ENABLE,
  FACET_META_CHANGE,
  FACET_REMOVE,
  FIELD_ADD,
  FIELD_LOADING_DISABLE,
  FIELD_LOADING_ENABLE,
  FIELD_REMOVE,
  LINK_ADD,
  LINK_LOADING_DISABLE,
  LINK_LOADING_ENABLE,
  REG_DICT_ITEM,
  REMOVE_LINK,
  REMOVE_LOAD_PROCESS,
  SET_ENTITY,
  SET_ENTITY_ASSIGNMENTS,
  SET_STATUS,
  SIDEBAR_SECTION_OPEN_SET,
  CROSS_FACET_CHANGE,
  CROSS_FACET_ADD,
  CROSS_FACET_REMOVE,
  ACTION_TOGGLE_HIDDEN,
  FACET_ORDER_CHANGE,
  UPDATE_FACET_LOG_COUNT,
} from './constants';
import {
  clearFacet,
  createFacet,
  createFieldSet,
  createLinkSet,
  initFieldSet,
  isFacetFilled,
  isFilledFieldSet,
  isDataFilled, getFillStatus,
} from './utils';
import { IN_PROGRESS } from '../../common/Enums/Stages';
import { ENTITY_COLUMNS } from '../../common/Enums/Columns';
import authSelector from '../../selectors/users';

const hiddenProperty = ENTITY_COLUMNS.HIDDEN;

const initialState = {
  assignedMode: false,
  dataToDelete: {
    facets: [],
    fields: [],
    links: [],
    cefs: [],
  },
  dictionaries: {},
  dictionariesItemNew: {},
  entities: [],
  filter: ALL,
  isLoading: false,
  loadProcesses: [],
  linkLoadingStatusMap: {},
  facetLoadingStatusMap: {},
  fieldLoadingStatusMap: {},
  page: 1,
  sortBy: null,
  total: 0,
  viewMode: false,
  entityEditorState: ENTITY_EDITOR_STATE_CLEAN,
  changeSet: [],
};
const generateLog = () => ({
  updated: Date.now(),
  event: 'updated',
  changed: true,
});

const toMap = (acc, value) => {
  if (value && value.id) {
    acc[value.id] = value;
  }
  return acc;
};

const DISALLOW_LEAVE_PAGE = [
  CHANGE_FIELDS,
  CHANGE_LINK,
  CLEAR_FACET,
  DICTIONARY_ITEM_ADD,
  FACET_ADD,
  FACET_META_CHANGE,
  FACET_ORDER_CHANGE,
  FACET_REMOVE,
  REG_DICT_ITEM,
  REMOVE_LINK,
  FIELD_REMOVE,
];

const ACTIONS_AFFECTE_ON_ENTITY_STAGE = [
  CHANGE_FIELDS,
  CHANGE_LINK,
  CLEAR_FACET,
  FACET_ADD,
  FACET_ADD_TO_SECTION,
  FACET_META_CHANGE,
  FACET_ORDER_CHANGE,
  FACET_REMOVE,
  LINK_ADD,
  REMOVE_LINK,
  CROSS_FACET_CHANGE,
  CROSS_FACET_ADD,
  CROSS_FACET_REMOVE,
];

const hookBefore = (action, state) => {
  const { type, payload } = action;
  if (ACTIONS_AFFECTE_ON_ENTITY_STAGE.includes(type)) {
    const { entityKey } = payload;
    state.entities.find((entity) => {
      if (entity.key === entityKey) {
        if (entity.meta.stage !== IN_PROGRESS) {
          _set(entity, 'meta.stage', IN_PROGRESS);
        }
        return true;
      }
      return false;
    });
  }
};

const reducer = (state = initialState, action, store) => {
  const { type, payload } = action;
  if (!document.leavingpage) {
    document.leavingpage = DISALLOW_LEAVE_PAGE.includes(type);
  }
  hookBefore(action, state);
  switch (type) {
    case ADD_LOAD_PROCESS: {
      const processes = [...state.loadProcesses];
      processes.push(payload.processId);
      return {
        ...state,
        entityEditorState: ENTITY_EDITOR_STATE_SAVING,
        loadProcesses: processes,
        isLoading: true,
      };
    }
    case CLEAR_STORE: {
      return {
        ...state,
        entityEditorState: ENTITY_EDITOR_STATE_CLEAN,
        changeSet: [],
        entities: [],
        dataToDelete: {
          facets: [],
          fields: [],
          links: [],
          cefs: [],
        },
        dictionaries: {},
        dictionariesItemNew: {},
        page: 1,
        total: null,
      };
    }
    case CHANGE_FILTER: {
      return {
        ...state,
        filter: payload,
      };
    }
    case CHANGE_FIELDS: {
      const log = generateLog();
      let changed = false;
      const changeSet = [];
      const newState = {
        ...state,
        entities: state.entities.map((entity) => {
          let { name } = entity;
          if (entity.key === payload.entityKey) {
            const sections = entity.sections.map((section) => {
              if (section.key === payload.sectionKey) {
                const facets = section.facets.map((facet) => {
                  if (facet.key === payload.facetKey) {
                    let { label } = facet;
                    const wrappers = facet.wrappers.map((wrapper) => {
                      if (wrapper.key === payload.wrapperKey) {
                        const fieldSets = wrapper.fieldSets.map((fieldSet) => {
                          if (fieldSet.key === payload.fieldSetKey) {
                            const keys = Object.keys(payload.fields);
                            const fields = {
                              ...fieldSet.fields,
                            };
                            keys.forEach((key) => {
                              if (key === 'key' || key === 'sourceKey') {
                                fields[key].value = payload.fields[key].key;
                                fields[key].href = payload.fields[key].href;
                              } else {
                                fields[key].value = payload.fields[key];
                              }
                            });
                            const newFieldSet = {
                              ...fieldSet,
                              fields,
                              componentLogs: log,
                            };
                            newFieldSet.filled = isFilledFieldSet(newFieldSet);
                            if (fieldSet.type.name === 'Label' && fieldSet.type.required) {
                              if (newFieldSet.fields.value.value !== label) {
                                label = newFieldSet.fields.value.value;
                              }
                            }
                            changed = true;
                            return newFieldSet;
                          }
                          return fieldSet;
                        });
                        if (facet.type.name === 'Name' && facet.type.required === true) {
                          if (name !== label) {
                            name = label;
                          }
                        }
                        return {
                          ...wrapper,
                          fieldSets,
                        };
                      }
                      return wrapper;
                    });
                    const newFacet = {
                      ...facet,
                      ...isFacetFilled(wrappers),
                      wrappers,
                      label,
                      componentLogs: log,
                    };
                    newFacet.status = getFillStatus(newFacet);
                    return newFacet;
                  }
                  return facet;
                });
                return {
                  ...section,
                  facets,
                  componentLogs: log,
                };
              }
              return section;
            });
            return {
              ...entity,
              name,
              sections,
              componentLogs: log,
            };
          }
          return entity;
        }),
        changeSet: [...state.changeSet, ...changeSet],
        entityEditorState: state.changeSet.length || changeSet.length
          ? ENTITY_EDITOR_STATE_DIRTY
          : ENTITY_EDITOR_STATE_CLEAN,
      };
      if (changed) return newState;
      return state;
    }
    case CHANGE_LINK: {
      const log = generateLog();
      const {
        entityKey, facetKey, sectionKey, key, changes,
      } = payload;
      let changed = false;
      const newState = {
        ...state,
        entityEditorState: ENTITY_EDITOR_STATE_DIRTY,
        entities: state.entities.map((entity) => {
          if (entity.key === entityKey) {
            return {
              ...entity,
              componentLogs: log,
              sections: entity.sections.map((section) => {
                if (section.key === sectionKey) {
                  return {
                    ...section,
                    facets: section.facets.map((facet) => {
                      if (facet.key === facetKey) {
                        const wrappers = facet.wrappers.map((wrapper) => {
                          if (wrapper.key === payload.wrapperKey) {
                            const fieldSets = wrapper.fieldSets.map((link) => {
                              if (link.key === key) {
                                changed = true;
                                return {
                                  ...link,
                                  ...changes,
                                  componentLogs: log,
                                };
                              }
                              return link;
                            });
                            return {
                              ...wrapper,
                              fieldSets,
                            };
                          }
                          return wrapper;
                        });
                        const newFacet = {
                          ...facet,
                          componentLogs: log,
                          wrappers,
                          ...isFacetFilled(wrappers),
                        };
                        newFacet.status = getFillStatus(newFacet);
                        return newFacet;
                      }
                      return facet;
                    }),
                  };
                }
                return section;
              }),
            };
          }
          return entity;
        }),
      };
      if (changed) return newState;
      return state;
    }
    case CLEAR_FACET: {
      const log = generateLog();
      let changed = false;
      const dataToDelete = {
        ...state.dataToDelete,
        fields: [...state.dataToDelete.fields],
        links: [...state.dataToDelete.links],
        cefs: [...state.dataToDelete.cefs],
      };
      const newState = {
        ...state,
        dataToDelete,
        entityEditorState: ENTITY_EDITOR_STATE_DIRTY,
        entities: state.entities.map((entity) => {
          let { name } = entity;
          if (payload.entityKey === entity.key) {
            return {
              ...entity,
              sections: entity.sections.map((section) => {
                if (payload.sectionKey === section.key) {
                  return {
                    ...section,
                    facets: section.facets.map((facet) => {
                      if (payload.facetKey === facet.key) {
                        changed = true;
                        const clearedFacet = clearFacet(facet);
                        if (clearedFacet.type.name === 'Name' && clearedFacet.type.required) {
                          if (name !== clearedFacet.label) {
                            name = clearedFacet.label;
                          }
                        }
                        clearedFacet.componentLogs = log;
                        return clearedFacet;
                      }
                      return facet;
                    }),
                  };
                }
                return section;
              }),
              name,
              componentLogs: log,
            };
          }
          return entity;
        }),
      };
      if (changed) return newState;
      return state;
    }
    case DICTIONARY_ITEM_ADD: {
      let changed = false;
      let newState = {};
      const { dictionaryKey, item } = payload;
      if (Object.prototype.hasOwnProperty.call(state.dictionaries, dictionaryKey)) {
        const { id } = state.dictionaries[dictionaryKey];
        newState = {
          ...state,
          dictionariesItemNew: {
            ...state.dictionariesItemNew,
          },
        };
        if (state.dictionariesItemNew[dictionaryKey]) {
          const dictionary = { ...state.dictionariesItemNew[dictionaryKey] };
          if (!dictionary.items.includes(item)) {
            changed = true;
            dictionary.items.push(item);
            newState.dictionariesItemNew[dictionaryKey] = dictionary;
          }
        } else {
          changed = true;
          newState.dictionariesItemNew[dictionaryKey] = {
            id,
            key: dictionaryKey,
            items: [item],
          };
        }
      }
      if (changed) return newState;
      return state;
    }
    case FACET_ADD: {
      const log = generateLog();
      const { entityKey, sectionKey, facetKey } = payload;
      const changeSet = [];
      let changed = false;
      const newState = {
        ...state,
        entityEditorState: ENTITY_EDITOR_STATE_DIRTY,
        entities: state.entities.map((entity) => {
          if (entity.key === entityKey) {
            const facetInspector = {
              ...entity.facetInspector,
            };
            return {
              ...entity,
              entityEditorState: ENTITY_EDITOR_STATE_DIRTY,
              facetInspector,
              sections: entity.sections.map((section) => {
                if (section.key === sectionKey) {
                  const facets = [];
                  section.facets.forEach((facet, ind) => {
                    // eslint-disable-next-line no-param-reassign
                    facet.order = changed ? ind + 1 : ind;
                    facets.push(facet);
                    if (!changed && facet.key === facetKey) {
                      changed = true;
                      const newFacet = createFacet({
                        meta: {
                          assignedToId: authSelector.getUser(store).id,
                          stage: IN_PROGRESS,
                        },
                      }, facet.type);
                      if (!Object.prototype.hasOwnProperty.call(facetInspector, facet.type.id)) {
                        facetInspector[facet.type.id] = {
                          count: 0,
                        };
                      }
                      facetInspector[facet.type.id].count += 1;
                      newFacet.order = ind + 1;
                      changeSet.push({ type: FACET_ADD, payload: facet.key });
                      facets.push(newFacet);
                    }
                  });
                  return {
                    ...section,
                    componentLogs: log,
                    facets,
                  };
                }
                return section;
              }),
              componentLogs: log,
            };
          }
          return entity;
        }),
        changeSet: [...state.changeSet, ...changeSet],
      };
      if (changed) {
        return newState;
      }
      return state;
    }
    case FACET_ADD_TO_SECTION: {
      let changed = false;
      const { entityKey, sectionKey, facetType } = payload;
      const log = generateLog();
      const changeSet = [];

      const newState = {
        ...state,
        entityEditorState: ENTITY_EDITOR_STATE_DIRTY,
        entities: state.entities.map((entity) => {
          if (entity.key === entityKey) {
            const facetInspector = {
              ...entity.facetInspector,
            };
            return {
              ...entity,
              facetInspector,
              componentLogs: log,
              sections: entity.sections.map((section) => {
                if (section.key === sectionKey) {
                  const newFacet = createFacet({
                    meta: {
                      assignedToId: authSelector.getUser(store).id,
                      stage: IN_PROGRESS,
                    },
                  }, facetType);
                  changed = true;
                  if (!Object.prototype.hasOwnProperty.call(facetInspector, facetType.id)) {
                    facetInspector[facetType.id] = {
                      count: 0,
                    };
                  }
                  facetInspector[facetType.id].count += 1;
                  newFacet.order = section.facets.length;

                  changeSet.push({ type: FACET_ADD, payload: newFacet.key });
                  return {
                    ...section,
                    componentLogs: log,
                    facets: [...section.facets, newFacet],
                  };
                }
                return section;
              }),
            };
          }
          return entity;
        }),
        changeSet: [state.changeSet, ...changeSet],
      };
      if (changed) {
        return newState;
      }
      return state;
    }
    case FACET_LOADING_DISABLE: {
      const id = action.payload;
      const changeSet = state.changeSet.filter((change) => change.type !== FACET_META_CHANGE && change.payload !== id);
      if (!changeSet.length) document.leavingpage = false;
      return {
        ...state,
        changeSet,
        entityEditorState: changeSet.length ? ENTITY_EDITOR_STATE_DIRTY : ENTITY_EDITOR_STATE_CLEAN,
        facetLoadingStatusMap: {
          ...state.facetLoadingStatusMap,
          [id]: false,
        },
      };
    }
    case FACET_LOADING_ENABLE: {
      const id = action.payload;
      return {
        ...state,
        entityEditorState: ENTITY_EDITOR_STATE_SAVING,
        facetLoadingStatusMap: {
          ...state.facetLoadingStatusMap,
          [id]: true,
        },
      };
    }
    case FACET_ORDER_CHANGE: {
      const log = generateLog();

      const {
        entityKey, sectionKey, prevOrder, newOrder,
      } = payload;

      let changed = false;
      const changeSet = [];
      const [iFrom, iTo] = newOrder > prevOrder ? [prevOrder, newOrder] : [newOrder, prevOrder];

      const newState = {
        ...state,
        entityEditorState: ENTITY_EDITOR_STATE_DIRTY,
        entities: state.entities.map((entity) => {
          if (entity.key === entityKey) {
            return {
              ...entity,
              sections: entity.sections.map((section) => {
                if (section.key === sectionKey) {
                  const [removed] = section.facets.splice(prevOrder, 1);
                  section.facets.splice(newOrder, 0, removed);

                  return {
                    ...section,
                    facets: section.facets.map((facet, i) => {
                      if (i >= iFrom && i <= iTo) {
                        changed = true;
                        changeSet.push({ type: FACET_ORDER_CHANGE, payload: facet.id });

                        return {
                          ...facet,
                          order: i,
                          componentLogs: log,
                        };
                      }

                      return facet;
                    }),
                  };
                }

                return section;
              }),
              componentLogs: log,
            };
          }

          return entity;
        }),
        changeSet: [...state.changeSet, ...changeSet],
      };

      if (changed) {
        return newState;
      }

      return state;
    }
    case FACET_META_CHANGE: {
      const log = generateLog();
      const {
        entityKey, sectionKey, facetKey, assignedToId, stage, values,
      } = payload;
      let changed = false;
      const changeSet = [];
      const newState = {
        ...state,
        entityEditorState: ENTITY_EDITOR_STATE_DIRTY,
        entities: state.entities.map((entity) => {
          if (entity.key === entityKey) {
            return {
              ...entity,
              sections: entity.sections.map((section) => {
                if (section.key === sectionKey) {
                  return {
                    ...section,
                    facets: section.facets.map((facet) => {
                      if (facet.key === facetKey) {
                        changed = true;
                        changeSet.push({ type: FACET_META_CHANGE, payload: facet.id });
                        if (!facet.filledRequired) {
                          message.warning(`Facet ${facet.type.name} has empty required fields.`, 5);
                        }
                        return {
                          ...facet,
                          meta: values || {
                            assignedToId: assignedToId === undefined ? facet.meta.assignedToId : assignedToId,
                            stage: stage === undefined ? facet.meta.stage : stage,
                          },
                          componentLogs: log,
                        };
                      }
                      return facet;
                    }),
                  };
                }
                return section;
              }),
              componentLogs: log,
            };
          }
          return entity;
        }),
        changeSet: [...state.changeSet, ...changeSet],
      };
      if (changed) return newState;
      return state;
    }
    case FACET_REMOVE: {
      const log = generateLog();
      const {
        entityKey, sectionKey, facetKey,
      } = payload;
      let changed = false;
      const deletedFacets = [...state.dataToDelete.facets];
      const changeSet = [];
      const newState = {
        ...state,
        entityEditorState: ENTITY_EDITOR_STATE_DIRTY,
        entities: state.entities.map((entity) => {
          if (entity.key === entityKey) {
            const facetInspector = {
              ...entity.facetInspector,
            };
            return {
              ...entity,
              facetInspector,
              sections: entity.sections.map((section) => {
                if (section.key === sectionKey) {
                  return {
                    ...section,
                    facets: section.facets.filter((facet) => {
                      if (facet.key === facetKey) {
                        if (facet.id) {
                          deletedFacets.push(facet.id);
                          changeSet.push({ type: FACET_REMOVE, payload: facet.id });
                        }
                        if (Object.prototype.hasOwnProperty.call(facetInspector, facet.type.id)) {
                          facetInspector[facet.type.id].count -= 1;
                        }
                        changed = true;
                        return false;
                      }
                      return true;
                    }),
                  };
                }
                return section;
              }),
              componentLogs: log,
            };
          }
          return entity;
        }),
        dataToDelete: {
          ...state.dataToDelete,
          facets: deletedFacets,
        },
        changeSet: [...state.changeSet, changeSet],
      };
      if (changed) return newState;
      return state;
    }
    case FIELD_ADD: {
      const log = generateLog();
      const {
        entityKey, sectionKey, facetKey, fieldSet, wrapperKey,
      } = payload;
      let changed = false;
      const changeSet = [];

      const entities = state.entities.map((entity) => {
        if (entity.key === entityKey) {
          return {
            ...entity,
            sections: entity.sections.map((section) => {
              if (section.key === sectionKey) {
                return {
                  ...section,
                  componentLogs: log,
                  facets: section.facets.map((facet) => {
                    if (facet.key === facetKey) {
                      const wrappers = facet.wrappers.map((wrapper) => {
                        if (wrapper.key === wrapperKey) {
                          changed = true;
                          changeSet.push({ type: FIELD_ADD });
                          const fs = createFieldSet({
                            type: fieldSet,
                            rules: fieldSet.rules,
                            innerOrder: wrapper.fieldSets.length,
                          });
                          initFieldSet(fs);
                          const fieldSets = [...wrapper.fieldSets, fs];
                          return {
                            ...wrapper,
                            fieldSets,
                          };
                        }
                        return wrapper;
                      });
                      const newFacet = {
                        ...facet,
                        wrappers,
                        componentLogs: log,
                        ...isFacetFilled(wrappers),
                      };
                      newFacet.status = getFillStatus(newFacet);
                      return newFacet;
                    }
                    return facet;
                  }),
                };
              }
              return section;
            }),
            componentLogs: log,
          };
        }
        return entity;
      });

      if (changed) {
        return {
          ...state,
          entities,
          entityEditorState: ENTITY_EDITOR_STATE_DIRTY,
          changeSet: [...state.changeSet, ...changeSet],
        };
      }
      return state;
    }
    case FIELD_LOADING_DISABLE: {
      const id = action.payload;
      const changeSet = state.changeSet.filter((change) => change.type !== CHANGE_FIELDS && change.payload !== id);
      if (!changeSet.length) document.leavingpage = false;
      return {
        ...state,
        changeSet,
        entityEditorState: changeSet.length ? ENTITY_EDITOR_STATE_DIRTY : ENTITY_EDITOR_STATE_CLEAN,
        fieldLoadingStatusMap: {
          ...state.fieldLoadingStatusMap,
          [id]: false,
        },
      };
    }
    case FIELD_LOADING_ENABLE: {
      const id = action.payload;
      return {
        ...state,
        entityEditorState: ENTITY_EDITOR_STATE_SAVING,
        fieldLoadingStatusMap: {
          ...state.fieldLoadingStatusMap,
          [id]: true,
        },
      };
    }
    case FIELD_REMOVE: {
      const log = generateLog();
      const {
        entityKey, sectionKey, facetKey, fieldSet, wrapperKey,
      } = payload;
      let changed = false;
      const dataToDelete = {
        ...state.dataToDelete,
        fields: [...state.dataToDelete.fields],
      };
      const changeSet = [];
      const newState = {
        ...state,
        entityEditorState: ENTITY_EDITOR_STATE_DIRTY,
        dataToDelete,
        entities: state.entities.map((entity) => {
          if (entity.key === entityKey) {
            return {
              ...entity,
              sections: entity.sections.map((section) => {
                if (section.key === sectionKey) {
                  return {
                    ...section,
                    componentLogs: log,
                    facets: section.facets.map((facet) => {
                      if (facet.key === facetKey) {
                        const wrappers = facet.wrappers.map((wrapper) => {
                          if (wrapper.key === wrapperKey) {
                            changed = true;
                            const idsToRemove = Object
                              .values(fieldSet.fields)
                              .map((field) => field.id)
                              .filter((id) => id);
                            dataToDelete.fields.push(...idsToRemove);
                            const fieldSets = wrapper.fieldSets.filter((set) => set.key !== fieldSet.key);
                            return {
                              ...wrapper,
                              fieldSets,
                            };
                          }
                          return wrapper;
                        });
                        const newFacet = {
                          ...facet,
                          componentLogs: log,
                          wrappers,
                          ...isFacetFilled(wrappers),
                        };
                        newFacet.status = getFillStatus(newFacet);
                        return newFacet;
                      }
                      return facet;
                    }),
                  };
                }
                return section;
              }),
              componentLogs: log,
            };
          }
          return entity;
        }),
        changeSet: [...state.changeSet, changeSet],
      };
      if (changed) {
        return newState;
      }
      return state;
    }
    case LINK_ADD: {
      const log = generateLog();
      const {
        entityKey, sectionKey, facetKey, linkType, wrapperKey,
      } = payload;
      const changeSet = [];
      return {
        ...state,
        entityEditorState: ENTITY_EDITOR_STATE_DIRTY,
        entities: state.entities.map((entity) => {
          if (entity.key === entityKey) {
            return {
              ...entity,
              sections: entity.sections.map((section) => {
                if (section.key === sectionKey) {
                  return {
                    ...section,
                    facets: section.facets.map((facet) => {
                      if (facet.key === facetKey) {
                        const wrappers = facet.wrappers.map((wrapper) => {
                          if (wrapper.key === wrapperKey) {
                            const linkSet = {
                              description: '',
                              direction: (
                                get(linkType, 'rules.Direction.fixedValue')
                                  || get(linkType, 'rules.Direction.type')
                                  || ''
                              ).toUpperCase(),
                              facetFieldTypeId: linkType.id,
                              facetFieldType: linkType,
                              id: undefined,
                              key: uniqueId('_linkSet'),
                              section: facet.type.section,
                              strength: (
                                get(linkType, 'rules.Significance.fixedValue')
                                  || get(linkType, 'rules.Significance.defaultValue', '')
                                  || null
                              ),
                              source: null,
                              sourceEntityType: null,
                              sourceId: null,
                              sourceType: null,
                              target: null,
                              targetEntityType: null,
                              targetId: null,
                              targetType: null,
                              type: null,
                              rules: linkType.rules,
                              createdAt: Date.now(),
                              componentLogs: {
                                updated: Date.now(),
                                changed: false,
                              },
                            };
                            return {
                              ...wrapper,
                              fieldSets: [...wrapper.fieldSets, createLinkSet(linkSet, log)],
                            };
                          }
                          return wrapper;
                        });
                        const newFacet = {
                          ...facet,
                          componentLogs: log,
                          wrappers,
                          ...isFacetFilled(wrappers),
                        };
                        newFacet.status = getFillStatus(newFacet);
                        return newFacet;
                      }
                      return facet;
                    }),
                  };
                }
                return section;
              }),
              componentLogs: log,
            };
          }
          return entity;
        }),
        changeSet: [...state.changeSet, changeSet],
      };
    }
    case LINK_LOADING_DISABLE: {
      const id = action.payload;
      const changeSet = state.changeSet.filter((change) => change.type !== CHANGE_LINK && change.payload !== id);
      if (!changeSet.length) document.leavingpage = false;
      return {
        ...state,
        changeSet,
        entityEditorState: changeSet.length ? ENTITY_EDITOR_STATE_DIRTY : ENTITY_EDITOR_STATE_CLEAN,
        linkLoadingStatusMap: {
          ...state.linkLoadingStatusMap,
          [id]: false,
        },
      };
    }
    case LINK_LOADING_ENABLE: {
      const id = action.payload;
      return {
        ...state,
        entityEditorState: ENTITY_EDITOR_STATE_SAVING,
        linkLoadingStatusMap: {
          ...state.linkLoadingStatusMap,
          [id]: true,
        },
      };
    }
    case SIDEBAR_SECTION_OPEN_SET: {
      const log = generateLog();
      return {
        ...state,
        entityEditorState: ENTITY_EDITOR_STATE_DIRTY,
        entities: state.entities.map((entity) => {
          if (entity.key === payload.entityKey) {
            return {
              ...entity,
              activeKeys: payload.activeKeys,
              componentLogs: log,
            };
          }
          return entity;
        }),
      };
    }
    case REG_DICT_ITEM: {
      let fields = {};
      if (state.newDictItems[payload.key]) {
        fields = {
          ...state.newDictItems[payload.key],
        };
      }
      fields = {
        ...fields,
        [payload.fieldId]: payload.value,
      };
      return {
        ...state,
        newDictItems: {
          ...state.newDictItems,
          [payload.key]: {
            ...fields,
          },
        },
      };
    }
    case REMOVE_LOAD_PROCESS: {
      const { errors, processId } = payload;
      const processes = state.loadProcesses.filter((e) => e !== processId);
      return {
        ...state,
        loadProcesses: processes,
        isLoading: processes.length > 0,
        entityEditorState: errors ? ENTITY_EDITOR_STATE_DIRTY : ENTITY_EDITOR_STATE_CLEAN,
      };
    }
    case REMOVE_LINK: {
      const log = generateLog();
      const {
        entityKey, facetKey, sectionKey, key, wrapperKey,
      } = payload;
      let changed = false;
      const dataToDelete = {
        ...state.dataToDelete,
      };
      const changeSet = [];
      const newState = {
        ...state,
        entityEditorState: ENTITY_EDITOR_STATE_DIRTY,
        entities: state.entities.map((entity) => {
          if (entity.key === entityKey) {
            return {
              ...entity,
              componentLogs: log,
              sections: entity.sections.map((section) => {
                if (section.key === sectionKey) {
                  return {
                    ...section,
                    facets: section.facets.map((facet) => {
                      if (facet.key === facetKey) {
                        const wrappers = facet.wrappers.map((wrapper) => {
                          if (wrapper.key === wrapperKey) {
                            return {
                              ...wrapper,
                              fieldSets: wrapper.fieldSets.filter((link) => {
                                if (link.key === key) {
                                  changed = true;
                                  if (link.id) {
                                    dataToDelete.links = [...dataToDelete.links, link.id];
                                  }
                                  return false;
                                }
                                return true;
                              }),
                            };
                          }
                          return wrapper;
                        });
                        const newFacet = {
                          ...facet,
                          componentLogs: log,
                          wrappers,
                          ...isFacetFilled(wrappers),
                        };
                        newFacet.status = getFillStatus(newFacet);
                        return newFacet;
                      }
                      return facet;
                    }),
                  };
                }
                return section;
              }),
            };
          }
          return entity;
        }),
        dataToDelete,
        changeSet: [...state.changeSet, ...changeSet],
      };
      newState.dataToDelete = dataToDelete;
      if (changed) return newState;
      return state;
    }
    case SET_ENTITY: {
      document.leavingpage = false;
      const facets = payload.entity.sections.flatMap((section) => section.facets);
      return {
        ...state,
        entityEditorState: payload.entity.id ? ENTITY_EDITOR_STATE_CLEAN : ENTITY_EDITOR_STATE_DIRTY,
        entities: [payload.entity],
        dataToDelete: {
          facets: [],
          fields: [],
          links: [],
          cefs: [],
        },
        dictionariesItemNew: {},
        dictionaries: { ...state.dictionaries, ...payload.dictionaries },
        activeKeys: {
          ...payload.activeKeys,
        },
        assignmentMode: false,
        viewMode: payload.viewMode,
        facets: facets.reduce(toMap, {}),
        changeSet: payload.entity.id ? [] : [{ type: 'CREATE_ENTITY' }],
        linkLoadingStatusMap: {},
        facetLoadingStatusMap: {},
        fieldLoadingStatusMap: {},
      };
    }
    case SET_ENTITY_ASSIGNMENTS: {
      document.leavingpage = false;
      return {
        ...state,
        entityEditorState: ENTITY_EDITOR_STATE_CLEAN,
        assignmentMode: true,
        dataToDelete: {
          facets: [],
          fields: [],
          links: [],
          cefs: [],
        },
        dictionariesItemNew: {},
        dictionaries: payload.dictionaries,
        entities: payload.entities.map((e) => {
          if (e.notSaved) {
            return state.entities.find((sf) => sf.id === e.id);
          }
          return e;
        }),
        total: payload.total,
        page: payload.page ? payload.page : state.page,
      };
    }
    case SET_STATUS: {
      const [entity] = state.entities;
      return {
        ...state,
        changeSet: [...state.changeSet, { type: SET_STATUS }],
        entityEditorState: ENTITY_EDITOR_STATE_DIRTY,
        entities: [{
          ...entity,
          meta: {
            ...entity.meta,
            stage: action.payload,
          },
        }],
      };
    }
    case CROSS_FACET_ADD: {
      const log = generateLog();
      return {
        ...state,
        entities: state.entities.map((entity) => {
          if (entity.key !== action.payload.entityKey) {
            return entity;
          }
          return {
            ...entity,
            sections: entity.sections.map((section) => {
              if (section.key !== action.payload.sectionKey) {
                return section;
              }

              return {
                ...section,
                facets: section.facets.map((facet) => {
                  if (facet.key !== action.payload.facetKey) {
                    return facet;
                  }
                  return {
                    ...facet,
                    crossEntityFacets: [
                      ...facet.crossEntityFacets,
                      {
                        key: uniqueId('cef'),
                        value: 0,
                        date: moment().format('YYYY-MM-DD[T]HH:mm:ss'),
                        crossEntityFacetTypeId: get(facet, 'crossEntityFacetType.id'),
                        crossEntityFacetType: get(facet, 'crossEntityFacetType'),
                      },
                    ],
                    componentLogs: log,
                  };
                }),
                componentLogs: log,
              };
            }),
            componentLogs: log,
          };
        }),
      };
    }
    case CROSS_FACET_REMOVE: {
      const log = generateLog();
      return {
        ...state,
        dataToDelete: {
          ...state.dataToDelete,
          cefs: action.payload.setId
            ? [...state.dataToDelete.cefs, action.payload.setId]
            : [...state.dataToDelete.cefs],
        },
        entities: state.entities.map((entity) => {
          if (entity.key !== action.payload.entityKey) {
            return entity;
          }
          return {
            ...entity,
            sections: entity.sections.map((section) => {
              if (section.key !== action.payload.sectionKey) {
                return section;
              }

              return {
                ...section,
                facets: section.facets.map((facet) => {
                  if (facet.key !== action.payload.facetKey) {
                    return facet;
                  }
                  return {
                    ...facet,
                    crossEntityFacets: facet.crossEntityFacets.filter(
                      (crossEntityFacet) => crossEntityFacet.key !== action.payload.setKey,
                    ),
                    componentLogs: log,
                  };
                }),
                componentLogs: log,
              };
            }),
            componentLogs: log,
          };
        }),
      };
    }
    case CROSS_FACET_CHANGE: {
      const log = generateLog();
      return {
        ...state,
        entities: state.entities.map((entity) => {
          if (entity.key !== action.payload.entityKey) {
            return entity;
          }
          return {
            ...entity,
            sections: entity.sections.map((section) => {
              if (section.key !== action.payload.sectionKey) {
                return section;
              }

              return {
                ...section,
                facets: section.facets.map((facet) => {
                  if (facet.key !== action.payload.facetKey) {
                    return facet;
                  }
                  const newFacet = {
                    ...facet,
                    crossEntityFacetType: payload.crossFacetKey === 'all'
                      ? payload.values.crossEntityFacetType
                      : facet.crossEntityFacetType,
                    crossEntityFacets: facet.crossEntityFacets.map((cef) => {
                      if (payload.crossFacetKey === 'all' || cef.key === payload.crossFacetKey) {
                        return {
                          ...cef,
                          ...payload.values,
                        };
                      }
                      return cef;
                    }),
                    componentLogs: log,
                  };
                  const facetFilled = isFacetFilled(facet.wrappers);
                  const dataFilled = isDataFilled(newFacet);
                  newFacet.filledAll = dataFilled.filledAll && facetFilled.filledAll;
                  newFacet.filledRequired = dataFilled.filledRequired && facetFilled.filledRequired;
                  newFacet.status = getFillStatus(newFacet);
                  return newFacet;
                }),
                componentLogs: log,
              };
            }),
            componentLogs: log,
          };
        }),
      };
    }
    case ACTION_TOGGLE_HIDDEN: {
      const log = generateLog();
      return {
        ...state,
        entityEditorState: state.changeSet.length || state.entityEditorState !== ENTITY_EDITOR_STATE_DIRTY
          ? ENTITY_EDITOR_STATE_DIRTY
          : ENTITY_EDITOR_STATE_CLEAN,
        entities: state.entities.map((entity) => {
          if (entity.key !== action.payload.entityKey) {
            return entity;
          }
          return {
            ...entity,
            [hiddenProperty]: action.payload.value,
            componentLogs: log,
          };
        }),
      };
    }
    case UPDATE_FACET_LOG_COUNT: {
      const log = generateLog();
      const {
        facetId, total,
      } = payload;
      const options = {
        entityKey: null,
        sectionKey: null,
        facetKey: null,
      };
      state.entities.find((e) => e.sections.find((s) => {
        const facet = s.facets.find((f) => f.id === facetId);
        if (facet) {
          options.facetKey = facet.key;
          options.sectionKey = s.key;
          options.entityKey = e.key;
        }
        return facet;
      }));
      const newState = {
        ...state,
        entities: state.entities.map((entity) => {
          if (entity.key === options.entityKey) {
            return {
              ...entity,
              sections: entity.sections.map((section) => {
                if (section.key === options.sectionKey) {
                  return {
                    ...section,
                    facets: section.facets.map((facet) => {
                      if (facet.key === options.facetKey) {
                        return {
                          ...facet,
                          logsCount: total,
                          componentLogs: log,
                        };
                      }
                      return facet;
                    }),
                  };
                }
                return section;
              }),
              componentLogs: log,
            };
          }
          return entity;
        }),
      };

      return newState;
    }
    default:
      return state;
  }
};

export default reducer;
