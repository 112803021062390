import React from 'react';
import { Row } from 'antd';

import Info from './common/Info';
import Description from './common/Description';
import PropertyType from './PropertyType';
import withRemoveButton from './HOC/withButtonRemove';
import ElementState from '../ElementState';
import useDebounceSave from './useDebounceSave';

function Label(props) {
  const {
    fieldSet,
    actions,
    options,
    readonly,
  } = props;
  const {
    rules: {
      Type,
      Label: label,
      Info: info,
      Chars: chars,
      Description: description,
    },
    type,
    fields: {
      value,
      selectedValue,
    },
  } = fieldSet;

  const debounceSave = useDebounceSave({ saveField: actions.saveField, fieldSet });

  return (
    <div className="facet-type-field">
      <Row type="flex" justify="space-between">
        <div className="label-text" style={type.required ? { fontWeight: 500 } : {}}>
          {label}
          <ElementState saved={selectedValue.id} />
        </div>
        <Info info={info} />
      </Row>

      <PropertyType
        rules={{ type: Type, chars }}
        onChange={(fields) => {
          debounceSave(
            fields,
            () => actions.changeFieldSet(fields),
          );
        }}
        fieldSelectedId={selectedValue}
        fieldValue={value}
        readonly={readonly}
      />

      <Description
        value={description}
        entityKey={options.entityKey}
      />
    </div>
  );
}

export default withRemoveButton(Label);
