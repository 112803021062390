import React, { useEffect, useState } from 'react';
import { Modal, Input, Button } from 'antd';
import uniqueId from 'lodash/uniqueId';
import styles from './CreateFromRaw.module.css';
import { prepareRawDictionaryData } from '../../utils';

const CreateFromRaw = ({ onConfirm, onCancel, visible }) => {
  const [rows, setRows] = useState([{
    key: uniqueId('category_'),
    category: '',
    value: '',
  }]);

  const [delimiter, setDelimiter] = useState(',');

  useEffect(() => {
    if (!visible) {
      setRows([{
        key: uniqueId('category_'),
        category: '',
        value: '',
      }]);
      setDelimiter(',');
    }
  }, [visible]);

  const handleImport = () => {
    const data = prepareRawDictionaryData({ rows, delimiter });
    onConfirm(data);
  };

  const handleChange = (prop) => (event) => {
    const { value, name } = event.target;
    setRows((current) => current.map((row) => {
      if (row.key !== name) return row;
      return { ...row, [prop]: value };
    }));
  };

  const handleRemoveRow = (key) => {
    setRows((current) => current.filter((r) => r.key !== key));
  };

  const handleAddRow = () => {
    const newRow = {
      key: uniqueId('category_'),
      category: '',
      value: '',
    };
    setRows((current) => ([...current, newRow]));
  };

  return (
    <Modal
      title="Import Raw Data"
      visible={visible}
      onOk={handleImport}
      onCancel={onCancel}
    >
      <div className={styles.main}>
        <span className="label">Delimiter</span>
        <Input
          value={delimiter}
          onChange={(e) => setDelimiter(e.target.value)}
        />
        {rows && rows.map((r) => (
          <div key={r.key} className={styles.row}>
            <div className={styles.category}>
              <span className="label">Category</span>
              <Input
                name={r.key}
                value={r.category}
                onChange={handleChange('category')}
              />
            </div>
            <div className={styles.value}>
              <span className="label">Raw Values</span>
              <Input name={r.key} value={r.value} onChange={handleChange('value')} />
            </div>
            <div className={styles.actions}>
              <span className="label" />
              <Button disabled={rows.length < 2} icon="delete" onClick={() => handleRemoveRow(r.key)} />
            </div>
          </div>
        ))}
        <Button
          className={styles.add}
          icon="plus"
          onClick={handleAddRow}
        >
          Add Category
        </Button>
      </div>
    </Modal>
  );
};

export default CreateFromRaw;
