/* eslint-disable linebreak-style */
import React from 'react';
import { Spin, message } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Form from '../../components/Auth/AuthForm';
import { authorization, setLoading } from './actions';

class Auth extends React.PureComponent {
  componentDidMount() {
    setTimeout(() => {
      this.props.actions.setLoading(false);
    }, 1200);
  }

  componentDidUpdate(last) {
    if (this.props.error.date !== last.error.date) {
      message.error(this.props.error.message, 3);
      this.props.actions.setLoading(false);
    }
    if (this.props.user) {
      this.props.history.push('/analyst/entities');
    }
  }

  resetPassword = () => {
    this.props.history.push('/reset-request');
  };

  render() {
    const {
      actions: {
        authorization: authFunc,
      },
      loading,
      error,
    } = this.props;
    return (
      <div
        className="Auth"
        style={{
          height: '100%', backgroundColor: '#f8f8f8',
        }}
      >
        {loading
          ? (
            <Spin
              size="large"
              style={{
                position: 'absolute',
                top: '45%',
                left: '50%',
                margin: '0 auto',
              }}
            />
          )
          : <Form authorization={authFunc} error={error} resetPassword={this.resetPassword} />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    authorization,
    setLoading,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
