import React, { useEffect, useState } from 'react';
import {
  Table,
  Button,
  Pagination,
  Spin,
  Modal,
  Row,
  Col,
  message,
} from 'antd';
import isEmpty from 'lodash/isEmpty';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import client from '../../common/utils/http';
import SearchColumn from '../../components/SearchColumn/SearchColumn';
import ModalEditUser from '../ModalEditUser';
import {
  getQueryParams, getUniqueDataTopics, PAGE_SIZE, processUsers,
} from './utils';
import styles from './Users.module.css';
import UserActions from './components/UserActions/UserActions';

const Topics = ({ data, topics = [] }) => {
  const dataTopics = getUniqueDataTopics({ data, topics }).join(', ');
  return <div title={dataTopics}>{dataTopics}</div>;
};

const Users = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [editUser, setEditUser] = useState(null);

  const [query, setQuery] = useState({
    filter: { email: '', name: '' },
    sort: { field: '', order: 'asc' },
    page: 1,
  });

  const queryClient = useQueryClient();

  const userDelete = useMutation((id) => client.delete(`/users/${id}`));

  const handleUserDelete = (id) => {
    userDelete.mutate(id, {
      onSuccess: () => {
        queryClient.invalidateQueries('users');
      },
      onError: () => {
        message.error('There was an error deleting the user');
      },
    });
  };

  const {
    isLoading: isLoadingUsers, isError: isErrorUsers, data: usersData, refetch,
  } = useQuery(
    ['users', query],
    () => {
      const params = getQueryParams(query);
      return client
        .get('/users', { params })
        .then(processUsers);
    },
    { keepPreviousData: true },
  );

  const { isLoading: isLoadingTopics, isError: isErrorTopics, data: topicsData } = useQuery(
    ['topics'],
    () => client
      .get('/countries?$limit=500&$sort[name]=1')
      .then(({ data }) => data.data),
  );

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  useEffect(() => {
    if (isErrorUsers) message.error('There was an error fetching the users');
  }, [isErrorUsers]);

  useEffect(() => {
    if (isErrorTopics) message.error('There was an error fetching the topics');
  }, [isErrorTopics]);

  const changePage = (newPage) => {
    setQuery((current) => ({
      ...current,
      page: newPage,
    }));
  };

  const onChangeTable = (pagination, filters, sorter) => {
    if (isEmpty(sorter)) return;
    setQuery((current) => ({
      ...current,
      sort: {
        field: sorter.field,
        order: sorter.order === 'ascend' ? 1 : -1,
      },
      page: 1,
    }));
  };

  const handleCloseEditUserModal = () => {
    refetch();
    setEditUser(null);
    setModalOpen(false);
  };

  const handleOpenEditUserModal = (user) => {
    setEditUser(user);
    setModalOpen(true);
  };

  const handleFilterUpdate = (field) => (value) => {
    setQuery((current) => ({
      ...current,
      filter: { ...current.filter, [field]: value },
      page: 1,
    }));
  };

  const columns = [
    {
      key: 'action',
      title: 'Actions',
      width: 75,
      render: (user) => (
        <UserActions
          user={user}
          onEditClick={() => handleOpenEditUserModal(user)}
          onDeleteClick={() => handleUserDelete(user.id)}
        />
      ),
    },
    {
      key: 'email',
      title: (
        <SearchColumn
          onClickSearch={handleFilterUpdate('email')}
          label="Email"
          style={{ width: 120 }}
        />
      ),
      dataIndex: 'email',
      render: (email) => <div title={email}>{email}</div>,
      sorter: true,
    },
    {
      key: 'name',
      title: (
        <SearchColumn
          onClickSearch={handleFilterUpdate('name')}
          label="Name"
          style={{ width: 120 }}
        />
      ),
      dataIndex: 'name',
      render: (name) => <div title={name}>{name}</div>,
      sorter: true,
    },
    {
      key: 'role',
      title: 'Role',
      dataIndex: 'role',
      render: (role) => <div className={styles.role}>{role}</div>,
      sorter: true,
    },
    {
      key: 'createdAt',
      title: 'Created',
      dataIndex: 'createdAt',
      render: (date) => <div title={date}>{date}</div>,
      sorter: true,
    },
    {
      key: 'inviter',
      title: (
        <SearchColumn
          onClickSearch={handleFilterUpdate('inviter')}
          label="Inviter"
          style={{ width: 100 }}
        />
      ),
      dataIndex: 'inviter',
      render: (data) => <div title={(data && data.name) || ''}>{(data && data.name) || ''}</div>,
      sorter: true,
    },
    {
      key: 'topics',
      title: 'Topics',
      dataIndex: 'topics',
      render: (data) => <Topics data={data} topics={topicsData} />,
      sorter: true,
    },
  ];

  return (
    <Spin spinning={isLoadingUsers || isLoadingTopics}>
      <Row className={styles.bottom} type="flex" justify="space-between">
        <Col>
          <Button
            type="primary"
            onClick={() => setModalOpen(true)}
          >
            Create User
          </Button>
        </Col>

        <Col>
          <Pagination
            onChange={changePage}
            current={query.page}
            total={usersData && usersData.total}
            pageSize={PAGE_SIZE}
          />
        </Col>
      </Row>

      <Table
        rowKey="id"
        size="small"
        dataSource={usersData && usersData.users}
        columns={columns}
        pagination={false}
        onChange={onChangeTable}
        bordered
      />

      <Row className={styles.top} type="flex" justify="end">
        <Pagination
          onChange={changePage}
          current={query.page}
          total={usersData && usersData.total}
          pageSize={PAGE_SIZE}
        />
      </Row>

      <Modal
        title={editUser ? 'Edit user' : 'Invite user'}
        width={600}
        visible={isModalOpen}
        onCancel={handleCloseEditUserModal}
        footer={null}
        closable={false}
        centered
        destroyOnClose
      >
        <ModalEditUser
          topics={topicsData}
          user={editUser || {}}
          onCancel={handleCloseEditUserModal}
        />
      </Modal>
    </Spin>
  );
};

export default Users;
