export const UNASSIGNED = 'unassigned';
export const IN_PROGRESS = 'in progress';
export const FAILED = 'failed';
export const REVIEW = 'review';
export const EDIT = 'edit';
export const ANALYST = 'analyst';
export const PUBLISHED = 'published';
export const EXPIRED = 'expired';
export const REJECTED = 'rejected';
export const NO_INFORMATION = 'no information';

export default [
  UNASSIGNED,
  ANALYST,
  IN_PROGRESS,
  REVIEW,
  EDIT,
  PUBLISHED,
  EXPIRED,
  REJECTED,
  NO_INFORMATION,
];
