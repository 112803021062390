import React from 'react';
import { ButtonSquare } from '@axis/xyz.admin.buttons.button-square';
import Modal from '../Modal/Modal';
import styles from './ConfirmModal.module.css';

const ConfirmModal = ({
  open,
  onOk,
  onCancel,
  okLabel,
  cancelLabel,
  children,
}) => (
  <Modal
    className={styles.modal}
    open={open}
    onOk={onOk}
    onCancel={onCancel}
  >
    <div className={styles.content}>
      {children}
    </div>
    <div className={styles.controls}>
      {cancelLabel && (
        <ButtonSquare
          color="secondary"
          label={cancelLabel}
          icon="close"
          onClick={onCancel}
        />
      )}
      {okLabel && (
        <ButtonSquare
          icon="check--circular"
          label={okLabel}
          onClick={onOk}
        />
      )}
    </div>
  </Modal>
);

export default ConfirmModal;
