import React from 'react';
import { Col, Pagination, Row } from 'antd';
import { PAGE_SIZE } from '../../utils';
import styles from './CrossEntityFacetsPagination.module.css';

const TablePagination = ({
  children,
  page,
  total,
  onPageChange,
}) => (
  <Row className={styles.row}>
    <Col span={12}>
      {children}
    </Col>
    <Col span={12} className={styles.container}>
      <Pagination
        onChange={onPageChange}
        current={page}
        total={total}
        pageSize={PAGE_SIZE}
      />
    </Col>
  </Row>
);

export default TablePagination;
