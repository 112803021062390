/* eslint-disable no-console */
import React, {
  useState, useEffect, useRef, useMemo, memo, useCallback,
} from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import set from 'lodash/set';
import debounce from 'lodash/debounce';
import startCase from 'lodash/startCase';
import styled from 'styled-components';
import ReactDataGrid from 'react-data-grid';
import {
  Row, Col, Spin, message, Button, Pagination, Modal, notification,
} from 'antd';
import ModalTemplates from './components/ModalCreateFromTemplate';

import {
  IN_PROGRESS, PUBLISHED, FAILED,
} from '../../common/Enums/Stages';
import { ENTITY_COLUMNS } from '../../common/Enums/Columns';
import { PAGE_SIZE, ROW_HEIGHT } from './utils';

import helpers from './helpers';
import columns from './components/columns';
import usersSelector from '../../selectors/users';
import featurePermissions from '../../selectors/featurePermissions';
import './styles.css';
import { simulateClick } from '../../common/utils/lib';

const {
  DraggableHeader: { DraggableContainer },
} = require('react-data-grid-addons');

const typesList = {
  actor: 'Create Actor',
  brief: 'Create Brief',
  context: 'Create Context',
  default: 'Create Entity',
  plot: 'Create Plot',
};

const stagesWithStartCase = [IN_PROGRESS, PUBLISHED, FAILED].map((e) => startCase(e));

const sortRows = (sortColumn, sortDirection, setState) => {
  let orderField = sortColumn;

  if (sortColumn === ENTITY_COLUMNS.ASSIGNEDTOID) {
    orderField = 'assignedToName';
  }

  if (sortColumn === ENTITY_COLUMNS.TOPIC) {
    orderField = 'topicName';
  }

  setState((prev) => ({
    ...prev,
    search: {
      ...prev.search,
      orderField,
      orderDirection: sortDirection,
    },
  }));
};

const setFilters = (filter, setState, getUserByName, topics) => {
  let value = get(filter, 'filterTerm.value') || get(filter, 'filterTerm');
  let { key } = filter.column;

  if (filter.column.key === ENTITY_COLUMNS.ASSIGNEDTOID) {
    value = get(getUserByName(get(filter, 'filterTerm.value')), 'id', '');
  }
  if (filter.column.key === ENTITY_COLUMNS.TOPIC) {
    const filterValue = get(filter, 'filterTerm.value');
    const topic = topics.find((t) => t.name === filterValue);
    value = topic ? topic.id : null;
    if (filterValue === 'None') {
      value = 'none';
    }
    key = 'topicId';
  }

  if (filter.column.key === ENTITY_COLUMNS.STAGE) {
    value = get(filter, 'filterTerm.value', '').toLowerCase();
  }

  if (filter.column.key === ENTITY_COLUMNS.NAME) {
    value = get(filter, 'filterTerm', '');
  }

  if (filter.column.key === ENTITY_COLUMNS.TYPE) {
    value = get(filter, 'filterTerm', '');
  }

  if (filter.column.key === ENTITY_COLUMNS.HIDDEN) {
    const selectedValue = get(filter, 'filterTerm', '');
    value = selectedValue === 'Visible' ? true : undefined;
  }

  setState((prev) => ({
    ...prev,
    page: 1,
    search: {
      ...prev.search,
      [key]: value,
    },
  }));
};

const changePage = (page, type, state, setState) => {
  setState((prev) => ({
    ...prev,
    page,
  }));
};

const removeEntity = (id, state, setState) => {
  helpers.removeEntity(id)
    .then((deletedId) => {
      const entities = state.entities.filter((entity) => entity.id !== deletedId);
      setState((prev) => ({ ...prev, entities }));
    })
    .catch((err) => {
      console.error(err);
      message.error('Cannot remove entity');
    });
};

const updateRow = (props, state, setState) => {
  const { rowIds, updated, cellKey } = props;
  const linksToUpdate = [];
  const linksToRemove = [];
  const metaToUpdate = [];
  const visibilityToUpdate = [];
  const rows = state.entities.map((row) => {
    if (rowIds.includes(row.id)) {
      const newRow = { ...row };
      if (cellKey === ENTITY_COLUMNS.TOPIC) {
        const remove = newRow.topics.filter((e) => !updated.topics.find((u) => u.id === e.id));
        const newTopics = updated.topics.filter((e) => !newRow.topics.find((u) => u.id === e.id));
        newRow.topics = updated.topics.map((e) => {
          const topic = row.topics.find((t) => t.id === e.id);
          return topic || e;
        });
        linksToUpdate.push({ entityId: row.id, topics: newTopics.map((e) => e.id) });
        linksToRemove.push(...remove.map((e) => e.linkId));
      }
      let isMetaUpdated = false;
      if (cellKey === ENTITY_COLUMNS.STAGE) {
        const stage = (updated.stage || '').toLowerCase() || null;
        const users = usersSelector.getUsersByStatus(state.users, stage); newRow.stage = stage;
        newRow.meta.stage = stage;
        if (!users.find((user) => user.id === row.assignedToId)) {
          newRow.assignedToId = null;
          newRow.meta.assignedToId = null;
        }
        isMetaUpdated = true;
      }
      if (cellKey === ENTITY_COLUMNS.ASSIGNEDTOID) {
        newRow.assignedToId = updated.assignedToId;
        newRow.meta.assignedToId = updated.assignedToId;
        const stage = (row.stage || '').toLowerCase() || null;
        const users = usersSelector.getUsersByStatus(state.users, stage);
        if (!users.find((user) => user.id === updated.assignedToId)) {
          newRow.assignedToId = row.assignedToId;
          newRow.meta.assignedToId = row.assignedToId;
        }
        isMetaUpdated = true;
      }
      if (cellKey === ENTITY_COLUMNS.HIDDEN) {
        newRow[ENTITY_COLUMNS.HIDDEN] = updated[ENTITY_COLUMNS.HIDDEN];
        visibilityToUpdate.push({ id: newRow.id, [ENTITY_COLUMNS.HIDDEN]: newRow[ENTITY_COLUMNS.HIDDEN] });
      }
      if (isMetaUpdated) {
        metaToUpdate.push(newRow.meta);
      }
      return newRow;
    }
    return row;
  });
  const promises = [];
  if (metaToUpdate.length > 0) {
    promises.push(helpers.updateMeta(metaToUpdate));
  }
  if (visibilityToUpdate.length > 0) {
    promises.push(helpers.updateEntities(visibilityToUpdate));
  }
  const linksToUpdateFiltered = linksToUpdate.filter((l) => l.topics.length > 0);
  if (linksToUpdateFiltered.length > 0) {
    promises.push(helpers.createLinksToTopic(linksToUpdateFiltered));
  }
  if (linksToRemove.length > 0) {
    linksToRemove.forEach((linkId) => linkId && promises.push(helpers.removeTopicLink(linkId)));
  }
  Promise
    .all(promises)
    .then((data) => {
      const errors = [];
      data.forEach((res) => {
        if (res.request.responseURL.includes(helpers.URL_TOPIC_LINKS)) {
          if (res.config.method === 'post') {
            res.data.data.forEach((link) => {
              const newRow = rows.find((e) => e.id === link.sourceId);
              if (newRow) {
                const newLink = newRow.topics.find((t) => t.id === link.targetId);
                set(newLink, 'linkId', link.id);
              }
            });
          }
          if (res.data.errors) {
            res.data.errors.forEach((err) => {
              const newRow = rows.find((e) => e.id === err.entityId);
              const oldRow = state.entities.find((e) => e.id === err.entityId);
              if (newRow && oldRow) {
                newRow.topics = oldRow.topics;
                errors.push({ name: newRow.name, message: err.error });
              }
            });
          }
        }
      });
      errors.map((e) => notification.error({
        message: `Can't update ${e.name}`,
        description: e.message,
        duration: 5,
      }));
      setState((prev) => ({ ...prev, entities: rows }));
    })
    .catch((err) => {
      console.error(err);
      const text = get(err, 'response.data.message', 'Can\'t update entities');
      message.error(text);
    });
};

const getValidFilterValues = (columnKey, getUsers, getTopics) => {
  if (columnKey === ENTITY_COLUMNS.STAGE) {
    return stagesWithStartCase;
  }
  if (columnKey === ENTITY_COLUMNS.ASSIGNEDTOID) {
    const users = getUsers();
    return users.map((user) => user.name);
  }
  if (columnKey === ENTITY_COLUMNS.TOPIC) {
    const topics = getTopics();
    const topicsMapped = topics.map((e) => e.name);
    topicsMapped.push('None');
    return topicsMapped;
  }
  if (columnKey === ENTITY_COLUMNS.HIDDEN) {
    return ['Visible', 'Hidden'];
  }
  return [];
};

const getTypeLabel = (type) => typesList[type] || typesList.default;

const onKeyDown = (event, row) => {
  switch (event.keyCode) {
    // press Enter - approve remove
    case 13: {
      const element = document.getElementById(`_popconfirm${row.id}`);
      if (element) {
        simulateClick(element.querySelector('.ant-btn-primary'));
      }
      break;
    }
    // press Escape - cancel remove
    case 27: {
      const element = document.getElementById(`_popconfirm${row.id}`);
      if (element) {
        simulateClick(element.querySelector('.ant-btn-sm'));
      }
      break;
    }
    // press D - init remove
    case 68: {
      const element = document.getElementById(`delete_${row.id}`);
      if (element) {
        simulateClick(element);
      }
      break;
    }
    default:
  }
};

const initialState = {
  limit: PAGE_SIZE,
  templates: [],
  topics: [],
  users: [],
  loading: true,
  isLoadingUsersAndTemplates: true,
  entities: [],
  page: 1,
  total: 0,
  search: {
    type: '',
    name: '',
    stage: '',
    assignedToId: '',
    orderField: '',
    orderDirection: '',
    untyped: '',
  },
};

const Entities = ({
  type, user, role, users, untyped,
}) => {
  const refRDG = useRef();
  const refRowSelected = useRef(null);
  const cols = useMemo(() => columns.map((column) => ({
    ...column,
    editable: featurePermissions.hasAccessToChangeEntityProperty(column.key, role),
    editor: featurePermissions.hasAccessToChangeEntityProperty(column.key, role) ? column.editor : undefined,
  })), [role]);
  const [columnsFormatted, setColumns] = useState(cols);

  useEffect(() => () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  const [state, setState] = useState(initialState);
  const setStateOld = useCallback((obj) => setState((prev) => ({ ...prev, ...obj })), [setState]);
  const [modalTemplates, setModalTemplates] = useState(false);
  const { current: debounceLoadEntities } = useRef(
    debounce(
      (page, search, limit) => {
        setState((prev) => ({ ...prev, loading: true }));

        helpers.loadEntities(page, type, search, limit)
          .then((data) => setState((prev) => ({
            ...prev,
            entities: data.data,
            total: data.total,
            page: data.page,
            loading: false,
          })))
          .catch((err) => {
            console.error(err);
            message.error('Cannot load Entities');
            setState((prev) => ({ ...prev, loading: false }));
          });
      },
      350,
    ),
  );

  useEffect(() => {
    helpers.loadEntityTemplates(type, untyped)
      .then(async (data) => {
        const topics = await helpers.loadTopics();
        setState((prev) => ({
          ...prev,
          templates: data,
          topics: orderBy(get(topics, 'data.data', []), 'name', 'asc'),
        }));

        if (refRDG.current) {
          refRDG.current.onToggleFilter();
        }
      })
      .catch((err) => {
        console.error(err);
        message.error('Something went wrong...');
      })
      .finally(() => setState((prev) => ({
        ...prev,
        isLoadingUsersAndTemplates: false,
        users,
        untyped,
      })));
  }, []);

  useEffect(() => {
    debounceLoadEntities(state.page, state.search, state.limit);
  }, [state.search, state.page, state.limit]);

  const onHeaderDrop = (source, target) => {
    const columnsCopy = columns.slice();
    const columnSourceIndex = columns.findIndex(
      (i) => i.key === source,
    );
    const columnTargetIndex = columns.findIndex(
      (i) => i.key === target,
    );

    columnsCopy.splice(
      columnTargetIndex,
      0,
      columnsCopy.splice(columnSourceIndex, 1)[0],
    );
    setColumns([]);
    setColumns(columnsCopy);
  };

  return (
    <Spin spinning={state.loading || state.isLoadingUsersAndTemplates}>
      <MarginBottomRowWrapper type="flex" justify="space-between">
        <Col>
          {!untyped ? (
            <Button
              onClick={() => setModalTemplates(true)}
              type="primary"
              disabled={state.loading || state.isLoadingUsersAndTemplates}
            >
              {getTypeLabel(type)}
            </Button>
          ) : null}
        </Col>

        <Col>
          <Pagination
            onChange={(page) => changePage(page, type, state, setState)}
            current={state.page}
            total={state.total}
            pageSize={state.limit}
            showSizeChanger
            pageSizeOptions={[25, 50, 100, 250, 500, 1000, state.total]}
            onShowSizeChange={(current, size) => setStateOld({ limit: size })}
          />
        </Col>
      </MarginBottomRowWrapper>

      <Row className="EntitiesTable__RDG">
        <DraggableContainer onHeaderDrop={onHeaderDrop}>
          <ReactDataGrid
            ref={refRDG}
            columns={columnsFormatted}
            rowGetter={(index) => ({
              ...state.entities[index],
              columnsActions: {
                getUserById: (userId) => usersSelector.getUserById(userId, state.users),
                removeEntity: (entityId) => removeEntity(entityId, state, setState),
                getUsers: () => state.users,
                getRole: () => role,
                getUser: () => user,
                getTopics: () => state.topics,
                untyped,
              },
            })}
            rowsCount={state.entities.length}
            minHeight={ROW_HEIGHT * (state.limit + 2)}
            enableCellSelect
            onGridRowsUpdated={(props) => updateRow(props, state, setState)}
            onGridSort={(cell, direction) => sortRows(cell, direction, setState)}
            onAddFilter={
            (filter) => setFilters(
              filter,
              setState,
              (userName) => usersSelector.getUserByName(userName, state.users),
              state.topics,
            )
          }
            getValidFilterValues={(columnKey) => getValidFilterValues(columnKey, () => state.users, () => state.topics)}
            onGridKeyDown={(event) => onKeyDown(event, refRowSelected.current)}
            onCellSelected={({ rowIdx }) => { refRowSelected.current = state.entities[rowIdx] || null; }}
          />
        </DraggableContainer>
      </Row>

      <MarginTopRowWrapper type="flex" justify="end">
        <Pagination
          onChange={(page) => changePage(page, type, state, setState)}
          current={state.page}
          total={state.total}
          pageSize={state.limit}
          showSizeChanger
          pageSizeOptions={[25, 50, 100, 250, 500, 1000, state.total]}
          onShowSizeChange={(current, size) => setStateOld({ limit: size })}
        />
      </MarginTopRowWrapper>

      <Modal
        title={getTypeLabel(type)}
        visible={modalTemplates}
        onCancel={() => setModalTemplates(false)}
        footer={null}
        width={650}
      >
        <ModalTemplates
          type={type}
          templates={state.templates}
          onCancel={() => setModalTemplates(false)}
          isLoading={state.loading || state.isLoadingUsersAndTemplates}
        />
      </Modal>
    </Spin>
  );
};

const mapStateToProps = (state) => {
  const user = usersSelector.getUser(state);
  const users = usersSelector.getUsers(state);
  return {
    user,
    users,
    role: usersSelector.getRole(user) || '',
  };
};

export default connect(mapStateToProps)(memo(Entities));

const MarginTopRowWrapper = styled(Row)`
  margin-top: 20px;
`;

const MarginBottomRowWrapper = styled(Row)`
  margin-bottom: 20px;
`;
