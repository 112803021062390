import React, {
  useState, useEffect, useRef, useMemo,
} from 'react';
import moment from 'moment';
import {
  Button, Input, message, Popconfirm, Collapse, Row,
} from 'antd';

import debounce from 'lodash/debounce';
import get from 'lodash/get';
import * as strings from '../../../../common/Strings';
import ElementState from '../ElementState';

import Description from './common/Description';
import Info from './common/Info';
import withRemoveButton from './HOC/withButtonRemove';
import {
  uploadFile,
} from './mediaHelpers';
import useDebounceSave from './useDebounceSave';
import uploadFileHandler from '../../../../common/utils/uploadFileHandler';

const { Panel } = Collapse;

const UPLOAD_DOC = 'Upload Doc';
const VIEW_ARCHIVE_URL = 'View Archive Url';
const VIEW_DOCUMENT = 'View Document';

function Provenance(props) {
  const {
    fieldType: {
      rules: {
        Field: fieldTitle,
      },
      name: fieldName,
    },
    fieldSet,
    actions,
    options,
    readonly,
  } = props;

  const {
    fields: {
      date,
      key,
      urlInput,
    },
    rules: {
      Description: description,
      Info: info,
      Label: label,
    },
  } = fieldSet;
  const [loading, setLoading] = useState(false);

  const { current: debounceInput } = useRef(
    debounce((fields) => actions.changeFieldSet(fields), 500),
  );

  const [fieldValue, setValue] = useState(get(fieldSet, 'fields.urlInput.value', ''));
  useEffect(() => {
    if (!urlInput.value) {
      setValue(urlInput.value);
    }
  }, [urlInput.value]);

  const debounceSave = useDebounceSave({ saveField: actions.saveField, fieldSet });

  const onChangeUrl = (value) => {
    const fields = {
      [urlInput.name]: value,
      [key.name]: { key: '', href: '' },
    };
    setValue(value);
    debounceSave({ ...fields, [key.name]: '' }, () => debounceInput(fields));
  };

  const onRemoveDoc = () => {
    const fields = {
      [key.name]: {
        key: '',
        href: '',
      },
      [urlInput.name]: '',
    };
    debounceSave({ ...fields, [key.name]: '' });
    debounceInput(fields);
  };

  const viewDoc = () => {
    const win = window.open(key.href, '_blank');
    win.focus();
  };

  const uploadDoc = () => {
    uploadFileHandler(({ formData }) => {
      if (!options.viewMode) {
        setLoading(true);
        uploadFile(formData)
          .then((data) => {
            const changes = {
              [key.name]: { key: data.key, href: data.href, value: data.key },
              [date.name]: moment().format('YYYY-MM-DD[T]12:00:00.000'),
            };
            debounceSave({ ...changes, [key.name]: data.key });
            debounceInput(changes);
          })
          .catch((err) => {
            console.error(err);
            message.error(strings.aErrorUploadFailed);
          })
          .finally(() => setLoading(false));
      }
    });
  };

  const onClick = useMemo(() => {
    if (key.href) return viewDoc;
    return uploadDoc;
  }, [key.href, urlInput.value, viewDoc, uploadDoc]);

  return (
    <div className="facet-type-field">
      <Collapse
        bordered={false}
        defaultActiveKey={!fieldSet.filled ? fieldSet.key : null}
      >
        <Panel
          key={fieldSet.key}
          header={(
            <div>
              {fieldTitle || fieldName}
              <ElementState saved={date.id} />
            </div>
          )}
        >
          <Row type="flex" justify="space-between">
            <div className="label-text">{label}</div>
            <Info info={info} />
          </Row>

          <div className="ant-row-flex">
            {urlInput.value ? null
              : (
                <Button
                  loading={loading}
                  style={{ width: 114, marginRight: 10 }}
                  onClick={onClick}
                  disabled={readonly}
                >
                  {key.href && urlInput.value && VIEW_ARCHIVE_URL}
                  {key.href && !urlInput.value && VIEW_DOCUMENT}
                  {!key.href && !urlInput.value && UPLOAD_DOC}
                </Button>
              )}

            <Input
              autoComplete="off"
              style={{ flex: 1 }}
              type="text"
              placeholder="Source URL"
              disabled={!!key.href || readonly}
              value={fieldValue}
              onChange={(e) => onChangeUrl(e.target.value)}
              data-testid="sourceUrlInput"
            />
            {!key.href
              ? (
                <span />
              ) : (
                <div className="ant-row-flex">
                  <Popconfirm
                    placement="topRight"
                    title="Are you sure?"
                    onConfirm={onRemoveDoc}
                    onCancel={() => {
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      className="mrg-left-10"
                      type="delete"
                      icon="close"
                    />
                  </Popconfirm>
                </div>
              )}
          </div>

          <Description
            value={description}
            entityKey={options.entityKey}
          />

        </Panel>
      </Collapse>
    </div>
  );
}

export default withRemoveButton(Provenance);
