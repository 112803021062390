import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, message, Modal } from 'antd';
import { SideMenu } from '@axis/xyz.admin.side-menu';
import { useLocation } from 'react-router-dom';
import { useMutation } from 'react-query';
import authSelector from '../../../selectors/users';
import { loggOut } from '../../../common/utils/auth';
import ModalEditUser from '../../ModalEditUser';
import menuItems from './menuItems.json';
import { getMenuItemsByRole } from './utils';
import css from './Menu.module.css';
import client from '../../../common/utils/http';

const Menu = ({
  role, user, collapse, toggleCollapse,
}) => {
  const location = useLocation();

  const [isModalVisible, setModalVisible] = useState(false);
  const [isRequestingNewsletter, setRequestingNewsletter] = useState(false);

  const newsletterRequest = useMutation(() => client.post('/send-newsletter'));

  useEffect(() => {
    if (!isRequestingNewsletter) return;
    newsletterRequest.mutate({}, {
      onSuccess: () => {
        message.success('The newsletter has been sent successfully! Check your inbox');
      },
      onError: () => {
        message.error('There was an error sending the newsletter');
      },
      onSettled: () => {
        setRequestingNewsletter(false);
      },
    });
  }, [isRequestingNewsletter]);

  return (
    <>
      <SideMenu
        isCollapse={collapse}
        toggleCollapse={toggleCollapse}
        menuItems={getMenuItemsByRole({ menuItems, role })}
        username={user.name}
        activeLocation={location.pathname}
        onLogoutClick={loggOut}
        onUsernameClick={() => setModalVisible(true)}
      />
      <Modal
        title={(
          <div className={css.menuHeader}>
            Account details
            <Button
              onClick={() => setRequestingNewsletter(true)}
              disabled={isRequestingNewsletter}
              type="primary"
            >
              Send newsletter
            </Button>
          </div>
        )}
        visible={isModalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
        closable={false}
        destroyOnClose
      >
        {user && user.id && (
          <ModalEditUser
            user={user}
            onCancel={() => setModalVisible(false)}
          />
        )}
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  role: authSelector.getRole(authSelector.getUser(state)),
  user: authSelector.getUser(state),
});

export default connect(mapStateToProps)(Menu);
