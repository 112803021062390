import client from '../../common/utils/http';
import {
  formatDateRelative, isSameDay, parseDate, parseDateIgnoreTZ, startOfDay,
} from '../../common/utils/date';

export const getRegulationsInfo = (regulationsTotal) => {
  if (regulationsTotal === 1) return '1 regulation';
  return `${regulationsTotal} regulations`;
};

const getQueryParams = ({ ids }) => ({
  $limit: ids.length,
  $skip: 0,
  regulationIds: ids,
});

const transformRegulation = (regulation) => ({
  ...regulation,
  actors: regulation.entities.filter((e) => e.entityType === 'Actor'),
  plots: regulation.entities.filter((e) => e.entityType === 'Plot'),
  contexts: regulation.entities.filter((e) => e.entityType === 'Context'),
  briefs: regulation.entities.filter((e) => e.entityType === 'Brief'),
  date: parseDateIgnoreTZ(regulation.date),
});

export const fetchSelectedRegulations = ({ ids }) => {
  const params = getQueryParams({ ids });
  return client
    .get('/regulation-feeds', { params })
    .then((res) => res.data.data)
    .then((regulations) => regulations.map(transformRegulation));
};

export const processRegulations = ({ selectedRegulations = [], selectedIds = [] }) => {
  const groupedRegulations = selectedRegulations
    .filter((regulation) => selectedIds.includes(regulation.id))
    .reduce((regulations, regulation) => {
      const regulationDate = parseDate(regulation.date);
      const EMPTY_DATE = { date: startOfDay(parseDate(regulationDate)), regulations: [] };
      const value = regulations.find((e) => isSameDay(e.date, regulationDate)) || EMPTY_DATE;
      value.regulations = [
        ...(value.regulations || []),
        regulation,
      ];
      return [
        ...regulations.filter((e) => !isSameDay(e.date, regulationDate)),
        value,
      ];
    }, []);
  const regulationsList = groupedRegulations
    .sort((group1, group2) => group2.date - group1.date)
    .map((group) => ({ ...group, date: formatDateRelative(parseDate(group.date)) }));
  return regulationsList;
};

export const getRegulationTags = (regulation) => regulation.entities.map((e) => e.entityName);
