import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from '@axis/xyz.admin.modal';
import { Typography } from '@axis/xyz.admin.typography';
import { Button } from '@axis/xyz.admin.buttons.button';
import { ButtonText } from '@axis/xyz.admin.buttons.button-text';
import { FormItemWrapper } from '@axis/xyz.admin.form.form-item-wrapper';
import { DateInput } from '@axis/xyz.admin.form.date-input';
import css from './ModalEditFeedFilters.module.css';
import SelectFeedTopic from '../../components/Form/SelectFeedTopic/SelectFeedTopic';
import SelectBrief from '../../components/Form/SelectBrief/SelectBrief';
import { generateDate } from '../../common/utils/date';

const ModalEditFeedFilters = ({
  title,
  visible,
  filters,
  onConfirm,
  onCancel,
  onClear,
}) => {
  const [newFilters, setNewFilters] = useState({});

  useEffect(() => {
    setNewFilters(filters);
  }, [filters]);

  const { maxDate } = useMemo(() => ({
    maxDate: generateDate(),
  }), []);

  return (
    <Modal
      name="edit-feed-filters"
      className={css.modal}
      visible={visible}
      onClose={onCancel}
    >
      <div className={css.content}>
        <Typography
          className={css.title}
          tag="h1"
          type="title"
          size="xl"
        >
          {title}
        </Typography>
        <div className={css.clear}>
          <ButtonText
            data-cy="feed-clear-elements"
            label="&#x2715; Clear all the selected elements"
            className={css.buttonSecondary}
            onClick={onClear}
          />
        </div>
        <div className={css.filters}>
          <FormItemWrapper
            className={css.input}
            label="Topic"
          >
            <SelectFeedTopic
              value={newFilters.topicId}
              onChange={({ topicId, topicName }) => setNewFilters((currentFilter) => ({
                ...currentFilter,
                topicId,
                topicName,
                sectorBriefs: [],
                subsectorBriefs: [],
                industryBriefs: [],
              }))}
            />
          </FormItemWrapper>
          <FormItemWrapper
            className={css.input}
            label="Date"
          >
            <DateInput
              maxDate={maxDate}
              value={newFilters.date}
              valuePosition="right"
              placeholder="Filter by date"
              variant="block"
              onClear={() => setNewFilters((f) => ({ ...f, date: null }))}
              onChange={(date) => setNewFilters((f) => ({ ...f, date }))}
              closeOnValueSelect
            />
          </FormItemWrapper>
          <FormItemWrapper
            className={css.input}
            label="Sector Briefs"
          >
            <SelectBrief
              briefType="sector"
              topicId={newFilters.topicId}
              value={newFilters.sectorBriefs}
              onChange={(sectorBriefs) => setNewFilters((currentFilter) => ({ ...currentFilter, sectorBriefs }))}
            />
          </FormItemWrapper>
          <FormItemWrapper
            className={css.input}
            label="Sub-Sector Briefs"
          >
            <SelectBrief
              briefType="sub-sector"
              topicId={newFilters.topicId}
              value={newFilters.subsectorBriefs}
              onChange={(subsectorBriefs) => setNewFilters((currentFilter) => ({ ...currentFilter, subsectorBriefs }))}
            />
          </FormItemWrapper>
          <FormItemWrapper
            className={css.input}
            label="Industry Briefs"
          >
            <SelectBrief
              briefType="industry"
              topicId={newFilters.topicId}
              value={newFilters.industryBriefs}
              onChange={(industryBriefs) => setNewFilters((currentFilter) => ({ ...currentFilter, industryBriefs }))}
            />
          </FormItemWrapper>
        </div>
        <div className={css.buttons}>
          <ButtonText
            color="secondary"
            className={css.confirm}
            label="Apply"
            onClick={() => onConfirm(newFilters)}
          />
          <Button
            label="Cancel"
            onClick={onCancel}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ModalEditFeedFilters;
