import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import get from 'lodash/get';

import entityEditorSelector from '../../../../selectors/entityEditor';
import authSelector from '../../../../selectors/users';
import statusesSelector from '../../../../selectors/statuses';
import featurePermissions from '../../../../selectors/featurePermissions';

import {
  facetAdd,
  facetRemove,
  facetMetaChange,
  addFieldSet,
  clearFacet,
  saveFacet,
  crossEntityFacetBlur,
  crossFacetAdd,
  crossFacetRemove,
  crossFacetChange,
} from '../../actions';

import Facet from './Facet';

const arrayToObject = (array, keyField) => array.reduce((obj, item) => {
  // eslint-disable-next-line no-param-reassign
  obj[item[keyField]] = item;
  return obj;
}, {});

const mapStateToProps = (state, props) => ({
  loading: get(state, 'entityEditor.facetLoadingStatusMap', {})[props.facet.id],
  users: arrayToObject(authSelector.getUsers(state), 'id'),
  filteredUsers: authSelector.getUsersByStatus(
    authSelector.getUsers(state),
    props.facet.meta.stage,
  ),
  count: entityEditorSelector.getFacetCount(
    state,
    props.options.entityKey,
    props.facet.type.id,
  ),
  stages: statusesSelector.getFacetStatuses(
    authSelector.getRole(authSelector.getUser(state)),
  ),
  hasAccessToRemoveFacet: featurePermissions.hasAccessToRemoveFacet(
    authSelector.getUser(state),
    props.facet,
  ),
  hasAccessToAssignFacetToUser: featurePermissions.hasAccessToAssignFacetToUser(
    authSelector.getUser(state),
    props.facet,
    props.facet.meta.stage,
  ),
});

const mapDispatchToProps = (dispatch, props) => ({
  actions: bindActionCreators(
    {
      crossFacetAdd,
      crossFacetRemove,
      crossFacetChange,
      facetAdd,
      facetRemove,
      facetMetaChange,
      clearFacet,
      saveFacet,
      fieldSetAdd: (fieldType) => addFieldSet(
        props.options.entityKey,
        props.options.sectionKey,
        props.options.facetKey,
        fieldType,
      ),
      crossEntityFacetSave: crossEntityFacetBlur,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Facet);
