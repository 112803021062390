import React, {
  useState, useEffect, useRef, memo,
} from 'react';
import { connect } from 'react-redux';
import ReactDataGrid from 'react-data-grid';
import {
  Row, Spin, message,
} from 'antd';

import helpers from './helpers';
import columnsBasic from './components/columns';
import usersSelector from '../../selectors/users';

import './styles.css';

const updateRow = (props, state, setState) => {
  const { updated, fromRow, toRow } = props;
  const update = [...state.facets];
  const create = [...state.newFacets];
  for (let i = fromRow; i <= toRow; i += 1) {
    if (update[i] && update[i].id) {
      update[i] = {
        ...update[i],
        ...updated,
      };
    } else {
      const j = i - update.length;
      if (!create[j]) {
        create[j] = {};
      }
      create[j] = {
        ...create[j],
        ...updated,
        id: null,
      };
    }
  }
  setState((prev) => ({ ...prev, facets: update, newFacets: create }));
};

const CrossEntityFacets = ({ users, id }) => {
  const refRDG = useRef();
  const [state, setState] = useState({
    loading: true,
    facets: [],
    newFacets: [],
    columns: [...columnsBasic],
    users,
  });

  useEffect(() => {
    (async () => {
      const { data, columns } = await helpers.getFacets(id);
      setState((prev) => ({
        ...prev,
        facets: data,
        columns: [...columnsBasic, ...columns],
        loading: false,
      }));
    })()
      .catch((err) => {
        message.error('Something went wrong...');
        console.error(err);
        setState((prev) => ({ ...prev, loading: false }));
      });
  }, []);

  const rowGetter = (index) => state.facets[index];
  const minHeight = ((state.facets.length + 1) * 35) + 40;

  return (
    <Spin spinning={state.loading}>
      {
        !state.loading
          ? (
            <Row className="CEFS_TABLE__RDG">
              <ReactDataGrid
                ref={refRDG}
                columns={state.columns}
                rowGetter={rowGetter}
                rowsCount={state.facets.length}
                minHeight={minHeight}
                enableCellSelect
                onGridRowsUpdated={(props) => updateRow(props, state, setState)}
              />
            </Row>
          )
          : null
      }
    </Spin>
  );
};

const mapStateToProps = (state) => {
  const user = usersSelector.getUser(state);
  return {
    user,
    users: usersSelector.getUsers(state),
    role: usersSelector.getRole(user) || '',
  };
};

export default connect(mapStateToProps)(
  memo(CrossEntityFacets),
);
