import { Select } from '@axis/xyz.admin.form.select';
import React from 'react';
import css from './SelectBriefType.module.css';

const SelectBriefType = ({ value, onChange, filter }) => {
  const briefTypes = [
    ...(filter ? ['All'] : []),
    ...['core', 'landscape', 'current', 'sector', 'sub-sector', 'industry'],
  ];

  return (
    <Select
      className={css.select}
      placeholder="Select brief type"
      name="brief-type"
      getOptionLabel={(option) => option}
      getOptionValue={(option) => option}
      filterOption={(options) => options}
      onChange={onChange}
      value={value}
      options={briefTypes}
    />
  );
};

export default SelectBriefType;
