import React, { memo } from 'react';
import { Divider, Button } from 'antd';
import styled from 'styled-components';
import moment from 'moment/moment';

import { DATE_FORMAT } from '../../../constants/common';

const CommentsLog = ({ comments, removeComment }) => (
  <>
    <Header>{`Comment Log (amount: ${comments.length})`}</Header>
    {
      comments.map((comment) => (
        <Comment key={comment.id}>
          <CommentHeader>
            <div>
              <UserName>{comment.username}</UserName>
              <Details>
                {` (Score: ${comment.assessment}, Date: ${moment(comment.createdAt).format(DATE_FORMAT)})`}
              </Details>
            </div>
            <Button onClick={() => removeComment(comment.id)} icon="delete" />
          </CommentHeader>
          <Text>{comment.details}</Text>
          <Divider dashed />
        </Comment>
      ))
    }
  </>
);

export default memo(CommentsLog);

const Header = styled.div`
  margin-top: 30px;
  margin-bottom: 10px;
  font-size: 18px;
`;

const CommentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 15px;
`;

const UserName = styled.span`
  font-weight: 700;
`;

const Details = styled.span`
  font-size: 15px;
  font-style: italic;
`;

const Comment = styled.div`
  margin-bottom: 10px;
  font-size: 17px;
`;

const Text = styled.div`
  font-style: italic;
`;
