export const PAGE_SIZE = 20;

export const FREQUENCY = [
  { key: 'YEAR', value: 'Annual' },
  { key: 'QUARTER', value: 'Quarterly' },
  { key: 'MONTH', value: 'Monthly' },
  { key: 'DAY', value: 'Daily' },
];

export const NUMBERS = [
  { key: 'Hundred', value: '0.01' },
  { key: 'Thousand', value: '0.001' },
  { key: 'Million', value: '0.000001' },
  { key: 'Billion', value: '0.1e-9' },
  { key: 'Trillion', value: '0.1e-12' },
];

export const FUTURE_DATES = [
  { key: 'Projection', value: 'Projection' },
  { key: 'Goal', value: 'Goal' },
];

export const DEFAULT_CEF = {
  periodicity: FREQUENCY[0].key,
  futureDates: FUTURE_DATES[0].key,
};

export const getPeriod = (key) => {
  const freq = FREQUENCY.find((f) => f.key === key);
  if (!freq) return '';
  return freq.value;
};

export const getQueryParams = ({ filter = {}, page = 1 }) => {
  const params = {
    $limit: PAGE_SIZE,
    $skip: PAGE_SIZE * (page - 1),
  };
  if (filter.name) {
    params['name[$iLike]'] = `%${filter.name.split(' ').join('%')}%`;
  }
  return params;
};

export const validateForm = (cef) => {
  const errors = {};
  if (!cef.name) {
    errors.name = 'Name is required.';
  }
  return errors;
};
