/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Layout } from 'antd';
import {
  PARAM_CEFT_EDITOR,
  PARAM_DICTIONARY_EDITOR,
  PARAM_ENTITIES_EDITOR,
  PARAM_CEFT_ID,
  ROUTE_ADMIN,
  ROUTE_ADMIN_ASSIGN_FACETS,
  ROUTE_ANALYST_ELEMENTS_CEFT,
  ROUTE_ADMIN_ELEMENTS_DICTIONARIES,
  ROUTE_ADMIN_ELEMENTS_DICTIONARIES_EDITOR,
  ROUTE_ADMIN_ENTITIES,
  ROUTE_ADMIN_USERS,
  ROUTE_ANALYST,
  ROUTE_ANALYST_BRIEFS_EDITOR,
  ROUTE_ANALYST_ELEMENTS_BRIEFS,
  ROUTE_ANALYST_ENTITIES,
  ROUTE_ANALYST_ENTITIES_ACTORS,
  ROUTE_ANALYST_ENTITIES_ALL,
  ROUTE_ANALYST_ENTITIES_CONTEXTS,
  ROUTE_ANALYST_ENTITIES_EDITOR,
  ROUTE_ANALYST_ENTITIES_PLOTS,
  ROUTE_ANALYST_ELEMENTS_CEFS,
  ROUTE_ANALYST_ELEMENTS_IMPORT_STATS,
  ROUTE_ANALYST_ELEMENTS_IMPORT_ENTITIES,
  ROUTE_ANALYST_ELEMENTS_IMPORT_CHALLENGES,
  ROUTE_AUTH,
  ROUTE_ANALYST_ELEMENTS_STATENTITY,
  ROUTE_ANALYST_STATENTITY_EDITOR,
  ROUTE_ANALYST_ELEMENTS_IMPORT_BRIEFS,
  ROUTE_ANALYST_FEED_ARTICLES,
  ROUTE_ANALYST_FEED_REVIEW,
  ROUTE_ANALYST_FEED_CREATE,
  ROUTE_ANALYST_FEED_CREATE_MANUAL,
  ROUTE_ANALYST_EVENTS_ALL,
  ROUTE_ANALYST_EVENT_EDITOR,
  ROUTE_ANALYST_REGULATIONS,
  ROUTE_ANALYST_REGULATION_EDITOR,
  ROUTE_ANALYST_SOURCES_ARTICLES,
  ROUTE_ANALYST_SOURCES_REGULATIONS,
  ROUTE_ANALYST_REGULATIONS_FEED,
  ROUTE_ANALYST_REGULATIONS_REVIEW,
  ROUTE_ANALYST_REGULATIONS_CREATE,
  ROUTE_ANALYST_REGULATIONS_CREATE_MANUAL,
  RSS_SOURCE_TYPES,
  ROUTE_ANALYST_SOURCES_REGULATION_ARTICLES,
  ROUTE_ANALYST_FEED_REGULATION_ARTICLES,
  REGULATION_FEED_TYPES,
  ROUTE_ANALYST_COUNTRIES_ALL,
  ROUTE_ANALYST_LANDSCAPES_ALL,
} from '../constants';
import DictEditor from '../containers/Dictionaries/components/Editor/Editor';
import UsersList from '../containers/Users/Users';
import EntitiesTable from '../containers/Entities';
import FacetsAssignTable from '../containers/FacetsAssign';
import EntityEditor from '../containers/EntityEditor';
import Sidebar from '../containers/EntityEditor/components/Sidebar';
import checkRoleForRoute from '../common/utils/checkPermissions';
import store from '../store';
import { isLoggedIn } from '../common/utils/auth';
import CrossEntityFacetEditor from '../containers/CrossEntityFacetTypes/CrossEntityFacetEditor/CrossEntityFacetEditor';
import CrossEntityFacetsTable from '../containers/CrossEntityFacetTypes/CrossEntityFacetsTable/CrossEntityFacetsTable';
import CEFS from '../containers/CrossEntityFacetsSpreadsheet';
import Import from '../containers/ImportSheet';
import Dictionaries from '../containers/Dictionaries/Dictionaries';
import ArticlesFeedList from '../containers/ArticlesFeedList/ArticlesFeedList';
import ArticlesFeedReview from '../containers/ArticlesFeedReview/ArticlesFeedReview';
import ArticlesFeedCreation from '../containers/ArticlesFeedCreation/ArticlesFeedCreation';
import Events from '../containers/Events/Events';
import EventPage from '../containers/EventPage/EventPage';
import RSSSources from '../containers/RSSSources/RSSSources';
import RegulationsFeedList from '../containers/RegulationsFeedList/RegulationsFeedList';
import RegulationsFeedReview from '../containers/RegulationsFeedReview/RegulationsFeedReview';
import RegulationsFeedCreation from '../containers/RegulationsFeedCreation/RegulationsFeedCreation';
import Regulations from '../containers/Regulations/Regulations';
import RegulationPage from '../containers/RegulationPage/RegulationPage';
import Countries from '../containers/Countries/Countries';
import Briefs from '../containers/Briefs/Briefs';
import Landscapes from '../containers/Landscapes/Landscapes';

const RouteProtected = ({
  pathKey, path, component, render, ...props
}) => (
  <Route
    key={pathKey}
    path={path}
    render={(routeProps) => {
      const user = isLoggedIn(store);
      if (!user || !user.role || user.role === 'user') return <Redirect to={ROUTE_AUTH} />;
      const { status, path: newPath } = checkRoleForRoute(user, pathKey);
      return status ? render(routeProps) : <Redirect to={newPath} />;
    }}
    {...props}
  />
);

export default () => (
  <Switch>
    <RouteProtected
      exact
      pathKey={ROUTE_ANALYST_LANDSCAPES_ALL}
      path={ROUTE_ANALYST_LANDSCAPES_ALL}
      render={() => <Landscapes />}
    />

    <RouteProtected
      exact
      pathKey={ROUTE_ANALYST_COUNTRIES_ALL}
      path={ROUTE_ANALYST_COUNTRIES_ALL}
      render={() => <Countries />}
    />

    <RouteProtected
      exact
      pathKey={ROUTE_ANALYST_SOURCES_ARTICLES}
      path={ROUTE_ANALYST_SOURCES_ARTICLES}
      render={() => <RSSSources type={RSS_SOURCE_TYPES.ARTICLE} />}
    />
    <RouteProtected
      exact
      pathKey={ROUTE_ANALYST_SOURCES_REGULATIONS}
      path={ROUTE_ANALYST_SOURCES_REGULATIONS}
      render={() => <RSSSources type={RSS_SOURCE_TYPES.REGULATION} />}
    />
    <RouteProtected
      exact
      pathKey={ROUTE_ANALYST_SOURCES_REGULATION_ARTICLES}
      path={ROUTE_ANALYST_SOURCES_REGULATION_ARTICLES}
      render={() => <RSSSources type={RSS_SOURCE_TYPES.REGULATION_ARTICLE} />}
    />
    <RouteProtected
      exact
      pathKey={ROUTE_ANALYST_FEED_ARTICLES}
      path={ROUTE_ANALYST_FEED_ARTICLES}
      render={() => <ArticlesFeedList />}
    />
    <RouteProtected
      exact
      pathKey={ROUTE_ANALYST_FEED_REVIEW}
      path={ROUTE_ANALYST_FEED_REVIEW}
      render={() => <ArticlesFeedReview />}
    />
    <RouteProtected
      exact
      pathKey={ROUTE_ANALYST_FEED_CREATE}
      path={ROUTE_ANALYST_FEED_CREATE}
      render={() => <ArticlesFeedCreation showArticles />}
    />
    <RouteProtected
      exact
      pathKey={ROUTE_ANALYST_FEED_CREATE_MANUAL}
      path={ROUTE_ANALYST_FEED_CREATE_MANUAL}
      render={() => <ArticlesFeedCreation />}
    />
    <RouteProtected
      exact
      pathKey={ROUTE_ANALYST_REGULATIONS_FEED}
      path={ROUTE_ANALYST_REGULATIONS_FEED}
      render={() => <RegulationsFeedList type={REGULATION_FEED_TYPES.REGULATION} />}
    />
    <RouteProtected
      exact
      pathKey={ROUTE_ANALYST_FEED_REGULATION_ARTICLES}
      path={ROUTE_ANALYST_FEED_REGULATION_ARTICLES}
      render={() => <RegulationsFeedList type={REGULATION_FEED_TYPES.ARTICLE} />}
    />
    <RouteProtected
      exact
      pathKey={ROUTE_ANALYST_REGULATIONS_REVIEW}
      path={ROUTE_ANALYST_REGULATIONS_REVIEW}
      render={() => <RegulationsFeedReview />}
    />
    <RouteProtected
      exact
      pathKey={ROUTE_ANALYST_REGULATIONS_CREATE}
      path={ROUTE_ANALYST_REGULATIONS_CREATE}
      render={() => <RegulationsFeedCreation showRegulations />}
    />
    <RouteProtected
      exact
      pathKey={ROUTE_ANALYST_REGULATIONS_CREATE_MANUAL}
      path={ROUTE_ANALYST_REGULATIONS_CREATE_MANUAL}
      render={() => <RegulationsFeedCreation />}
    />

    <RouteProtected
      exact
      pathKey={ROUTE_ANALYST_EVENTS_ALL}
      path={ROUTE_ANALYST_EVENTS_ALL}
      render={() => <Events />}
    />
    <RouteProtected
      exact
      pathKey={ROUTE_ANALYST_EVENT_EDITOR}
      path={ROUTE_ANALYST_EVENT_EDITOR}
      render={(props) => <EventPage {...props} />}
    />

    <RouteProtected
      exact
      pathKey={ROUTE_ANALYST_REGULATIONS}
      path={ROUTE_ANALYST_REGULATIONS}
      render={() => <Regulations />}
    />
    <RouteProtected
      exact
      pathKey={ROUTE_ANALYST_REGULATION_EDITOR}
      path={ROUTE_ANALYST_REGULATION_EDITOR}
      render={(props) => <RegulationPage {...props} />}
    />

    <RouteProtected
      exact
      pathKey={ROUTE_ADMIN_ELEMENTS_DICTIONARIES}
      path={ROUTE_ADMIN_ELEMENTS_DICTIONARIES}
      render={() => <Dictionaries />}
    />

    <RouteProtected
      exact
      pathKey={ROUTE_ADMIN_ELEMENTS_DICTIONARIES_EDITOR}
      path={`${ROUTE_ADMIN_ELEMENTS_DICTIONARIES_EDITOR}/:${PARAM_DICTIONARY_EDITOR}?`}
      render={(props) => <DictEditor {...props} />}
    />

    <RouteProtected
      exact
      pathKey={ROUTE_ANALYST_ELEMENTS_CEFT}
      path={`${ROUTE_ANALYST_ELEMENTS_CEFT}/:${PARAM_CEFT_EDITOR}`}
      render={(props) => <CrossEntityFacetEditor {...props} />}
    />

    <RouteProtected
      exact
      pathKey={ROUTE_ANALYST_ELEMENTS_CEFT}
      path={ROUTE_ANALYST_ELEMENTS_CEFT}
      render={() => <CrossEntityFacetsTable />}
    />

    <RouteProtected
      exact
      pathKey={ROUTE_ADMIN_USERS}
      path={ROUTE_ADMIN_USERS}
      render={() => <UsersList />}
    />

    <RouteProtected
      exact
      pathKey={ROUTE_ADMIN}
      path={ROUTE_ADMIN}
      render={() => <Redirect to={ROUTE_ADMIN_ENTITIES} />}
    />

    <RouteProtected
      exact
      pathKey={ROUTE_ADMIN_ASSIGN_FACETS}
      path={ROUTE_ADMIN_ASSIGN_FACETS}
      render={() => <FacetsAssignTable />}
    />

    <RouteProtected
      exact
      pathKey={ROUTE_ANALYST}
      path={ROUTE_ANALYST}
      render={() => <Redirect to={ROUTE_ANALYST_ENTITIES} />}
    />

    <RouteProtected
      exact
      pathKey={ROUTE_ANALYST_ENTITIES_ALL}
      path={ROUTE_ANALYST_ENTITIES_ALL}
      render={() => <EntitiesTable />}
    />

    <RouteProtected
      exact
      pathKey={ROUTE_ANALYST_ENTITIES}
      path={ROUTE_ANALYST_ENTITIES}
      render={() => <Redirect to={ROUTE_ANALYST_ENTITIES_ALL} />}
    />

    <RouteProtected
      exact
      pathKey={ROUTE_ANALYST_ENTITIES_ACTORS}
      path={ROUTE_ANALYST_ENTITIES_ACTORS}
      render={() => <EntitiesTable type="actor" />}
    />

    <RouteProtected
      exact
      pathKey={ROUTE_ANALYST_ENTITIES_PLOTS}
      path={ROUTE_ANALYST_ENTITIES_PLOTS}
      render={() => <EntitiesTable type="plot" />}
    />

    <RouteProtected
      exact
      pathKey={ROUTE_ANALYST_ENTITIES_CONTEXTS}
      path={ROUTE_ANALYST_ENTITIES_CONTEXTS}
      render={() => <EntitiesTable type="context" />}
    />

    <RouteProtected
      exact
      pathKey={ROUTE_ANALYST_ENTITIES_EDITOR}
      path={`${ROUTE_ANALYST_ENTITIES_EDITOR}/:${PARAM_ENTITIES_EDITOR}?`}
      render={(props) => (
        <Layout style={{ flexDirection: 'row' }}>
          <Sidebar />
          <EntityEditor {...props} />
        </Layout>
      )}
    />

    <RouteProtected
      exact
      pathKey={ROUTE_ANALYST_ELEMENTS_BRIEFS}
      path={ROUTE_ANALYST_ELEMENTS_BRIEFS}
      render={() => <Briefs />}
    />

    <RouteProtected
      exact
      pathKey={ROUTE_ANALYST_BRIEFS_EDITOR}
      path={`${ROUTE_ANALYST_BRIEFS_EDITOR}/:${PARAM_ENTITIES_EDITOR}?`}
      render={(props) => (
        <Layout style={{ flexDirection: 'row' }}>
          <Sidebar />
          <EntityEditor {...props} isCollection />
        </Layout>
      )}
    />

    <RouteProtected
      exact
      pathKey={ROUTE_ANALYST_ELEMENTS_STATENTITY}
      path={ROUTE_ANALYST_ELEMENTS_STATENTITY}
      render={() => <EntitiesTable type="untyped" isCollection />}
    />

    <RouteProtected
      exact
      pathKey={ROUTE_ANALYST_STATENTITY_EDITOR}
      path={`${ROUTE_ANALYST_STATENTITY_EDITOR}/:${PARAM_ENTITIES_EDITOR}?`}
      render={(props) => (
        <Layout style={{ flexDirection: 'row' }}>
          <Sidebar />
          <EntityEditor {...props} isCollection />
        </Layout>
      )}
    />

    <RouteProtected
      exact
      pathKey={ROUTE_ANALYST_ELEMENTS_CEFS}
      path={`${ROUTE_ANALYST_ELEMENTS_CEFS}/:${PARAM_CEFT_ID}`}
      render={({ match: { params: { id } } }) => <CEFS id={id} />}
    />

    <RouteProtected
      exact
      pathKey={ROUTE_ANALYST_ELEMENTS_IMPORT_STATS}
      path={ROUTE_ANALYST_ELEMENTS_IMPORT_STATS}
      render={(props) => <Import {...props} />}
    />

    <RouteProtected
      exact
      pathKey={ROUTE_ANALYST_ELEMENTS_IMPORT_ENTITIES}
      path={ROUTE_ANALYST_ELEMENTS_IMPORT_ENTITIES}
      render={(props) => <Import {...props} type="TOPIC" />}
    />

    <RouteProtected
      exact
      pathKey={ROUTE_ANALYST_ELEMENTS_IMPORT_CHALLENGES}
      path={ROUTE_ANALYST_ELEMENTS_IMPORT_CHALLENGES}
      render={(props) => <Import {...props} type="CHALLENGES" />}
    />

    <RouteProtected
      exact
      pathKey={ROUTE_ANALYST_ELEMENTS_IMPORT_BRIEFS}
      path={ROUTE_ANALYST_ELEMENTS_IMPORT_BRIEFS}
      render={(props) => <Import {...props} type="BRIEF" />}
    />

    <RouteProtected
      render={() => <Redirect to="/" />}
    />
  </Switch>
);
