/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import Layout from './containers/Layout';
import SignUp from './containers/SignUp';
import RequestResetPassword from './containers/RequestResetPassword/RequestResetPassword';
import ResetPassword from './containers/ResetPassword/ResetPassword';
import { isLoggedIn } from './common/utils/auth';
import Auth from './containers/Auth';
import {
  ROUTE_ANALYST_ENTITIES,
  ROUTE_AUTH,
  ROUTE_RESET_PASSWORD,
  ROUTE_RESET_REQUEST,
  ROUTE_USER_SIGNUP,
} from './constants';

const Router = ({ store }) => (
  <Switch>
    <Route
      exact
      path="/"
      render={() => (isLoggedIn(store)
        ? <Redirect to={ROUTE_ANALYST_ENTITIES} />
        : <Redirect to={ROUTE_AUTH} />)}
    />
    <Route
      exact
      path={ROUTE_USER_SIGNUP}
      component={SignUp}
    />
    <Route
      exact
      path={ROUTE_RESET_REQUEST}
      component={RequestResetPassword}
    />
    <Route
      exact
      path={ROUTE_RESET_PASSWORD}
      component={ResetPassword}
    />
    <Route
      exact
      path={ROUTE_AUTH}
      render={(props) => (isLoggedIn(store)
        ? <Redirect to="/" />
        : <Auth {...props} />)}
    />
    <Route
      render={(props) => (isLoggedIn(store)
        ? <Layout {...props} />
        : <Redirect to={ROUTE_AUTH} />)}
    />
    <Route
      render={() => <Redirect to="/" />}
    />
  </Switch>
);

export default Router;
