/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Button, Row } from 'antd';

function withRemoveButton(WrappedComponent) {
  return (props) => {
    const {
      fieldType,
      count,
      removeField,
    } = props;

    return (
      <>
        <WrappedComponent {...props} />
        {
          count > 0 && (fieldType.duplicate || !fieldType.required)
          && (
            <Row type="flex" justify="end">
              <Button
                type="link"
                className="button-like-link"
                onClick={removeField}
              >
                {`× Remove ${fieldType.rules.Field || fieldType.name}`}
              </Button>
            </Row>
          )
        }
      </>
    );
  };
}

export default withRemoveButton;
