import queryString from 'query-string';

export const verifyNewPassword = ({ password, repassword }) => {
  if (!password) {
    return { status: 'ko', error: 'Password is too short' };
  }
  if (password !== repassword) {
    return { status: 'ko', error: 'Passwords do not match' };
  }
  return { status: 'ok' };
};

export const getResetToken = (location) => {
  const params = queryString.parse(location.search);
  return params.resetToken;
};
