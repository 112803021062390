/* eslint-disable linebreak-style */
import jwt from 'jsonwebtoken';
import client from './http';
import { INITIAL_LOAD } from '../../containers/Auth/constants';

const tokenField = 'auth_token';

export function setAuthToken(token) {
  localStorage.setItem(tokenField, token);
  client.defaults.headers.common.Authorization = `Bearer ${token}`;
}

export function isLoggedIn(store) {
  const token = localStorage.getItem(tokenField);
  const decoded = jwt.decode(token);
  if (!decoded || decoded.exp * 1000 < (new Date()).getTime()) {
    return false;
  }
  client.defaults.headers.common.Authorization = `Bearer ${token}`;
  store.dispatch({
    type: INITIAL_LOAD,
    payload: {
      userId: decoded.userId,
    },
  });
  return decoded;
}

export function loggOut() {
  localStorage.removeItem(tokenField);
  client.defaults.headers.common.Authorization = '';
  window.location = '/';
}
