import React from 'react';
import { Icon, Popconfirm, Row } from 'antd';
import { Link } from 'react-router-dom';
import {
  ROUTE_ANALYST_ENTITIES_EDITOR,
  ROUTE_ANALYST_BRIEFS_EDITOR,
} from '../../../../constants';
import featurePermissions from '../../../../selectors/featurePermissions';
import * as strings from '../../../../common/Strings';
import styles from './styles.module.css';
import { sendEvent } from '../../../../contexts/AnalyticsTrackingContext/AnalyticsTrackingContext';

const formatter = ({
  row: {
    id, type, subType, name, columnsActions: { removeEntity, getRole, untyped },
  },
}) => (
  <Row>
    {untyped || type === 'none' ? null : (
      <Link
        className={styles.LinkLikeIcon}
        to={type === 'Brief' ? `${ROUTE_ANALYST_BRIEFS_EDITOR}/${id}` : `${ROUTE_ANALYST_ENTITIES_EDITOR}/${id}`}
      >
        <Icon
          className="cursor-pointer"
          type="edit"
          title="Edit"
        />
      </Link>
    )}
    {featurePermissions.hasAccessToRemoveEntity(getRole()) && subType !== 'Comparison Set' ? (
      <Popconfirm
        id={`_popconfirm${id}`}
        placement="topRight"
        title={`${strings.dDeleteEntity} '${name}'?`}
        onConfirm={() => {
          sendEvent('entity_deletion', {
            description: 'Entity deletion',
            entityId: id,
            entityName: name,
            entityType: type,
          });
          removeEntity(id);
        }}
        onCancel={() => {}}
        getPopupContainer={() => document.getElementsByClassName('react-grid-Grid')[0]}
        okText="Yes"
        cancelText="No"
      >
        <Icon
          id={`delete_${id}`}
          className="cursor-pointer mrg-left-10"
          type="delete"
          title="Delete"
        />
      </Popconfirm>
    ) : null}
    {featurePermissions.hasAccessToRemoveEntity(getRole()) && subType === 'Comparison Set' ? (
      <Icon
        style={{ opacity: 0.5 }}
        id={`delete_${id}`}
        className="cursor-pointer mrg-left-10"
        type="delete"
        title="Comparison sets for statistics cannot be deleted for now"
      />
    ) : null}
  </Row>
);

export default {
  key: 'action',
  name: 'Action',
  width: 60,
  sortable: false,
  resizable: true,
  formatter,
};
