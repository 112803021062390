import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Pagination } from '@axis/xyz.admin.pagination';
import { useHistory, useParams } from 'react-router-dom';
import css from './RegulationsFeedCreation.module.css';
import { STORAGE_ADMIN_FEED_REGULATIONS, STORAGE_ADMIN_FEED_REGULATION_ARTICLES } from '../../common/utils/storage';
import Article from '../../components/Article/Article';
import RegulationForm from '../RegulationForm/RegulationForm';
import { fetchSelectedRegulations } from '../RegulationsFeedReview/utils';
import { useToast } from '../../contexts/ToastManager/ToastManager';
import {
  createRegulation,
  getActiveRegulationPage,
  transformRegulation,
} from './utils';
import Loading from '../../components/Loading/Loading';
import { sendEvent } from '../../contexts/AnalyticsTrackingContext/AnalyticsTrackingContext';
import useLocalStorage from '../../hooks/useLocalStorage';
import { REGULATION_FEED_TYPES } from '../../constants';

const EMPTY_REGULATION_FORM = {
  issuerEntity: { name: '', id: '' },
  topic: { name: '', id: '' },
  summarizedName: '',
  legalName: '',
  dateStart: null,
  dateEnd: null,
  industryBriefs: [],
  source: '',
  attachment: '',
};

const RegulationsFeedCreation = ({ showRegulations = false }) => {
  const [activeRegulation, setActiveRegulation] = useState(null);
  const [regulationForm, setRegulationForm] = useState(EMPTY_REGULATION_FORM);
  const [isRegulationFormEnabled, setRegulationFormEnabled] = useState(true);

  const history = useHistory();
  const toast = useToast();
  const { type } = useParams();

  const [selectedRegulations] = useLocalStorage(type === REGULATION_FEED_TYPES.REGULATION ? STORAGE_ADMIN_FEED_REGULATIONS : STORAGE_ADMIN_FEED_REGULATION_ARTICLES, []);

  useEffect(() => {
    if (showRegulations && activeRegulation) {
      const form = transformRegulation(activeRegulation);
      setRegulationForm(form);
    }
  }, [showRegulations, activeRegulation]);

  const {
    data: regulations = [],
    isFetching: isFetchingRegulations,
  } = useQuery(
    ['selectedRegulations', selectedRegulations],
    () => fetchSelectedRegulations({ ids: selectedRegulations }),
    {
      enabled: showRegulations,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    if (regulations && (regulations.length > 0)) {
      setActiveRegulation(regulations[0]);
    }
  }, [regulations]);

  const updateActiveRegulation = (regulationIndex) => {
    setActiveRegulation(regulations[regulationIndex - 1]);
  };

  const handleRegulationFormChange = (field, value) => {
    setRegulationForm((currentEvent) => ({ ...currentEvent, [field]: value }));
  };

  const hasRegulations = showRegulations && (regulations.length > 0);

  const handleSubmit = () => {
    setRegulationFormEnabled(false);

    sendEvent('regulation_creation', {
      description: 'Creating a new regulation',
      entityId: regulationForm.issuerEntity.id,
      entityName: regulationForm.issuerEntity.name,
      name: regulationForm.legalName,
    });

    createRegulation(regulationForm)
      .then(() => {
        toast.success({ content: 'Regulation successfully created!' });
        // If we don't have any regulations, we reset the form
        if (!hasRegulations) {
          setRegulationForm(EMPTY_REGULATION_FORM);
        } else {
          // We move on automatically to the next regulation
          const currentPage = getActiveRegulationPage({ activeRegulation, regulations });
          if (currentPage < regulations.length) {
            updateActiveRegulation(currentPage + 1);
            window.scrollTo(0, 0);
          } else {
            // We have nothing else to work on - we go back to the regulations
            history.push(`/analyst/feed/regulations/review/${type}`);
          }
        }
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setRegulationFormEnabled(true);
      });
  };

  return (
    <Loading isLoading={isFetchingRegulations}>
      <div className={css.main} data-content={hasRegulations ? 'regulations' : 'manual'}>
        {hasRegulations && activeRegulation && (
          <div className={css.regulations}>
            <Article article={activeRegulation} />
            <div className={css.pagination}>
              <Pagination
                page={getActiveRegulationPage({ activeRegulation, regulations })}
                totalPages={regulations.length}
                onPageChange={updateActiveRegulation}
              />
            </div>
          </div>
        )}
        <div className={css.creation} data-cy="regulations-creation-event-form">
          <RegulationForm
            title="Create Regulation"
            regulation={regulationForm}
            buttonLabel={isRegulationFormEnabled ? 'Publish' : 'Publishing...'}
            onChange={handleRegulationFormChange}
            submitEnabled={isRegulationFormEnabled}
            onSubmit={handleSubmit}
          />
        </div>
      </div>
    </Loading>
  );
};

export default RegulationsFeedCreation;
