/* eslint-disable react/no-danger */
import React from 'react';
import { Popover, Icon } from 'antd';
import styled from 'styled-components';

const replace = (str) => (str ? str.replace(/\n/gm, '<br/>') : str);

const Info = ({ info }) => info && (
  <Popover
    placement="topRight"
    content={
      <Content dangerouslySetInnerHTML={{ __html: replace(info) }} />
    }
  >
    <Icon type="info" className="info-icon" />
  </Popover>
);

export default Info;

const Content = styled.div`
  max-width: 300px;
  -webkit-hyphens: manual;
  -ms-hyphens: manual;
  hyphens: manual;
  font-size: 12px;
  font-style: italic;
  background-color: #f2f2f7;
`;
