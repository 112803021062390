import React from 'react';
import { Icon } from '@axis/xyz.assets.icon';
import { Typography } from '@axis/xyz.admin.typography';
import { Link } from 'react-router-dom';
import styles from './SuggestedUpdateItem.module.css';

const SuggestedUpdateItem = ({
  entityId, entityName, facets,
}) => (
  <div className={styles.main}>
    <Link className={styles.name} to={`/analyst/entities/editor/${entityId}`} target="_blank">
      <Typography type="title" size="sm">{entityName}</Typography>
    </Link>
    <div className={styles.facets}>
      {facets.map((facet) => (
        <div key={facet.facetId} className={styles.keywords}>
          <Icon type="arrow-right" className={styles.icon} />
          <Typography size="md" type="text" className={styles.type}>{facet.facetType}</Typography>
          {facet.facetRelationship && (
            <>
              <Icon type="arrow-right" className={styles.icon} />
              <Typography size="md" type="text" className={styles.relationship}>{facet.facetRelationship}</Typography>
            </>
          )}
          {facet.facetSubtype && (
            <>
              <Icon type="arrow-right" className={styles.icon} />
              <Typography size="md" type="text" className={styles.subtype}>{facet.facetSubtype}</Typography>
            </>
          )}
          {facet.keywords.map((word) => (
            <Typography
              size="sm"
              type="text"
              key={word}
              className={styles.keyword}
            >
              {word}
            </Typography>
          ))}
        </div>
      ))}
    </div>
  </div>
);

export default SuggestedUpdateItem;
