/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */

import React, { useEffect, useState } from 'react';
import {
  Col, Input, Row, Select, Spin, Form, message,
} from 'antd';

import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import isEmpty from 'lodash/isEmpty';
import FormHeader from '../components/CrossEntityFacetsFormHeader/CrossEntityFacetsFormHeader';
import ComparisonSetSelector from '../components/ComparisonSetSelector/ComparisonSetSelector';
import {
  DEFAULT_CEF,
  FREQUENCY, FUTURE_DATES, NUMBERS, validateForm,
} from '../utils';
import client from '../../../common/utils/http';
import './CrossEntityFacetEditor.css';

const CrossEntityFacetEditor = () => {
  const { id } = useParams();
  const isNew = id === 'new';

  const queryClient = useQueryClient();

  const history = useHistory();

  const [facet, setFacet] = useState(DEFAULT_CEF);
  const [errors, setErrors] = useState({});

  const { isLoading, isError } = useQuery(['cross-entity-facet-type', id], () => {
    client.get(`/cross-entity-facet-types/${id}`)
      .then(({ data }) => data)
      .then(setFacet);
  }, {
    enabled: !isNew,
    refetchOnWindowFocus: false,
  });

  const cefSave = useMutation((facetDetails) => {
    if (isNew) {
      return client.get(`/cross-entity-facet-types?name=${facetDetails.name}`)
        .then(({ data }) => {
          if (data.total > 0) throw new Error('This face already exists');
          return true;
        })
        .then(() => {
          const { comparisonSet, ...data } = facetDetails;
          return client.post('/cross-entity-facet-types', data);
        });
    }
    return client.put(`/cross-entity-facet-types/${facetDetails.id}`, facetDetails);
  });

  useEffect(() => {
    if (isError) message.error('There was an error recovering the facet');
  }, [isError]);

  useEffect(() => {
    if (!facet.units && facet.multiplier) {
      setFacet((currentFacet) => ({ ...currentFacet, units: facet.multiplier }));
    }
  }, [facet.multiplier]);

  const handleInputChange = (field) => (event) => {
    let value = event.target.value || '';
    if (field === 'name') value = value.slice(0, 32);
    if (field === 'units') value = value.slice(0, 20);
    setFacet((currentValue) => ({
      ...currentValue,
      [field]: value,
    }));
  };

  const handleSelectChange = (field) => (value) => {
    setFacet((currentValue) => ({
      ...currentValue,
      [field]: value || null,
    }));
  };

  const handleCancel = () => history.push('/analyst/elements/ceft');

  const handleSave = () => {
    const formErrors = validateForm(facet);
    if (isEmpty(formErrors)) {
      cefSave.mutate(facet, {
        onSuccess: ({ data: updatedFacet }) => {
          if (isNew) {
            message.success('Cross entity facet created');
            history.push(`/analyst/elements/ceft/${updatedFacet.id}`);
          } else {
            message.success('Cross entity facet updated');
            queryClient.invalidateQueries(['cross-entity-facet-type', updatedFacet.id]);
          }
        },
        onError: message.error,
      });
    }
    setErrors(formErrors);
  };

  return (
    <Spin spinning={isLoading}>
      <div className="CrossEntityFacetEditor">
        <FormHeader onSave={handleSave} onCancel={handleCancel} id={id} />

        <Form>
          <Row>
            <Col className="FormContainer-Col" xs={24} sm={18} md={14} lg={12} xl={10}>

              <Row type="flex" justify="start">
                <Col span={24}>
                  <label htmlFor="cefs-name">Name</label>
                  <Form.Item
                    hasFeedback={!!errors.name}
                    validateStatus={errors.name ? 'error' : null}
                    help={errors.name}
                    style={{ marginBottom: 0 }}
                  >
                    <Input
                      name="cefs-name"
                      autoComplete="off"
                      maxLength={254}
                      onChange={handleInputChange('name')}
                      value={facet.name}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <label htmlFor="cefs-comparison-set">Comparison Set</label>
                  <Form.Item
                    style={{ marginBottom: 0 }}
                    className="Field_CompressionSet"
                  >
                    <ComparisonSetSelector
                      name="cefs-comparison-set"
                      onChange={handleSelectChange('comparisonSetId')}
                      value={facet.comparisonSetId}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={8}>
                <Col span={6}>
                  <label htmlFor="cefs-currency">Currency</label>
                  <Input
                    name="cefs-currency"
                    onChange={handleInputChange('currency')}
                    value={facet.currency}
                  />
                </Col>
                <Col span={10}>
                  <label htmlFor="cefs-units">Units</label>
                  <Input
                    name="cefs-units"
                    onChange={handleInputChange('units')}
                    value={facet.units}
                  />
                </Col>
                <Col span={8}>
                  <label htmlFor="cefs-periodicity">Periodicity</label>
                  <Select
                    name="cefs-periodicity"
                    value={facet.periodicity}
                    onChange={handleSelectChange('periodicity')}
                  >
                    {FREQUENCY.map(({ key, value }) => (
                      <Select.Option value={key} key={key}>
                        {value}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
              </Row>

              <Row gutter={8}>
                <Col span={6}>
                  <label htmlFor="cefs-futureDates">Future Dates</label>
                  <Select
                    name="cefs-periodicity"
                    value={facet.futureDates}
                    onChange={handleSelectChange('futureDates')}
                  >
                    {FUTURE_DATES.map(({ key, value }) => (
                      <Select.Option key={key} value={key}>
                        {value}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
                <Col span={10}>
                  <label htmlFor="cefs-multiplier">Multiplier</label>
                  <Select
                    name="cefs-multiplier"
                    allowClear
                    onChange={handleSelectChange('multiplier')}
                    value={facet.multiplier}
                  >
                    {NUMBERS.map((n) => (
                      <Select.Option key={n.value} value={n.value}>
                        {n.key}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
              </Row>

              <Row gutter={8} style={{ marginBottom: '16px' }}>
                <Col span={24}>
                  <div style={{ fontSize: '18px' }}>Subtitle</div>
                  <Input
                    type="text"
                    name="subtitle"
                    value={facet.subtitle}
                    onChange={handleInputChange('subtitle')}
                  />
                </Col>
              </Row>

              <Row gutter={8} style={{ marginBottom: '60px' }}>
                <Col span={24}>
                  <div style={{ fontSize: '18px' }}>Footnote</div>
                  <Input.TextArea
                    name="footnote"
                    rows={4}
                    value={facet.footnote}
                    onChange={handleInputChange('footnote')}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </div>
    </Spin>
  );
};

export default CrossEntityFacetEditor;
