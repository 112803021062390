import get from 'lodash/get';
import React, { createRef } from 'react';
import { Filters } from 'react-data-grid-addons';
import debounce from 'lodash/debounce';
import { Select } from 'antd';
import usersSelectors from '../../../../selectors/users';
import styles from './styles.module.css';
import SelectOption from '../../../../components/Avatar/SelectOption';
import { PUBLISHED } from '../../../../common/Enums/Stages';
import { ENTITY_COLUMNS } from '../../../../common/Enums/Columns';

const property = ENTITY_COLUMNS.ASSIGNEDTOID;
const propertyStage = ENTITY_COLUMNS.STAGE;

class CustomEditor extends React.PureComponent {
  refInput = createRef();

  debouncedCommit = debounce(() => this.props.onCommit(), 0);

  constructor(props) {
    super(props);
    this.state = {
      assignedToId: '',
      users: [],
    };
  }

  componentDidMount() {
    const stage = this.props.rowData[propertyStage];
    const usersAll = this.props.rowData.columnsActions.getUsers();
    const users = usersSelectors.getUsersByStatus(usersAll, stage);
    this.setState({ users, assignedToId: this.props.value });
    this.refInput.current.rcSelect.rootRef.click();
    document.addEventListener('mousedown', this.handleClick, false);
    document.addEventListener('scroll', this.handleScroll, false);
    document.addEventListener('wheel', this.handleScroll, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
    document.removeEventListener('scroll', this.handleScroll, false);
    document.removeEventListener('wheel', this.handleScroll, false);
  }

  getValue() {
    return { [property]: this.state.assignedToId };
  }

  getInputNode() {
    return this.refInput.current;
  }

  // eslint-disable-next-line react/sort-comp,class-methods-use-this
  disableContainerStyles() {}

  onChange = (assignedToId) => {
    this.setState({ assignedToId }, this.debouncedCommit);
  };

  handleClick = (e) => {
    if (this.refInput.current.rcSelect.rootRef.contains(e.target)) { return; }
    if (this.refInput.current.rcSelect.getPopupDOMNode().contains(e.target)) { return; }
    this.props.onCommit();
  };

  handleScroll = (e) => {
    if (this.refInput.current.rcSelect.getPopupDOMNode().contains(e.target)) { return; }
    this.props.onCommit();
  };

  filterOption = (input, option) => option.props.filter.toLowerCase().includes(input);

  render() {
    const { users, assignedToId } = this.state;
    return (
      <Select
        showSearch
        ref={(ref) => { this.refInput.current = ref; }}
        onChange={this.onChange}
        value={usersSelectors.isUserExists(assignedToId) ? assignedToId : null}
        className={styles.Select}
        filterOption={this.filterOption}
      >
        {users.map(
          (user) => (
            <Select.Option
              key={user.id}
              filter={user.name}
              value={user.id}
            >
              <SelectOption avatar={user.avatar} label={user.name} small />
            </Select.Option>
          ),
        )}
        {!usersSelectors.isUserExists(assignedToId, users)
        && users.length > 0
        && (
          <Select.Option
            disabled
            key={assignedToId}
            filter="Removed User"
            value={assignedToId}
          >
            Removed User
          </Select.Option>
        )}
      </Select>
    );
  }
}

const { SingleSelectFilter } = Filters;

const formatter = ({ row: { [property]: assignedToId, [propertyStage]: stage, columnsActions: { getUserById } } }) => {
  const user = getUserById(assignedToId);
  if (stage === PUBLISHED) {
    return null;
  }
  return (
    <SelectOption
      small
      isExists={!!user}
      assigned={!!assignedToId}
      avatar={get(user, 'avatar')}
      label={get(user, 'name') || get(user, 'email', '')}
    />
  );
};

const assignColumn = {
  key: property,
  name: 'Assigned',
  editable: true,
  filterable: true,
  sortable: true,
  resizable: true,
  width: 150,
  formatter,
  filterRenderer: SingleSelectFilter,
  editor: CustomEditor,
};

export default assignColumn;
