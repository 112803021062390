import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Wrapper from './Wrapper';
import {
  addFieldSet,
  addLink,
  removeFieldSet,
  removeLink,
} from '../../actions';
import authSelector from '../../../../selectors/users';

const mapStateToProps = (state, { facetType, data }) => ({
  allowDuplicate: !!(facetType.fields || facetType.facetFieldTypes).find((fieldType) => fieldType.id === data.fieldType.id),
  userRole: authSelector.getRole(authSelector.getUser(state)),
});
const mapDispatchToProps = (dispatch, props) => {
  const {
    options: {
      entityKey, sectionKey, facetKey,
    },
    data: {
      key: wrapperKey,
    },
  } = props;
  return {
    actions: bindActionCreators({
      addFieldSet: (fieldType) => addFieldSet(entityKey, sectionKey, facetKey, wrapperKey, fieldType),
      addLinkSet: (linkType) => addLink({
        linkType, facetKey, sectionKey, entityKey, wrapperKey,
      }),
      removeFieldSet: (fieldSet) => removeFieldSet(entityKey, sectionKey, facetKey, wrapperKey, fieldSet),
      removeLinkSet: (key) => removeLink(entityKey, sectionKey, facetKey, wrapperKey, key),
    }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Wrapper);
