import React from 'react';
import { Select, Row, Col } from 'antd';
import styles from './PreviewDictionary.module.css';

const { OptGroup, Option } = Select;

const PreviewDictionary = ({
  name, description, values, keys,
}) => (
  <Row className={styles.row}>
    <Row type="flex" justify="space-between">
      <Col className={styles.col}>
        <span title={name}>{`Name: ${name}`}</span>
      </Col>
      <Col className={styles.col}>
        <span title={description}>{`Description: ${description}`}</span>
      </Col>
    </Row>

    <Select className={styles.select} showSearch optionFilterProp="children">
      {values && values.map((v, i) => v && (
        <OptGroup label={keys[i]} key={keys[i]}>
          {v.map((o) => o.value && (
          <Option key={o.id} title={o.value}>
            {o.value}
          </Option>
          ))}
        </OptGroup>
      ))}
    </Select>
  </Row>
);

export default PreviewDictionary;
