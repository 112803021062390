import xml2js from 'xml2js';
import client from '../../../../common/utils/http';

const uploadFile = async (formData) => {
  const { data } = await client.post(
    '/media', `filename=${formData.get('filename')}&contentType=${formData.get('contentType')}`,
    { headers: { 'content-type': 'application/x-www-form-urlencoded' } },
  );
  const config = {
    headers:
      {
        Authorization: '',
        'cache-control': 'no-cache',
        'content-type': 'multipart/form-data',
      },
  };
  const formDataS3 = new FormData();
  Object.keys(data.params).forEach((k) => {
    formDataS3.append(k, data.params[k]);
  });
  formDataS3.append('file', formData.get('file'));
  const { data: response } = await client.post(data.endpoint_url, formDataS3, config);
  return new Promise((resolve, reject) => {
    xml2js.parseString(response, (err, result) => {
      if (err) {
        reject(err);
      } else {
        const [href] = result.PostResponse.Location;
        const [key] = result.PostResponse.Key;
        resolve({ key, href });
      }
    });
  });
};

export {
  // eslint-disable-next-line import/prefer-default-export
  uploadFile,
};
