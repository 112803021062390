import client from '../../common/utils/http';

export const PAGE_SIZE = 100;

const getQueryParams = ({
  filters, page, orderByName,
}) => {
  const params = {
    $limit: PAGE_SIZE,
    $skip: PAGE_SIZE * (page - 1),
  };

  const {
    countryName,
  } = filters;
  if (countryName) params.name = countryName.name;
  if (orderByName) {
    params['$sort[name]'] = orderByName === 'asc' ? -1 : 1;
  } else {
    params['$sort[updated_at]'] = -1;
    params['$sort[created_at]'] = -1;
  }
  return params;
};

export const fetchCountriesPage = ({
  filters, orderByName = '', pageParam = {}, type,
}) => {
  const { page = 1 } = pageParam;
  const params = getQueryParams({
    filters, orderByName, page, type,
  });
  return client.get('/countries', { params })
    .then((res) => res.data)
    .then(({ total, data }) => ({
      total,
      countries: data,
    }));
};

export const deleteCountry = (id) => client.delete(`/countries/${id}`);

export const createCountry = (country) => client.post('/countries', country);

export const updateCountry = ({ id, country }) => client.patch(`/countries/${id}`, country);
