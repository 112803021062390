import React, { useRef } from 'react';
import debounce from 'lodash/debounce';
import styled from 'styled-components';
import { Row, Input } from 'antd';

import Description from './common/Description';
import Info from './common/Info';
import withRemoveButton from './HOC/withButtonRemove';
import ElementState from '../ElementState';
import useDebounceSave from './useDebounceSave';

const { TextArea } = Input;

function Detail(props) {
  const {
    fieldType: {
      rules: {
        Field: fieldTitle,
      },
      name: fieldName,
    },
    fieldSet,
    actions,
    options,
    readonly,
  } = props;
  const { current: debounceInput } = useRef(
    debounce((fields) => actions.changeFieldSet(fields), 500),
  );

  const debounceSave = useDebounceSave({ saveField: actions.saveField, fieldSet });

  const onChangeField = (value) => {
    const fields = { [fieldSet.fields.value.name]: value };
    debounceSave(fields, () => debounceInput(fields));
  };

  const {
    rules: {
      Description: description,
      Info: info,
      Label: label,
    },
  } = fieldSet;

  return (
    <div className="facet-type-field">
      <FieldName>
        {fieldTitle || fieldName}
        <ElementState saved={fieldSet.fields.value.id} />
      </FieldName>

      <Row type="flex" justify="space-between">
        <div className="label-text">{label}</div>
        <Info info={info} />
      </Row>

      <TextArea
        autoComplete="off"
        defaultValue={fieldSet.fields.value.value}
        type="text"
        rows={5}
        onChange={({ target: { value: v } }) => onChangeField(v)}
        disabled={readonly}
      />

      <Description
        value={description}
        entityKey={options.entityKey}
      />
    </div>
  );
}

export default withRemoveButton(Detail);

const FieldName = styled.p`
  color: black;
  font-size: 18px;
  margin-bottom: 12px;
`;
