import client from '../../common/utils/http';

export const PAGE_SIZE = 100;

const getQueryParams = ({
  filters, page, orderByName,
}) => {
  const params = {
    $limit: PAGE_SIZE,
    $skip: PAGE_SIZE * (page - 1),
  };

  const {
    briefName,
    briefType,
  } = filters;
  if (briefName) params.name = briefName.name;
  if (briefType) params.type = [briefType];
  if (orderByName) {
    params['$sort[name]'] = orderByName === 'asc' ? -1 : 1;
  } else {
    params['$sort[updated_at]'] = -1;
    params['$sort[created_at]'] = -1;
  }
  return params;
};

export const fetchBriefsPage = ({
  filters, orderByName = '', pageParam = {}, type,
}) => {
  const { page = 1 } = pageParam;
  const params = getQueryParams({
    filters, orderByName, page, type,
  });
  return client.get('/briefs', { params })
    .then((res) => res.data)
    .then(({ total, data }) => ({
      total,
      briefs: data,
    }));
};

export const deleteBrief = (id) => client.delete(`/briefs/${id}`);

export const createBrief = (brief) => client.post('/briefs', brief);

export const updateBrief = ({ id, brief }) => client.patch(`/briefs/${id}`, brief);
