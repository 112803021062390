import client from '../../common/utils/http';

const IMPORT_ENDPOINTS = {
  TOPIC: '/import-topic',
  BRIEF: '/import-briefs',
  CHALLENGES: '/import-challenges-scores',
  CURRENT_THEME: '/import-current-themes',
  default: '/import-sheet',
};

const importData = async (url, type) => {
  const result = {
    status: true,
    errors: [],
  };
  const apiUrl = IMPORT_ENDPOINTS[type] || IMPORT_ENDPOINTS.default;
  const { data } = await client.post(apiUrl, { url });
  if (data.status !== 'success') {
    result.status = false;
    result.errors.push({ message: 'Sheet not found, or sheet is not available' });
    return result;
  }
  return result;
};

const uploadTheme = async (formData) => {
  const result = {
    status: true,
    errors: [],
  };

  const { data } = await client.post(IMPORT_ENDPOINTS.CURRENT_THEME, formData);
  if (data.status !== 'success') {
    result.status = false;
    result.errors.push({ message: 'Error occurred trying to import current themes sheet' });
    return result;
  }
  return result;
};

export default { importData, uploadTheme };
