import React from 'react';
import { Typography } from '@axis/xyz.admin.typography';
import styles from './Hint.module.css';

const Actors = () => (
  <div className={styles.container}>
    <Typography>
      This is a list of people, companies, government bodies, and organizations from the Axis Database that have
      been automatically identified in this piece of text. Words in grey bubbles next to the Actor&apos;s name are the
      related Keywords that were picked up in the text.
    </Typography>
    <Typography>
      Some Actors in the list may be incorrectly identified. Incorrect identification, or missed Actors that are
      present in the text, should trigger review of Axis&apos; search Keywords for the relevant Actors.
    </Typography>
  </div>
);

const Plots = () => (
  <div className={styles.container}>
    <Typography>
      This is a list of Plots from the Axis Database that have been automatically identified in this piece of text.
      Words in grey bubbles next to the Plot name are the related Keywords that were picked up in the text.
    </Typography>
    <Typography>
      Some Plots may be incorrectly identified. Incorrect identification, or missed Plots that are present in the text,
      should trigger review of Axis&apos; search Keywords for the relevant Plots.
    </Typography>
  </div>
);

const Contexts = () => (
  <div className={styles.container}>
    <Typography>
      This is a list of Contexts from the Axis Database that have been automatically identified in this piece of text.
      Words in grey bubbles next to the Context name are the related Keywords that were picked up in the text.
    </Typography>
    <Typography>
      Some Contexts in the list may be incorrectly identified. Incorrect identification, or missed Contexts that are present
      in the text, should trigger review of Axis&apos; search Keywords for the relevant Contexts.
    </Typography>
  </div>
);

const SuggestedUpdates = () => (
  <div className={styles.container}>
    <Typography>
      This is a list of additional (non-Event) updates for Axis entities (for example, Quotes, Relationships, Alerts, Regulations, etc)
      that the analyst should review and consider for inclusion in the database.
      To add a non-Event update, navigate to the entity&apos;s page in the Admin interface.
    </Typography>
    <Typography>
      Not all the suggested updates may be accurate.
    </Typography>
  </div>
);

const MachineLearningSuggestions = () => (
  <div className={styles.container}>
    <Typography>
      This is a list of briefs that could be relevant for the article.
      <br />
      Suggestions are based on the machine learning algorithms developed by Axis.
    </Typography>
    <Typography>
      Not all the suggested updates may be accurate.
    </Typography>
  </div>
);

const MachineLearningENRSuggestions = () => (
  <div className={styles.container}>
    <Typography>
      This is a list of entities that could be relevant for the article.
      <br />
      Suggestions are based on the machine learning algorithms developed by Axis.
    </Typography>
    <Typography>
      Not all the suggested updates may be accurate.
    </Typography>
  </div>
);

const Briefs = () => (
  <div className={styles.container}>
    <Typography>
      This is a list of Industry and Core Briefs from the Axis Database that have been automatically identified in this
      piece of text. Words in grey bubbles next to the Brief name are the related Keywords that were picked up in the text.
      Not all the Briefs may be correctly identified.
    </Typography>
  </div>
);

const Hint = {
  Actors,
  Plots,
  Contexts,
  SuggestedUpdates,
  Briefs,
  MachineLearningSuggestions,
  MachineLearningENRSuggestions,
};

export default Hint;
