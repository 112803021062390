import React, { useEffect, useState } from 'react';
import { Modal } from '@axis/xyz.admin.modal';
import { Typography } from '@axis/xyz.admin.typography';
import { FormItemWrapper } from '@axis/xyz.admin.form.form-item-wrapper';
import { TextInput } from '@axis/xyz.admin.form.text-input';
import { Button } from '@axis/xyz.admin.buttons.button';
import { ButtonIcon } from '@axis/xyz.admin.buttons.button-icon';
import { useQuery } from 'react-query';
import css from './ModalEditLandscape.module.css';
import { fetchLandscape } from './utils';

const Item = ({ item, onClick }) => (
  <div className={css.item}>
    <Typography className={css.itemText} help={item}>{item}</Typography>
    <ButtonIcon
      icon="close"
      help="Remove item"
      className={css.buttonRemove}
      onClick={() => onClick(item)}
    />
  </div>
);

const ModalEditLandscape = ({
  id, visible, onUpdate, onClose,
}) => {
  const [landscape, setLandscape] = useState({
    name: '',
    alternative_names: [],
  });

  const [newAlternativeName, setNewAlternativeName] = useState('');
  const [newKeyword, setNewKeyword] = useState('');

  const {
    data = {},
  } = useQuery(
    ['landscapes', id],
    () => fetchLandscape({ id }),
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    if (data && data.id) {
      setLandscape({
        name: data.name,
        alternative_names: data.alternative_names || [],
      });
    }
  }, [data]);

  useEffect(() => {
    if (!visible) {
      setLandscape({
        name: '',
        alternative_names: [],
      });
    }
  }, [visible]);

  const handleSubmit = async () => {
    const payload = {
      id,
      name: landscape.name,
      alternative_names: landscape.alternative_names || [],
    };

    onUpdate(payload);
  };

  const handleInputChange = (field, value) => {
    setLandscape({ ...landscape, [field]: value });
  };

  const handleNewAlternativeNameSubmit = () => {
    setLandscape({ ...landscape, alternative_names: [...landscape.alternative_names, newAlternativeName] });
    setNewAlternativeName('');
  };

  const handleNewAlternativeNameRemoval = (alternativeName) => {
    setLandscape({ ...landscape, alternative_names: landscape.alternative_names.filter((altName) => altName !== alternativeName) });
    setNewAlternativeName('');
  };

  const onKeyUp = (event, type) => {
    const ENTER_KEYCODE = 13;
    const { keyCode } = event;
    if (keyCode === ENTER_KEYCODE) {
      if (type === 'alternative_name') handleNewAlternativeNameSubmit();
    }
  };

  const submitEnabled = !!landscape.name;

  return (
    <Modal
      name="new-landscape"
      className={css.modal}
      visible={visible}
      onClose={onClose}
    >
      <div className={css.content}>
        <Typography
          className={css.title}
          type="title"
          tag="h2"
          size="lg"
        >
          Update landscape
        </Typography>
        <div className={css.form}>
          <FormItemWrapper
            className={css.input}
            isRequired
            label="Name"
          >
            <TextInput
              name="landscape-name"
              placeholder="Landscape name"
              value={landscape.name || ''}
              onChange={(e) => handleInputChange('name', e.target.value)}
            />
          </FormItemWrapper>

          <FormItemWrapper
            className={css.inputContainer}
            label="Alternative Names"
          >
            <TextInput
              placeholder="Alternative name"
              name="landscape-alternative-name"
              value={newAlternativeName}
              onChange={(event) => setNewAlternativeName(event.target.value)}
              onKeyUp={(event) => onKeyUp(event, 'alternative_name')}
            />
            <div className={css.itemList}>
              {landscape.alternative_names.map((alternativeName) => (
                <Item key={alternativeName} item={alternativeName} onClick={() => handleNewAlternativeNameRemoval(alternativeName)} />
              ))}
            </div>
          </FormItemWrapper>

          <Button
            className={css.submit}
            disabled={!submitEnabled}
            onClick={handleSubmit}
            label="Update"
            type="button"
          />
        </div>
      </div>
    </Modal>
  );
};

export default ModalEditLandscape;
