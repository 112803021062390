/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import isEmail from 'validator/lib/isEmail';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import startCase from 'lodash/startCase';
import get from 'lodash/get';
import {
  Input, Form, Button, Select, Spin, message,
  Checkbox, DatePicker,
} from 'antd';
import moment from 'moment';
import isNull from 'lodash/isNull';
import { ROLES, ROLE_TRIAL } from '../../common/Enums/Roles';
import * as strings from '../../common/Strings';
import { setUserDataAction } from '../Auth/actions';
import utils from './utils';
import './index.css';

const FormItem = Form.Item;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16, offset: 4 },
};

const PROFILES = ['default', 'uae', 'sap'];

const ModalEditUser = ({
  onCancel, form, authUser, user, setUserData,
}) => {
  const [state, setStateBase] = useState({
    isLoading: false,
    email: user.email,
    name: user.name,
    role: user.role || '',
    topics: user.topics || [],
    newsletter: user.newsletter || [],
    profile: user.profile || 'default',
    isBlocked: false,
    searchedTopics: [],
    defaultTopics: [],
    trialEndDate: user.trialEndDate || moment(),
  });

  const setState = (obj) => setStateBase((prev) => ({ ...prev, ...obj }));

  const resetState = () => {
    setState({
      email: '',
      name: '',
      role: '',
      topics: [],
      isBlocked: false,
      newsletter: [],
      profile: 'default',
      trialEndDate: moment(),
    });
  };

  const {
    role, email, name, isLoading, isBlocked, topics, newsletter, searchedTopics, profile, defaultTopics, trialEndDate,
  } = state;

  useEffect(() => {
    setState({ isLoading: true });
    utils.fetchTopics()
      .then((data) => {
        setState({ isLoading: false, defaultTopics: data, searchedTopics: data });
      });
  }, []);

  const onChange = (value, field) => {
    setState({ [field]: value });
  };

  const searchTopics = (text) => {
    const data = defaultTopics.filter((e) => e.name.toLowerCase().includes(text.toLowerCase()));
    setState({ searchedTopics: data });
  };

  const onSaveChanges = () => {
    if (!isEmail(email) || !name || !role.length || !profile) {
      form.validateFieldsAndScroll();
      return;
    }
    const params = {
      name,
      role,
      topics: topics || [],
      newsletter: newsletter || [],
      profile,
      email,
      trialEndDate: role === ROLE_TRIAL ? trialEndDate : null,
    };
    setState({ isLoading: true });
    utils
      .editUser(user.id, params)
      .then(() => {
        message.success('User was changed');
        if (authUser.id === user.id) {
          setUserData({ name });
        } else {
          resetState();
        }
        onCancel();
      })
      .catch((err) => {
        const msg = get(err.response, 'data.message', err.message);
        message.error(msg);
      })
      .finally(() => setState({ isLoading: false }));
  };

  const { getFieldDecorator } = form;
  const isDisabledPermissions = authUser.id === user.id;
  const buttonSaveText = user.id ? 'Save changes' : 'Invite user';

  const handleSelectAll = () => {
    const allTopicIds = searchedTopics.map((e) => e.id);
    setState({ topics: allTopicIds });
  };

  const handleDeselectAll = () => {
    setState({ topics: [] });
  };

  return (
    <Spin spinning={isLoading}>
      <Form
        className="login-form"
        style={{ width: '100%', margin: '0 auto' }}
      >
        <FormItem {...formItemLayout} label="Email: ">
          {getFieldDecorator('email', {
            initialValue: email,
            rules: [
              { type: 'email', message: strings.iuErrorInvalidEmail },
              { required: true, message: strings.iuErrorNoEmail },
            ],
          })(
            <Input
              autoComplete="off"
              name="email"
              onChange={(ev) => onChange(ev.target.value, 'email')}
              disabled={user.id}
            />,
          )}
        </FormItem>
        <FormItem {...formItemLayout} label="Name: ">
          {getFieldDecorator('name', {
            initialValue: name,
            rules: [
              { required: true, message: strings.iuErrorNoName, whitespace: true },
            ],
          })(
            <Input
              autoComplete="off"
              onChange={(ev) => onChange(ev.target.value, 'name')}
            />,
          )}
        </FormItem>
        <FormItem {...formItemLayout} label="Role: ">
          {getFieldDecorator('role', {
            initialValue: role,
            rules: [
              { required: true, message: strings.iuErrirNoPermissions },
            ],
          })(
            <Select
              value={role}
              onChange={(value) => onChange(value, 'role')}
              disabled={isDisabledPermissions}
            >
              {ROLES.map((r) => (
                <Select.Option key={r} value={r}>
                  {startCase(r)}
                </Select.Option>
              ))}
            </Select>,
          )}
        </FormItem>
        {state.role === ROLE_TRIAL
        && (
        <FormItem {...formItemLayout} label="Trial End Date: ">
          {getFieldDecorator('trialEndDate', {
            initialValue: moment(trialEndDate),
          })(
            <DatePicker
              getCalendarContainer={() => document.getElementById('trialEndDate')}
              onChange={(value) => onChange(isNull(value) ? null : value, 'trialEndDate')}
              defaultValue={moment(trialEndDate)}
              mode="date"
              format="MM/DD/YYYY"
            />,
          )}
        </FormItem>
        )}
        <FormItem {...formItemLayout} label="Profile: ">
          {getFieldDecorator('profile', {
            initialValue: profile,
            rules: [
              {
                required: true,
                message: strings.iuErrorNoProfile,
              },
            ],
          })(
            <Select
              value={profile}
              onChange={(value) => onChange(value, 'profile')}
            >
              {PROFILES.map((r) => (
                <Select.Option key={r} value={r}>
                  {startCase(r)}
                </Select.Option>
              ))}
            </Select>,
          )}
        </FormItem>
        <FormItem {...formItemLayout} label="Topics: ">
          {getFieldDecorator('topics', {
            initialValue: topics,
          })(
            <Select
              showSearch
              onSearch={(value) => searchTopics(value)}
              value={topics}
              mode="multiple"
              filterOption={false}
              onChange={(value) => onChange(value, 'topics')}
            >
              {searchedTopics.map((r) => (
                <Select.Option key={r.id} value={r.id}>
                  {startCase(r.name)}
                </Select.Option>
              ))}
            </Select>,
          )}
          <Button onClick={handleSelectAll}>Select All Topics</Button>
          <Button onClick={handleDeselectAll}>Deselect All Topics</Button>
        </FormItem>
        {/* <FormItem {...formItemLayout} label="Newsletter: ">
          {getFieldDecorator('newsletter', {
            initialValue: newsletter,
          })(
            <Select
              showSearch
              onSearch={(value) => searchTopics(value)}
              value={newsletter}
              mode="multiple"
              filterOption={false}
              onChange={(value) => onChange(value, 'newsletter')}
            >
              {searchedTopics.map((r) => (
                <Select.Option key={r.id} value={r.id}>
                  {startCase(r.name)}
                </Select.Option>
              ))}
            </Select>,
          )}
        </FormItem> */}
        <FormItem {...formItemLayout} label="Block access: ">
          {getFieldDecorator('isBlocked', {
            initialValue: isBlocked,
          })(
            <Checkbox
              checked={isBlocked}
              onChange={(ev) => onChange(ev.target.checked, 'isBlocked')}
            />,
          )}
        </FormItem>
        <FormItem {...formTailLayout}>
          <Button type="primary" onClick={onSaveChanges} block>{buttonSaveText}</Button>
        </FormItem>
        <FormItem {...formTailLayout}>
          <Button type="default" onClick={onCancel} block>
            Cancel
          </Button>
        </FormItem>
      </Form>
    </Spin>
  );
};

const mapStateToProps = (state) => ({
  authUser: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  setUserData: bindActionCreators(setUserDataAction, dispatch),
});

export default Form.create()(
  connect(mapStateToProps, mapDispatchToProps)(ModalEditUser),
);
