/* eslint-disable linebreak-style */
import React, { useState, useEffect, useRef } from 'react';
import debounce from 'lodash/debounce';
import {
  Button, Select, Input, Icon, Spin, Row, Col,
} from 'antd';
import ReactCrop from 'react-image-crop';
import styled from 'styled-components';
import 'react-image-crop/dist/ReactCrop.css';

const { Option } = Select;
const widthStyle = {
  width: '140px',
};

const dimensions = {
  Icon: {
    x: 400, y: 400, aspect: 1, type: 'Icon',
  },
  Photo: {
    x: 1000, y: 1000, aspect: 1, type: 'Photo',
  },
  freeform: { x: 0, y: 0, type: 'freeform' },
  square: {
    x: 200, y: 200, aspect: 1, type: 'square',
  },
};
const getDimensioins = (imageType) => (dimensions[imageType] ? dimensions[imageType] : dimensions.freeform);

const Crop = ({
  src, isLoading, onOk, imageType,
}) => {
  const [state, setStates] = useState({
    crop: {
      aspect: getDimensioins(imageType).aspect,
    },
    blob: '',
    type: getDimensioins(imageType),
    croppedImageUrl: '',
    imageStore: null,
  });
  const [isImageError, setIsImageError] = useState(false);
  const setState = ({ ...newState }) => { setStates((last) => ({ ...last, ...newState })); };

  const setDefaultCrop = () => setState({
    crop: {
      aspect: 1,
    },
    croppedImageUrl: '',
  });
  const { current: debouncedDefaultCrop } = useRef(debounce(setDefaultCrop, 500));

  useEffect(() => debouncedDefaultCrop, []);

  const imageRef = useRef();
  const fileUrl = useRef();

  const getCroppedImg = (image, pixelCrop, fileName) => {
    const canvas = document.createElement('canvas');
    canvas.width = pixelCrop.croppedWidth || pixelCrop.width;
    canvas.height = pixelCrop.croppedHeight || pixelCrop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.croppedWidth || pixelCrop.width,
      pixelCrop.croppedHeight || pixelCrop.height,
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        // eslint-disable-next-line no-param-reassign
        blob.name = fileName;
        fileUrl.current = window.URL.createObjectURL(blob);
        resolve({ url: fileUrl.current, blob });
      }, 'image/jpeg');
    });
  };
  const onCropComplete = async (crop, pixelCrop) => {
    if (crop.width && crop.height) {
      window.URL.revokeObjectURL(fileUrl.current);
      const { url: croppedImageUrl, blob } = await getCroppedImg(
        imageRef.current,
        pixelCrop,
        'newFile.jpeg',
      );
      setState({ croppedImageUrl, blob });
    }
  };
  const onImageLoaded = (image) => {
    imageRef.current = image;

    const { type, crop } = state;
    if (type.type === 'freeform') {
      onCropComplete(
        crop,
        {
          x: 0,
          y: 0,
          width: 100,
          height: 50,
        },
      );
    }
  };

  const onChangeSelect = (value) => {
    const type = getDimensioins(value);
    setState({
      type,
      crop: {
        aspect: type.aspect,
      },
    });
  };

  const onCropChange = (crop) => setState({ crop });

  const onOkHandler = (type, imageStore, blob) => {
    onOk(type === 'square' ? imageStore : blob);
    debouncedDefaultCrop();
  };

  const {
    croppedImageUrl, type: { type }, imageStore, crop, blob,
  } = state;

  const width = type === 'freeform' ? Math.round(crop.width) : dimensions[type].x;
  const height = type === 'freeform' ? Math.round(crop.height) : dimensions[type].y;
  const unit = 'px';

  return (
    <>
      <Spin spinning={isLoading}>
        <Row type="flex" justify="center">
          <Description>
            If this is a person, crop to a square or similar.
            Be sure to make the face horizontally centered and vertically near the top.
            Leave enough shoulders and background for context.
          </Description>
        </Row>
        <Row type="flex" justify="center">
          <Col>
            <div>Image Type</div>
            <Select
              disabled={imageType !== 'freeform'}
              style={widthStyle}
              onChange={onChangeSelect}
              value={type}
            >
              <Option value="Icon">Icon</Option>
              <Option value="Photo">Photo</Option>
              <Option value="freeform">Free Form</Option>
            </Select>
          </Col>
          <Col>
            <div>Image Crop</div>
            <Row type="flex" justify="center" align="middle">
              <Col>
                <Input
                  disabled
                  addonAfter={unit}
                  value={width || 0}
                />
              </Col>
              <Col>
                <Icon type="close" />
              </Col>
              <Col>
                <Input
                  disabled
                  addonAfter={unit}
                  value={height || 0}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <RowWrapper type="flex" justify="center" align="middle">
          <Row
            style={{ minHeight: 500, width: '100%' }}
            type="flex"
            justify="center"
            align="middle"
          >
            {
              src
                && (
                  <ReactCrop
                    src={src}
                    crop={state.crop}
                    onImageLoaded={onImageLoaded}
                    onComplete={onCropComplete}
                    onChange={onCropChange}
                    onImageError={() => setIsImageError(true)}
                  />
                )
            }
            {
              isImageError && (
                <span style={{ color: 'red' }}>
                  Something went wrong, try to upload image from your PC or use other URL
                </span>
              )
            }
          </Row>
        </RowWrapper>

        <Cropped type="flex" justify="center">
          {
            croppedImageUrl && type !== 'square' && (
              <Image
                style={dimensions[type].x ? {
                  width: dimensions[type].x,
                  height: dimensions[type].y,
                } : {}}
                alt="Crop"
                src={croppedImageUrl}
              />
            )
          }

          {
            imageStore && imageType === 'square' && (
              <>
                <Image
                  alt="cropped"
                  src={imageStore.find((image) => image.name === 'cropped').url}
                />
                <Image
                  style={{ width: 200, height: 200 }}
                  alt="200x200"
                  src={imageStore.find((image) => image.name === '200x200').url}
                />
                <Image
                  style={{ width: 50, height: 50 }}
                  alt="50x50"
                  src={imageStore.find((image) => image.name === '50x50').url}
                />
              </>
            )
          }
        </Cropped>
      </Spin>
      <Buttons>
        <Button
          type="primary"
          onClick={() => onOkHandler(type, imageStore, blob)}
          disabled={!src}
        >
          Save
        </Button>
      </Buttons>
    </>
  );
};

export default Crop;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Description = styled.p`
  width: 700px;

  font-size: 18px;
  text-align: center;
`;

const RowWrapper = styled(Row)`
  margin-top: 20px;
`;

const Cropped = styled(Row)`
  margin-top: 10px;
  border: 1px solid #d9d9d9;
`;

const Image = styled.img`
  max-width: 900px;
`;
