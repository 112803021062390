import React from 'react';

const style = {
  marginLeft: 6,
  fontSize: 14,
  fontWeight: 600,
  color: '#c0c0c0',
};

const ElementState = ({ saved }) => (
  <span style={style}>
    {saved ? '<saved>' : '<new>'}
  </span>
);

export default ElementState;
