import React, {
  useCallback, useEffect, useState, memo,
} from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { connect } from 'react-redux';
import { message } from 'antd';
import get from 'lodash/get';
import { Loading } from '@axis/xyz.admin.loading';
import EventForm from '../EventForm/EventForm';
import { getEvent, updateEvent } from './utils';
import styles from './EventPage.module.css';
import { sendEvent } from '../../contexts/AnalyticsTrackingContext/AnalyticsTrackingContext';
import usersSelector from '../../selectors/users';

const EVENT_TEMPLATE = {
  topics: [],
  entities: [],
  briefs: [],
  sources: [],
  locations: [],
  date: null,
};

const EventPage = (props) => {
  const { userRole, match: { params: { id } } } = props;
  const [event, setEvent] = useState(EVENT_TEMPLATE);
  const [isEventFormEnabled, setEventFormEnabled] = useState(true);
  const [loading, setLoading] = useState(true);
  const queryClient = useQueryClient();
  const mutateEvent = useMutation(updateEvent);

  const handleEventFormChange = useCallback((field, value) => {
    setEvent((currentEvent) => ({ ...currentEvent, [field]: value }));
  }, [setEvent]);

  const onSubmit = useCallback(() => {
    event.topics.map((t) => sendEvent('event_update', {
      description: 'Updating an event',
      eventId: event,
      eventTopic: t.name,
      eventTopicId: t.id,
    }));
    setLoading(true);
    setEventFormEnabled(false);
    mutateEvent
      .mutateAsync(event)
      .then((data) => {
        setEvent(data);
        queryClient.invalidateQueries(['event', id]);
        message.success('Event was saved');
      })
      .catch((error) => {
        const text = get(error, 'response.data.message', 'Can\'t update event');
        message.error(text, 3);
      })
      .finally(() => {
        setEventFormEnabled(true);
        setLoading(false);
      });
  }, [mutateEvent, event]);

  useEffect(() => {
    setLoading(true);
    queryClient
      .fetchQuery(['event', id], () => getEvent(id))
      .then(setEvent)
      .catch((error) => {
        const text = get(error, 'response.data.message', 'Can\'t load event');
        message.error(text, 3);
      })
      .finally(() => setLoading(false));
    return () => {};
  }, [queryClient, id]);

  return (
    <div className={styles.page}>
      <div className={styles.container}>
        {loading
          ? <Loading className={styles.loader} />
          : (
            <EventForm
              title="Edit Event"
              label="Edit Event"
              buttonLabel={isEventFormEnabled ? 'Save changes' : 'Saving...'}
              event={event}
              onChange={handleEventFormChange}
              submitEnabled={isEventFormEnabled}
              onSubmit={onSubmit}
              userRole={userRole}
            />
          )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const user = usersSelector.getUser(state);
  return {
    userRole: usersSelector.getRole(user) || '',
  };
};

export default connect(mapStateToProps)(memo(EventPage));
