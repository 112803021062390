import uniqueId from 'lodash/uniqueId';
import isFilledFieldSet from './isFilledFieldSet';

const linkDirectionToUpper = (direction = '') => {
  const fullDirectionNames = {
    to: 'TO',
    from: 'FROM',
    bi: 'BILATERAL',
  };
  return fullDirectionNames[direction.toLowerCase()] || '';
};

const generateLog = () => ({
  updated: Date.now(),
  event: 'updated',
  changed: true,
});

const cleaner = {
  Bullet: (fieldSet) => {
    const clearedFieldSet = {
      ...fieldSet,
      fields: {
        ...fieldSet.fields,
      },
      filled: false,
    };
    clearedFieldSet.fields.description.value = '';
    clearedFieldSet.fields.section.value = '';
    clearedFieldSet.fields.requirement.value = '';
    clearedFieldSet.fields.requirementSelectedId.value = '';
    clearedFieldSet.fields.sectionSelectedId.value = '';
    clearedFieldSet.componentLogs = generateLog();
    return clearedFieldSet;
  },
  Checkbox: (fieldSet) => {
    const clearedFieldSet = {
      ...fieldSet,
      fields: {
        ...fieldSet.fields,
      },
      filled: false,
    };
    clearedFieldSet.fields.checked.value = false;
    clearedFieldSet.componentLogs = generateLog();
    return clearedFieldSet;
  },
  Detail: (fieldSet) => {
    const clearedFieldSet = {
      ...fieldSet,
      fields: {
        ...fieldSet.fields,
      },
      filled: false,
    };
    clearedFieldSet.fields.value.value = '';
    clearedFieldSet.componentLogs = generateLog();
    clearedFieldSet.key = uniqueId('_fieldSet');
    return clearedFieldSet;
  },
  Date: (fieldSet) => {
    const clearedFieldSet = {
      ...fieldSet,
      fields: {
        ...fieldSet.fields,
      },
      filled: false,
    };
    clearedFieldSet.fields.dateEnd.value = '';
    clearedFieldSet.fields.dateSingle.value = '';
    clearedFieldSet.fields.dateStart.value = '';
    clearedFieldSet.fields.selectedLabel.value = '';
    clearedFieldSet.fields.selectedValue.value = '';
    clearedFieldSet.fields.dateType.value = fieldSet.rules.Date === 'select'
      ? 'range'
      : fieldSet.rules.Date;
    if (fieldSet.rules.Format === 'select') {
      clearedFieldSet.fields.format.value = 'MM/DD/YYYY';
    } else {
      clearedFieldSet.fields.format.value = fieldSet.rules.Format.toUpperCase();
    }
    if (fieldSet.rules['Event-Type'].type !== 'off') {
      clearedFieldSet.fields.selectedLabel.value = fieldSet.rules['Event-Type'].fixedValue || fieldSet.rules['Event-Type'].defaultValue;
    }
    clearedFieldSet.filled = false;
    clearedFieldSet.componentLogs = generateLog();
    return clearedFieldSet;
  },
  Importance: (fieldSet) => {
    const clearedFieldSet = {
      ...fieldSet,
      fields: {
        ...fieldSet.fields,
      },
    };
    if (fieldSet.rules.Dictionary.type !== 'off') {
      clearedFieldSet.fields.selectedLabel.value = fieldSet.rules.Dictionary.fixedValue
        || fieldSet.rules.Dictionary.defaultValue;
    }
    clearedFieldSet.fields.selectedValue.value = '';
    clearedFieldSet.filled = isFilledFieldSet(clearedFieldSet);
    clearedFieldSet.componentLogs = generateLog();
    return clearedFieldSet;
  },
  Label: (fieldSet) => {
    const clearedFieldSet = {
      ...fieldSet,
      fields: {
        ...fieldSet.fields,
      },
    };
    if (fieldSet.rules.Type.type !== 'off') {
      clearedFieldSet.fields.value.value = fieldSet.rules.Type.fixedValue
        || fieldSet.rules.Type.defaultValue;
    }
    clearedFieldSet.fields.selectedValue.value = '';
    clearedFieldSet.filled = isFilledFieldSet(clearedFieldSet);
    clearedFieldSet.componentLogs = generateLog();
    clearedFieldSet.key = uniqueId('_fieldSet');
    return clearedFieldSet;
  },
  Location: (fieldSet) => {
    const clearedFieldSet = {
      ...fieldSet,
      fields: {
        ...fieldSet.fields,
      },
    };
    if (fieldSet.rules['Location-Type'].type !== 'off') {
      clearedFieldSet.fields.selectedLocationName.value = fieldSet.rules['Location-Type'].fixedValue
        || fieldSet.rules['Location-Type'].defaultValue;
    }
    clearedFieldSet.fields.selectedLocationValue.value = '';
    clearedFieldSet.fields.lat.value = '';
    clearedFieldSet.fields.lng.value = '';
    clearedFieldSet.fields.address.value = '';
    clearedFieldSet.fields.zoom.value = 1;
    clearedFieldSet.filled = isFilledFieldSet(clearedFieldSet);
    clearedFieldSet.componentLogs = generateLog();
    return clearedFieldSet;
  },
  Media: (fieldSet) => {
    const clearedFieldSet = {
      ...fieldSet,
      fields: {
        ...fieldSet.fields,
      },
    };
    clearedFieldSet.fields.imageDetail.value = '';
    clearedFieldSet.fields.imageLabel.value = '';
    clearedFieldSet.fields.urlInput.value = '';
    clearedFieldSet.fields.sourceUrl.value = '';
    clearedFieldSet.fields.key.value = '';
    clearedFieldSet.fields.key.href = '';
    clearedFieldSet.fields.sourceKey.href = '';
    clearedFieldSet.fields.sourceKey.value = '';
    clearedFieldSet.filled = isFilledFieldSet(clearedFieldSet);
    clearedFieldSet.componentLogs = generateLog();
    return clearedFieldSet;
  },
  Provenance: (fieldSet) => {
    const clearedFieldSet = {
      ...fieldSet,
      fields: {
        ...fieldSet.fields,
      },
    };
    clearedFieldSet.fields.format.value = 'MM/DD/YYYY';
    clearedFieldSet.fields.analystNotes.value = '';
    clearedFieldSet.fields.date.value = '';
    clearedFieldSet.fields.datePublished.value = '';
    clearedFieldSet.fields.urlInput.value = '';
    clearedFieldSet.fields.key.value = '';
    clearedFieldSet.fields.key.href = '';
    clearedFieldSet.filled = isFilledFieldSet(clearedFieldSet);
    clearedFieldSet.componentLogs = generateLog();
    return clearedFieldSet;
  },
  Relationship: (fieldSet, fieldType) => ({
    ...fieldSet,
    key: uniqueId('_link'),
    id: undefined,
    description: '',
    direction: linkDirectionToUpper(fieldType.rules.Direction.fixedValue || fieldType.rules.Direction.type || ''),
    source: null,
    sourceEntityType: null,
    sourceId: null,
    sourceType: null,
    strength: (fieldType.rules.Significance.fixedValue || fieldType.rules.Significance.defaultValue || 3),
    target: null,
    targetEntityType: null,
    targetId: null,
    targetType: null,
    type: null,
  }),
  Statistic: (fieldSet) => {
    const clearedFieldSet = {
      ...fieldSet,
      fields: {
        ...fieldSet.fields,
      },
    };
    if (fieldSet.rules.Units.type !== 'off') {
      clearedFieldSet.fields.units.value = fieldSet.rules.Units.fixedValue
        || fieldSet.rules.Units.defaultValue;
    }
    if (fieldSet.rules.Compare.type !== 'off') {
      clearedFieldSet.fields.compare.value = fieldSet.rules.Compare.fixedValue
        || fieldSet.rules.Compare.defaultValue;
    }
    if (fieldSet.rules['Cross-Link'].type !== 'off') {
      clearedFieldSet.fields.crossLink.value = fieldSet.rules['Cross-Link'].fixedValue
        || fieldSet.rules['Cross-Link'].defaultValue;
    }
    if (fieldSet.rules['Rank-Total'].type !== 'off') {
      clearedFieldSet.fields.rankTotal.value = fieldSet.rules['Rank-Total'].fixedValue
        || fieldSet.rules['Rank-Total'].defaultValue;
    }
    if (fieldSet.rules['Rank-Set'].type !== 'off') {
      clearedFieldSet.fields.rankSet.value = fieldSet.rules['Rank-Set'].fixedValue
        || fieldSet.rules['Rank-Set'].defaultValue;
    }
    if (fieldSet.rules['Rank-Format'].type !== 'off') {
      clearedFieldSet.fields.rankFormat.value = fieldSet.rules['Rank-Format'].fixedValue
        || fieldSet.rules['Rank-Format'].defaultValue;
    }
    clearedFieldSet.fields.value.value = '';
    clearedFieldSet.filled = isFilledFieldSet(clearedFieldSet);
    clearedFieldSet.componentLogs = generateLog();
    return clearedFieldSet;
  },
};

const clearFieldSet = (fieldSet, fieldType) => (cleaner[fieldType.name] ? cleaner[fieldType.name](fieldSet, fieldType) : null);

export default clearFieldSet;
