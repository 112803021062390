import { useRef } from 'react';
import debounce from 'lodash/debounce';

const useDebounceSave = ({ saveField, fieldSet }) => {
  const { current: debounceSave } = useRef(
    debounce((fields, cb = () => {}) => {
      const keys = Object.keys(fields);
      keys.forEach((k) => {
        if (fieldSet.fields[k].value !== fields[k]) {
          saveField({
            ...fieldSet.fields[k],
            value: fields[k],
          });
        }
      });
      cb();
    }, 300),
  );
  return debounceSave;
};

export default useDebounceSave;
