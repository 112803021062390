import React from 'react';
import {
  Col,
  Icon,
  Popconfirm,
  Row,
} from 'antd';
import { Link } from 'react-router-dom';
import styles from './CrossEntityFacetsActions.module.css';

const CrossEntityFacetsActions = ({ facet, onDelete }) => (
  <Row type="flex" justify="center" align="middle" gutter={8}>
    <Col>
      <Link
        className={styles.link}
        to={`/analyst/elements/ceft/${facet.id}`}
      >
        <Icon type="edit" />
      </Link>
    </Col>

    <Col title="View Data">
      <Link
        className={styles.link}
        to={`/analyst/elements/cefs/${facet.id}`}
      >
        <Icon type="eye" />
      </Link>
    </Col>

    <Col>
      <Popconfirm
        id={facet.id}
        placement="topRight"
        title={`Are you sure you want to delete '${facet.name}'?`}
        onConfirm={onDelete}
        okText="Yes"
        cancelText="No"
      >
        <Icon
          className={styles.delete}
          type="delete"
        />
      </Popconfirm>
    </Col>
  </Row>
);

export default CrossEntityFacetsActions;
