/* eslint-disable no-param-reassign */
import isFilledFieldSet from './isFilledFieldSet';

const initField = {
  Checkbox: (fieldSet) => {
    fieldSet.filled = isFilledFieldSet(fieldSet);
  },
  Bullet: (fieldSet) => {
    fieldSet.filled = isFilledFieldSet(fieldSet);
  },
  Date: (fieldSet) => {
    if (fieldSet.rules.Format === 'select' && !fieldSet.fields.format.value) {
      fieldSet.fields.format.value = 'MM/DD/YYYY';
    } else if (fieldSet.rules.Format !== 'select') {
      fieldSet.fields.format.value = fieldSet.rules.Format.toUpperCase();
    }
    if (!fieldSet.fields.selectedLabel.value && fieldSet.rules['Event-Type'].type !== 'off') {
      fieldSet.fields.selectedLabel.value = fieldSet.rules['Event-Type'].fixedValue || fieldSet.rules['Event-Type'].defaultValue;
    }
    if (!fieldSet.fields.dateType.value) {
      fieldSet.fields.dateType.value = fieldSet.rules.Date === 'select'
        ? 'range'
        : fieldSet.rules.Date;
    }
    if (fieldSet.type.rules.Date !== 'select') {
      fieldSet.fields.dateType.value = fieldSet.type.rules.Date;
    }
    fieldSet.filled = isFilledFieldSet(fieldSet);
  },
  Detail: (fieldSet) => {
    // defaults not exist
    fieldSet.filled = isFilledFieldSet(fieldSet);
  },
  Importance: (fieldSet) => {
    if (!fieldSet.fields.selectedLabel.value && fieldSet.rules.Dictionary.type !== 'off') {
      fieldSet.fields.selectedLabel.value = fieldSet.rules.Dictionary.fixedValue
        || fieldSet.rules.Dictionary.defaultValue;
    }
    fieldSet.filled = isFilledFieldSet(fieldSet);
  },
  Label: (fieldSet) => {
    if (!fieldSet.fields.value.value && fieldSet.rules.Type.type !== 'off') {
      fieldSet.fields.value.value = fieldSet.rules.Type.fixedValue
      || fieldSet.rules.Type.defaultValue;
    }
    fieldSet.filled = isFilledFieldSet(fieldSet);
  },
  Location: (fieldSet) => {
    if (!fieldSet.fields.selectedLocationName.value && fieldSet.rules['Location-Type'].type !== 'off') {
      fieldSet.fields.selectedLocationName.value = fieldSet.rules['Location-Type'].fixedValue
        || fieldSet.rules['Location-Type'].defaultValue;
    }
    fieldSet.filled = isFilledFieldSet(fieldSet);
  },
  Media: (fieldSet) => {
    // defaults not exist
    fieldSet.filled = isFilledFieldSet(fieldSet);
  },
  Provenance: (fieldSet) => {
    if (!fieldSet.fields.format.value) {
      fieldSet.fields.format.value = 'MM/DD/YYYY';
    }
    fieldSet.filled = isFilledFieldSet(fieldSet);
  },
  Statistic: (fieldSet) => {
    if (!fieldSet.fields.units.value && fieldSet.rules.Units.type !== 'off') {
      fieldSet.fields.units.value = fieldSet.rules.Units.fixedValue
        || fieldSet.rules.Units.defaultValue;
    }
    if (!fieldSet.fields.compare.value && fieldSet.rules.Compare.type !== 'off') {
      fieldSet.fields.compare.value = fieldSet.rules.Compare.fixedValue
        || fieldSet.rules.Compare.defaultValue;
    }
    if (!fieldSet.fields.crossLink.value && fieldSet.rules['Cross-Link'].type !== 'off') {
      fieldSet.fields.crossLink.value = fieldSet.rules['Cross-Link'].fixedValue
        || fieldSet.rules['Cross-Link'].defaultValue;
    }
    if (!fieldSet.fields.rankTotal.value && fieldSet.rules['Rank-Total'].type !== 'off') {
      fieldSet.fields.rankTotal.value = fieldSet.rules['Rank-Total'].fixedValue
        || fieldSet.rules['Rank-Total'].defaultValue;
    }
    if (!fieldSet.fields.rankSet.value && fieldSet.rules['Rank-Set'].type !== 'off') {
      fieldSet.fields.rankSet.value = fieldSet.rules['Rank-Set'].fixedValue
        || fieldSet.rules['Rank-Set'].defaultValue;
    }
    if (!fieldSet.fields.rankFormat.value && fieldSet.rules['Rank-Format'].type !== 'off') {
      fieldSet.fields.rankFormat.value = fieldSet.rules['Rank-Format'].fixedValue
        || fieldSet.rules['Rank-Format'].defaultValue;
    }
    fieldSet.filled = isFilledFieldSet(fieldSet);
  },
  default: () => {},
};

const initFieldSet = (fieldSet) => initField[fieldSet.type.name]
    && initField[fieldSet.type.name](fieldSet);

export default initFieldSet;
