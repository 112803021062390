import { notification } from 'antd';
import validator from 'validator';
import client from '../common/utils/http';

export const fetchCountriesRelationship = async (entityId) => {
  if (!entityId || !validator.isUUID(entityId)) return { data: [] };
  return client.get(`/relationship-to-country/${entityId}`);
};

export const saveCountriesRelationship = async (entityId, countryIds, primary = false) => {
  if (!entityId || !validator.isUUID(entityId)) {
    notification.error({
      message: 'Error Creating Country Relationship',
      description: 'You cannot create country relationship with an entity that does not exist',
      duration: 5,
    });
    return {};
  }
  return client.post('/relationship-to-country', {
    entityId,
    countryIds,
    primary,
  });
};

export const fetchBriefRelationship = async (entityId) => {
  if (!entityId || !validator.isUUID(entityId)) return { data: [] };
  return client.get(`/brief-entity-relationship/${entityId}`);
};

export const saveBriefRelationship = async (entityId, briefIds) => {
  if (!entityId || !validator.isUUID(entityId)) {
    notification.error({
      message: 'Error Creating Brief Relationship',
      description: 'You cannot create brief relationship with an entity that does not exist',
      duration: 5,
    });
    return {};
  }
  return client.post('/brief-entity-relationship', {
    entityId,
    briefIds,
  });
};

export const fetchLandscapeRelationship = async (entityId) => {
  if (!entityId || !validator.isUUID(entityId)) return { data: [] };
  return client.get(`/relationship-to-landscape/${entityId}`);
};

export const saveLandscapeRelationship = async (entityId, landscapeIds) => {
  if (!entityId || !validator.isUUID(entityId)) {
    notification.error({
      message: 'Error Creating Brief Relationship',
      description: 'You cannot create brief relationship with an entity that does not exist',
      duration: 5,
    });
    return {};
  }
  return client.post('/relationship-to-landscape', {
    entityId,
    landscapeIds,
  });
};

export const fetchRelationshipTypes = async () => {
  const dictionaries = await client.get('/dictionaries?key[$in][]=ActorPerson_to_Actor&key[$in][]=ActorNonPerson_To_Actor&key[$in][]=ActorPersonNonPerson_to_ActorNonperson');

  return dictionaries.data.data.reduce((acc, dictionary) => {
    const { items } = dictionary;
    items.forEach((item) => {
      const { category, value } = item;
      if (acc[category]) {
        acc[category].push(value);
      } else {
        acc[category] = [value];
      }
    });
    return acc;
  }, {});
};

export const fetchEntityRelationships = async (entityId) => {
  if (!entityId || !validator.isUUID(entityId)) return { data: [] };
  return client.get(`/relationship-to-entities/${entityId}`);
};

export const deleteEntityRelationship = async (entityId, targetId, type) => {
  if (!targetId || !validator.isUUID(targetId) || !entityId || !validator.isUUID(entityId) || !type) return {};
  return client.delete(`/relationship-to-entities/${entityId}?targetId=${targetId}&type=${type}`);
};

export const createEntityRelationship = async (entityId, targetId, type, strength) => {
  if (!targetId || !validator.isUUID(targetId) || !entityId || !validator.isUUID(entityId) || !type) return {};
  return client.post('/relationship-to-entities', {
    sourceId: entityId,
    targetId,
    type,
    strength,
  });
};
