import {
  dayDifference, generateDate, parseDateIgnoreTZ,
} from '../../common/utils/date';
import client from '../../common/utils/http';

export const PAGE_SIZE = 100;

const getQueryParams = ({
  filters, page, orderByName, type,
}) => {
  const params = {
    $limit: PAGE_SIZE,
    $skip: PAGE_SIZE * (page - 1),
    type,
  };

  const {
    sourceName,
    topic,
  } = filters;
  if (topic) params.topicId = topic.id;
  if (sourceName) params.name = sourceName.name;
  if (orderByName) params.orderByName = orderByName;
  return params;
};

export const getRSSSourceStatus = ({ lastUpdated, createdAt, updatedAt }) => {
  if (createdAt === updatedAt) return 'new';
  if (lastUpdated < 7) return 'healthy';
  if (lastUpdated < 60) return 'warning';
  return 'danger';
};

export const fetchRSSSourcesPage = ({
  filters, orderByName = '', pageParam = {}, type,
}) => {
  const { page = 1 } = pageParam;
  const params = getQueryParams({
    filters, orderByName, page, type,
  });
  return client.get('/rss-sources', { params })
    .then((res) => res.data)
    .then(({ total, data }) => ({
      total,
      sources: data.map((source) => ({
        id: source.id,
        language: source.language,
        name: source.name,
        websiteUrl: source.websiteUrl,
        type: source.type,
        topics: source.topics && (source.topics.map((topic) => topic.topicName)).join(', '),
        createdAt: source.createdAt,
        updatedAt: source.updatedAt,
        lastUpdated: dayDifference(generateDate(), parseDateIgnoreTZ(source.updatedAt)),
      })),
    }));
};

export const deleteRSSSource = (id) => client.delete(`/rss-sources/${id}`);

export const createRSSSource = (source) => client.post('/rss-sources', source);

export const updateRSSSource = ({ id, source }) => client.patch(`/rss-sources/${id}`, source);
