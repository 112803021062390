import includes from 'lodash/includes';

const parseDictionaryField = (str, name) => {
  const obj = {
    name,
    type: 'text',
    fixedValue: null,
    defaultValue: null,
    mode: null,
    key: null,
    complexField: true,
  };
  const fullDirectionNames = {
    to: 'TO',
    from: 'FROM',
    bi: 'BILATERAL',
    off: 'off',
  };
  const relationshipSimpleValues = ['to', 'from', 'bi'];

  if (str === 'off' || includes(relationshipSimpleValues, str)) {
    obj.type = fullDirectionNames[str];
    return obj;
  }

  if (name === 'Allow-Link-To') {
    if (str.match(/(^Brief)(\('(.*?)'\))/)) {
      const [, entityType, , briefValue] = str.match(/(^Brief)(\('(.*?)'\))/);
      obj.entityType = entityType;
      obj.briefValue = briefValue;
      return obj;
    }

    obj.entityType = str;
    return obj;
  }
  if (name === 'Type' && str === 'textarea') {
    obj.type = 'textarea';
  }
  if (str.match(/,*\s*default\('(.+)'\)$/)) {
    const [, defaultValue] = str.match(/,*\s*default\('(.+)'\)$/);
    obj.defaultValue = defaultValue;
  }
  if (str.match(/(^fixed)(\('(.*?)'\))/)) {
    const [,,, fixedValue] = str.match(/(^fixed)(\('(.*?)'\))/);
    obj.type = 'fixed';
    obj.fixedValue = fixedValue;
    return obj;
  }
  // remove after editing all templates
  const matchOne = str.match(/(^dictionary)(\('(.+)'\))/);
  if (matchOne) {
    const [,,, dictionaryKey] = matchOne;
    obj.type = 'dictionary';
    obj.key = dictionaryKey;
    obj.mode = 'fixed';
  }
  const match = str.match(/(^dictionary)(\('(.+)'\))(,)(\s*)(\w+)/);
  if (match) {
    const [, , , dictionaryKey, , , dictionaryMode] = match;
    obj.type = 'dictionary';
    obj.key = dictionaryKey;
    obj.mode = dictionaryMode || 'fixed';
  }
  return obj;
};

const parseRules = (template) => {
  const { rules = {} } = template;
  const names = [
    'Type', 'Section', 'Requirement', 'Location-Type', 'Rank-Set', 'Rank-Total', 'Rank-Format', 'Compare',
    'Units', 'Cross-Link', 'Event-Type', 'Location-Type',
    'Relationship-Type-to',
    'Relationship-Type-from',
    'Relationship-Type-bi',
    'Dictionary',
    'Direction',
    'Significance',
    'Allow-Link-To',
  ];
  const dictFields = Object.keys(rules);
  const parsed = dictFields.map((e) => {
    if (names.includes(e)) {
      return parseDictionaryField(rules[e], e);
    }
    return {
      name: e,
      value: rules[e],
      complexField: false,
    };
  });
  const result = {
    dictionaries: {},
  };
  parsed.forEach((e) => {
    if (e.complexField) {
      result[e.name] = { ...e };
      result.dictionaries[e.key] = e.key;
    } else {
      result[e.name] = e.value;
    }
    delete result.complexField;
  });
  return result;
};

export default parseRules;
