export const dpCreateDictionarySuccess = 'Dictionary has been created'; // admin / dictionaries
export const dpUpdateDictionarySuccess = 'Dictionary has been updated'; // admin / dictionaries
export const seUpdateEntDataSuccess = 'Template has been updated'; // admin / editor
export const seSaveEntDataSuccess = 'Template has been created'; // admin / editor
export const seInviteUserSuccess = 'User has been invited'; // admin / users
export const seDeleteUserSuccess = 'User has been deleted'; // admin / users
export const seUserCreatedSuccess = 'User has been created.';
export const seResetError = 'Something went wrong...'; // request for reset password / send new pass
export const seUserCreatedError = 'Something went wrong...'; // request for reset password / send new pass
export const iuErrorInvalidEmail = 'Invalid Email';
export const iuErrorNoName = 'Enter Name';
export const iuErrorNoEmail = 'Enter Email';
export const iuErrorNoPassword = 'Enter Password';
export const iuErrorNoPermissions = 'Enter Permissions';
export const iuErrorNoProfile = 'Enter Profile';
export const seCreateEntData = 'Create Entity'; // admin / editor
export const seUpdateEntData = 'Update Entity'; // admin / editor
export const seLoadEntities = 'Load Templates'; // admin / templates
export const seRemoveEntities = 'Remove Entity'; // admin / templates
export const seDeleteUserError = 'Delete User'; // admin / users
export const seInviteUserError = 'Invite Users'; // admin / users
export const ftValidateErrorNoType = 'Field `type` is required for every Entity';
export const ftValidateErrorNoContent = 'Field `content` is required for every Entity';
export const ftSuccessValidate = 'Success';
export const ftInvalidJSON = 'Invalid JSON';
export const ftSaveError = 'Can not save';
export const ftSaveTemplate = 'Save template?';
export const ftDictionaryIsNotFound = 'Dictionary is not found:';
export const ftInvalidEntityFormat = 'Invalid Entity Format';
