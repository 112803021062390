/* eslint-disable linebreak-style */
import React from 'react';
import {
  Form, Icon, Input, Button, Modal,
} from 'antd';
import * as strings from '../../../common/Strings';

const FormItem = Form.Item;

class NormalLoginForm extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.authorization(values);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { resetPassword } = this.props;
    return (
      <Modal
        visible
        footer={null}
        closable={false}
        centered
        maskStyle={{
          backgroundColor: '#f5f5f5',
        }}
      >
        <Form
          onSubmit={this.handleSubmit}
          className="login-form"
          style={{ width: 400, margin: '0 auto', paddingTop: 24 }}
        >
          <FormItem>
            {getFieldDecorator('email', {
              rules: [{ required: true, message: strings.authErrorNoEmail }],
            })(
              <Input
                autoComplete="off"
                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Email"
              />,
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator('password', {
              rules: [{ required: true, message: strings.authErrorNoPassword }],
            })(
              <Input
                autoComplete="off"
                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                type="password"
                placeholder="Password"
              />,
            )}
          </FormItem>
          <FormItem>
            <Button htmlType="submit" className="login-form-button axisPrimaryButton" style={{ width: '100%' }}>
              Sign In
            </Button>
          </FormItem>
          {resetPassword && (
            <FormItem>
              <Button className="login-form-button axisDefaultButton" onClick={resetPassword} style={{ width: '100%' }}>
                Reset Password
              </Button>
            </FormItem>
          )}
        </Form>
      </Modal>
    );
  }
}

const WrappedNormalLoginForm = Form.create()(NormalLoginForm);

export default WrappedNormalLoginForm;
