import React from 'react';

import helpers from '../../helpers';

const SourceLink = ({ source, text }) => <a href={source} title={text} target="_blank" rel="noopener noreferrer">{text}</a>;

const formatter = ({ row: { sources } }) => {
  const arr = sources.split(',').filter((s) => s).map((s, i) => {
    const source = s.trim();
    return {
      key: i,
      source,
      text: helpers.getHost(source),
    };
  });
  return (
    <div>
      {arr.map((e, i) => (
        <React.Fragment key={e.key}>
          <SourceLink source={e.source} text={e.text} />
          {i !== arr.length - 1 && <span>, </span>}
        </React.Fragment>
      ))}
    </div>
  );
};

const sourcensColumn = {
  key: 'sources',
  name: 'Sources',
  filterable: true,
  sortable: false,
  resizable: true,
  editable: false,
  width: 200,
  formatter,
  draggable: true,
};

export default sourcensColumn;
