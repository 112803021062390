import moment from 'moment';
import get from 'lodash/get';
import find from 'lodash/find';
import filter from 'lodash/filter';
import join from 'lodash/join';
import compact from 'lodash/compact';

const getFacetLabel = ({ facet, fieldSets, facetType }) => {
  let label = '';
  const fieldSetLabel = fieldSets.find((fieldSet) => fieldSet.type.name === 'Label' && fieldSet.type.required);
  if (fieldSetLabel) {
    label = fieldSetLabel.fields.value.value;
  }

  if (!label) {
    if (facetType.name === 'Statistic') {
      label = get(facet, 'crossEntityFacetType.name');
    }

    if (facetType.name === 'Quote') {
      const { links, fields } = facet;
      const speakerLink = find(links, ['facetFieldType.rules.Tag', 'speaker']);
      const speaker = get(speakerLink, 'targetEntity.name', '') || '';
      const authorField = find(filter(fields, ['name', 'value']), ['type.rules.Tag', 'author']);
      const author = get(authorField, 'value', '') || '';

      label = speaker || author;
    }

    if (facetType.name === 'Location') {
      const { fields } = facet;
      const locationField = find(filter(fields, ['name', 'value']), ['type.rules.Tag', 'name']);
      const locationName = get(locationField, 'value', '') || '';

      label = locationName;
    }

    if (facetType.name === 'Lifespan') {
      const { fields } = facet;
      const dateTypeField = find(fields, ['name', 'dateType']);
      const dateType = get(dateTypeField, 'value');
      const formatField = find(filter(fields, ['name', 'format']), ['type.rules.Tag', 'date']);
      const format = get(formatField, 'value');

      if (dateType === 'start') {
        const dateStartField = find(fields, ['name', 'dateStart']);
        const dateStart = get(dateStartField, 'value', '')
          ? moment(get(dateStartField, 'value')).format(format)
          : '';

        label = dateStart || '';
      } else if (dateType === 'single') {
        const dateSingleField = find(fields, ['name', 'dateSingle']);
        const dateSingle = get(dateSingleField, 'value', '')
          ? moment(get(dateSingleField, 'value')).format(format)
          : '';

        label = dateSingle || '';
      } else if (dateType === 'end') {
        const dateEndField = find(fields, ['name', 'dateEnd']);
        const dateEnd = get(dateEndField, 'value', '')
          ? moment(get(dateEndField, 'value')).format(format)
          : '';

        label = dateEnd || '';
      } else {
        const dateStartField = find(fields, ['name', 'dateStart']);
        const dateEndField = find(fields, ['name', 'dateEnd']);
        const dateStart = get(dateStartField, 'value', '')
          ? moment(get(dateStartField, 'value')).format(format)
          : '';
        const dateEnd = get(dateEndField, 'value', '')
          ? moment(get(dateEndField, 'value')).format(format)
          : '';

        label = join(compact([dateStart, dateEnd]), ' - ');
      }
    }
  }

  if (facetType.name === 'Stock Ticker') {
    const { fields } = facet;
    const tickerField = find(filter(fields, ['name', 'value']), ['type.rules.Tag', 'ticker']);
    const ticker = get(tickerField, 'value', '') || '';

    label = ticker || '';
  }

  if (facetType.name === 'Education') {
    const { fields } = facet;
    const educationFields = filter(fields, ['name', 'value']);
    const degree = get(find(educationFields, ['type.rules.Tag', 'degree']), 'value', '') || '';
    const institution = get(find(educationFields, ['type.rules.Tag', 'inst']), 'value', '') || '';

    label = join(compact([degree, institution]), ', ');
  }

  if (facetType.name === 'Activity') {
    const { fields } = facet;
    const activityFields = filter(fields, ['name', 'value']);
    const type = get(find(activityFields, ['type.rules.Tag', 'type']), 'value', '') || '';
    const organization = get(find(activityFields, ['type.rules.Tag', 'org']), 'value', '') || '';

    label = join(compact([type, organization]), ', ');
  }

  return label;
};

export default getFacetLabel;
