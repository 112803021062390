import client from '../../common/utils/http';
import { convertDate, formatDateISO, parseDateIgnoreTZ } from '../../common/utils/date';
import { uploadFile } from '../EntityEditor/components/Field/mediaHelpers';

const processEventDataFromResponse = ({ data }) => ({
  ...data,
  entities: data.entities.map((entity) => ({
    id: entity.entityId,
    name: entity.entityName,
    topicId: entity.topicId,
    topicName: entity.topicName,
  })),
  date: parseDateIgnoreTZ(data.date).toJSDate(),
});

const getEvent = (id) => client
  .get(`/events/${id}`)
  .then(processEventDataFromResponse);

export const getAttachments = async (data) => {
  const attachments = data.attachments || [];
  const newAttachments = attachments.filter((value) => !value.source);
  const oldAttachments = attachments.filter((value) => value.source);
  const loaded = await Promise.all(newAttachments.map((value) => uploadFile(value.formData)));

  return [
    ...oldAttachments.map((file) => ({ key: file.key, name: file.name })),
    ...loaded.map((file, index) => ({ key: file.key, name: newAttachments[index].key })),
  ];
};

export const processEventData = async (data) => ({
  title: data.title,
  description: data.description,
  topics: data.topics.map((t) => t.id),
  locations: data.locations,
  date: formatDateISO(convertDate(data.date)),
  entityIds: data.entities.map((e) => e.id),
  briefIds: data.briefs.map((b) => b.id),
  sources: data.sources,
  attachments: await getAttachments(data),
});

const updateEvent = async (data) => {
  const { id } = data;

  const event = await processEventData(data);

  return client
    .patch(`/events/${id}`, event)
    .then(processEventDataFromResponse);
};

export {
  getEvent,
  updateEvent,
};
