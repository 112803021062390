import React, { useEffect, useState } from 'react';
import {
  Button,
  message,
  Spin,
  Table,
} from 'antd';
import { Link } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import SearchColumn from '../../../components/SearchColumn/SearchColumn';
import CrossEntityFacetsPagination from '../components/CrossEntityFacetsPagination/CrossEntityFacetsPagination';
import CrossEntityFacetsActions from '../components/CrossEntityFacetsActions/CrossEntityFacetsActions';
import { getPeriod, getQueryParams } from '../utils';
import client from '../../../common/utils/http';

const columnsFactory = ({ onDelete, onChangeFilterName }) => [
  {
    key: 'actions',
    title: 'Actions',
    width: 100,
    render: (facet) => (
      <CrossEntityFacetsActions
        facet={facet}
        onDelete={() => onDelete(facet.id)}
      />
    ),
  },
  {
    key: 'name',
    title: (
      <SearchColumn
        onClickSearch={(name) => onChangeFilterName(name)}
        label="Name"
        style={{ width: '100%' }}
      />
    ),
    dataIndex: 'name',
  },
  {
    dataIndex: 'comparisonSet.name',
    title: 'Comparison Set',
    key: 'comparisonSet',
  },
  {
    dataIndex: 'periodicity',
    title: 'Periodicy',
    key: 'periodicy',
    render: getPeriod,
  },
  {
    dataIndex: 'currency',
    title: 'Currency',
    key: 'currency',
  },
  {
    dataIndex: 'units',
    title: 'Unit',
    key: 'unit',
  },
];

const CrossEntityFacetsTable = () => {
  const queryClient = useQueryClient();

  const [query, setQuery] = useState({
    filter: { name: '' },
    page: 1,
  });

  const { isLoading, isError, data: result } = useQuery(
    ['cross-entity-facet-types', query],
    () => {
      const params = getQueryParams(query);
      return client
        .get('/cross-entity-facet-types', { params })
        .then((res) => res.data);
    },
    { keepPreviousData: true },
  );

  const cefDelete = useMutation((id) => client.delete(`/cross-entity-facet-types/${id}`));

  const handleCEFDelete = (id) => {
    cefDelete.mutate(id, {
      onSuccess: () => {
        queryClient.invalidateQueries('cross-entity-facet-types');
      },
      onError: () => {
        message.error('There was an error deleting the cross entity facet');
      },
    });
  };

  useEffect(() => {
    if (!isError) return;
    message.error('There was an error fetching the Cross Entity Facets');
  }, [isError]);

  useEffect(() => () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  const changePage = (newPage) => {
    setQuery((current) => ({
      ...current,
      page: newPage,
    }));
  };

  const updateFilter = (searchName) => {
    setQuery((current) => ({
      ...current,
      filter: { ...current.filter, name: searchName },
    }));
  };

  return (
    <Spin spinning={isLoading}>
      <div className="CrossEntityFacetsTable">
        <CrossEntityFacetsPagination
          page={query.page}
          total={result && result.total}
          onPageChange={changePage}
        >
          <Link to="/analyst/elements/ceft/new">
            <Button type="primary">Create Cross-Entity Facet Type</Button>
          </Link>
        </CrossEntityFacetsPagination>

        <Table
          id="DictionariesTable"
          rowKey="id"
          size="small"
          columns={columnsFactory({
            onDelete: handleCEFDelete,
            onChangeFilterName: updateFilter,
          })}
          pagination={false}
          dataSource={result && result.data}
          bordered
        />

        <CrossEntityFacetsPagination
          page={query.page}
          total={result && result.total}
          onPageChange={changePage}
        />
      </div>
    </Spin>
  );
};

export default CrossEntityFacetsTable;
