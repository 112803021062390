export const getFormErrors = (event) => {
  const errors = [];
  if (!event.topics || event.topics.length === 0) errors.push({ field: 'topics', message: 'At least one topic is required' });
  if (!event.title) errors.push({ field: 'title', message: 'The title is required' });
  if (!event.entities || event.entities.length === 0) errors.push({ field: 'entities', message: 'At least one entity is required' });
  if (!event.briefs || event.briefs.length === 0) errors.push({ field: 'briefs', message: 'At least one brief is required' });
  if (!event.date) errors.push({ field: 'date', message: 'The event date is required' });
  const hasSources = event.sources && event.sources.length > 0;
  const hasAttachments = event.attachments && event.attachments.length > 0;
  if (!hasSources && !hasAttachments) {
    errors.push({ field: 'sources', message: 'One source or attachment is required' });
    errors.push({ field: 'attachments', message: 'One source or attachment is required' });
  }
  return errors;
};
