const isFilledFieldSet = (fieldSet) => {
  const checker = {
    Checkbox: (fields) => !!fields.checked,
    Bullet: (fields) => !!(fields.requirement.value && fields.section.value && fields.description),
    Date: (fields) => {
      if (fields.dateType.value === 'range'
        && !(!!fields.dateStart.value && !!fields.dateEnd.value)) return false;
      if (fields.dateType.value === 'single' && !fields.dateSingle.value) return false;
      if (fields.dateType.value === 'start' && !fields.dateStart.value) return false;
      if (fields.dateType.value === 'end' && !fields.dateEnd.value) return false;
      if (!fields.format.value) return false;
      if (fieldSet.rules['Event-Type'].type !== 'off' && !fields.selectedLabel.value) return false;
      return true;
    },
    Detail: (fields) => fields.value.value !== '<p><br></p>' && !!fields.value.value,
    Importance: (fields) => !!fields.selectedLabel.value,
    Label: (fields) => !(!fields.value.value && fieldSet.rules.Type.type !== 'off'),
    Location: (fields) => {
      if (!fields.selectedLocationName.value && fieldSet.rules['Location-Type'].type !== 'off') return false;
      return !!(fields.lat.value && fields.lng.value && fields.address.value);
    },
    Media: (fields) => !!(fields.key.value),
    Provenance: (fields) => !!(fields.key.value || fields.urlInput.value),
    Statistic: () => true,
  };
  return checker[fieldSet.type.name]
    ? checker[fieldSet.type.name](fieldSet.fields)
    : true;
};

export default isFilledFieldSet;
