import client from '../../common/utils/http';
import {
  formatDateRelative, isSameDay, parseDate, parseDateIgnoreTZ, startOfDay,
} from '../../common/utils/date';

export const getArticlesInfo = (articlesTotal) => {
  if (articlesTotal === 1) return '1 article';
  return `${articlesTotal} articles`;
};

const getQueryParams = ({ ids }) => ({
  $limit: ids.length,
  $skip: 0,
  articleIds: ids,
});

export const fetchSelectedArticles = ({ ids }) => {
  const params = getQueryParams({ ids });
  return client
    .get('/articles', { params })
    .then((res) => res.data.data)
    .then((articles) => articles.map((article) => ({
      ...article,
      date: parseDateIgnoreTZ(article.date),
    })));
};

export const processArticles = ({ selectedArticles = [], selectedIds = [] }) => {
  const groupedArticles = selectedArticles
    .filter((article) => selectedIds.includes(article.id))
    .reduce((articles, article) => {
      const articleDate = parseDate(article.date);
      const EMPTY_DATE = { date: startOfDay(parseDate(articleDate)), articles: [] };
      const value = articles.find((e) => isSameDay(e.date, articleDate)) || EMPTY_DATE;
      value.articles = [
        ...(value.articles || []),
        article,
      ];
      return [
        ...articles.filter((e) => !isSameDay(e.date, articleDate)),
        value,
      ];
    }, []);
  const articlesList = groupedArticles
    .sort((group1, group2) => group2.date - group1.date)
    .map((group) => ({ ...group, date: formatDateRelative(parseDate(group.date)) }));
  return articlesList;
};

export const getArticleTags = (article) => [
  ...(article.actors || []).map((a) => a.name),
  ...(article.plots || []).map((a) => a.entityName),
  ...(article.contexts || []).map((a) => a.entityName),
];
