import React from 'react';
import { Modal, List, Button } from 'antd';

const { Item } = List;

function ModalAddFacet({
  visible, onCancel, additions, addFacet,
}) {
  return (
    <Modal
      title="You can add the following facets at this point:"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>Cancel</Button>,
      ]}
    >
      <List
        bordered
        size="small"
        dataSource={additions}
        renderItem={(item) => (
          <Item
            actions={[
              <Button
                size="small"
                icon="plus"
                onClick={() => addFacet(item)}
              />,
            ]}
          >
            {item.name}
          </Item>
        )}
      />
    </Modal>
  );
}

export default ModalAddFacet;
