import React, { useState, useEffect, useRef } from 'react';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import styled from 'styled-components';
import { Row, Input } from 'antd';

import withRemoveButton from './HOC/withButtonRemove';
import ElementState from '../ElementState';
import PropertyType from './PropertyType';
import useDebounceSave from './useDebounceSave';

const { TextArea } = Input;

const Bullet = (props) => {
  const {
    fieldType: {
      rules: {
        Field: fieldTitle,
      },
      name: fieldName,
    },
    fieldSet,
    fieldSet: {
      rules: {
        Label: label,
      },
    },
    actions,
    readonly,
  } = props;

  const [description, setDescription] = useState(get(fieldSet, 'fields.description.value', ''));
  const { current: debounceInput } = useRef(
    debounce((fields) => actions.changeFieldSet(fields), 500),
  );
  const saveDescription = useDebounceSave({ saveField: actions.saveField, fieldSet });
  const saveSection = useDebounceSave({ saveField: actions.saveField, fieldSet });
  const saveRequirement = useDebounceSave({ saveField: actions.saveField, fieldSet });

  useEffect(() => {
    if (!fieldSet.fields.description.value) {
      setDescription(fieldSet.fields.description.value);
    }
  }, [fieldSet.fields.description.value]);

  const onChangeField = (value, name) => {
    if (name === fieldSet.fields.description.name) {
      setDescription(value);
    }
    const fields = { [name]: value };
    saveDescription(fields, () => debounceInput(fields));
  };

  const {
    rules: {
      Section,
      Requirement,
    },
  } = fieldSet;

  return (
    <div className="facet-type-field">
      <FieldName>
        {fieldTitle || fieldName}
        <ElementState saved={fieldSet.fields.description.id} />
      </FieldName>
      <Row type="flex" justify="space-between">
        <div className="label-text">{label}</div>
      </Row>

      <Row type="flex" justify="space-between">
        <div className="label-text">Section name</div>
      </Row>

      <PropertyType
        name={fieldSet.fields.section.name}
        rules={{ type: Section }}
        onChange={(fields) => {
          saveSection(
            fields,
            () => actions.changeFieldSet(fields),
          );
        }}
        fieldSelectedId={fieldSet.fields.sectionSelectedId}
        fieldValue={fieldSet.fields.section}
        readonly={readonly}
      />

      <Row type="flex" justify="space-between">
        <div className="label-text">Requirement</div>
      </Row>

      <PropertyType
        name={fieldSet.fields.requirement.name}
        rules={{ type: Requirement }}
        onChange={(fields) => {
          saveRequirement(
            fields,
            () => actions.changeFieldSet(fields),
          );
        }}
        fieldSelectedId={fieldSet.fields.requirementSelectedId}
        fieldValue={fieldSet.fields.requirement}
        readonly={readonly}
      />

      <Row type="flex" justify="space-between">
        <div className="label-text">Description</div>
      </Row>

      <TextArea
        autoComplete="off"
        value={description}
        type="text"
        rows={5}
        name={fieldSet.fields.description.name}
        onChange={({ target: { value: v, name } }) => onChangeField(v, name)}
        disabled={readonly}
      />
    </div>
  );
};

export default withRemoveButton(Bullet);

const FieldName = styled.p`
  color: black;
  font-size: 18px;
  margin-bottom: 12px;
`;
