/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React, { useRef, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { Button } from '@axis/xyz.admin.buttons.button';
import { ArticleSummary } from '@axis/xyz.admin.article-summary';
import { ContentSeparator } from '@axis/xyz.admin.content-separator';
import { CheckboxCircle } from '@axis/xyz.admin.form.checkbox-circle';
import { Typography } from '@axis/xyz.admin.typography';
import { useHistory } from 'react-router-dom';
import { ButtonText } from '@axis/xyz.admin.buttons.button-text';
import { STORAGE_ADMIN_FEED_ARTICLES } from '../../common/utils/storage';
import ConfirmModal from '../../components/ConfirmModal/ConfirmModal';
import EndOfContent from '../../components/EndOfContent/EndOfContent';
import ArticleModal from '../../components/Article/Article';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import {
  fetchArticlesPage, getArticlesInfo, getArticleTags, getFilterCount, getFilterDescription, processArticles,
} from './utils';
import { formatDateTime, parseDate } from '../../common/utils/date';
import css from './ArticlesFeedList.module.css';
import ModalEditFeedFilters from '../../modals/ModalEditFeedFilters/ModalEditFeedFilters';
import useLocalStorage from '../../hooks/useLocalStorage';

const ArticleSeparator = ({ index, mainText, secondaryText }) => {
  if (index !== 0) return <hr className={css.separator} />;
  return <ContentSeparator mainText={mainText} secondaryText={secondaryText} />;
};

const ArticlesFeedList = () => {
  const history = useHistory();

  const [isFiltersOpen, setFiltersOpen] = useState(false);

  const [filters, setFilters] = useState({
    since: new Date().getTime(),
    date: null,
    topicId: '',
    sectorBriefs: [],
    subsectorBriefs: [],
    industryBriefs: [],
  });

  const totalFilters = getFilterCount(filters);
  const filterDescription = getFilterDescription(filters);

  const [selectedArticles, setSelectedArticles] = useLocalStorage(STORAGE_ADMIN_FEED_ARTICLES, []);

  const [modalDetails, setModalDetails] = useState({ open: false, article: null });

  const loadNextRef = useRef();

  const {
    data: articles = {},
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ['articles', filters],
    ({ pageParam }) => fetchArticlesPage({
      topicId: filters.topicId,
      since: filters.since,
      date: filters.date,
      briefIds: [
        ...filters.sectorBriefs.map((brief) => brief.briefIds).flat(),
        ...filters.subsectorBriefs.map((brief) => brief.briefIds).flat(),
        ...filters.industryBriefs.map((brief) => brief.briefIds).flat(),
      ],
      pageParam,
    }),
    {
      getNextPageParam: (lastPage, pages) => (lastPage.hasMore ? ({ page: pages.length + 1 }) : undefined),
    },
  );

  useIntersectionObserver({
    target: loadNextRef,
    onIntersect: fetchNextPage,
    enabled: hasNextPage,
  });

  const handleArticleCheckboxClick = (article) => {
    setSelectedArticles((currentArticles) => {
      if (currentArticles.includes(article.id)) {
        return currentArticles.filter((e) => e !== article.id);
      }
      return [...currentArticles, article.id].sort();
    });
  };

  const handleArticleModalConfirm = () => {
    setSelectedArticles((currentArticles) => {
      if (currentArticles.includes(modalDetails.article.id)) return currentArticles;
      return [...currentArticles, modalDetails.article.id];
    });
    setModalDetails((currentDetails) => ({ ...currentDetails, open: false }));
  };

  const groupedArticles = processArticles(articles.pages || []);
  return (
    <>
      <div className={css.container}>
        <section className={css.header}>
          <div className={css.pills} />
          <div className={css.actions} />
        </section>
        <section>
          <div className={css.actions}>
            <Typography
              className={css.title}
              tag="h1"
              type="title"
              size="xl"
            >
              Latest articles
            </Typography>
            <div className={css.buttons}>
              <ButtonText
                data-cy="feed-manually-create"
                label="Manually create"
                className={css.buttonSecondary}
                onClick={() => history.push('/analyst/feed/manually-create')}
              />
              <Button
                className={css.buttonPrimary}
                data-cy="feed-review"
                label="Review"
                disabled={selectedArticles.length === 0}
                badge={`${selectedArticles.length}`}
                onClick={() => history.push('/analyst/feed/review')}
              />
              <Button
                className={css.buttonPrimary}
                data-cy="feed-create"
                label="Create"
                disabled={selectedArticles.length === 0}
                badge={`${selectedArticles.length}`}
                onClick={() => history.push('/analyst/feed/create')}
              />
            </div>
          </div>
          <hr className={css.titleSeparator} />
          <div className={css.filters}>
            <p className={css.filterDescription}>
              {filterDescription}
            </p>
            <ButtonText
              className={css.filterEdit}
              type="button"
              color="primary"
              label={totalFilters > 0 ? `Edit filters (${totalFilters})` : 'Filter articles'}
              onClick={() => setFiltersOpen(true)}
            />
          </div>
          {groupedArticles.map((articlesDate) => (
            <React.Fragment key={articlesDate.date}>
              {articlesDate.articles.map((article, index) => (
                <React.Fragment key={article.id}>
                  <ArticleSeparator
                    index={index}
                    mainText={articlesDate.date}
                    secondaryText={getArticlesInfo(articlesDate.articles.length)}
                  />
                  <div className={css.article}>
                    <ArticleSummary
                      title={article.title}
                      isUsed={article.isUsed}
                      summary={article.summary}
                      source={article.source}
                      favicon={article.favicon}
                      isTranslated={article.isTranslated}
                      fromNewsCatcher={article.fromNewsCatcher}
                      date={formatDateTime(parseDate(article.date))}
                      image={article.image}
                      tags={getArticleTags(article)}
                      onClick={() => setModalDetails({ open: true, article })}
                    />
                    <div className={css.input}>
                      <CheckboxCircle
                        data-cy="radio-article"
                        id={article.id}
                        value={article.id}
                        checked={selectedArticles.includes(article.id)}
                        onChange={() => handleArticleCheckboxClick(article)}
                      />
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
          <div className={css.end} ref={loadNextRef}>
            <EndOfContent
              isError={false}
              isLoading={isFetching || isFetchingNextPage}
              isEnd={!hasNextPage}
              onClick={fetchNextPage}
            />
          </div>
        </section>
        <ConfirmModal
          open={modalDetails.open}
          okLabel="Select and Close"
          cancelLabel="Close"
          onOk={handleArticleModalConfirm}
          onCancel={() => setModalDetails((currentDetails) => ({ ...currentDetails, open: false }))}
        >
          <ArticleModal article={modalDetails.article} />
        </ConfirmModal>
      </div>
      <ModalEditFeedFilters
        title="Article Filters"
        filters={filters}
        visible={isFiltersOpen}
        onCancel={() => setFiltersOpen(false)}
        onConfirm={(newFilters) => {
          setFiltersOpen(false);
          setFilters(newFilters);
        }}
        onClear={() => {
          setFiltersOpen(false);
          setSelectedArticles([]);
        }}
      />
    </>
  );
};

export default ArticlesFeedList;
