export const AUTH = 'AUTH:AUTH';
export const SET_INITIAL_LOAD = 'AUTH:SET_INITIAL_LOAD';
export const AUTH_FAIL = 'AUTH:AUTH_FAIL';
export const SET_LOADING = 'AUTH:SET_LOADING';
export const INITIAL_LOAD = 'AUTH:INITIAL_LOAD';
export const SET_USER = 'AUTH:SET_USER';
export const OPEN_MODAL = 'AUTH:OPEN_MODAL';
export const CLOSE_MODAL = 'AUTH:CLOSE_MODAL';
export const RELOGIN = 'AUTH:RELOGIN';
export const REMOVE_USER = 'AUTH:REMOVE_USER';
export const EDIT_USER = 'AUTH:EDIT_USER';
