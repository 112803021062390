import get from 'lodash/get';
import clearFieldSet from './clearFieldSet';
import isFacetFilled, { getFillStatus, isDataFilled } from './isFacetFilled';
import { LABEL, RELATIONSHIP } from '../../../common/Enums/fieldTypeNames';

const clearFacet = (facet) => {
  const fieldsToRemove = [];
  const linksToRemove = [];
  const cefsToRemove = [];

  facet.crossEntityFacets.map((cef) => cefsToRemove.push(cef.id));
  const wrappers = facet.wrappers.map((wrapper) => {
    const { type } = facet;
    const fieldTypes = type.fields || type.facetFieldTypes;
    const isFieldTypeExist = fieldTypes.find((fieldType) => fieldType.id === wrapper.fieldType.id);
    if (!isFieldTypeExist) {
      wrapper.fieldSets.forEach((fieldSet) => {
        if (wrapper.fieldType.name === RELATIONSHIP) {
          linksToRemove.push(fieldSet.id);
        } else if (wrapper.fieldType.name !== RELATIONSHIP) {
          Object
            .values(fieldSet.fields)
            .forEach((field) => field.id && fieldsToRemove.push(field.id));
        }
      });
      return null;
    }
    return ({
      ...wrapper,
      fieldSets: wrapper.fieldSets.filter((fieldSet, i) => {
        if (wrapper.fieldType.name === RELATIONSHIP) {
          linksToRemove.push(fieldSet.id);
        }
        if (!wrapper.fieldType.required || i > 0 || (wrapper.fieldType.required && !wrapper.fieldType.create)) {
          if (wrapper.fieldType.name !== RELATIONSHIP) {
            Object
              .values(fieldSet.fields)
              .forEach((field) => field.id && fieldsToRemove.push(field.id));
          }
          return false;
        }
        return true;
      }).map((fieldSet) => clearFieldSet(fieldSet, wrapper.fieldType)),
    });
  }).filter((wrapper) => !!wrapper);
  const label = wrappers.find((wrapper) => wrapper.fieldType.name === LABEL && wrapper.fieldType.required);
  const clearedFacet = {
    ...facet,
    wrappers,
    label: get(label, 'fieldSets[0].fields.value.value', ''),
    ...isFacetFilled(wrappers),
    crossEntityFacets: [],
    crossEntityFacetType: {},
    crossEntityFacetTypeId: '',
    fieldsToRemove,
    linksToRemove,
    cefsToRemove,
  };
  if (clearedFacet.type.name === 'Statistic') {
    const { filledAll, filledRequired } = isDataFilled(clearedFacet);
    clearedFacet.filledRequired = filledRequired && clearedFacet.filledRequired;
    clearedFacet.filledAll = filledAll && clearedFacet.filledAll;
  }
  clearedFacet.status = getFillStatus(clearedFacet);
  return clearedFacet;
};

export default clearFacet;
