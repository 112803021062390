export const STORAGE_ADMIN_FEED_ARTICLES = 'ADMIN_FEED_ARTICLES';
export const STORAGE_ADMIN_FEED_REGULATIONS = 'ADMIN_FEED_REGULATIONS';
export const STORAGE_ADMIN_FEED_REGULATION_ARTICLES = 'ADMIN_FEED_REGULATION_ARTICLES';
export const STORAGE_ADMIN_ACTIVE_FILTER_EVENTS = 'ADMIN_ACTIVE_FILTER_EVENTS';
export const STORAGE_ADMIN_ACTIVE_FILTER_REGULATIONS = 'ADMIN_ACTIVE_FILTER_REGULATIONS';

export const getStoredItem = (key, defaultValue = null) => {
  const storedValue = localStorage.getItem(key);
  if (storedValue === null) return defaultValue;
  return JSON.parse(storedValue);
};

export const setStoredItem = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const removeItem = (key) => {
  localStorage.removeItem(key);
};
