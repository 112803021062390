import { createStore, createEvent, createEffect } from 'effector';
import queryString from 'query-string';
import uniqueId from 'lodash/uniqueId';
import orderBy from 'lodash/orderBy';

import client from '../../../common/utils/http';
import { catchError } from '../../../saga/sagas';

export const $facetLogs = createStore([]);
export const $isLoadingFacetLogs = createStore(false);

export const getFacetLogs = createEffect();

export const resetFacetLogs = createEvent();

getFacetLogs
  .use((id) => {
    const query = queryString.stringify({
      facetId: id,
      '$sort[updatedAt]': -1,
      $limit: 100,
    });

    return client.get(`/facet-logs?${query}`).catch(catchError);
  });

$facetLogs
  .on(getFacetLogs.done, (_, { result: { data: { data } } }) => {
    let ind = 0;
    const grouped = [{}];
    const orderedData = orderBy(data, ['createdAt'], ['desc']);
    orderedData.forEach((e, i) => {
      if (grouped[ind].userId !== e.userId) {
        if (i !== 0) {
          ind += 1;
        }
        grouped[ind] = {
          key: uniqueId('_log'),
          userId: e.userId,
          username: e.username,
          logs: [],
          updatedAt: e.updatedAt,
        };
      }
      const { length } = grouped[ind].logs;
      if (length === 0
        || grouped[ind].logs[length - 1].action !== e.action
        || grouped[ind].logs[length - 1].description !== e.description
      ) {
        grouped[ind].logs.push(e);
      }
    });
    return grouped;
  })
  .reset(resetFacetLogs);

$isLoadingFacetLogs
  .on(getFacetLogs, () => true)
  .on(getFacetLogs.done, () => false)
  .on(getFacetLogs.fail, () => false);
