import dj from 'moment/moment';

// const countDecimal = (number = 0, multiplier) => {
//   let mDigits = 0;
//   if (multiplier) {
//     mDigits = countDecimal(Number(multiplier));
//   }
//   if (!Number(number).toString().includes('.') && mDigits === 0) return 0;
//   const num = Number(number * (multiplier || 1)).toString().split('.');
//   const count = num[1] ? num[1].length : 0;
//   return count >= 1 ? 2 : count;
// };

// eslint-disable-next-line import/prefer-default-export
export const extractStatistics = (data) => {
  const format = {
    ANNUAL: 'YYYY',
    YEAR: 'YYYY',
    DAILY: 'MM/DD/YYYY',
    DAY: 'MM/DD/YYYY',
    MONTHLY: 'MM/YYYY',
    MONTH: 'MM/YYYY',
    QUARTERLY: '[Q]Q YYYY',
    QUARTER: '[Q]Q YYYY',
  };

  if (Array.isArray(data)) {
    return data.map((ceft) => {
      if (ceft.isStat) {
        return ceft;
      }
      if (ceft.values.length === 0) {
        return null;
      }
      const values = ceft.values.sort((a, b) => (new Date(b.date)).getTime() - (new Date(a.date)).getTime());
      const obj = {
        isStat: true,
        label: ceft.name,
        currentDate: null,
        currentStat: null,
        currentStatFooter: null,
        footerText: [],
        footerTextBold: false,
        goal: true,
        goalDifference: null,
        goalText: null,
        sparkline: values.map((v) => v.value).reverse(),
        lastDate: null,
        lastStat: null,
      };
      const rawCurrentDate = new Date(values[0].date);
      const currentDate = format[ceft.periodicity] === 'QQ YYYY'
        ? `Q${dj(rawCurrentDate).quarter()} ${dj(rawCurrentDate).year()}`
        : dj(rawCurrentDate).format(format[ceft.periodicity]);
      // TODO: Check it
      // const fixedCount = countDecimal(Number(values[0].value), ceft.multiplier);
      const fixedCount = 2;

      const currentStat = ceft.multiplier
        ? ceft.multiplier * Number(values[0].value)
        : Number(values[0].value);

      obj.currentStat = `${ceft.currency ? ceft.currency : ''}${currentStat.toFixed(fixedCount)}${ceft.units === '%' ? ceft.units : ''}`;

      obj.currentStatFooter = ceft.units !== '%' ? ceft.units : '';
      obj.currentDate = currentDate;
      if (values[0].rank && values[0].total) {
        obj.footerText.push(`${values[0].rank}/${values[0].total} Countries`);
      }
      if (values[0].percentile) {
        obj.footerText.push(values[0].percentile > 50 ? `Top ${100 - values[0].percentile}%` : `Bottom ${values[0].percentile}%`);
      }
      obj.goal = rawCurrentDate.getTime() > Date.now();
      const text = ceft.futureDates ? ceft.futureDates.toUpperCase() : 'Projection';
      obj.goalText = text[0] + text.slice(1).toLowerCase();
      if (values.length === 1) {
        return obj;
      }
      const rawLastDate = new Date(values[1].date);
      const lastDate = format[ceft.periodicity] === 'QQ YYYY'
        ? `Q${dj(rawLastDate).quarter()} ${dj(rawLastDate).year()}`
        : dj(rawLastDate).format(format[ceft.periodicity]);

      const lastStat = ceft.multiplier
        ? ceft.multiplier * Number(values[1].value)
        : Number(values[1].value);

      obj.lastStat = `${ceft.currency ? ceft.currency : ''}${lastStat.toFixed(fixedCount)}${ceft.units === '%' ? ceft.units : ''}`;
      obj.lastDate = lastDate;
      obj.lastStatFooter = obj.currentStatFooter;
      obj.goalDifference = (((currentStat - lastStat) / lastStat) * 100).toFixed(0);
      return obj;
    }).filter((s) => !!s);
  }
  return [];
};
