import React, {
  memo, useState, useEffect, useRef,
} from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import isNull from 'lodash/isNull';

const formats = {
  'MM/DD/YYYY': 'YYYY-MM-DD[T]12:00:00',
  'MM/YYYY': 'YYYY-MM-01[T]12:00:00',
  YYYY: 'YYYY-01-01[T]12:00:00',
};

const DatePickerWrapper = ({
  mode,
  placeholder,
  format,
  dateId,
  onChangeField,
  valueDate,
  disabled,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [time, setTime] = useState(valueDate);
  const ref = useRef();
  useEffect(() => {
    setTime(valueDate);
  }, [valueDate]);

  const handlePanelChange = (value) => {
    setTime(value);
    onChangeField(value.format(formats[format]), dateId);
    setOpen(false);
  };

  const handleOpenChange = (status) => {
    if (status) {
      setOpen(true);
    } else {
      setOpen(false);
      ref.current.focus();
    }
  };

  const onChange = (value) => {
    setTime(value);
    if (isNull(value)) {
      onChangeField(value, dateId);
    } else {
      onChangeField(value.format(formats[format]), dateId);
    }
  };

  return (
    <DatePicker
      ref={ref}
      dropdownClassName="dropdown-zindex"
      getCalendarContainer={() => document.getElementById('entity-editor-id')}
      value={time}
      open={isOpen}
      mode={mode}
      placeholder={placeholder}
      format={format}
      onOpenChange={handleOpenChange}
      onPanelChange={handlePanelChange}
      onChange={onChange}
      disabled={disabled}
      defaultValue={moment(null)}
      data-testid="dateInput"
    />
  );
};

export default memo(DatePickerWrapper);
