/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { DateContext } from '@axis/xyz.admin.form.date-input';
import store from './store';
import * as strings from './common/Strings';
import Router from './Router';
import ErrorDetail from './contexts/ErrorDetail/ErrorDetail';
import { ToastManager } from './contexts/ToastManager/ToastManager';
import FullStory from './contexts/FullStory/FullStory';
import { AnalyticsTracking } from './contexts/AnalyticsTrackingContext/AnalyticsTrackingContext';

import './index.css';
import './styles/main.css';
import './styles/ant.customs.css';

const queryClient = new QueryClient();

window.onbeforeunload = (e) => {
  if (document.leavingpage) {
    const dialogText = strings.dLeavePageUnsavedChanges;
    e.returnValue = dialogText;
    return dialogText;
  }
  return undefined;
};

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ErrorDetail>
          <DateContext>
            <AnalyticsTracking>
              <ToastManager>
                <FullStory />
                <Router store={store} />
              </ToastManager>
            </AnalyticsTracking>
          </DateContext>
        </ErrorDetail>
      </QueryClientProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root'),
);
