/* eslint-disable no-param-reassign,linebreak-style,no-plusplus */
import { put } from 'redux-saga/effects';
import orderBy from 'lodash/orderBy';
import lowerCase from 'lodash/lowerCase';
import client from '../../../common/utils/http';
import { SET_SEARCH_RESULT } from '../constants';
import entityTypes from '../../../common/models/EntityTypes';
import { catchError } from '../../../saga/sagas';

export default function* search({ payload: { value, id, types = '*' } }) {
  try {
    const allowed = types.split(',');
    let queryType = '';
    let i = 0;
    allowed.forEach((q) => {
      const param = q.trim();
      if (param === '*') return null;
      if (param.split(':').length === 2) {
        i += 1;
        const params = param.split(':');
        queryType += `&$or[${i}][$and][type]=${params[0]}&$or[${i}][$and][subType]=${params[1]}`;
        i += 1;
      } else {
        queryType += `&$or[${i}][type]=${param}`;
      }
      return null;
    });
    if (value.split(':').length === 2 && queryType === '') {
      if (entityTypes[lowerCase(value.split(':')[0])]) {
        const category = value.split(':');
        queryType = `&$or[1][$and][type]=${category[0]}&$or[1][$and][subType]=${category[1].split(',')[0]}`;
        value = category[1].split(',').length > 2 ? category[1].split(',')[1] : '';
      }
    }
    const { data } = yield client
      .get(`/entities?name[%24iLike]=%25${value}%25${queryType}&%24limit=50`)
      .catch(catchError);
    const map = {};
    const arr = orderBy(data.data, [(e) => `${e.type} ${e.subType}`, 'name'], ['asc', 'asc']);
    arr.forEach((e) => {
      const key = `${e.type} ${e.subType}`;
      if (!Object.prototype.hasOwnProperty.call(map, key)) {
        map[key] = [];
      }
      map[key].push(e);
    });
    yield put({ type: SET_SEARCH_RESULT, payload: { result: map, id } });
  } catch (e) {
    console.error(e);
  }
}
