import uniqueId from 'lodash/uniqueId';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import last from 'lodash/last';
import orderBy from 'lodash/orderBy';

import getFacetLabel from './getFacetLabel';
import isFacetFilled, { isDataFilled, getFillStatus } from './isFacetFilled';
import getFieldSets from './getFieldSets';
import parseRules from './parseRules';
import { getCrossEntityFacets } from './crossEntityFacets';
import { FACET_LOG } from '../constants';
import { RELATIONSHIPS_FACETS } from '../../../constants/facetTypes';
import { getLinkSets } from './createLink';
import { RELATIONSHIP } from '../../../common/Enums/fieldTypeNames';
import { UNASSIGNED } from '../../../common/Enums/Stages';

const createWrapper = (fieldType, fieldSets = [], order = 0) => ({
  key: uniqueId('_wrapper'),
  fieldSets,
  fieldType: {
    ...fieldType,
    rules: parseRules(fieldType),
  },
  addFieldSet: null,
  onChange: null,
  removeFieldSet: null,
  order,
});
const getWrappers = (facetType) => get(facetType, 'fields', facetType.facetFieldTypes).reduce((arr, fieldType) => {
  if (!fieldType.archived) {
    arr.push(createWrapper(fieldType, []));
  }
  return arr;
}, []);

const getLastUserIdThatReassignmentFacet = (logs = []) => {
  const reassignLogs = logs.filter((log) => log.action === 'reassign');
  const sorted = sortBy(reassignLogs, 'updatedAt');
  const lastUser = last(sorted);
  return lastUser ? lastUser.userId : null;
};

/**
 * @param facet {object} - raw facet
 * @param facetType {object} - facet template
 * @param entityLinks {array} - links for this special facet
 * @param facetInspector {function} - count number of added facets for current facetType
 * @param sourceId {string} - for links from entity
 */
const createFacet = (rawFacet = {}, facetType, entityLinks, facetInspector = null, sourceId) => {
  const key = uniqueId('_facet');
  const linkTypes = [];
  const facet = {
    ...rawFacet,
  };
  if (entityLinks) {
    facet.links = entityLinks;
  }
  const wrappers = getWrappers(facetType);
  const fieldSets = getFieldSets(facet, facetType)
    .filter((fieldSet) => {
      if (fieldSet.type.name === RELATIONSHIP) {
        linkTypes.push(fieldSet);
        return false;
      }
      return true;
    });

  fieldSets.forEach((fieldSet) => {
    const wrapper = wrappers.find((w) => w.fieldType.id === fieldSet.type.id);
    if (wrapper && (fieldSet.createdFields || fieldSet.type.create)) {
      wrapper.fieldSets.push(fieldSet);
    }
  });
  if (facetInspector) {
    facetInspector(facetType);
  }
  const linkSets = getLinkSets(
    linkTypes.filter(({ type }) => !type.archived),
    get(facet, 'links', []),
    facetType,
    RELATIONSHIPS_FACETS.includes(facetType.name) ? sourceId : facet.id,
  );
  linkSets.forEach((linkSet) => {
    const wrapper = wrappers.find((w) => w.fieldType.id === linkSet.facetFieldTypeId);
    if (wrapper) {
      wrapper.fieldSets.push(linkSet);
    } else {
      wrappers.push(createWrapper(linkSet.facetFieldType, [linkSet], 1));
    }
  });
  const statisticTemplate = wrappers.find((w) => w.fieldType.name === 'Statistic');
  const crossEntityFacets = getCrossEntityFacets(facetType, facet.crossEntityFacets, statisticTemplate);
  const newFacet = {
    amountComment: get(facet, 'comments', []).length,
    id: get(facet, 'id'),
    label: getFacetLabel({ facet, fieldSets, facetType }),
    creatorId: get(facet, 'creatorId', null),
    componentLogs: {
      updated: Date.now(),
      event: get(facet, 'id') ? FACET_LOG.INIT : FACET_LOG.CREATE,
      changed: !get(facet, 'id'),
    },
    type: facetType,
    meta: {
      assignedToId: get(facet, 'meta.assignedToId', null),
      stage: get(facet, 'meta.stage', null) || UNASSIGNED,
    },
    logsCount: get(facet, 'logs', []).length,
    lastUserIdThatReassignmentFacet: getLastUserIdThatReassignmentFacet(get(facet, 'logs', [])),
    order: get(facet, 'order', uniqueId()),
    key,
    wrappers: orderBy(wrappers, ['order', 'fieldType.order'], ['asc', 'asc']),
    entityId: facetType.name === 'Name' ? sourceId : undefined,
    ...isFacetFilled(wrappers),
    crossEntityFacets,
    crossEntityFacetType: facet.crossEntityFacetType ? facet.crossEntityFacetType : {},
    crossEntityFacetTypeId: get(facet, 'crossEntityFacetType.id'),
    statisticTemplate,
  };

  if (facetType.name === 'Statistic') {
    const { filledAll, filledRequired } = isDataFilled(newFacet);
    newFacet.filledRequired = filledRequired && newFacet.filledRequired;
    newFacet.filledAll = filledAll && newFacet.filledAll;
  }
  newFacet.status = getFillStatus(newFacet);
  return newFacet;
};

export default createFacet;
