export const CREATE = 'CREATE';
export const INIT = 'INIT';
export const UPDATE = 'UPDATE';

const createCompomnentLog = (type = CREATE, changed = true) => ({
  updated: Date.now(),
  type,
  changed,
});

export default createCompomnentLog;
