import React, { createRef } from 'react';
import { Filters } from 'react-data-grid-addons';
import debounce from 'lodash/debounce';
import startCase from 'lodash/startCase';
import { Select } from 'antd';
import statusesSelector from '../../../../selectors/statuses';
import styles from './styles.module.css';
import { ENTITY_COLUMNS } from '../../../../common/Enums/Columns';

const property = ENTITY_COLUMNS.STAGE;

const { SingleSelectFilter } = Filters;

class CustomEditor extends React.PureComponent {
  refInput = createRef();

  debouncedCommit = debounce(() => this.props.onCommit(), 0);

  constructor(props) {
    super(props);
    this.state = {
      [property]: '',
      statuses: [],
    };
  }

  componentDidMount() {
    const role = this.props.rowData.columnsActions.getRole();
    const statuses = statusesSelector.getEntityStatuses(role);
    this.setState({ statuses, [property]: this.props.value });
    this.refInput.current.rcSelect.rootRef.click();
    document.addEventListener('mousedown', this.handleClick, false);
    document.addEventListener('scroll', this.handleScroll, false);
    document.addEventListener('wheel', this.handleScroll, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
    document.removeEventListener('scroll', this.handleScroll, false);
    document.removeEventListener('wheel', this.handleScroll, false);
  }

  getValue() {
    return { [property]: this.state[property] };
  }

  getInputNode() {
    return this.refInput.current;
  }

  // eslint-disable-next-line class-methods-use-this,react/sort-comp
  disableContainerStyles() {}

  onChange = (value) => {
    this.setState({ [property]: value }, this.debouncedCommit);
  };

  handleClick = (e) => {
    if (this.refInput.current.rcSelect.rootRef.contains(e.target)) { return; }
    if (this.refInput.current.rcSelect.getPopupDOMNode().contains(e.target)) { return; }
    this.props.onCommit();
  };

  handleScroll = (e) => {
    if (this.refInput.current.rcSelect.getPopupDOMNode().contains(e.target)) { return; }
    this.props.onCommit();
  };

  render() {
    const { statuses } = this.state;
    return (
      <Select
        showSearch
        ref={(ref) => { this.refInput.current = ref; }}
        onChange={this.onChange}
        value={this.state[property]}
        className={styles.Select}
      >
        {statuses.map(
          (stage) => <Select.Option key={stage} value={stage}>{startCase(stage)}</Select.Option>,
        )}
      </Select>
    );
  }
}

const formatter = ({ row }) => (<div>{startCase(row[property])}</div>);

const stageColumn = {
  key: property,
  name: 'Stage',
  editable: true,
  filterable: true,
  sortable: true,
  resizable: true,
  width: 140,
  editor: CustomEditor,
  filterRenderer: SingleSelectFilter,
  formatter,
};

export default stageColumn;
