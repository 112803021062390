export { default as background } from './backgroundColor.module.css';
export { default as border } from './borderColor.module.css';
export { default as fontColor } from './fontColor.module.css';
export { default as gradient } from './backgroundGradient.module.css';

export const GRADIENT_COLORS = {
  business: { from: '#4e92c6', to: '#1a6dab' },
  security: { from: '#BA3a3f', to: '#8c0612' },
  culture: { from: '#7456EB', to: '#4e3494' },
  governance: { from: '#fdd000', to: '#deaf00' },
  government: { from: '#fdd000', to: '#deaf00' },
  environment: { from: '#3ECF7C', to: '#00994e' },
  economy: { from: '#ff7e14', to: '#e85e00' },
};
