import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import entityEditorSelector from '../../../../selectors/entityEditor';
import authSelector from '../../../../selectors/users';
import { sidebarSectionOpenSet, changeFilter } from '../../actions';

import Sidebar from './Sidebar';

const mapStateToProps = (state) => ({
  header: entityEditorSelector.getEntityHeader(state),
  isLoading: entityEditorSelector.getIsLoading(state),
  entity: entityEditorSelector.getFilteredEntity(state),
  filter: entityEditorSelector.getFilter(state),
  userId: authSelector.getUserProperty(state, 'id'),
});

const mapDispatchToProps = (dispatch) => ({
  openSectionAction: bindActionCreators(sidebarSectionOpenSet, dispatch),
  changeFilterAction: bindActionCreators(changeFilter, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
