import moment from 'moment';

import { DATE_FORMAT } from '../../../../constants/common';

import actionColumn from './actionColumn';
import nameColumn from './nameColumn';
import topicColumn from './topicColumn';
import typeColumn from './typeColumn';
import stageColumn from './stageColumn';
import assignColumn from './assignColumn';
import completenessColumn from './completenessColumn';
import hiddenStatusColumn from './hiddenStatusColumn';

export default [
  actionColumn,
  typeColumn,
  nameColumn,
  topicColumn,
  stageColumn,
  assignColumn,
  {
    key: 'createdAt',
    name: 'Created',
    sortable: true,
    resizable: true,
    width: 120,
    formatter: ({ row: { createdAt } }) => moment(createdAt).format(DATE_FORMAT),
  },
  {
    key: 'updatedAt',
    name: 'Modified',
    sortable: true,
    resizable: true,
    width: 120,
    formatter: ({ row: { updatedAt } }) => moment(updatedAt).format(DATE_FORMAT),
  },
  hiddenStatusColumn,
  completenessColumn,
];
