import React, { useState, useEffect } from 'react';
import {
  Select,
  Button,
  Typography,
} from 'antd';
import { ButtonIcon } from '@axis/xyz.admin.buttons.button-icon';
import styles from './EntityToEntityRelationship.module.css';
import SearchSelect from '../Form/SearchSelect/SearchSelect';
import {
  createEntityRelationship, deleteEntityRelationship, fetchEntityRelationships, fetchRelationshipTypes,
} from '../../api/create-relationship';
import { useToast } from '../../contexts/ToastManager/ToastManager';

const Item = ({ item, onClick }) => (
  <div className={styles.item}>
    <Typography className={styles.itemText} help={item}>{item}</Typography>
    <ButtonIcon
      icon="close"
      help="Remove item"
      className={styles.buttonRemove}
      onClick={() => onClick(item)}
    />
  </div>
);

const EntityToEntityRelationship = ({
  entityId,
}) => {
  const [entityToEntityRelationship, setEntityToEntityRelationship] = useState({});
  const [removedRelationships, setRemovedRelationships] = useState([]);
  const [relationships, setRelationships] = useState([]);
  const [relationship, setRelationship] = useState({
    source_id: entityId,
    target_name: '',
    target_id: '',
    type: '',
    strength: 1,
  });
  const toast = useToast();
  const handleChange = (value, field) => {
    if (field === 'entity') {
      setRelationship({
        ...relationship,
        target_name: value.name,
        target_id: value.id,
      });
    } else if (field === 'type') {
      const [, type] = value.split('-');
      setRelationship({
        ...relationship,
        [field]: type,
      });
    } else {
      setRelationship({
        ...relationship,
        [field]: value,
      });
    }
  };

  const handleSave = async () => {
    if ((!relationship.target_id || !relationship.type) && removedRelationships.length === 0) {
      toast.error({ content: 'Please select a relationship type and entity' });
      return;
    }

    if (removedRelationships.length > 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const relation of removedRelationships) {
        // eslint-disable-next-line no-await-in-loop
        await deleteEntityRelationship(entityId, relation.id, relation.type).catch((err) => {
          toast.error({ content: err.message });
        });
      }
    }
    if (relationship.target_id && relationship.type) {
      await createEntityRelationship(entityId, relationship.target_id, relationship.type, relationship.strength).catch((err) => {
        toast.error({ content: err.message });
      });
      setRelationships([...relationships.filter((rel) => rel.target_id !== relationship.target_id && rel.type !== relationship.type), {
        id: relationship.target_id,
        name: relationship.target_name,
        type: relationship.type,
        strength: relationship.strength,
      }]);
    }
    setRemovedRelationships([]);
    toast.success({ content: 'Relationship save!' });
  };

  const handleRemoval = (relation) => {
    setRemovedRelationships([...removedRelationships, relation]);
    setRelationships(relationships.filter((r) => r.name !== relation.name || r.type !== relation.type));
  };

  useEffect(() => {
    fetchRelationshipTypes().then((res) => setEntityToEntityRelationship(res));
    fetchEntityRelationships(entityId).then(({ data }) => setRelationships(data));
  }, []);

  return (
    <>
      <div className={styles.relationshipEntityWrapper}>
        <SearchSelect
          name="entity-entity-relationship"
          className="select-entities"
          onChange={(e) => handleChange(e, 'entity')}
          searchUrl="/entities?$limit=50&name[$iLike]="
          value={{ id: relationship.target_id, name: relationship.target_name }}
        />

        <Select
          dropdownClassName="dropdown-zindex"
          showSearch
          placeholder="Select Strength"
          onChange={(e) => handleChange(e, 'strength')}
          value={relationship.strength}
        >
          {
        Array.from(Array(5).keys()).map((i) => (
          <Select.Option key={i} value={i + 1}>{i + 1}</Select.Option>
        ))
      }
        </Select>

        <Select
          dropdownClassName="dropdown-zindex"
          showSearch
          placeholder="Select Relationship Type"
          onChange={(e) => handleChange(e, 'type')}
        >
          { entityToEntityRelationship && Object.entries(entityToEntityRelationship).map(([key, value]) => (
            <Select.OptGroup label={key} key={key}>
              {value && value.map((o, i) => (
              // eslint-disable-next-line react/no-array-index-key
                <Select.Option key={`${key}-${o}-${i}`} title={o}>
                  {o}
                </Select.Option>
              ))}
            </Select.OptGroup>
          ))}
        </Select>
        <Button
          onClick={() => handleSave()}
          style={{ marginTop: 10 }}
        >
          Save
        </Button>
      </div>
      <div className={styles.itemList}>
        {relationships.map((relation) => (
          <Item
            key={`${relation.name}-${relation.type}-${relation.strength}`}
            item={`${relation.type} -${relation.strength}- ${relation.name}`}
            onClick={() => handleRemoval(relation)}
          />
        ))}
      </div>
    </>
  );
};

export default EntityToEntityRelationship;
