import React, {
  useEffect, useState,
} from 'react';
import { Typography } from '@axis/xyz.admin.typography';
import { Button } from '@axis/xyz.admin.buttons.button';
import { DataTable } from '@axis/xyz.admin.tables.data-table';
import { Pagination } from '@axis/xyz.admin.pagination';
import { Icon } from '@axis/xyz.assets.icon';
import {
  useQuery, useMutation, useQueryClient,
} from 'react-query';
import { message } from 'antd';
import { Loading } from '@axis/xyz.admin.loading';
import { get } from 'lodash';
import css from './Countries.module.css';
import {
  fetchCountriesPage, PAGE_SIZE, createCountry, updateCountry, deleteCountry,
} from './utils';
import SearchSelect from '../../components/Form/SearchSelect/SearchSelect';
import ModalNewCountry from '../../modals/ModalNewCountry/ModalNewCountry';
import ModalEditCountry from '../../modals/ModalEditCountry/ModalEditCountry';
import ModalDeleteCountry from '../../modals/ModalDeleteCountry/ModalDeleteCountry';

const LoadingIndicator = ({ visible }) => {
  if (!visible) return null;
  return (
    <div className={css.loadingIndicator}>
      <Loading className={css.spinner} />
      <p className={css.loading}>Refreshing countries</p>
    </div>
  );
};

const Countries = () => {
  const [filters, setFilters] = useState({
    countryName: null,
  });

  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({ field: '', order: '' });

  const [showNewCountryModal, setShowNewCountryModal] = useState(false);

  const [selectedCountry, setSelectedCountry] = useState({ source: null, action: '' });

  const queryClient = useQueryClient();

  const eventCreate = useMutation(createCountry);
  const eventUpdate = useMutation(updateCountry);
  const eventDelete = useMutation(deleteCountry);

  const {
    data = { total: 0, countries: [] },
    isFetching,
    isLoading,
  } = useQuery(
    ['countries', filters, sort, page],
    () => fetchCountriesPage({
      filters,
      orderByName: sort.order,
      pageParam: { page },
    }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    queryClient.invalidateQueries('countries');
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  const handleCreateCountry = (country) => {
    eventCreate.mutate(country, {
      onSuccess: () => {
        queryClient.invalidateQueries(['countries', filters]);
        message.success('Country successfully created');
        setShowNewCountryModal(false);
      },
      onError: (err) => {
        const detail = get(err, 'response.data.message', err.message);
        message.error(detail);
      },
    });
  };

  const handleUpdateCountry = ({ id, ...country }) => {
    eventUpdate.mutate({ id, country }, {
      onSuccess: () => {
        queryClient.invalidateQueries(['countries', filters]);
        message.success('Country successfully updated');
        setSelectedCountry({ country: null, action: '' });
      },
      onError: (err) => {
        const detail = get(err, 'response.data.message', err.message);
        message.error(detail);
      },
    });
  };

  const handleDeleteConfirmed = (countryId) => {
    eventDelete.mutate(countryId, {
      onSuccess: () => {
        queryClient.invalidateQueries(['countries', filters]);
        message.success('Country successfully deleted');
      },
      onError: (err) => {
        const detail = get(err, 'response.data.message', err.message);
        message.error(detail);
      },
      onSettled: () => {
        setSelectedCountry({ source: null, action: '' });
      },
    });
  };

  const handleFilterChange = (field) => (value) => {
    if (!value) {
      setFilters({ ...filters, [field]: null });
      setPage(1);
    } else {
      setFilters({ ...filters, [field]: value });
      setPage(1);
    }
  };

  const handleSortChange = (sortField) => {
    setSort(({ field, order }) => {
      if (field !== sortField) {
        return { field: sortField, order: 'asc' };
      }
      if (order === 'asc') {
        return { field, order: 'desc' };
      }
      if (order === 'desc') {
        return { field: '', order: '' };
      }
      return { field: '', order: '' };
    });
  };

  return (
    <div className={css.container}>
      <section>
        <div className={css.row}>
          <Typography
            className={css.title}
            tag="h1"
            type="title"
            size="xl"
          >
            Countries
            <LoadingIndicator
              visible={isFetching && !isLoading && data.length > 0}
            />
          </Typography>
          <Button
            className={css.buttonNew}
            color="primary"
            onClick={() => setShowNewCountryModal((m) => !m)}
            label="New Country"
          />
        </div>
        <hr className={css.titleSeparator} />
        <div className={css.filters}>
          <div className={css.filter}>
            <SearchSelect
              isClearable
              placeholder="Filter by Name"
              name="filter-name"
              value={filters.countryName || ''}
              onChange={handleFilterChange('countryName')}
              searchUrl="/countries?$limit=50&name[$iLike]="
            />
          </div>
        </div>
      </section>
      <section>
        <DataTable
          columns={[
            {
              label: 'Name', key: 'name', sortable: true, sortKey: 'name',
            },
            { label: 'ISO Code', key: 'iso_code', sortable: false },
            { label: 'Main Entity', key: 'main_entity_name', sortable: false },
            { label: 'Flag', key: 'flag', sortable: false },
            {
              label: 'Actions', key: 'actions', className: css.columnActions, sortable: false,
            },
          ]}
          rows={data.countries.map((country) => ({
            id: country.id,
            values: {
              name: country.name,
              iso_code: country.iso_code,
              main_entity_name: country.main_entity_name,
              flag: (
                country.flag ? (
                  <div>
                    <img style={{ height: 30 }} src={country.flag} alt={`${country.name}-flag ${country.flag}`} />
                  </div>
                ) : null
              ),
              actions: (
                <div className={css.icons} data-cy="rss-sources-options">
                  <span className={css.iconContainer} data-cy="rss-sources-options-edit">
                    <Icon
                      className={css.icon}
                      type="edit"
                      onClick={() => setSelectedCountry({ country: country.id, action: 'edit' })}
                    />
                  </span>
                  <span className={css.iconContainer} data-cy="rss-sources-options-delete">
                    <Icon
                      className={css.icon}
                      type="trash"
                      onClick={() => setSelectedCountry({ country: country.id, action: 'delete' })}
                    />
                  </span>
                </div>
              ),
            },
          }))}
          sortKey={sort.field}
          sortOrder={sort.order}
          onSort={handleSortChange}
        />
        <div className={css.pagination}>
          <Pagination
            page={page}
            totalPages={Math.ceil(data.total / PAGE_SIZE)}
            onPageChange={setPage}
          />
        </div>
      </section>
      <ModalNewCountry
        visible={showNewCountryModal}
        onCreate={handleCreateCountry}
        onClose={() => setShowNewCountryModal(false)}
      />
      <ModalEditCountry
        visible={selectedCountry.country && selectedCountry.action === 'edit'}
        id={selectedCountry.country}
        onUpdate={handleUpdateCountry}
        onClose={() => setSelectedCountry({ country: null, action: '' })}
      />
      <ModalDeleteCountry
        visible={selectedCountry.country && selectedCountry.action === 'delete'}
        onConfirm={() => handleDeleteConfirmed(selectedCountry.country)}
        onCancel={() => setSelectedCountry({ country: null, action: '' })}
      />
    </div>
  );
};

export default Countries;
