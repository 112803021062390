import { createStore, applyMiddleware } from 'redux';
import createSagaMidelware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootSaga from './saga';
import reducer from './reducer';
import client from './common/utils/http';
import { OPEN_MODAL } from './containers/Auth/constants';

const middlewares = [];
const sagaMiddleware = createSagaMidelware();

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || composeWithDevTools;

middlewares.push(sagaMiddleware);
let middlewaresComposed = applyMiddleware(...middlewares);
if (process.env.REACT_APP_ENVIRONMENT === 'local') {
  middlewaresComposed = composeEnhancers(middlewaresComposed);
}
const store = createStore(
  reducer,
  middlewaresComposed,
);
sagaMiddleware.run(rootSaga);

client.dispatch401 = () => store.dispatch({ type: OPEN_MODAL });

export default store;
