import React from 'react';
import { Filters } from 'react-data-grid-addons';

const { SingleSelectFilter } = Filters;

const formatter = ({ row: { section } }) => (<div title={section} data-testid="facetItemSection">{section}</div>);

const sectionColumn = {
  key: 'section',
  name: 'Section',
  filterable: true,
  sortable: false,
  resizable: true,
  width: 80,
  formatter,
  filterRenderer: SingleSelectFilter,
  draggable: true,
};

export default sectionColumn;
