import React from 'react';
import { Typography } from '@axis/xyz.admin.typography';
import css from './FieldHint.module.css';

const ExternalLink = ({ url, title }) => (
  <a href={url} target="_blank" rel="noopener noreferrer">{title}</a>
);

// Common components that should work across all facets

const IssuerEntity = () => (
  <div className={css.container}>
    <Typography>
      Indicate the main issuer entity linked to this regulation. Details of this regulation will be available in the regulations menu of the left navigation drawer.
    </Typography>
  </div>
);

const Topic = () => (
  <div className={css.container}>
    <Typography>
      Indicate the topic (Jurisdiction) under which this regulation should exist.
    </Typography>
  </div>
);

const FacetEntities = () => (
  <div className={css.container}>
    <Typography>
      This field is where you choose Axis entities that are connected to the facet.
      Review the automated suggestions. Add any other Actors, Plots, and Contexts that are directly involved.
      Delete any Actors, Plots, or Contexts that are not relevant.
    </Typography>
  </div>
);

const FacetBriefs = () => (
  <div className={css.container}>
    <Typography>
      Briefs are categories that help organize Axis data. Review the suggestions.
      Add any relevant Briefs that are missing. Delete any Briefs that are not relevant.
    </Typography>
    <Typography>
      Resources:
      {' '}
      <ExternalLink url="https://docs.google.com/spreadsheets/d/1jmRBRKm2q3IkrynDG-PjyGGaG9calYHCw93KMsU2sxA" title="Axis Core Briefs" />
      ,
      {' '}
      <ExternalLink url="https://docs.google.com/spreadsheets/d/1lRI55kX9HCUG8sPmZKDcmt_Z8IkTIzl_LWtxV3zffPE" title="Axis Industry Briefs" />
    </Typography>
  </div>
);

const FacetSource = () => (
  <div className={css.container}>
    <Typography>
      The URL is pre-filled from the feed. Most facets will have just one (1) source.
      If the source refers to a primary source (for example, a social media post, or the text of a policy),
      search for and add the primary source. You may also include additional sources if necessary to build
      a full picture of the facet.
    </Typography>
    <Typography>
      Resources:
      {' '}
      <ExternalLink
        url="https://www.notion.so/axisxyz/Axis-Content-Updates-Feeds-Guidance-81ba745b7f5e4b2e82093c875f23b4e1#061843d4e0b24d2d88df89dac6adfd06"
        title="Axis Sourcing Guide"
      />
    </Typography>
  </div>
);

const FacetAttachments = () => (
  <div className={css.container}>
    <Typography>
      If there are relevant documents related to this facet (e.g. a PDF of a law or regulation), attach them here.
    </Typography>
  </div>
);

const FacetImage = () => (
  <div className={css.container}>
    <Typography>
      If there is a copyright-free image available to illustrate this regulation, please UPLOAD it here. Stock images are acceptable.
    </Typography>
  </div>
);

const FacetImageCredit = () => (
  <div className={css.container}>
    <Typography>
      If the image comes from a source that requires author attribution, please add the website or author details.
    </Typography>
  </div>
);

const FacetImageSource = () => (
  <div className={css.container}>
    <Typography>
      Please indicate the URL the image was obtained from
    </Typography>
  </div>
);

// Event specific components

const EventTitle = () => (
  <div className={css.container}>
    <Typography>
      The Event Title summarizes a single action or development that is relevant to Axis entities.
      To help you, this field is pre-filled with the article headline, but you should decide which development
      from the source you are covering (it will
      {' '}
      <strong>not</strong>
      {' '}
      always be the headline). Review and rewrite to fit Axis
      style, or delete and add a different Title. Write in the present tense.
    </Typography>
    <Typography>
      Go to the
      {' '}
      <ExternalLink url="https://app.axis.xyz" title="dashboard" />
      {' '}
      to view examples.
    </Typography>
    <Typography>
      Limit: 150 characters.
    </Typography>
    <Typography>
      Resources:
      {' '}
      <ExternalLink url="https://axisxyz.notion.site/Events-374d07d709f144eaab65113fd3dc4ce7" title="Axis Events Guide" />
      ,
      {' '}
      <ExternalLink url="https://axisxyz.notion.site/Style-Guide-c54c63203e424da8ab98aeede831927b" title="Axis Style Guide" />
    </Typography>
  </div>
);

const EventDescription = () => (
  <div className={css.container}>
    <Typography>
      This is an optional field where you can add more information about the Event, if the Event Title needs
      clarification. For example, if you use an acronym in the Event Title, write a Description of the Event
      that expands the acronym. Write in the past tense.
    </Typography>
    <Typography>
      Limit: 200 characters.
    </Typography>
    <Typography>
      Resources:
      {' '}
      <ExternalLink url="https://axisxyz.notion.site/Events-374d07d709f144eaab65113fd3dc4ce7" title="Axis Events Guide" />
      ,
      {' '}
      <ExternalLink url="https://axisxyz.notion.site/Style-Guide-c54c63203e424da8ab98aeede831927b" title="Axis Style Guide" />
    </Typography>
  </div>
);

const EventCountry = () => (
  <div className={css.container}>
    <Typography>
      This field lists the countries where the Event happened, or countries that the Event affected.
      Review the suggested countries. Delete any that are not relevant. Add any other countries directly related
      to the event. Most of the time, the Event will have just one country.
    </Typography>
  </div>
);

const EventLocation = () => (
  <div className={css.container}>
    <Typography>
      <strong>If</strong>
      {' '}
      the Event happened at a specific place (city or street address
      {' '}
      <strong>only</strong>
      ), type in this field to search and add the location. For example, &quot;White House&quot; or &quot;Washington, DC&quot;.
    </Typography>
  </div>
);

const EventDate = () => (
  <div className={css.container}>
    <Typography>
      Select the date when the event happened. This may be different from the date the source article was published.
    </Typography>
  </div>
);

// Regulation specific components

const RegulationSummarizedName = () => (
  <div className={css.container}>
    <Typography>
      Write down the summarized name for this regulation
    </Typography>
  </div>
);

const RegulationLegalName = () => (
  <div className={css.container}>
    <Typography>
      Enter the legal name by which this piece of regulation is officially known.
    </Typography>
  </div>
);

const RegulationIssuer = () => (
  <div className={css.container}>
    <Typography>
      Start typing in the box to select the name of the body or individual that enacted this regulation (or proposed, if under consideration).
      If the entity does not exist on the list, type its name and press Enter.
    </Typography>
  </div>
);

const RegulationDate = () => (
  <div className={css.container}>
    <Typography>
      Enter the date that this regulation was enacted (or proposed, if currently under consideration). If you cannot find the date
      leave the fields blank to mark it as &quot;Unknown Date&quot;.
    </Typography>
  </div>
);

export const Events = {
  Title: EventTitle,
  Description: EventDescription,
  Country: EventCountry,
  Location: EventLocation,
  Date: EventDate,
  Entities: FacetEntities,
  Briefs: FacetBriefs,
  Source: FacetSource,
  Attachments: FacetAttachments,
};

export const Regulations = {
  IssuerEntity,
  Topic,
  SummarizedName: RegulationSummarizedName,
  LegalName: RegulationLegalName,
  Issuer: RegulationIssuer,
  Image: FacetImage,
  ImageCredit: FacetImageCredit,
  ImageSource: FacetImageSource,
  Date: RegulationDate,
  Entities: FacetEntities,
  CoreBriefs: FacetBriefs,
  IndustryBriefs: FacetBriefs,
  Source: FacetSource,
  Attachments: FacetAttachments,
};
