import React, { PureComponent } from 'react';
import styled from 'styled-components';
import {
  Collapse, Button, Row, Col, Modal,
} from 'antd';

import { RELATIONSHIPS_FACETS } from '../../../../constants/facetTypes';
import Header from './Header';
import FieldWrapper from '../Wrapper';
import './index.css';
import { RELATIONSHIP } from '../../../../common/Enums/fieldTypeNames';
import CrossStatistic from '../Field/CrossStatistic';
import Statistics from '../Field/StatisticPreview/Statistic';
import {
  sendEvent,
} from '../../../../contexts/AnalyticsTrackingContext/AnalyticsTrackingContext';

const { Panel } = Collapse;
const buttonAddFacet = {
  marginTop: 32,
  marginBottom: 64,
  marginLeft: 47,
};

class Facet extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      specialFacet: false,
      statPreview: false,
    };
  }

  componentDidMount() {
    if (RELATIONSHIPS_FACETS.includes(this.props.facet.type.name)) {
      this.setState({ specialFacet: true });
    }
  }

  onChangeMetaField = ({ assignedToId, stage }) => {
    sendEvent('facet_update', {
      description: 'Changing the details of a facet (stage or assigned to)',
      facetId: this.props.facet.id,
      entityId: this.props.facet.entityId,
      facetStage: stage,
      facetAssignedToId: assignedToId,
    });
    this.props.actions.facetMetaChange({
      ...this.props.options,
      ...this.props.facet.meta,
      assignedToId,
      stage,
    });
  };

  handleFacetRemove = (options) => {
    sendEvent('facet_deletion', {
      description: 'Deleting a facet',
      facetId: this.props.facet.id,
      facetType: this.props.facet.type,
      facetStage: this.props.facet.meta.stage,
      entityId: this.props.facet.entityId,
    });
    this.props.actions.facetRemove(options);
  }

  handleFacetAdd = () => {
    sendEvent('facet_creation', {
      description: 'Creating a new facet',
      facetType: this.props.facet.type,
      entityId: this.props.facet.entityId,
    });
    this.props.actions.facetAdd(this.props.options);
  }

  render() {
    const {
      facet,
      users,
      filteredUsers,
      actions,
      options,
      count,
      loading,
      stages,
      hasAccessToRemoveFacet,
      hasAccessToAssignFacetToUser,
      collapseChanged,
    } = this.props;

    const {
      id,
      key,
      type,
      label,
      filledAll,
      meta,
      amountComment,
      lastUserIdThatReassignmentFacet,
      entityId,
      crossEntityFacetTypeId,
      status,
    } = facet;
    const { specialFacet } = this.state;
    const readonly = facet.meta.stage === 'published';

    return (
      <div className="facet Facet" id={key} data-id={id}>
        <Collapse
          // defaultActiveKey={type.required && !filledRequired ? key : null}
          bordered={false}
          onChange={(value) => collapseChanged && collapseChanged(key, value)}
        >
          <Panel
            key={key}
            header={(
              <Header
                hasAccessToAssignFacetToUser={hasAccessToAssignFacetToUser}
                openPreview={
                  facet.statisticTemplate && crossEntityFacetTypeId
                    ? () => this.setState({ statPreview: true })
                    : false
                }
                facetId={id}
                viewMode={options.viewMode}
                users={filteredUsers}
                activeUser={meta.assignedToId}
                activeStage={meta.stage}
                name={type.name}
                onChangeMetaField={this.onChangeMetaField}
                label={label}
                status={status}
                // todo: redo this
                count={
                  specialFacet
                    ? facet.wrappers.reduce((acc, e) => {
                      if (e.fieldType.name === RELATIONSHIP) {
                        return acc + e.fieldSets.length;
                      }
                      return acc;
                    }, 0)
                    : false
                }
                amountComment={amountComment}
                onBlur={() => actions.saveFacet(facet, options)}
                loading={loading}
                stages={stages}
                logsCount={facet.logsCount}
                userReassignmentFacet={users[lastUserIdThatReassignmentFacet]}
              />
            )}
          >
            <div className="Facet-Content">
              {facet.wrappers.map((wrapper) => {
                if (
                  facet.statisticTemplate
                  && wrapper.fieldType.name === 'Statistic'
                ) {
                  return (
                    <CrossStatistic
                      key={wrapper.key}
                      crossEntityFacetType={facet.crossEntityFacetType}
                      cefs={facet.crossEntityFacets}
                      options={options}
                      actions={actions}
                      readonly={readonly}
                    />
                  );
                }
                return (
                  <FieldWrapper
                    key={wrapper.key}
                    options={options}
                    data={wrapper}
                    facetType={facet.type}
                    specialFacet={specialFacet}
                    readonly={readonly}
                    entityId={entityId}
                  />
                );
              })}

              <Row type="flex" justify="end" gutter={18}>
                {(count <= 1 || specialFacet || !hasAccessToRemoveFacet) && (
                  <Col>
                    <ButtonAsLink
                      type="link"
                      onClick={() => actions.clearFacet(options)}
                      disabled={readonly}
                    >
                      × Clear this facet
                    </ButtonAsLink>
                  </Col>
                )}
                {hasAccessToRemoveFacet
                  && ((count > 1 && !specialFacet)
                    || type.duplicate === 'section') && (
                    <Col>
                      <ButtonAsLink
                        type="link"
                        onClick={this.handleFacetRemove}
                        disabled={readonly}
                      >
                        × Remove this facet
                      </ButtonAsLink>
                    </Col>
                )}
              </Row>
            </div>

            {!filledAll && !specialFacet && type.duplicate === 'true' && (
              <Button
                style={buttonAddFacet}
                onClick={this.handleFacetAdd}
                disabled={readonly}
              >
                {`+ Add ${type.name}`}
              </Button>
            )}
          </Panel>
        </Collapse>
        {filledAll && !specialFacet && type.duplicate === 'true' && (
          <Button
            style={buttonAddFacet}
            onClick={this.handleFacetAdd}
          >
            {`+ Add ${type.name}`}
          </Button>
        )}
        <Modal
          centered
          destroyOnClose
          visible={this.state.statPreview}
          onOk={() => this.setState({ statPreview: false })}
          onCancel={() => this.setState({ statPreview: false })}
        >
          <Statistics
            ceft={facet.crossEntityFacetType}
            stats={facet.crossEntityFacets}
          />
        </Modal>
      </div>
    );
  }
}

export default Facet;

const ButtonAsLink = styled(Button)`
  color: #b5adcc !important;
  padding-left: 0;
  padding-right: 0;
  margin-top: 10px;
  margin-bottom: 32px;
  border: none;

  :hover {
    color: #9382c4 !important;
  }

  :active {
    color: #6a56a3 !important;
  }
`;
