import React, { useEffect, useState } from 'react';
import { Modal } from '@axis/xyz.admin.modal';
import { Typography } from '@axis/xyz.admin.typography';
import { FormItemWrapper } from '@axis/xyz.admin.form.form-item-wrapper';
import { TextInput } from '@axis/xyz.admin.form.text-input';
import { Button } from '@axis/xyz.admin.buttons.button';
import css from './ModalNewCountry.module.css';
import AttachmentField from '../../components/Form/AttachmentField/AttachmentField';
import { uploadFile } from '../../containers/EntityEditor/components/Field/mediaHelpers.js';
import SearchSelect from '../../components/Form/SearchSelect/SearchSelect';

const ModalNewCountry = ({
  visible, onCreate, onClose,
}) => {
  const [country, setCountry] = useState({
    name: '',
    iso_code: '',
    flag: null,
    main_entity_id: null,
    main_entity_name: null,
  });

  const [flagFormDatq, setFlagFormData] = useState(null);

  useEffect(() => {
    setCountry({
      name: '',
      iso_code: '',
      flag: null,
      main_entity_id: null,
      main_entity_name: null,
    });
    setFlagFormData(null);
  }, [visible]);

  const handleInputChange = (field, value) => {
    if (field === 'main_entity') {
      setCountry({ ...country, main_entity_id: value.id, main_entity_name: value.name });
    } else {
      setCountry({ ...country, [field]: value });
    }
  };

  const submitEnabled = !!country.name && (!!flagFormDatq || !!country.flag);

  const handleCreate = async () => {
    const { key } = await uploadFile(flagFormDatq);
    onCreate({
      name: country.name,
      iso_code: country.iso_code,
      flag: key,
      main_entity_id: country.main_entity_id,
      main_entity_name: country.main_entity_name,
    });
  };

  return (
    <Modal
      name="new-country"
      className={css.modal}
      visible={visible}
      onClose={onClose}
    >
      <div className={css.content}>
        <Typography
          className={css.title}
          type="title"
          tag="h2"
          size="lg"
        >
          Create a new country
        </Typography>
        <div className={css.form}>
          <FormItemWrapper
            className={css.input}
            isRequired
            label="Name"
          >
            <TextInput
              name="country-name"
              value={country.name || ''}
              onChange={(e) => handleInputChange('name', e.target.value)}
            />
          </FormItemWrapper>
          <FormItemWrapper
            className={css.input}
            label="ISO Code"
          >
            <TextInput
              name="iso_code"
              value={country.iso_code || ''}
              onChange={(e) => handleInputChange('iso_code', e.target.value)}
            />
          </FormItemWrapper>
          <FormItemWrapper
            className={css.input}
            label="Main Entity"
          >
            {country.main_entity_name ? (
              <span style={{ padding: 5, borderWidth: 1 }}>
                {country.main_entity_name}
                {' '}
                <button
                  type="button"
                  style={{
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    color: 'red',
                    fontSize: 20,
                  }}
                  onClick={() => setCountry({ ...country, main_entity_id: null, main_entity_name: null })}
                >
                  x
                </button>

              </span>
            ) : (
              <SearchSelect
                placeholder="Search an Entity"
                name="main-entity-name"
                value={country.main_entity_name || ''}
                onChange={(e) => handleInputChange('main_entity', e)}
                searchUrl="/entities?$limit=50&name[$iLike]="
              />
            )}
          </FormItemWrapper>
          <FormItemWrapper
            className={css.input}
            isRequired
            label="Flag Image"
          >
            {!country.flag ? (
              <AttachmentField
                label="Upload Image"
                fileType="image"
                limitFiles={1}
                onChange={(e) => {
                  setFlagFormData(e[0].formData);
                  handleInputChange('flag', e[0].formData.get('uri'));
                }}
              />
            ) : (
              <div style={{
                display: 'flex', justifyContet: 'center', position: 'relative',
              }}
              >
                <button
                  type="button"
                  onClick={() => handleInputChange('flag', null)}
                  style={{
                    border: 'none', background: 'none', cursor: 'pointer',
                  }}
                >
                  <img style={{ maxHeight: 50, maxWidth: 50 }} src={country.flag} alt="" />
                </button>
              </div>
            )}
          </FormItemWrapper>

          <Button
            className={css.submit}
            disabled={!submitEnabled}
            onClick={handleCreate}
            label="Create"
            type="button"
          />
        </div>
      </div>
    </Modal>
  );
};

export default ModalNewCountry;
