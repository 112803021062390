import client from '../../common/utils/http';
import { convertDate, formatDateISO, parseDateIgnoreTZ } from '../../common/utils/date';
import { uploadFile } from '../EntityEditor/components/Field/mediaHelpers';

const processRegulationDataFromResponse = ({ data }) => ({
  ...data,
  legalName: data.legalName,
  topic: {
    id: data.topic.id,
    name: data.topic.name,
  },
  industryBriefs: data.industryBriefs || [],
  dateStart: data.startDate ? parseDateIgnoreTZ(data.startDate).toJSDate() : null,
  dateEnd: data.endDate ? parseDateIgnoreTZ(data.endDate).toJSDate() : null,
  sources: data.source ? [data.source] : [],
  attachments: data.attachment ? [data.attachment] : [],
});

const getRegulation = (id) => client
  .get(`/regulation/${id}`)
  .then(processRegulationDataFromResponse);

const getAttachment = async (data) => {
  const attachments = data.attachments || [];
  const attachment = attachments[0];
  if (!attachment) return null;
  if (attachment.source) return attachment;
  const file = await uploadFile(attachment.formData);
  return { key: file.key, name: attachment.name };
};

export const getImage = async (data) => {
  const [localImage] = data.image || null;
  const uploadedImage = (localImage && localImage.formData) ? await uploadFile(localImage.formData) : {};
  return uploadedImage.href;
};

export const getDateInfo = (data) => {
  const { dateStart, dateEnd } = data;
  if (dateStart && !dateEnd) return { type: 'start', dateStart: formatDateISO(convertDate(dateStart)) };
  if (!dateStart && dateEnd) return { type: 'end', dateEnd: formatDateISO(convertDate(dateEnd)) };
  if (dateStart && dateEnd) return { type: 'range', dateStart: formatDateISO(convertDate(dateStart)), dateEnd: formatDateISO(convertDate(dateEnd)) };
  return { type: 'unknown' };
};

export const processRegulationData = async (data) => ({
  issuerEntity: data.issuerEntity.id,
  topicId: data.topic.id,
  summarizedName: data.summarizedName,
  legalName: data.legalName,
  date: getDateInfo(data),
  industryBriefIds: data.industryBriefs.map((b) => b.id),
  source: data.sources[0],
  attachment: await getAttachment(data),
});

const updateRegulation = async (data) => {
  const { id } = data;

  const regulation = await processRegulationData(data);

  return client
    .patch(`/regulation/${id}`, regulation)
    .then(processRegulationDataFromResponse);
};

export {
  getRegulation,
  updateRegulation,
};
