import client from '../../common/utils/http';
import { catchError } from '../../saga/sagas';

/**
 * @param {number} page - page
 * @param {string} type - type/subType of entity
 * @param {object} search - params for search: { type, name, assignedToId, stage, orderField, orderDirection }
 * */
const loadEntities = (page = 1, type, search = {}, limit) => {
  const params = {
    $limit: limit,
    $skip: limit * (page - 1),
  };
  const { untyped } = search;
  const isNotEmptySearch = Object.keys(search).some((key) => search[key]);

  if (isNotEmptySearch) {
    if (search.type) {
      let { type: searchType } = search;
      let subType = '';
      if (search.type.indexOf(':') !== -1) {
        const typeAndSubType = search.type.split(':');
        [searchType, subType] = typeAndSubType;
      }

      params['type[$and][0][$iLike]'] = `%${searchType}%`;
      params['subType[$iLike]'] = `%${subType}%`;
    }

    if (search.name) {
      params['name[$iLike]'] = `%${search.name.split(' ').join('%')}%`;
    }

    if (search.stage) {
      params.stage = search.stage;
    }

    if (search.assignedToId) {
      params.assignedToId = search.assignedToId;
    }

    if (search.topicId) {
      params.topicId = search.topicId;
    }

    if (search.orderField && !(params && params.topicId === 'none')) {
      params[`$sort[${search.orderField}]`] = (search.orderDirection === 'ASC' ? -1 : 1);
    }
  }

  if (untyped) {
    params['type[$iLike]'] = 'none';
  } else if (!type) {
    params['type[$and][1][$ne]'] = 'Brief';
    params['type[$and][2][$ne]'] = 'none';
    params['type[$and][3][$ne]'] = 'Statistic Entity';
  } else {
    params['type[$iLike]'] = type;
  }

  return client
    .get('/entities', { params })
    .then((response) => {
      const { data: { total, data } } = response;

      return {
        data: data.map((e) => ({
          ...e,
          assignedToId: e.meta ? e.meta.assignedToId : null,
          stage: e.meta ? e.meta.stage : null,
          topics: e.countries,
        })),
        total,
        page,
      };
    }).catch(catchError);
};

const LIMIT_TEMPLATES = 1000;
const loadEntityTemplates = (type, untyped = false) => {
  const typeProp = {};

  if (!untyped) {
    if (!type) {
      typeProp['type[$ne]'] = 'Brief';
    } else {
      typeProp['type[$iLike]'] = type;
    }
  }

  return client
    .get('/entity-templates', { params: { $limit: LIMIT_TEMPLATES, ...typeProp } })
    .then((response) => response.data.data)
    .catch(catchError);
};

const removeEntity = async (id) => {
  const { data: { id: deletedId } } = await client.delete(`/entities/${id}`).catch(catchError);
  return deletedId;
};

const updateMeta = (metaToUpdate) => client.patch('/entity-meta-data/', metaToUpdate).catch(catchError);
const updateEntities = (entitiesToUpdate) => client.patch('/entities/', entitiesToUpdate).catch(catchError);

const QUERY_FIND_TOPICS = '/countries?';
const loadTopics = (text) => {
  let findByName = '';
  if (text) {
    findByName = `&name[%24iLike]=%25${text}%25`;
  }
  findByName += '&$limit=1000';
  return client.get(QUERY_FIND_TOPICS + findByName);
};

const URL_TOPIC_LINKS = '/topic-links';
const createLinksToTopic = (links) => client.post(URL_TOPIC_LINKS, links);

const removeTopicLink = (id) => client.delete(`${URL_TOPIC_LINKS}/${id}`);

export default {
  loadEntityTemplates,
  loadEntities,
  removeEntity,
  updateMeta,
  updateEntities,
  loadTopics,
  createLinksToTopic,
  removeTopicLink,
  URL_TOPIC_LINKS,
};
