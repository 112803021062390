/* eslint-disable no-param-reassign,linebreak-style,no-plusplus */
import { put } from 'redux-saga/effects';
import uniqueId from 'lodash/uniqueId';
import client from '../../../common/utils/http';
import * as strings from '../../../common/Strings';
import { CREATE_ERROR } from '../../../constants';
import { formatTemplate } from '../../../common/utils/lib';
import { createSchema } from '../utils';
import loadDictionaries from './loadDictionaries';

import {
  SET_ENTITY, ADD_LOAD_PROCESS, REMOVE_LOAD_PROCESS,
} from '../constants';
import { catchError } from '../../../saga/sagas';

export default function* loadEntity({ payload: id }) {
  const processId = uniqueId();
  let errors = false;
  try {
    yield put({ type: ADD_LOAD_PROCESS, payload: { processId } });
    const { data: entity, data: { entityTemplate: rawTemplate } } = yield client
      .get(`/entities/${id}`)
      .catch(catchError);
    const template = formatTemplate(rawTemplate);

    const preparedEntity = createSchema(template, entity);
    const dictionaries = yield loadDictionaries([preparedEntity]);
    yield put({
      type: SET_ENTITY,
      payload: {
        dictionaries,
        entity: preparedEntity,
      },
    });
  } catch (e) {
    yield put({
      type: CREATE_ERROR,
      payload: `${strings.seLoadEntData}: ${e.message}.`,
    });
    errors = true;
    console.error(e);
  } finally {
    yield put({ type: REMOVE_LOAD_PROCESS, payload: { processId, errors } });
  }
}
