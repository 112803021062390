import { put } from 'redux-saga/effects';
import client from '../../../common/utils/http';
import { CREATE_ERROR } from '../../../constants';
import { catchError } from '../../../saga/sagas';
import { UPDATE_FACET_LOG_COUNT } from '../constants';

export default function* ({ payload: { facetId } }) {
  if (facetId) {
    try {
      const { data: { total } } = yield client.get(`/facet-logs?facetId=${facetId}&$limit=0`).catch(catchError);
      yield put({
        type: UPDATE_FACET_LOG_COUNT,
        payload: {
          facetId,
          total,
        },
      });
    } catch (e) {
      yield put({
        type: CREATE_ERROR,
        payload: e.message,
      });
      console.error(e);
    }
  }
}
