import client from '../../../common/utils/http';
import { group } from '../../../common/utils/lib';
import { catchError } from '../../../saga/sagas';

const dictionaryAddItems = async (dictionaryItemsToAdd, dictionaries) => {
  const values = Object.values(dictionaryItemsToAdd);
  const newValues = [];
  const hashes = {};
  values.forEach((dictionary) => {
    if (Object.prototype.hasOwnProperty.call(dictionaries, dictionary.key)) {
      dictionary.items.forEach((item) => {
        const hash = `${item}${dictionary.id}`;
        if (!dictionaries[dictionary.key].items.find((dItem) => item === dItem.value) && !hashes[hash]) {
          hashes[hash] = hash;
          newValues.push({
            value: item,
            dictionaryId: dictionary.id,
            category: ' ',
          });
        }
      });
    }
    return null;
  });
  const { data: items } = await client.post('/dictionary-items', newValues)
    .catch(catchError);
  const newDictionaries = {
    ...dictionaries,
  };
  const dictionariesArray = Object.values(dictionaries);
  items.forEach((item) => {
    const res = dictionariesArray.find((d) => d.id === item.dictionaryId);
    if (res) {
      newDictionaries[res.key].items.push(item);
    }
  });
  Object.values(newDictionaries).forEach((dictionary) => {
    newDictionaries[dictionary.key] = {
      ...dictionary,
      ...group(dictionary.items, ['category', 'value'], ['asc', 'asc']),
      updated: Date.now(),
    };
  });
  return newDictionaries;
};

export default dictionaryAddItems;
