import { put } from 'redux-saga/effects';
import queryString from 'query-string';
import client from '../../../common/utils/http';
import { CREATE_ERROR } from '../../../constants';
import { group } from '../../../common/utils/lib';
import * as strings from '../../../common/Strings';
import { catchError } from '../../../saga/sagas';

const fillDicitionaries = (raw) => {
  const dictionaries = {};
  raw.forEach((d) => {
    const {
      items, id: idd, key, name,
    } = d;
    dictionaries[key] = {
      items,
      ...group(items, ['category', 'value'], ['asc', 'asc']),
      id: idd,
      key,
      name,
      updated: Date.now(),
    };
  });
  return dictionaries;
};

export default function* loadDictionaries(entities) {
  let namesObj = {};
  entities.forEach((entity) => entity.sections.forEach((section) => section.facets.forEach((facet) => {
    facet.wrappers.forEach((field) => {
      namesObj = {
        ...namesObj,
        ...field.fieldType.rules.dictionaries,
      };
    });
  })));
  const names = Object.values(namesObj);
  let dictionaries = {};
  let query = '';
  names.forEach((n) => {
    query = `${query}${queryString.stringify({ 'key[$in][]': n })}&`;
  });
  try {
    const data = yield client.fetchAll('/dictionaries', 50, 0, [], query).catch(catchError);
    dictionaries = {
      ...dictionaries,
      ...fillDicitionaries(data),
    };
  } catch (e) {
    yield put({
      type: CREATE_ERROR,
      payload: `${strings.dpLoadDictionaries}: ${e.message}.`,
    });
    console.error(e);
  }
  return dictionaries;
}
