import React from 'react';
import { Modal } from '@axis/xyz.admin.modal';
import { Typography } from '@axis/xyz.admin.typography';
import { Button } from '@axis/xyz.admin.buttons.button';
import { ButtonText } from '@axis/xyz.admin.buttons.button-text';
import { Loading } from '@axis/xyz.admin.loading';
import css from './ModalDeleteBrief.module.css';

const ModalDeleteBrief = ({
  visible, onConfirm, onCancel, loading,
}) => (

  <Modal
    name="delete-rss-source"
    className={css.modal}
    visible={visible}
    onClose={onCancel}
  >
    <div className={css.content}>
      <Typography>Are you sure you want to delete this brief?</Typography>
      {loading && (
      <div className={css.loadingContainer}>
        <Loading className={css.loader} />
      </div>
      )}
      <div className={css.buttons}>
        <ButtonText
          color="secondary"
          className={css.confirm}
          label="Delete"
          onClick={onConfirm}
        />
        <Button
          label="Cancel"
          onClick={onCancel}
        />
      </div>
    </div>
  </Modal>
);

export default ModalDeleteBrief;
