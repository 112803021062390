import React from 'react';
import { Loading as Spinner } from '@axis/xyz.admin.loading';
import styles from './Loading.module.css';

const Loading = ({ isLoading = false, children }) => {
  if (isLoading) {
    return (
      <div className={styles.main}>
        <Spinner className={styles.loading} />
      </div>
    );
  }
  return children;
};

export default Loading;
