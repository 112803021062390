import React from 'react';

const typeColumn = {
  key: 'type',
  name: 'Type',
  filterable: true,
  resizable: true,
  sortable: true,
  width: 150,
  formatter: ({ row: { type, subType } }) => {
    const text = subType ? `${type}:${subType}` : type;
    return (
      <div title={text}>{text}</div>
    );
  },
};

export default typeColumn;
