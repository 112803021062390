const FIELDS = {
  Checkbox: ['checked'],
  Bullet: ['section', 'description', 'requirement', 'sectionSelectedId', 'requirementSelectedId'],
  Date: ['selectedValue', 'format', 'dateStart', 'dateEnd', 'dateSingle', 'selectedLabel', 'dateType'],
  Detail: ['value'],
  Importance: ['selectedLabel', 'selectedValue'],
  Label: ['value', 'selectedValue'],
  Location: ['address', 'lat', 'lng', 'selectedLocationValue', 'selectedLocationName', 'zoom'],
  Media: ['imageLabel', 'imageDetail', 'key', 'urlInput', 'sourceUrl', 'sourceKey'],
  Provenance: ['urlInput', 'format', 'date', 'datePublished', 'analystNotes', 'key'],
  Statistic: ['value', 'units', 'rankFormat', 'rankSet', 'rankTotal', 'crossLink', 'compare'],
};

const fieldsGetter = {
  Bullet: ({ type, defaults, order }) => ({
    section: {
      id: defaults ? defaults.sectionId : undefined,
      facetFieldTypeId: defaults ? defaults.facetFieldTypeId : type.id,
      name: 'section',
      order,
      value: defaults ? defaults.section : '',
    },
    sectionSelectedId: {
      id: defaults ? defaults.sectionSelectedIdId : undefined,
      facetFieldTypeId: defaults ? defaults.facetFieldTypeId : type.id,
      name: 'sectionSelectedId',
      order,
      value: defaults ? defaults.sectionSelectedId : '',
    },
    requirement: {
      id: defaults ? defaults.requirementId : undefined,
      facetFieldTypeId: defaults ? defaults.facetFieldTypeId : type.id,
      name: 'requirement',
      order,
      value: defaults ? defaults.requirement : '',
    },
    requirementSelectedId: {
      id: defaults ? defaults.requirementSelectedIdId : undefined,
      facetFieldTypeId: defaults ? defaults.facetFieldTypeId : type.id,
      name: 'requirementSelectedId',
      order,
      value: defaults ? defaults.requirementSelectedId : '',
    },
    description: {
      id: defaults ? defaults.descriptionId : undefined,
      facetFieldTypeId: defaults ? defaults.facetFieldTypeId : type.id,
      name: 'description',
      order,
      value: defaults ? defaults.description : '',
    },
  }),
  Label: ({ type, defaults, order }) => ({
    value: {
      id: defaults ? defaults.valueId : undefined,
      facetFieldTypeId: defaults ? defaults.facetFieldTypeId : type.id,
      name: 'value',
      order,
      value: defaults ? defaults.value : '',
    },
    selectedValue: {
      id: defaults ? defaults.selectedValueId : undefined,
      facetFieldTypeId: defaults ? defaults.facetFieldTypeId : type.id,
      name: 'selectedValue',
      order,
      value: defaults ? defaults.selectedValue : '',
    },
  }),
  Checkbox: ({ type, defaults, order }) => ({
    checked: {
      id: defaults ? defaults.checkedId : undefined,
      facetFieldTypeId: defaults ? defaults.facetFieldTypeId : type.id,
      name: 'checked',
      order,
      value: defaults ? defaults.checked : '',
    },
  }),
  Date: ({ type, defaults, order }) => ({
    dateEnd: {
      id: defaults ? defaults.dateEndId : undefined,
      facetFieldTypeId: defaults ? defaults.facetFieldTypeId : type.id,
      name: 'dateEnd',
      order,
      value: defaults ? defaults.dateEnd : '',
    },
    dateSingle: {
      id: defaults ? defaults.dateSingleId : undefined,
      facetFieldTypeId: defaults ? defaults.facetFieldTypeId : type.id,
      name: 'dateSingle',
      order,
      value: defaults ? defaults.dateSingle : '',
    },
    dateStart: {
      id: defaults ? defaults.dateStartId : undefined,
      facetFieldTypeId: defaults ? defaults.facetFieldTypeId : type.id,
      name: 'dateStart',
      order,
      value: defaults ? defaults.dateStart : '',
    },
    dateType: {
      id: defaults ? defaults.dateTypeId : undefined,
      facetFieldTypeId: defaults ? defaults.facetFieldTypeId : type.id,
      name: 'dateType',
      order,
      value: defaults ? defaults.dateType : '',
    },
    format: {
      id: defaults ? defaults.formatId : undefined,
      facetFieldTypeId: defaults ? defaults.facetFieldTypeId : type.id,
      name: 'format',
      order,
      value: defaults ? defaults.format : '',
    },
    selectedLabel: {
      id: defaults ? defaults.selectedLabelId : undefined,
      facetFieldTypeId: defaults ? defaults.facetFieldTypeId : type.id,
      name: 'selectedLabel',
      order,
      value: defaults ? defaults.selectedLabel : '',
    },
    selectedValue: {
      id: defaults ? defaults.selectedValueId : undefined,
      facetFieldTypeId: defaults ? defaults.facetFieldTypeId : type.id,
      name: 'selectedValue',
      order,
      value: defaults ? defaults.selectedValue : '',
    },
  }),
  Detail: ({ type, defaults, order }) => ({
    value: {
      id: defaults ? defaults.valueId : undefined,
      facetFieldTypeId: defaults ? defaults.facetFieldTypeId : type.id,
      name: 'value',
      order,
      value: defaults ? defaults.value : '',
    },
  }),
  Importance: ({ type, defaults, order }) => ({
    selectedLabel: {
      id: defaults ? defaults.selectedLabelId : undefined,
      facetFieldTypeId: defaults ? defaults.facetFieldTypeId : type.id,
      name: 'selectedLabel',
      order,
      value: defaults ? defaults.selectedLabel : '',
    },
    selectedValue: {
      id: defaults ? defaults.selectedValueId : undefined,
      facetFieldTypeId: defaults ? defaults.facetFieldTypeId : type.id,
      name: 'selectedValue',
      order,
      value: defaults ? defaults.selectedValue : '',
    },
  }),
  Location: ({ type, defaults, order }) => ({
    lat: {
      id: defaults ? defaults.latId : undefined,
      facetFieldTypeId: defaults ? defaults.facetFieldTypeId : type.id,
      name: 'lat',
      order,
      value: defaults ? defaults.lat : '',
    },
    lng: {
      id: defaults ? defaults.lngId : undefined,
      facetFieldTypeId: defaults ? defaults.facetFieldTypeId : type.id,
      name: 'lng',
      order,
      value: defaults ? defaults.lng : '',
    },
    address: {
      id: defaults ? defaults.addressId : undefined,
      facetFieldTypeId: defaults ? defaults.facetFieldTypeId : type.id,
      name: 'address',
      order,
      value: defaults ? defaults.address : '',
    },
    zoom: {
      id: defaults ? defaults.zoomId : undefined,
      facetFieldTypeId: defaults ? defaults.facetFieldTypeId : type.id,
      name: 'zoom',
      order,
      value: defaults ? defaults.zoom : 3,
    },
    selectedLocationName: {
      id: defaults ? defaults.selectedLocationNameId : undefined,
      facetFieldTypeId: defaults ? defaults.facetFieldTypeId : type.id,
      name: 'selectedLocationName',
      order,
      value: defaults ? defaults.selectedLocationName : '',
    },
    selectedLocationValue: {
      id: defaults ? defaults.selectedLocationValueId : undefined,
      facetFieldTypeId: defaults ? defaults.facetFieldTypeId : type.id,
      name: 'selectedLocationValue',
      order,
      value: defaults ? defaults.selectedLocationValue : '',
    },
  }),
  Media: ({ type, defaults, order }) => ({
    imageDetail: {
      id: defaults ? defaults.imageDetailId : undefined,
      facetFieldTypeId: defaults ? defaults.facetFieldTypeId : type.id,
      name: 'imageDetail',
      order,
      value: defaults ? defaults.imageDetail : '',
    },
    imageLabel: {
      id: defaults ? defaults.imageLabelId : undefined,
      facetFieldTypeId: defaults ? defaults.facetFieldTypeId : type.id,
      name: 'imageLabel',
      order,
      value: defaults ? defaults.imageLabel : '',
    },
    urlInput: {
      id: defaults ? defaults.urlInputId : undefined,
      facetFieldTypeId: defaults ? defaults.facetFieldTypeId : type.id,
      name: 'urlInput',
      order,
      value: defaults ? defaults.urlInput : '',
    },
    sourceUrl: {
      id: defaults ? defaults.sourceUrlId : undefined,
      facetFieldTypeId: defaults ? defaults.facetFieldTypeId : type.id,
      name: 'sourceUrl',
      order,
      value: defaults ? defaults.sourceUrl : '',
    },
    key: {
      id: defaults ? defaults.keyId : undefined,
      facetFieldTypeId: defaults ? defaults.facetFieldTypeId : type.id,
      name: 'key',
      order,
      value: defaults ? defaults.key : '',
      href: defaults ? defaults.href : '',
    },
    sourceKey: {
      id: defaults ? defaults.sourceKeyId : undefined,
      facetFieldTypeId: defaults ? defaults.facetFieldTypeId : type.id,
      name: 'sourceKey',
      order,
      value: defaults ? defaults.sourceKey : '',
      href: defaults ? defaults.sourceHref : '',
    },
  }),
  Provenance: ({ type, defaults, order }) => ({
    analystNotes: {
      id: defaults ? defaults.analystNotesId : undefined,
      facetFieldTypeId: defaults ? defaults.facetFieldTypeId : type.id,
      name: 'analystNotes',
      order,
      value: defaults ? defaults.analystNotes : '',
    },
    date: {
      id: defaults ? defaults.dateId : undefined,
      facetFieldTypeId: defaults ? defaults.facetFieldTypeId : type.id,
      name: 'date',
      order,
      value: defaults ? defaults.date : '',
    },
    datePublished: {
      id: defaults ? defaults.datePublishedId : undefined,
      facetFieldTypeId: defaults ? defaults.facetFieldTypeId : type.id,
      name: 'datePublished',
      order,
      value: defaults ? defaults.datePublished : '',
    },
    key: {
      id: defaults ? defaults.keyId : undefined,
      facetFieldTypeId: defaults ? defaults.facetFieldTypeId : type.id,
      name: 'key',
      order,
      value: defaults ? defaults.key : '',
      href: defaults ? defaults.href : '',
    },
    format: {
      id: defaults ? defaults.formatId : undefined,
      facetFieldTypeId: defaults ? defaults.facetFieldTypeId : type.id,
      name: 'format',
      order,
      value: defaults ? defaults.format : '',
    },
    urlInput: {
      id: defaults ? defaults.urlInputId : undefined,
      facetFieldTypeId: defaults ? defaults.facetFieldTypeId : type.id,
      name: 'urlInput',
      order,
      value: defaults ? defaults.urlInput : '',
    },
  }),
  Statistic: ({ type, defaults, order }) => ({
    compare: {
      id: defaults ? defaults.compareId : undefined,
      facetFieldTypeId: defaults ? defaults.facetFieldTypeId : type.id,
      name: 'compare',
      order,
      value: defaults ? defaults.compare : '',
    },
    crossLink: {
      id: defaults ? defaults.crossLinkId : undefined,
      facetFieldTypeId: defaults ? defaults.facetFieldTypeId : type.id,
      name: 'crossLink',
      order,
      value: defaults ? defaults.crossLink : '',
    },
    rankFormat: {
      id: defaults ? defaults.rankFormatId : undefined,
      facetFieldTypeId: defaults ? defaults.facetFieldTypeId : type.id,
      name: 'rankFormat',
      order,
      value: defaults ? defaults.rankFormat : '',
    },
    rankSet: {
      id: defaults ? defaults.rankSetId : undefined,
      facetFieldTypeId: defaults ? defaults.facetFieldTypeId : type.id,
      name: 'rankSet',
      order,
      value: defaults ? defaults.rankSet : '',
    },
    rankTotal: {
      id: defaults ? defaults.rankTotalId : undefined,
      facetFieldTypeId: defaults ? defaults.facetFieldTypeId : type.id,
      name: 'rankTotal',
      order,
      value: defaults ? defaults.rankTotal : '',
    },
    value: {
      id: defaults ? defaults.valueId : undefined,
      facetFieldTypeId: defaults ? defaults.facetFieldTypeId : type.id,
      name: 'value',
      order,
      value: defaults ? defaults.value : '',
    },
    units: {
      id: defaults ? defaults.unitsId : undefined,
      facetFieldTypeId: defaults ? defaults.facetFieldTypeId : type.id,
      name: 'units',
      order,
      value: defaults ? defaults.units : '',
    },
  }),
  default: () => ({}),
};

const prepareFields = ({ type, fields, innerOrder }) => {
  let defaults = null;
  if (FIELDS[type.name]) {
    FIELDS[type.name].forEach((name) => {
      const field = fields.find((f) => f.name === name);
      if (field) {
        defaults = {
          ...defaults,
          [name]: field.value,
          [`${name}Id`]: field.id,
          order: innerOrder,
          facetFieldTypeId: field.facetFieldTypeId,
        };
        if (field.href && name !== 'sourceKey') {
          defaults.href = field.href;
        }
        if (name === 'sourceKey' && field.href) {
          defaults.sourceHref = field.href;
        }
      }
    });
  }
  return fieldsGetter[type.name]
    ? fieldsGetter[type.name]({ type, defaults, order: innerOrder })
    : fieldsGetter.default();
};

const createFields = ({ type, innerOrder }) => (fieldsGetter[type.name]
  ? fieldsGetter[type.name]({ type, order: innerOrder })
  : fieldsGetter.default());

export {
  prepareFields,
  createFields,
  FIELDS,
};
