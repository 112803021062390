/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React, { useRef, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { Button } from '@axis/xyz.admin.buttons.button';
import { ArticleSummary } from '@axis/xyz.admin.article-summary';
import { ContentSeparator } from '@axis/xyz.admin.content-separator';
import { CheckboxCircle } from '@axis/xyz.admin.form.checkbox-circle';
import { Typography } from '@axis/xyz.admin.typography';
import { useHistory } from 'react-router-dom';
import { ButtonText } from '@axis/xyz.admin.buttons.button-text';
import { STORAGE_ADMIN_FEED_REGULATIONS, STORAGE_ADMIN_FEED_REGULATION_ARTICLES } from '../../common/utils/storage';
import ConfirmModal from '../../components/ConfirmModal/ConfirmModal';
import EndOfContent from '../../components/EndOfContent/EndOfContent';
import ArticleModal from '../../components/Article/Article';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import {
  fetchRegulationsPage, getRegulationsInfo, getRegulationTags, getFilterCount, getFilterDescription, processRegulations,
} from './utils';
import { formatDateTime, parseDate } from '../../common/utils/date';
import css from './RegulationsFeedList.module.css';
import ModalEditFeedFilters from '../../modals/ModalEditFeedFilters/ModalEditFeedFilters';
import useLocalStorage from '../../hooks/useLocalStorage';
import { REGULATION_FEED_TYPES } from '../../constants';

const RegulationSeparator = ({ index, mainText, secondaryText }) => {
  if (index !== 0) return <hr className={css.separator} />;
  return <ContentSeparator mainText={mainText} secondaryText={secondaryText} />;
};

const RegulationsFeedList = ({ type }) => {
  const history = useHistory();

  const [isFiltersOpen, setFiltersOpen] = useState(false);

  const [filters, setFilters] = useState({
    since: new Date().getTime(),
    date: null,
    topicId: '',
    sectorBriefs: [],
    subsectorBriefs: [],
    industryBriefs: [],
  });

  const totalFilters = getFilterCount(filters);
  const filterDescription = getFilterDescription(filters, type);

  const [selectedRegulations, setSelectedRegulations] = useLocalStorage(type === REGULATION_FEED_TYPES.REGULATION ? STORAGE_ADMIN_FEED_REGULATIONS : STORAGE_ADMIN_FEED_REGULATION_ARTICLES, []);

  const [modalDetails, setModalDetails] = useState({ open: false, regulation: null });

  const loadNextRef = useRef();

  const {
    data: regulations = {},
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ['regulations', filters],
    ({ pageParam }) => fetchRegulationsPage({
      topicId: filters.topicId,
      since: filters.since,
      date: filters.date,
      briefIds: [
        ...filters.sectorBriefs.map((brief) => brief.briefIds).flat(),
        ...filters.subsectorBriefs.map((brief) => brief.briefIds).flat(),
        ...filters.industryBriefs.map((brief) => brief.briefIds).flat(),
      ],
      pageParam,
    }, type),
    {
      getNextPageParam: (lastPage, pages) => (lastPage.hasMore ? ({ page: pages.length + 1 }) : undefined),
    },
  );

  useIntersectionObserver({
    target: loadNextRef,
    onIntersect: fetchNextPage,
    enabled: hasNextPage,
  });

  const handleArticleCheckboxClick = (regulation) => {
    setSelectedRegulations((currentRegulations) => {
      if (currentRegulations.includes(regulation.id)) {
        return currentRegulations.filter((e) => e !== regulation.id);
      }
      return [...currentRegulations, regulation.id].sort();
    });
  };

  const handleArticleModalConfirm = () => {
    setSelectedRegulations((currentRegulations) => {
      if (currentRegulations.includes(modalDetails.regulation.id)) return currentRegulations;
      return [...currentRegulations, modalDetails.regulation.id];
    });
    setModalDetails((currentDetails) => ({ ...currentDetails, open: false }));
  };

  const groupedRegulations = processRegulations(regulations.pages || []);

  return (
    <>
      <div className={css.container}>
        <section className={css.header}>
          <div className={css.pills} />
          <div className={css.actions} />
        </section>
        <section>
          <div className={css.actions}>
            <Typography
              className={css.title}
              tag="h1"
              type="title"
              size="xl"
            >
              Latest regulations
            </Typography>
            <div className={css.buttons}>
              <ButtonText
                data-cy="regulations-manually-create"
                label="Manually create"
                className={css.buttonSecondary}
                onClick={() => history.push('/analyst/feed/regulations/manually-create')}
              />
              <Button
                className={css.buttonPrimary}
                data-cy="regulations-review"
                label="Review"
                disabled={selectedRegulations.length === 0}
                badge={`${selectedRegulations.length}`}
                onClick={() => history.push(`/analyst/feed/regulations/review/${type}`)}
              />
              <Button
                className={css.buttonPrimary}
                data-cy="regulations-create"
                label="Create"
                disabled={selectedRegulations.length === 0}
                badge={`${selectedRegulations.length}`}
                onClick={() => history.push(`/analyst/feed/regulations/create/${type}`)}
              />
            </div>
          </div>
          <hr className={css.titleSeparator} />
          <div className={css.filters}>
            <p className={css.filterDescription}>
              {filterDescription}
            </p>
            <ButtonText
              className={css.filterEdit}
              type="button"
              color="primary"
              label={totalFilters > 0 ? `Edit filters (${totalFilters})` : 'Filter regulations'}
              onClick={() => setFiltersOpen(true)}
            />
          </div>
          {groupedRegulations.map((regulationsDate) => (
            <React.Fragment key={regulationsDate.date}>
              {regulationsDate.regulations.map((regulation, index) => (
                <React.Fragment key={regulation.id}>
                  <RegulationSeparator
                    index={index}
                    mainText={regulationsDate.date}
                    secondaryText={getRegulationsInfo(regulationsDate.regulations.length)}
                  />
                  <div className={css.regulation}>
                    <ArticleSummary
                      title={regulation.title}
                      isUsed={regulation.isUsed}
                      summary={regulation.summary}
                      source={regulation.source}
                      favicon={regulation.favicon}
                      isTranslated={regulation.isTranslated}
                      date={formatDateTime(parseDate(regulation.date))}
                      image={regulation.image}
                      tags={getRegulationTags(regulation)}
                      onClick={() => setModalDetails({ open: true, regulation })}
                    />
                    <div className={css.input}>
                      <CheckboxCircle
                        data-cy="radio-regulation"
                        id={regulation.id}
                        value={regulation.id}
                        checked={selectedRegulations.includes(regulation.id)}
                        onChange={() => handleArticleCheckboxClick(regulation)}
                      />
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
          <div className={css.end} ref={loadNextRef}>
            <EndOfContent
              isError={false}
              isLoading={isFetching || isFetchingNextPage}
              isEnd={!hasNextPage}
              onClick={fetchNextPage}
            />
          </div>
        </section>
        <ConfirmModal
          open={modalDetails.open}
          okLabel="Select and Close"
          cancelLabel="Close"
          onOk={handleArticleModalConfirm}
          onCancel={() => setModalDetails((currentDetails) => ({ ...currentDetails, open: false }))}
        >
          <ArticleModal article={modalDetails.regulation} />
        </ConfirmModal>
      </div>
      <ModalEditFeedFilters
        title="Regulation Filters"
        filters={filters}
        visible={isFiltersOpen}
        onCancel={() => setFiltersOpen(false)}
        onConfirm={(newFilters) => {
          setFiltersOpen(false);
          setFilters(newFilters);
        }}
        onClear={() => {
          setFiltersOpen(false);
          setSelectedRegulations([]);
        }}
      />
    </>
  );
};

export default RegulationsFeedList;
