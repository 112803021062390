import React from 'react';

const Entity = ({ name }) => <div>{name}</div>;

const formatter = ({ row: { entity } }) => entity && (<Entity key={entity.id} name={entity.name} />);

const column = {
  key: 'entity',
  name: 'Entity',
  editable: false,
  sortable: false,
  resizable: true,
  width: 150,
  formatter,
};

export default column;
