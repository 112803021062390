/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { Button } from '@axis/xyz.admin.buttons.button';
import { ArticleSummary } from '@axis/xyz.admin.article-summary';
import { ContentSeparator } from '@axis/xyz.admin.content-separator';
import { CheckboxCircle } from '@axis/xyz.admin.form.checkbox-circle';
import { ButtonText } from '@axis/xyz.admin.buttons.button-text';
import { Loading } from '@axis/xyz.admin.loading';
import { Typography } from '@axis/xyz.admin.typography';
import { Alert } from '@axis/xyz.admin.alert';
import { useHistory } from 'react-router-dom';
import { STORAGE_ADMIN_FEED_ARTICLES } from '../../common/utils/storage';
import ConfirmModal from '../../components/ConfirmModal/ConfirmModal';
import ArticleModal from '../../components/Article/Article';
import {
  fetchSelectedArticles, getArticlesInfo, getArticleTags, processArticles,
} from './utils';
import { formatDateTime, parseDate } from '../../common/utils/date';
import style from './ArticlesFeedReview.module.css';
import useLocalStorage from '../../hooks/useLocalStorage';

const ArticleSeparator = ({ index, mainText, secondaryText }) => {
  if (index !== 0) return <hr className={style.separator} />;
  return <ContentSeparator mainText={mainText} secondaryText={secondaryText} />;
};

const EndOfContent = ({
  isError, isLoading,
}) => {
  if (isError) {
    return (
      <Alert className={style.alert} type="error">
        Seems the connection was lost!
        <br />
        Refresh the page or come back later
      </Alert>
    );
  }
  if (isLoading) return <Loading className={style.loading} />;
  return (
    <Alert className={style.alert} type="success">
      You got to the end of the list.
    </Alert>
  );
};

const ArticlesFeedReview = () => {
  const history = useHistory();

  const [selectedArticles, setSelectedArticles] = useLocalStorage(STORAGE_ADMIN_FEED_ARTICLES, []);
  const [modalDetails, setModalDetails] = useState({ open: false, article: null });

  const loadNextRef = useRef();

  const {
    data: articles = [],
    isLoading,
    isError,
    isSuccess,
  } = useQuery(
    ['selectedArticles', selectedArticles],
    () => fetchSelectedArticles({ ids: selectedArticles }),
  );

  useEffect(() => {
    if (selectedArticles.length === 0 || (articles.length === 0 && !isSuccess)) return;
    const existingIds = articles.map((article) => article.id);
    setSelectedArticles(existingIds);
  }, [articles]);

  const handleArticleCheckboxClick = (article) => {
    setSelectedArticles((currentArticles) => {
      if (currentArticles.includes(article.id)) {
        return currentArticles.filter((e) => e !== article.id);
      }
      return [...currentArticles, article.id];
    });
  };

  const handleArticleModalConfirm = () => {
    setSelectedArticles((currentArticles) => {
      if (currentArticles.includes(modalDetails.article.id)) return currentArticles;
      return [...currentArticles, modalDetails.article.id];
    });
    setModalDetails((currentDetails) => ({ ...currentDetails, open: false }));
  };

  const groupedArticles = processArticles({
    selectedArticles: articles,
    selectedIds: selectedArticles,
  });

  return (
    <div className={style.container}>
      <section className={style.header}>
        <div className={style.actions}>
          <ButtonText
            data-cy="feed-manually-create"
            label="Manually create"
            className={style.buttonSecondary}
            onClick={() => history.push('/analyst/feed/manually-create')}
          />
          <Button
            data-cy="feed-create"
            label="Create"
            disabled={selectedArticles.length === 0}
            badge={`${selectedArticles.length}`}
            onClick={() => history.push('/analyst/feed/create')}
          />
        </div>
      </section>
      <section>
        <Typography
          className={style.title}
          tag="h1"
          type="title"
          size="xl"
        >
          Articles to be reviewed
        </Typography>
        <Typography
          className={style.subtitle}
          tag="p"
          type="text"
          size="md"
        >
          List of articles that you selected to be used
        </Typography>
        <hr className={style.titleSeparator} />
        {groupedArticles.map((articlesDate) => (
          <React.Fragment key={articlesDate.date}>
            {articlesDate.articles.map((article, index) => (
              <React.Fragment key={article.id}>
                <ArticleSeparator
                  index={index}
                  mainText={articlesDate.date}
                  secondaryText={getArticlesInfo(articlesDate.articles.length)}
                />
                <div className={style.article}>
                  <ArticleSummary
                    title={article.title}
                    isUsed={article.isUsed}
                    summary={article.summary}
                    source={article.source}
                    favicon={article.favicon}
                    isTranslated={article.isTranslated}
                    date={formatDateTime(parseDate(article.date))}
                    image={article.image}
                    tags={getArticleTags(article)}
                    onClick={() => setModalDetails({ open: true, article })}
                  />
                  <div className={style.input}>
                    <CheckboxCircle
                      id={article.id}
                      value={article.id}
                      checked={selectedArticles.includes(article.id)}
                      onChange={() => handleArticleCheckboxClick(article)}
                    />
                  </div>
                </div>
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
        <div className={style.end} ref={loadNextRef}>
          <EndOfContent
            isError={isError}
            isLoading={isLoading}
          />
        </div>
      </section>
      <ConfirmModal
        open={modalDetails.open}
        cancelLabel="Close"
        onOk={handleArticleModalConfirm}
        onCancel={() => setModalDetails((currentDetails) => ({ ...currentDetails, open: false }))}
      >
        <ArticleModal article={modalDetails.article} />
      </ConfirmModal>
    </div>
  );
};

export default ArticlesFeedReview;
