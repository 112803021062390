/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classnames from 'classnames';
import css from './Text.module.css';
import Container from './Container';
import fontSize from './fontSize.module.css';
import { fontColor } from './colors';

const Text = ({
  Component,
  color,
  size,
  span,
  bold,
  italic,
  inline,
  inlineBlock,
  children,
  className,
  extraSmall,
  small,
  large,
  extraLarge,
  uppercase,
  capitalize,
  centered,
  noWrap,
  ...props
}) => (
  <Container
    {...props}
    Component={span ? 'span' : Component}
    className={classnames(className, css.text, fontColor[color], css[size], {
      [fontSize.extraSmall]: extraSmall,
      [fontSize.small]: small,
      [fontSize.medium]: !small && !extraSmall && !large && !extraLarge,
      [fontSize.large]: large,
      [fontSize.extraLarge]: extraLarge,
      [css.bold]: bold,
      [css.italic]: italic,
      [css.inline]: inline,
      [css.inlineBlock]: inlineBlock,
      [css.uppercase]: uppercase,
      [css.capitalize]: capitalize,
      [css.centered]: centered,
      [css.noWrap]: noWrap,
    })}
  >
    {children}
  </Container>
);

Text.defaultProps = {
  Component: 'div',
};

export default Text;
