import React, { useEffect, useState } from 'react';
import { Modal } from '@axis/xyz.admin.modal';
import { Typography } from '@axis/xyz.admin.typography';
import { FormItemWrapper } from '@axis/xyz.admin.form.form-item-wrapper';
import { TextInput } from '@axis/xyz.admin.form.text-input';
import { Button } from '@axis/xyz.admin.buttons.button';
import { useQuery } from 'react-query';
import css from './ModalEditRSSSource.module.css';
import { fetchRSSSource } from './utils';
import SearchSelect from '../../components/Form/SearchSelect/SearchSelect';

const ModalEditRSSSource = ({
  id, visible, onUpdate, onClose, sourceType,
}) => {
  const [source, setSource] = useState({
    name: '',
    websiteUrl: '',
    language: '',
    topics: [],
    type: sourceType,
  });

  const {
    data: sourceDetails = {},
  } = useQuery(
    ['rss-source', id],
    () => fetchRSSSource({ id }),
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    if (sourceDetails && sourceDetails.id) {
      setSource({
        name: `${sourceDetails.name}`,
        websiteUrl: sourceDetails.websiteUrl,
        language: sourceDetails.language,
        type: sourceDetails.type,
        topics: sourceDetails.topics.map((topic) => ({ id: topic.topicId, name: topic.topicName })),
      });
    }
  }, [sourceDetails]);

  useEffect(() => {
    if (!visible) {
      setSource({
        name: '',
        websiteUrl: '',
        language: '',
        topics: [],
        type: sourceType,
      });
    }
  }, [visible]);

  const handleSubmit = () => {
    onUpdate({
      id,
      name: source.name,
      websiteUrl: source.websiteUrl,
      language: source.language,
      type: source.type,
      topics: source.topics.map((topic) => ({ topicId: topic.id, topicName: topic.name })),
    });
  };

  const handleInputChange = (field, value) => {
    setSource({ ...source, [field]: value });
  };

  const submitEnabled = (source.name && source.websiteUrl && source.language && source.type && source.topics.length);

  return (
    <Modal
      name="edit-rss-source"
      className={css.modal}
      visible={visible}
      onClose={onClose}
    >
      <div className={css.content}>
        <Typography
          className={css.title}
          type="title"
          tag="h2"
          size="lg"
        >
          Update source
        </Typography>
        <div className={css.form}>
          <FormItemWrapper
            className={css.input}
            isRequired
            label="Name"
          >
            <TextInput
              name="source-rss"
              value={source.name || ''}
              onChange={(e) => handleInputChange('name', e.target.value)}
            />
          </FormItemWrapper>
          <FormItemWrapper
            className={css.input}
            isRequired
            label="Website URL"
          >
            <TextInput
              name="source-website"
              value={source.websiteUrl || ''}
              onChange={(e) => handleInputChange('websiteUrl', e.target.value)}
            />
          </FormItemWrapper>
          <FormItemWrapper
            className={css.input}
            isRequired
            label="Language of origin"
          >
            <TextInput
              name="source-lang"
              value={source.language || ''}
              onChange={(e) => handleInputChange('language', e.target.value)}
            />
          </FormItemWrapper>
          <FormItemWrapper
            className={css.input}
            isRequired
            label="Source Topics"
          >
            <SearchSelect
              name="source-topics"
              isMulti
              value={source.topics || []}
              onChange={(value) => handleInputChange('topics', value)}
              getOptionLabel={((topic) => topic.name)}
              searchUrl="/countries?$limit=50&name[$iLike]="
            />
          </FormItemWrapper>
          <Button
            className={css.submit}
            disabled={!submitEnabled}
            onClick={handleSubmit}
            label="Save"
            type="button"
          />
        </div>
      </div>
    </Modal>
  );
};

export default ModalEditRSSSource;
