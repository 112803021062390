import React from 'react';
import styled from 'styled-components';
import emptyBlue from '../../../img/unchecked-blue.svg';
import fillBlue from '../../../img/checked-blue.svg';
import emptyRed from '../../../img/unchecked-red.svg';
import { STATUSES } from '../utils/isFacetFilled.js';

const Img = styled.img`
  height: 16px;
  margin-right: 8px;
  margin-left: 2px;
`;

const Icon = ({ status }) => {
  if (status === STATUSES.BLUE_EMPTY) return <Img src={emptyBlue} />;
  if (status === STATUSES.BLUE) return <Img src={fillBlue} />;
  return <Img src={emptyRed} />;
};

export default Icon;
