import React, { useEffect, useState } from 'react';
import { Typography } from '@axis/xyz.admin.typography';
import { Button } from '@axis/xyz.admin.buttons.button';
import { TextInput } from '@axis/xyz.admin.form.text-input';
import { FormItemWrapper } from '@axis/xyz.admin.form.form-item-wrapper';
import { DateInput } from '@axis/xyz.admin.form.date-input';
import isURL from 'validator/lib/isURL';
import SearchSelect from '../../components/Form/SearchSelect/SearchSelect';
import Source from '../../components/Form/Source/Source';
import Location from '../../components/Form/Location/Location';
import { Events as FieldHint } from '../../components/Form/FieldHint/FieldHint';
import AttachmentField from '../../components/Form/AttachmentField/AttachmentField';
import getEntityOptionLabel from '../../common/utils/getEntityOptionLabel';
import { ROLE_ADMIN } from '../../common/Enums/Roles';
import css from './EventForm.module.css';
import { getFormErrors } from './utils';

const getBriefQueryByRole = (userRole) => {
  if (userRole === ROLE_ADMIN) {
    return '/briefs?$limit=100&name=';
  }
  return '/briefs?type[]=industry&type[]=core&$limit=100&name=';
};

const QUERIES = {
  FIND_ENTITIES: '/entity-infos?type[$ne]=Brief&$limit=50&name[$iLike]=',
  FIND_TOPICS: '/countries?$limit=50&name[$iLike]=',
};

const EventForm = ({
  event,
  onChange,
  onSubmit,
  buttonLabel = 'Publish',
  submitEnabled = true,
  title = 'Event',
  userRole,
}) => {
  const [formErrors, setFormErrors] = useState([]);

  const [newSource, setNewSource] = useState('');
  const [newSourceError, setNewSourceError] = useState(false);

  useEffect(() => {
    setNewSourceError(false);
  }, [newSource]);

  useEffect(() => setFormErrors([]), [event.articleId]);

  const handleSubmitClick = () => {
    const errors = getFormErrors(event);
    if (errors.length === 0) {
      onSubmit();
      setNewSource('');
    } else {
      setFormErrors(errors);
    }
  };

  const handleInputChange = (field, value) => {
    if (formErrors.find((e) => e.field === field)) {
      if (['sources', 'attachments'].includes(field)) {
        setFormErrors((errors) => errors.filter((e) => !(['sources', 'attachments'].includes(e.field))));
      } else {
        setFormErrors((errors) => errors.filter((e) => e.field !== field));
      }
    }
    onChange(field, value);
  };

  const getErrorMessage = (field) => {
    const error = formErrors.find((e) => e.field === field);
    if (!error) return null;
    return error.message;
  };

  const handleNewSourceSubmit = () => {
    if (!isURL(newSource)) {
      setNewSourceError(true);
    } else {
      handleInputChange('sources', [...(event.sources || []), newSource]);
      setNewSource('');
    }
  };

  const handleSourceRemove = (source) => {
    handleInputChange('sources', (event.sources || []).filter((s) => s !== source));
  };

  return (
    <div className={css.container}>
      <div className={`${css.row} ${css.titleContainer}`}>
        <Typography
          type="title"
          tag="h2"
          size="xl"
          className={css.title}
        >
          {title}
        </Typography>
      </div>
      <FormItemWrapper
        className={css.input}
        label="Topic"
        isRequired
        helpMessage={<FieldHint.Country />}
        helpMessageTitle="Topic"
        errorMessage={getErrorMessage('topic')}
      >
        <SearchSelect
          name="event-topics"
          isMulti
          value={event.topics || {}}
          onChange={(value) => handleInputChange('topics', value)}
          searchUrl={QUERIES.FIND_TOPICS}
          getOptionLabel={getEntityOptionLabel}
        />
      </FormItemWrapper>
      <FormItemWrapper
        className={css.input}
        isRequired
        label="Entities"
        helpMessage={<FieldHint.Entities />}
        helpMessageTitle="Entities"
        errorMessage={getErrorMessage('entities')}
      >
        <SearchSelect
          name="event-entities"
          isMulti
          value={event.entities || []}
          onChange={(value) => handleInputChange('entities', value)}
          getOptionLabel={getEntityOptionLabel}
          searchUrl={QUERIES.FIND_ENTITIES}
        />
      </FormItemWrapper>
      <FormItemWrapper
        className={css.input}
        isRequired
        label="Event Title"
        helpMessage={<FieldHint.Title />}
        helpMessageTitle="Event Title"
        errorMessage={getErrorMessage('title')}
        maxLength={`${300 - (event.title || '').length}`}
      >
        <TextInput
          name="event-title"
          multiline
          value={event.title || ''}
          onChange={(e) => handleInputChange('title', e.target.value)}
          maxLength={300}
        />
      </FormItemWrapper>
      <FormItemWrapper
        className={css.input}
        label="Description"
        helpMessage={<FieldHint.Description />}
        helpMessageTitle="Description"
        errorMessage={getErrorMessage('description')}
        maxLength={`${200 - (event.description || '').length}`}
      >
        <TextInput
          name="event-description"
          multiline
          value={event.description || ''}
          onChange={(e) => handleInputChange('description', e.target.value)}
          maxLength={200}
        />
      </FormItemWrapper>
      <FormItemWrapper
        className={`${css.input} ${css.location}`}
        label="Location"
        helpMessage={<FieldHint.Location />}
        helpMessageTitle="Location"
      >
        <Location
          values={event.locations || []}
          onChange={(locations) => handleInputChange('locations', locations)}
        />
      </FormItemWrapper>
      <FormItemWrapper
        className={css.input}
        label="Briefs"
        isRequired
        helpMessage={<FieldHint.Briefs />}
        helpMessageTitle="Briefs"
        errorMessage={getErrorMessage('briefs')}
      >
        <SearchSelect
          name="event-briefs"
          isMulti
          value={event.briefs || []}
          onChange={(value) => handleInputChange('briefs', value)}
          searchUrl={getBriefQueryByRole(userRole)}
          getOptionLabel={getEntityOptionLabel}
        />
      </FormItemWrapper>
      <FormItemWrapper
        className={css.input}
        label="Date"
        isRequired
        helpMessage={<FieldHint.Date />}
        helpMessageTitle="Date"
        errorMessage={getErrorMessage('date')}
      >
        <DateInput
          name="event-date"
          placeholder="Event date"
          value={event.date}
          onChange={(date) => handleInputChange('date', date)}
          onClear={() => handleInputChange('date', null)}
          valuePosition="right"
          closeOnValueSelect
        />
      </FormItemWrapper>
      <FormItemWrapper
        className={css.input}
        label="Event source"
        isRequired
        helpMessage={<FieldHint.Source />}
        helpMessageTitle="Sources"
        errorMessage={getErrorMessage('sources')}
      >
        <Source
          sources={event.sources || []}
          newSource={newSource}
          error={newSourceError}
          onNewSourceChange={setNewSource}
          onNewSourceSubmit={handleNewSourceSubmit}
          onSourceRemove={handleSourceRemove}
        />
      </FormItemWrapper>
      <FormItemWrapper
        className={css.input}
        label="Attachments"
        helpMessage={<FieldHint.Attachments />}
        helpMessageTitle="Attachments"
        errorMessage={getErrorMessage('attachments')}
      >
        <AttachmentField
          values={event.attachments}
          onChange={(attachments) => handleInputChange('attachments', attachments)}
        />
      </FormItemWrapper>
      <Button
        className={css.button}
        disabled={!submitEnabled || formErrors.length > 0}
        onClick={handleSubmitClick}
        label={buttonLabel}
        type="button"
      />
    </div>
  );
};

export default EventForm;
