const ANALYST_BRIEF_TAG = ['Core Brief Membership', 'Industry Brief', 'Macro Brief Membership', 'Country Brief Membership', 'Industry Brief Membership'];
const ADMIN_BRIEF_TAG = [...ANALYST_BRIEF_TAG, 'Sector Brief', 'Sub-Sector Brief Membership', 'Sector Brief Membership'];
// Brief Membership Facets Ids
const BRIEF_RELATIONSHIP_ID = [
  '1b4de5f4-67fa-4d01-970a-f8615094ea7d',
  '3dc63c0e-e898-40e1-8b05-699c361cfd80',
  '48be6edf-84b9-4064-962f-bff1f1b8f35b',
  '4ecb2e45-b3be-42de-81bb-82627bbed83c',
  'a355debe-121f-4773-a12f-ce811b9f30eb',
  'bbf2d44f-e1d0-42ff-ad75-caef644acc39',
  'd998953b-8bb9-4cdb-90aa-7ece78827f6e',
];

export { ANALYST_BRIEF_TAG, ADMIN_BRIEF_TAG, BRIEF_RELATIONSHIP_ID };
