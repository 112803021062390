export const containerStyles = {
  paddingTop: 17,
  paddingLeft: 26,
  paddingRight: 26,
  position: 'relative',
  zIndex: 1,
};

export const selectStyles = {
  width: '100%',
  color: '#000',
  fontSize: 14,
  backgroundColor: 'white',
};

export const menuStyles = {
  paddingTop: 14,
};

export const subMenuStyles = {
  paddingBottom: 20,
};
