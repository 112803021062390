import { useEffect, useState } from 'react';
import { getStoredItem, setStoredItem } from '../common/utils/storage';

const useLocalStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(initialValue);

  useEffect(() => {
    try {
      const item = getStoredItem(key, initialValue);
      setStoredValue(item);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    setStoredItem(key, storedValue);
  }, [storedValue]);

  return [storedValue, setStoredValue];
};

export default useLocalStorage;
