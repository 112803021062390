import React from 'react';
import { Modal as ModalAntd } from 'antd';

const MODAL_WIDTH = 823;
const MASK_STYLES = { backgroundColor: 'var(--color-secondary-dark)' };

const Modal = ({
  open,
  onOk,
  onCancel,
  children,
  className,
  footer = null,
  closable = false,
  forceRender = false,
  width = MODAL_WIDTH,
  maskStyle = MASK_STYLES,
}) => (
  <ModalAntd
    width={width}
    className={className}
    visible={open}
    onOk={onOk}
    onCancel={onCancel}
    footer={footer}
    closable={closable}
    maskStyle={maskStyle}
    forceRender={forceRender}
  >
    {children}
  </ModalAntd>
);

export default Modal;
