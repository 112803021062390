import React from 'react';

const formatter = ({ row: { label } }) => (<div title={label}>{label}</div>);

const labelColumn = {
  key: 'label',
  name: 'Label',
  filterable: true,
  sortable: false,
  resizable: true,
  editable: true,
  width: 150,
  formatter,
  draggable: true,
};

export default labelColumn;
