import React, { useEffect, useState } from 'react';
import { Modal } from '@axis/xyz.admin.modal';
import { Typography } from '@axis/xyz.admin.typography';
import { FormItemWrapper } from '@axis/xyz.admin.form.form-item-wrapper';
import { TextInput } from '@axis/xyz.admin.form.text-input';
import { ButtonIcon } from '@axis/xyz.admin.buttons.button-icon';
import { Button } from '@axis/xyz.admin.buttons.button';
import css from './ModalNewLandscape.module.css';

const Item = ({ item, onClick }) => (
  <div className={css.item}>
    <Typography className={css.itemText} help={item}>{item}</Typography>
    <ButtonIcon
      icon="close"
      help="Remove item"
      className={css.buttonRemove}
      onClick={() => onClick(item)}
    />
  </div>
);

const ModalNewLandscape = ({
  visible, onCreate, onClose,
}) => {
  const [landscape, setLandscape] = useState({
    name: '',
    alternative_names: [],
  });

  const [newAlternativeName, setNewAlternativeName] = useState('');
  const [newKeyword, setNewKeyword] = useState('');

  const handleNewAlternativeNameSubmit = () => {
    setLandscape({ ...landscape, alternative_names: [...landscape.alternative_names, newAlternativeName] });
    setNewAlternativeName('');
  };

  const handleNewAlternativeNameRemoval = (alternativeName) => {
    setLandscape({ ...landscape, alternative_names: landscape.alternative_names.filter((altName) => altName !== alternativeName) });
    setNewAlternativeName('');
  };

  const onKeyUp = (event, type) => {
    const ENTER_KEYCODE = 13;
    const { keyCode } = event;
    if (keyCode === ENTER_KEYCODE) {
      if (type === 'alternative_name') handleNewAlternativeNameSubmit();
    }
  };

  useEffect(() => {
    setLandscape({
      name: '',
      alternative_names: [],
    });
  }, [visible]);

  const handleInputChange = (field, value) => {
    setLandscape({ ...landscape, [field]: value });
  };

  const submitEnabled = !!landscape.name;

  const handleCreate = async () => {
    onCreate({
      name: landscape.name,
      alternative_names: landscape.alternative_names,
    });
  };

  return (
    <Modal
      name="new-landscape"
      className={css.modal}
      visible={visible}
      onClose={onClose}
    >
      <div className={css.content}>
        <Typography
          className={css.title}
          type="title"
          tag="h2"
          size="lg"
        >
          Create a new landscape
        </Typography>
        <div className={css.form}>
          <FormItemWrapper
            className={css.input}
            isRequired
            label="Name"
          >
            <TextInput
              name="landscape-name"
              placeholder="Landscape name"
              value={landscape.name || ''}
              onChange={(e) => handleInputChange('name', e.target.value)}
            />
          </FormItemWrapper>

          <FormItemWrapper
            className={css.inputContainer}
            label="Alternative Names"
          >
            <TextInput
              placeholder="Alternative name"
              name="landscape-alternative-name"
              value={newAlternativeName}
              onChange={(event) => setNewAlternativeName(event.target.value)}
              onKeyUp={(event) => onKeyUp(event, 'alternative_name')}
            />
            <div className={css.itemList}>
              {landscape.alternative_names.map((alternativeName) => (
                <Item key={alternativeName} item={alternativeName} onClick={() => handleNewAlternativeNameRemoval(alternativeName)} />
              ))}
            </div>
          </FormItemWrapper>

          <Button
            className={css.submit}
            disabled={!submitEnabled}
            onClick={handleCreate}
            label="Create"
            type="button"
          />
        </div>
      </div>
    </Modal>
  );
};

export default ModalNewLandscape;
