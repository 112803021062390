import { DateTime } from 'luxon';

export const generateDate = () => DateTime.now();

export const parseDate = (date) => DateTime.fromISO(date);

export const parseDateIgnoreTZ = (date) => (typeof date === 'string' ? DateTime.fromISO(date.slice(0, 19)) : date);

export const convertDate = (date) => DateTime.fromJSDate(date);

export const transformDateTZ = (date) => {
  const dateWithOffset = new Date(date.getTime() + 1000 * 60 * date.getTimezoneOffset());
  return new Date(
    Date.UTC(
      dateWithOffset.getFullYear(),
      dateWithOffset.getMonth(),
      dateWithOffset.getDate(),
      dateWithOffset.getHours(),
      dateWithOffset.getMinutes(),
      dateWithOffset.getSeconds(),
    ),
  );
};

export const startOfDay = (date) => date.startOf('day');

export const isPastDate = (date) => {
  const now = generateDate();
  return date < now;
};

export const isSameDay = (date1, date2) => date1.hasSame(date2, 'day');

export const forwardDays = (date, days) => date.plus({ days });

export const dayDifference = (date1, date2) => date1.diff(date2, 'days').toObject().days;

export const formatDateISO = (date) => date.toISO();

export const formatDate = (date) => date.toLocaleString(DateTime.DATE_SHORT);

export const formatTime = (date) => date.toLocaleString(DateTime.TIME_SIMPLE);

export const formatDateTime = (date, separator = ' ') => `${formatDate(date)}${separator}${formatTime(date)}`;

export const formatDateRelative = (date) => {
  const today = generateDate();
  const yesterday = generateDate().minus({ days: 1 });
  if (isSameDay(today, date)) return 'Today';
  if (isSameDay(yesterday, date)) return 'Yesterday';
  return formatDate(date);
};
