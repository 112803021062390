import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import forOwn from 'lodash/forOwn';
import parseRules from './parseRules';
import createFieldSet from './createFieldSet';
import initFieldSet from './initFieldSet';

const getFieldSets = (facet, facetType) => {
  const template = {};
  const fieldTypes = facetType.fields || facetType.facetFieldTypes || [];
  fieldTypes.filter((field) => !field.archived).forEach((fieldTemplate) => {
    template[fieldTemplate.id] = {
      count: 0,
      type: fieldTemplate,
      rules: parseRules(fieldTemplate),
    };
  });
  const fields = facet && facet.fields
    ? facet.fields.filter(
      (field) => Object.prototype.hasOwnProperty.call(template, get(field, 'type.id')),
    )
    : [];
  const groupedFields = groupBy(fields, (f) => `${get(f, 'type.id')}-${get(f, 'order')}`);
  const fieldsValues = Object.values(groupedFields);
  const fieldSets = fieldsValues.map((set) => {
    if (set.length === 0) return null;
    const typeId = set[0].type.id;
    const fieldSet = createFieldSet({
      type: template[typeId].type,
      rules: template[typeId].rules,
      innerOrder: template[typeId].count,
      fields: set,
      createdFields: true,
    });
    initFieldSet(fieldSet);
    template[typeId].count += 1;
    return fieldSet;
  });
  forOwn(template, (data) => {
    if (data.count === 0) {
      const fieldSet = createFieldSet({ type: data.type, rules: data.rules });
      initFieldSet(fieldSet);
      fieldSets.push(fieldSet);
      /* eslint-disable no-param-reassign */
      data.count += 1;
    }
  });
  return orderBy(fieldSets, ['order', 'innerOrder'], ['asc', 'asc']);
};

export default getFieldSets;
