import React from 'react';

const typeColumn = {
  key: 'type',
  name: 'Type',
  filterable: true,
  resizable: true,
  sortable: true,
  width: 90,
  draggable: true,
  formatter: (props) => {
    const { row } = props;
    const { entity = {} } = row;
    const { type = '', subType = '' } = entity || {};
    const text = subType ? `${type}:${subType}` : type;
    return (
      <div title={text}>{text}</div>
    );
  },
};

export default typeColumn;
