export const DICTIONARY_ITEM_ADD = 'ENTITY_EDITOR:DICTIONARY_ITEM_ADD';
export const LOAD_TEMPLATE = 'ENTITY_EDITOR:LOAD_TEMPLATE';
export const FACET_ADD = 'ENTITY_EDITOR:FACET_ADD';
export const FACET_ADD_TO_SECTION = 'ENTITY_EDITOR:FACET_ADD_TO_SECTION';
export const FACET_REMOVE = 'ENTITY_EDITOR:FACET_REMOVE';
export const FACET_META_CHANGE = 'ENTITY_EDITOR:FACET_META_CHANGE';
export const FACET_ORDER_CHANGE = 'ENTITY_EDITOR:FACET_ORDER_CHANGE';
export const FIELD_ADD = 'ENTITY_EDITOR:FIELD_ADD';
export const FIELD_REMOVE = 'ENTITY_EDITOR:FIELD_REMOVE';
export const CROSS_ENTITY_FACET_BLUR = 'CROSS_ENTITY_FACET_BLUR';
export const LOAD_ENTITY = 'ENTITY_EDITOR:LOAD_ENTITY';
export const SET_ENTITY = 'ENTITY_EDITOR:SET_ENTITY';
export const SIDEBAR_SECTION_OPEN_SET = 'ENTITY_EDITOR:SIDEBAR_SECTION_OPEN_SET';
export const CHANGE_FIELDS = 'ENTITY_EDITOR:CHANGE_FIELDS';
export const CHANGE_META = 'ENTITY_EDITOR:CHANGE_META';
export const SAVE = 'ENTITY_EDITOR:SAVE';
export const SAVE_ASSIGNED_ENTITY = 'ENTITY_EDITOR:SAVE_ASSIGNED_ENTITY';
export const CLEAR_STORE = 'ENTITY_EDITOR:CLEAR_STORE';
export const LIMIT = 100;
export const ADD_LOAD_PROCESS = 'ENTITY_EDITOR:ADD_LOAD_PROCESS';
export const REMOVE_LOAD_PROCESS = 'ENTITY_EDITOR:REMOVE_LOAD_PROCESS';
export const USE_VIEW_MODE = 'ENTITY_EDITOR:USE_VIEW_MODE';
export const CLEAR_FACET = 'ENTITY_EDITOR:CLEAR_FACET';
export const CHANGE_FILTER = 'ENTITY_EDITOR:CHANGE_FILTER';
export const SET_OPENED_SECTIONS = 'ENTITY_EDITOR:SET_OPENED_SECTIONS';
export const REG_DICT_ITEM = 'ENTITY_EDITOR:REG_DICT_ITEM';
export const REMOVE_FIELDS = 'ENTITY_EDITOR:REMOVE_FIELDS';
export const REMOVE_LINKS = 'ENTITY_EDITOR:REMOVE_LINKS';
export const REMOVE_CEFS = 'ENTITY_EDITOR:REMOVE_CEFS';
export const FETCH_FACET_LOG_COUNT = 'ENTITY_EDITOR:FETCH_FACET_LOG_COUNT';
export const UPDATE_FACET_LOG_COUNT = 'ENTITY_EDITOR:UPDATE_FACET_LOG_COUNT';

export const UPLOAD_IMAGE = 'Upload Image';
export const UPLOAD_BY_URL = 'Save URL';
export const VIEW_IMAGE = 'View Image';

export const ALL = 'All';
export const REQUIRED = 'Required';
export const ASSIGNED_TO_ME = 'Assigned to me';
export const ASSIGNED_REQUIRED = 'Assigned & Required';

export const FILTERS_SIDEBAR = [
  ALL, REQUIRED, ASSIGNED_TO_ME, ASSIGNED_REQUIRED,
];

export const LINK_ADD = 'ENTITY_EDITOR:LINK_ADD';
export const REMOVE_LINK = 'ENTITY_EDITOR:REMOVE_LINK';
export const CHANGE_LINK = 'ENTITY_EDITOR:CHANGE_LINK';
export const SEARCH = 'ENTITY_EDITOR:SEARCH';
export const SET_SEARCH_RESULT = 'ENTITY_EDITOR:SET_SEARCH_RESULT';
export const SET_ENTITY_ASSIGNMENTS = 'ENTITY_EDITOR:SET_ENTITY_ASSIGNMENTS';

export const FACET_LOADING_DISABLE = 'ENTITY_EDITOR:FACET_LOADING_DISABLE';
export const FACET_LOADING_ENABLE = 'ENTITY_EDITOR:FACET_LOADING_ENABLE';
export const FACET_SAVE = 'ENTITY_EDITOR:FACET_SAVE';
export const FIELD_LOADING_DISABLE = 'ENTITY_EDITOR:FIELD_LOADING_DISABLE';
export const FIELD_LOADING_ENABLE = 'ENTITY_EDITOR:FIELD_LOADING_ENABLE';
export const FIELD_SAVE = 'ENTITY_EDITOR:FIELD_SAVE';
export const LINK_LOADING_DISABLE = 'ENTITY_EDITOR:LINK_LOADING_DISABLE';
export const LINK_LOADING_ENABLE = 'ENTITY_EDITOR:LINK_LOADING_ENABLE';
export const LINK_SAVE = 'ENTITY_EDITOR:LINK_SAVE';

export const ENTITY_EDITOR_STATE_DIRTY = 'STATE_DIRTY';
export const ENTITY_EDITOR_STATE_CLEAN = 'STATE_CLEAN';
export const ENTITY_EDITOR_STATE_SAVING = 'STATE_SAVING';

export const CROSS_FACET_ADD = 'ENTITY_EDITOR:CROSS_FACET_ADD';
export const CROSS_FACET_REMOVE = 'ENTITY_EDITOR:CROSS_FACET_REMOVE';
export const CROSS_FACET_CHANGE = 'ENTITY_EDITOR:CROSS_FACET_CHANGE';

export const ACTION_TOGGLE_HIDDEN = 'ENTITY_EDITOR:ACTION_TOGGLE_HIDDEN';

export const SAVE_BUTTON_TEXT = {
  [ENTITY_EDITOR_STATE_SAVING]: 'Saving',
  [ENTITY_EDITOR_STATE_CLEAN]: 'Saved',
  [ENTITY_EDITOR_STATE_DIRTY]: 'Save',
};

export const getEntityStage = (state) => {
  switch (state) {
    case 'in progress':
    case 'published':
      return 'ok';
    case 'unsaved':
    case 'hidden':
      return 'unsaved';
    case 'failed':
      return 'error';
    default:
      return '';
  }
};

export const SET_STATUS = 'ENTITY_EDITOR:SET_STATUS';

export const FACET_LOG = {
  CREATE: 'CREATE_FACET',
  INIT: 'INIT_FACET',
  UPDATE: 'UPDATE_FACET',
};

export const ENTITY_STATUSES_FOR_DISPLAY = {
  failed: 'Failed',
  'in progress': 'In Progress...',
  published: 'Published',
  unsaved: 'Unsaved',
  hidden: 'Hidden',
};
