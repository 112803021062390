import React from 'react';
import { Button, Row } from 'antd';
import styles from './CrossEntityFacetsFormHeader.module.css';

const FormHeader = ({ onSave, onCancel, id }) => (
  <Row className={styles.header}>
    <Button type="primary" onClick={onSave}>
      Save
    </Button>
    {id === 'new' && (
      <Button type="secondary" onClick={onCancel}>
        Clear form
      </Button>
    )}
  </Row>
);

export default FormHeader;
