/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Typography } from '@axis/xyz.admin.typography';
import { ButtonIcon } from '@axis/xyz.admin.buttons.button-icon';
import { Link } from 'react-router-dom';
import { formatDateTime, parseDate } from '../../../common/utils/date';
import styles from './Row.module.css';

export const Header = () => (
  <div className={`${styles.tableRow} ${styles.tableHeader}`}>
    <div className={styles.cell}>
      <Typography size="sm" type="title">Title</Typography>
    </div>
    <div className={styles.cell}>
      <Typography size="sm" type="title">Created</Typography>
    </div>
    <div className={styles.cell}>
      <Typography size="sm" type="title">Country</Typography>
    </div>
    <div className={styles.cell}>
      <Typography size="sm" type="title">Main Entity</Typography>
    </div>
    <div className={styles.cell}>
      <Typography size="sm" type="title">Published</Typography>
    </div>
    <div className={styles.cell} />
  </div>
);

const Row = ({
  id, index, onRemove, title, createdBy = {}, date, issuerEntity = {}, topic = {},
}) => {
  const { createdByName } = createdBy;
  const { name: topicName } = topic;
  const { name: entityName = '--' } = issuerEntity;
  const displaySeparator = index !== 0;
  const dateFormatted = formatDateTime(parseDate(date), ' at ');
  return (
    <>
      {displaySeparator && <hr className={styles.separator} />}
      <Link className={styles.titleLink} to={`/analyst/regulations/${id}`}>
        <div className={styles.tableRow}>
          <div className={styles.cell} title={title}>
            <Typography className={styles.title} type="title" size="md">
              {title}
            </Typography>
          </div>
          <div className={styles.cell} title={createdByName}>
            <Typography>{createdByName}</Typography>
          </div>
          <div className={styles.cell} title={topicName}>
            <Typography>{topicName}</Typography>
          </div>
          <div className={styles.cell} title={entityName}>
            <Typography>{entityName}</Typography>
          </div>
          <div className={styles.cell} title={dateFormatted}>
            <Typography>{dateFormatted}</Typography>
          </div>
          <div className={styles.cell}>
            <ButtonIcon
              className={styles.buttonTrash}
              icon="trash"
              onClick={(event) => {
                onRemove({ id, title });
                event.preventDefault();
                event.stopPropagation();
                event.nativeEvent.stopImmediatePropagation();
              }}
            />
          </div>
        </div>
      </Link>

    </>
  );
};

export default Row;
