import {
  SET_INITIAL_LOAD,
  AUTH_FAIL,
  SET_LOADING,
  SET_USER,
  OPEN_MODAL,
  CLOSE_MODAL,
  EDIT_USER,
  REMOVE_USER,
} from './constants';

const initialState = {
  user: null,
  users: [],
  error: {
    message: null,
    date: Date.now(),
  },
  loading: false,
  modal: false,
};

// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_LOAD: {
      return {
        ...state,
        user: payload.user,
        users: payload.users,
      };
    }
    case SET_USER: {
      return {
        ...state,
        user: {
          ...state.user,
          ...payload,
        },
      };
    }
    case AUTH_FAIL: {
      return {
        ...state,
        error: {
          date: Date.now(),
          message: payload.message,
        },
      };
    }
    case SET_LOADING: {
      return {
        ...state,
        loading: payload,
      };
    }
    case OPEN_MODAL: {
      return {
        ...state,
        modal: true,
      };
    }
    case CLOSE_MODAL: {
      return {
        ...state,
        modal: false,
      };
    }
    case EDIT_USER: {
      return {
        ...state,
        users: state.users.map((user) => (user.id === payload.id ? ({ ...user, ...payload }) : user)),
      };
    }
    case REMOVE_USER: {
      return {
        ...state,
        users: state.users.filter((user) => user.id !== payload),
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
