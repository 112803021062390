import authReducer from './containers/Auth/reducer';
import entityEditor from './containers/EntityEditor/reducer';
import { CREATE_ERROR, SET_DICTIONARIES } from './constants';

const initialState = {
  error: {
    message: null,
    date: Date.now(),
    payload: null,
  },
  dictionaries: {},
};

const common = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_DICTIONARIES: {
      return {
        ...state,
        dictionaries: { ...state.dictionaries, ...payload },
      };
    }
    case CREATE_ERROR: {
      return {
        ...state,
        error: {
          message: action.payload,
          date: Date.now(),
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default (state = {}, action) => ({
  auth: authReducer(state.auth, action, state),
  common: common(state.common, action, state),
  entityEditor: entityEditor(state.entityEditor, action, state),
});
