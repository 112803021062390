import { createStore, createEvent, createEffect } from 'effector';
import queryString from 'query-string';

import client from '../../../common/utils/http';
import { $sidePanel } from '../../../effectorModel/sidePanel';
import { catchError } from '../../../saga/sagas';

export const $comments = createStore([]);
export const $isLoadingComments = createStore(false);

export const $assessment = createStore(3);
export const $details = createStore('');

export const changeAssessment = createEvent();
export const changeDetails = createEvent();
export const resetForm = createEvent();
export const resetComments = createEvent();

export const getComments = createEffect();
export const createComment = createEffect();
export const removeComment = createEffect();

getComments
  .use((id) => {
    const query = queryString.stringify({
      sourceId: id,
      '$sort[createdAt]': -1,
      $limit: 100,
    });

    return client.get(`/comments?${query}`).catch(catchError);
  });

createComment
  .use((params) => client.post('/comments', params));

removeComment
  .use((id) => client.delete(`/comments/${id}`));

removeComment.done.watch(() => {
  const { options: { facetId } } = $sidePanel.getState();
  getComments(facetId);
});

createComment.done.watch(() => resetForm());

$comments
  .on(getComments.done, (_, { result }) => result.data.data)
  .on(createComment.done, (state, { result }) => [result.data, ...state]);

$isLoadingComments
  .on(getComments, () => true)
  .on(getComments.done, () => false)
  .on(getComments.fail, () => false)
  .on(createComment, () => true)
  .on(createComment.done, () => false)
  .on(createComment.fail, () => false)
  .on(removeComment, () => true)
  .on(removeComment.done, () => false)
  .on(removeComment.fail, () => false);

$assessment
  .on(changeAssessment, (_, value) => value)
  .reset(resetForm);

$details
  .on(changeDetails, (_, value) => value)
  .reset(resetForm);
