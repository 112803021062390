import React, { PureComponent } from 'react';
import startCase from 'lodash/startCase';
import {
  Select, Icon, Collapse, Row, Col, Spin, Input,
} from 'antd';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import { Link as RouterLink } from 'react-router-dom';

import PropertyType from '../Field/PropertyType';
import Description from '../Field/common/Description';
import Info from '../Field/common/Info';

import { RELATIONSHIP } from '../../../../common/Enums/fieldTypeNames';
import ElementState from '../ElementState';
import { UNASSIGNED } from '../../../../common/Enums/Stages';

import SelectLink from './components/SelectLink';
import helper from './linkHelpers';

import styles from './styles.module.css';
import '../Field/index.css';
import { ROUTE_ANALYST_ENTITIES_EDITOR } from '../../../../constants';

const { Panel } = Collapse;
const { Option } = Select;
const { TextArea } = Input;

const DIRECTIONS_MAP = {
  to: 'TO',
  bi: 'BILATERAL',
  from: 'FROM',
};

const DIRECTIONS_ARROW_MAP = {
  to: 'arrow-right',
  bi: 'swap',
  from: 'arrow-left',
};

const DIRECTIONS_TITLE_MAP = {
  to: 'To',
  bi: 'Bilateral',
  from: 'From',
};

class Link extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      topics: null,
      description: get(props, 'link.description', ''),
      provenance: get(props, 'link.provenance', ''),
    };
    this.debouncedChange = debounce(this.changeLink, 500);
  }

  componentDidMount() {
    const { link } = this.props;
    if (link) {
      this.setState({ description: link.description });
      if (link.target && link.target.id) {
        helper.getTopics(link.target.id).then((data) => { this.setState({ topics: data }); });
      }
    }
  }

  changeLink = (...args) => {
    let changes = {};
    if (args.length === 2) {
      const [value, property] = args;
      changes[property] = value;
      if (property === 'direction') {
        changes.type = '';
      }
    } else {
      [changes] = args;
    }
    const {
      options: { facetKey, sectionKey, entityKey },
      link: { key },
      link: linkSet,
      wrapperKey,
    } = this.props;

    if (!linkSet.type && !changes.type) {
      const preparedLinkSet = this.prepareLinkSet(linkSet);
      changes.type = preparedLinkSet.type;
    }

    const keys = Object.keys(changes);
    const isUpdated = keys.some((k) => changes[k] !== linkSet[k]);
    if (isUpdated) {
      const newLinkSet = { ...linkSet, ...changes };
      this.props.actions.saveLink(newLinkSet, this.props.facetId);
    }

    this.props.actions.changeFieldLink({
      facetKey,
      sectionKey,
      entityKey,
      wrapperKey,
      key,
      changes,
    });
  };

  onChangeWrapperDescription = (description) => {
    if (description !== this.state.description) {
      this.setState({ description });
      this.debouncedChange(description, 'description');
    }
  };

  onChangeWrapperProvenance = (provenance) => {
    if (provenance !== this.state.provenance) {
      this.setState({ provenance });
      this.debouncedChange(provenance, 'provenance');
    }
  };

  selectLink = (id, ev) => {
    const {
      specialFacet,
      options: { facetKey, sectionKey, entityKey },
      wrapperKey,
      link: linkSet,
    } = this.props;
    const changes = {
      target: {
        ...ev.props.obj,
      },
      targetId: ev.props.obj.id,
      targetType: 'entity',
      targetEntityType: ev.props.obj.type,
    };
    const keys = Object.keys(changes);
    const isUpdated = keys.some((k) => changes[k] !== linkSet[k]);
    if (isUpdated) {
      const newLinkSet = { ...linkSet, ...changes };
      this.props.actions.saveLink(newLinkSet, this.props.facetId);
    }
    this.props.actions.changeFieldLink({
      facetKey,
      sectionKey,
      entityKey,
      specialFacet,
      wrapperKey,
      key: linkSet.key,
      changes,
    });
    this.setState({ topics: null });
  };

  prepareLinkSet = (linkSet) => {
    const {
      link: {
        direction,
        rules: {
          Direction: {
            type: directionType,
          },
          'Relationship-Type-bi': {
            fixedValue: biFixedValue,
          },
          'Relationship-Type-from': {
            fixedValue: fromFixedValue,
          },
          'Relationship-Type-to': {
            fixedValue: toFixedValue,
          },
        },
      },
    } = this.props;

    if (!linkSet.type && directionType === 'fixed') {
      const fixedValues = {
        TO: toFixedValue,
        FROM: fromFixedValue,
        BI: biFixedValue,
        BILATERAL: biFixedValue,
      };
      return {
        ...linkSet,
        type: fixedValues[direction.toUpperCase()],
      };
    }
    return linkSet;
  }

  render() {
    const {
      stages,
      editable,
      link: {
        key,
        target,
        direction,
        stage,
        rules: {
          Field: fieldLabel,
          Direction: { type: directionType },
          'Allowed-Directions': allowedDirections,
          'Allow-Link-To': allowedEntities,
          'Relationship-Type-to': typeTo,
          'Relationship-Type-from': typeFrom,
          'Relationship-Type-bi': typeBi,
          'Topic-Search': topicSearch,
          Label: label,
          Info: info,
          Description: descriptionTemplate,
          Chars: chars,
          Significance: significance,
          'Detailed-Description': detailedDescription,
        },
        strength,
      },
      link: linkSet,
      options,
      entityName,
      specialFacet,
      loading,
      readonly,
    } = this.props;
    const { description, provenance, topics } = this.state;

    const defaultLabel = !specialFacet
      ? 'this facet'
      : entityName || 'this entity';

    const targetType = get(target, 'type');
    const entityType = get(allowedEntities, 'entityType');
    const labelText = (() => {
      if (targetType) return `this ${target.type}`;
      if (entityType !== '*') return `this ${allowedEntities.entityType}`;
      return 'this selected Actor, Plot, Context, or Brief';
    })();

    const useTopicSelector = topicSearch;

    return (
      <Spin spinning={loading}>
        <div data-cy="facet-link" className={`facet-type-field ${styles.Link}`}>
          <Collapse defaultActiveKey={false} bordered={false}>
            <Panel
              key={key}
              header={(
                <div
                  style={{
                    marginTop: 10, display: 'flex', justifyContent: 'space-between', alignContent: 'center',
                  }}
                >
                  <div>
                    {target ? target.name : fieldLabel || RELATIONSHIP}
                    {target
                      ? (
                        <RouterLink
                          to={`${ROUTE_ANALYST_ENTITIES_EDITOR}/${target.id}`}
                          rel="noopener noreferrer"
                          target="_blank"
                          title={`Open ${target.name} in new tab`}
                          style={{ marginLeft: 10 }}
                        >
                          <Icon type="link" />
                        </RouterLink>
                      ) : null}
                    <ElementState saved={linkSet.id} />
                  </div>
                  {specialFacet ? (
                    // eslint-disable-next-line max-len
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                    >
                      <Select
                        disabled={readonly}
                        name="stage"
                        onChange={(v) => this.changeLink(v, 'stage')}
                        showSearch
                        style={{ width: 130 }}
                        placeholder="Stage"
                        dropdownClassName="dropdown-facet-header dropdown-zindex"
                        value={startCase(stage) || UNASSIGNED}
                        optionFilterProp="children"
                        filterOption={(input, option) => this.filterOption(input, option)}
                      >
                        {stages.map((e) => <Select.Option key={e}>{startCase(e)}</Select.Option>)}
                      </Select>
                    </div>
                  ) : null}
                </div>
              )}
              forceRender
            >
              <div className="content column">
                <div className="ant-row-flex">
                  <div
                    className="column"
                    style={{ width: '100%', marginBottom: 20 }}
                  >
                    {directionType !== 'fixed' && (
                      <>
                        <span className="label-text">
                          Direction (Select this first)
                        </span>
                        <Select
                          dropdownClassName="dropdown-zindex"
                          getPopupContainer={() => document.getElementById('entity-editor-id')}
                          style={{ width: 200 }}
                          placeholder="Direction"
                          onChange={(v) => this.changeLink(v, 'direction')}
                          value={direction}
                          disabled={
                            readonly || directionType === 'fixed' || !editable
                          }
                        >
                          {allowedDirections.map((e) => (
                            <Option value={DIRECTIONS_MAP[e]} key={e}>
                              {DIRECTIONS_TITLE_MAP[e]}
                              &nbsp;&nbsp;&nbsp;
                              <Icon style={{ fontSize: 10 }} type={DIRECTIONS_ARROW_MAP[e]} />
                            </Option>
                          ))}
                        </Select>
                      </>
                    )}

                    <Description
                      value={descriptionTemplate}
                      entityKey={options.entityKey}
                    />
                  </div>
                </div>

                <div className="label-row" style={{ marginBottom: 20 }}>
                  <span className="label-text" style={{ fontWeight: 600 }}>
                    {label}
                  </span>
                </div>

                {direction === 'TO' && (
                  <Row type="flex" gutter={16}>
                    <Col span={8}>
                      <div
                        className="label-text"
                        title={typeTo.type === 'off' ? '' : entityName}
                        style={{
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          wordWrap: 'break-word',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {typeTo && typeTo.type === 'off'
                          ? ''
                          : `${defaultLabel} is`}
                      </div>
                      <PropertyType
                        rules={{ type: typeTo, chars }}
                        onChange={this.changeLink}
                        fieldValue={{ name: 'type', value: linkSet.type }}
                        fieldSelectedId={{}}
                        readonly={readonly || !editable}
                      />
                    </Col>

                    <Col span={16}>
                      <Row type="flex" justify="space-between">
                        <Col>
                          <div className="label-text">{labelText}</div>
                        </Col>
                        <Col>
                          <Info info={info} />
                        </Col>
                      </Row>

                      <div>
                        <SelectLink
                          useTopicSelector={useTopicSelector}
                          defaultTopics={topics}
                          placeholder="Select Entity"
                          disabled={readonly || !editable}
                          linkSet={linkSet}
                          selectLink={this.selectLink}
                        />
                      </div>
                    </Col>
                  </Row>
                )}

                {direction === 'FROM' && (
                  <Row type="flex" gutter={16}>
                    <Col span={16}>
                      <div className="label-text">{labelText}</div>
                      <div>
                        <SelectLink
                          useTopicSelector={useTopicSelector}
                          defaultTopics={topics}
                          placeholder="Select Entity"
                          disabled={readonly || !editable}
                          linkSet={linkSet}
                          selectLink={this.selectLink}
                        />
                        <span className="label-text">
                          {`to ${defaultLabel}`}
                        </span>
                      </div>
                    </Col>

                    <Col span={8}>
                      <Row type="flex" justify="space-between">
                        <Col>
                          <div className="label-text">
                            {typeFrom && typeFrom.type === 'off' ? '' : 'is'}
                          </div>
                        </Col>
                        <Col>
                          <Info info={info} />
                        </Col>
                      </Row>

                      <PropertyType
                        rules={{ type: typeFrom, chars }}
                        onChange={this.changeLink}
                        fieldValue={{
                          name: 'type',
                          value: linkSet.type,
                        }}
                        readonly={readonly || !editable}
                        fieldSelectedId={{}}
                      />
                    </Col>
                  </Row>
                )}
                {(direction.toUpperCase() === 'BILATERAL'
                  || direction.toUpperCase() === 'BI') && (
                  <Row type="flex" gutter={16}>
                    <Col span={16}>
                      <div className="label-text">{labelText}</div>
                      <div>
                        <SelectLink
                          useTopicSelector={useTopicSelector}
                          defaultTopics={topics}
                          placeholder="Select Entity"
                          disabled={readonly || !editable}
                          linkSet={linkSet}
                          selectLink={this.selectLink}
                        />
                        <span className="label-text">
                          {`to ${defaultLabel}`}
                        </span>
                      </div>
                    </Col>

                    <Col span={8}>
                      <Row type="flex" justify="space-between">
                        <Col>
                          <div className="label-text">
                            {typeBi && typeBi.type === 'off' ? '' : 'is'}
                          </div>
                        </Col>
                        <Col>
                          <Info info={info} />
                        </Col>
                      </Row>

                      <PropertyType
                        rules={{ type: typeBi, chars }}
                        onChange={this.changeLink}
                        fieldValue={{ name: 'type', value: linkSet.type }}
                        fieldSelectedId={{}}
                        readonly={readonly}
                      />
                    </Col>
                  </Row>
                )}
              </div>

              {significance.type === 'off' ? null : (
                <div
                  className="column"
                  style={{ width: 200, marginTop: 10, marginBottom: 10 }}
                >
                  <span className="label-text">Significance</span>
                  <Select
                    dropdownClassName="dropdown-zindex"
                    showSearch
                    getPopupContainer={() => document.getElementById('entity-editor-id')}
                    placeholder="Select Significance"
                    onChange={(e) => this.changeLink(e, 'strength')}
                    style={{ width: '100%' }}
                    value={strength}
                    disabled={readonly}
                  >
                    <Select.Option value={1}>1</Select.Option>
                    <Select.Option value={2}>2</Select.Option>
                    <Select.Option value={3}>3</Select.Option>
                    <Select.Option value={4}>4</Select.Option>
                    <Select.Option value={5}>5</Select.Option>
                  </Select>
                </div>
              )}

              {detailedDescription === 'on' && (
                <>
                  <div className="ant-row-flex mrg-top-10">
                    <span className="label-text">Detailed Description</span>
                  </div>
                  <div className="ant-row-flex">
                    <TextArea
                      autoComplete="off"
                      value={description}
                      type="text"
                      rows={5}
                      onChange={({ target: { value: v } }) => this.onChangeWrapperDescription(v)}
                      disabled={readonly}
                    />
                  </div>
                </>
              )}
              {specialFacet ? (
                <>
                  <div className="ant-row-flex mrg-top-10">
                    <span className="label-text">Source URL</span>
                  </div>
                  <Input
                    className="mrg-bt-10"
                    autoComplete="off"
                    style={{ flex: 1 }}
                    type="text"
                    placeholder="Source URL"
                    disabled={readonly}
                    value={provenance}
                    onChange={({ target: { value: v } }) => this.onChangeWrapperProvenance(v)}
                  />
                </>
              ) : null }
            </Panel>
          </Collapse>
        </div>
      </Spin>
    );
  }
}

export default Link;
