import React, { memo, useEffect } from 'react';
import { useStore } from 'effector-react';
import { Divider, Spin } from 'antd';
import moment from 'moment/moment';

import { DATE_FORMAT } from '../../../constants/common';

import {
  $facetLogs,
  $isLoadingFacetLogs,
  getFacetLogs,
  resetFacetLogs,
} from './model';

const FacetLogs = ({ facetId }) => {
  const facetLogs = useStore($facetLogs);
  const isLoadingFacetLogs = useStore($isLoadingFacetLogs);

  useEffect(() => {
    getFacetLogs(facetId);
    return () => resetFacetLogs();
  }, [facetId]);

  return (
    <Spin spinning={isLoadingFacetLogs}>
      {facetLogs.map((logGrouped) => (
        <div key={logGrouped.key} style={{ fontSize: 17 }}>
          <i>
            {moment(logGrouped.updatedAt)
              .local()
              .format(DATE_FORMAT)}
          </i>
          <div>
            <b>{`${logGrouped.username}`}</b>
            {logGrouped.logs.map((log) => (
              <div key={log.id} style={{ paddingLeft: 15 }}>
                <i style={{ color: '#502DB3' }}>{log.action}</i>
                {!!log.description && <span>{' > '}</span>}
                {!!log.description && log.description}
              </div>
            ))}
          </div>
          <Divider />
        </div>
      ))}
    </Spin>
  );
};

export default memo(FacetLogs);
