/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  Input, Button, Row, Col,
} from 'antd';
import styles from './Category.module.css';

const { Search } = Input;

const Category = ({
  category,
  onChangeCategory,
  onRemoveCategory,
  onRemoveItem,
  onAddItem,
  onTypeInner,
  itemType,
}) => {
  const { id, key, items } = category;

  return (
    <Col className={styles.main}>
      <Row type="flex" justify="space-between">
        <Col span={20}>
          <Input
            size="small"
            autoComplete="off"
            maxLength={254}
            value={key || undefined}
            onChange={(event) => onChangeCategory(event.target.value, id)}
            className={styles.noBorder}
          />
        </Col>

        <Col span={4}>
          <Button
            size="small"
            icon="delete"
            onClick={() => onRemoveCategory(id)}
            className={styles.noBorder}
          />
        </Col>
      </Row>

      <Search
        size="small"
        enterButton="+"
        autoComplete="off"
        placeholder="New Item"
        name={id}
        maxLength={254}
        onChange={onTypeInner}
        value={itemType[id] || ''}
        onSearch={(value) => onAddItem(value, id)}
      />
      <div className={styles.content}>
        {items && items.map((item, i) => item && (
          <Row
            key={i}
            className={styles.item}
            type="flex"
            justify="space-between"
            data-id={item}
          >
            <div className={styles.text} title={item}>
              {item}
            </div>
            <Button
              icon="delete"
              size="small"
              onClick={() => onRemoveItem(item, id)}
              className={styles.noBorder}
            />
          </Row>
        ))}
      </div>
    </Col>
  );
};

export default Category;
