import React from 'react';

const CEFT = ({ name }) => <div>{name}</div>;

const formatter = ({ row: { ceft } }) => ceft && (<CEFT key={ceft.id} name={ceft.name} />);

const column = {
  key: 'ceft',
  name: 'CEFT',
  editable: false,
  sortable: false,
  resizable: true,
  width: 150,
  formatter,
};

export default column;
