import { message } from 'antd';
import * as strings from '../Strings';

export const onLoadEndHandler = ({
  file, reader, filename, onChange,
}) => {
  const base64data = reader.result;
  const contentType = base64data.split(',')[0].split(':')[1].split(';')[0];
  const formData = new FormData();
  formData.append('uri', base64data);
  formData.append('file', file);
  formData.append('filename', filename);
  formData.append('contentType', contentType);
  onChange({ filename, formData });
};

const uploadFile = (onChange) => {
  const existingInput = document.querySelector('input#file-input');
  if (existingInput) document.body.removeChild(existingInput);
  const x = document.createElement('INPUT');
  x.setAttribute('id', 'file-input');
  x.setAttribute('type', 'file');
  x.addEventListener('change', (ev) => {
    const filename = ev.target.files[0].name;
    const file = ev.target.files[0];
    if (!file || file.size > 49000000) {
      message.error(strings.aErrorLargeFile);
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(ev.target.files[0]);
      reader.onloadend = () => onLoadEndHandler({
        file, filename, onChange, reader,
      });
    }
    document.body.removeChild(document.querySelector('#file-input'));
    x.remove();
  });
  document.body.appendChild(x);
  x.click();
};

export const uploadImage = (onChange) => {
  const existingInput = document.querySelector('input#file-input');
  if (existingInput) document.body.removeChild(existingInput);
  const x = document.createElement('INPUT');
  x.setAttribute('id', 'file-input');
  x.setAttribute('type', 'file');
  x.setAttribute('accept', 'image/png, image/jpeg, image/jpg');
  x.addEventListener('change', (ev) => {
    const filename = ev.target.files[0].name;
    const file = ev.target.files[0];
    if (!file || file.size > 49000000) {
      message.error(strings.aErrorLargeFile);
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(ev.target.files[0]);
      reader.onloadend = () => onLoadEndHandler({
        file, filename, onChange, reader,
      });
    }
    document.body.removeChild(document.querySelector('#file-input'));
    x.remove();
  });
  document.body.appendChild(x);
  x.click();
};

export default uploadFile;
