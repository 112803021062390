import { createSelector } from 'reselect';

import {
  ROLE_ADMIN,
} from '../common/Enums/Roles';

import { ENTITY_COLUMNS } from '../common/Enums/Columns';

const hasAccessToChangeStatusEntity = createSelector(
  (role) => role,
  (role) => role === ROLE_ADMIN,
);

const hasAccessToReassignEntity = createSelector(
  (role) => role,
  (role) => role === ROLE_ADMIN,
);

const hasAccessToLinkEntity = createSelector((role) => role, (role) => role === ROLE_ADMIN);

const hasAccessToChangeVisibilityEntity = createSelector(
  (role) => role,
  (role) => role === ROLE_ADMIN,
);

const hasAccessToRemoveEntity = createSelector(
  (role) => role,
  (role) => role === ROLE_ADMIN,
);

const hasAccessToChangeEntityProperty = createSelector(
  (property) => property,
  (property, role) => role,
  (property, role) => {
    switch (property) {
      case ENTITY_COLUMNS.ASSIGNEDTOID: return hasAccessToReassignEntity(role);
      case ENTITY_COLUMNS.TOPIC: return hasAccessToLinkEntity(role);
      case ENTITY_COLUMNS.STAGE: return hasAccessToChangeStatusEntity(role);
      case ENTITY_COLUMNS.HIDDEN: return hasAccessToChangeVisibilityEntity(role);
      default: return false;
    }
  },
);

const hasAccessToRemoveFacet = createSelector(
  (user) => user,
  (user, facet) => facet,
  (user, facet) => user.role === ROLE_ADMIN
    || !facet.id
    || facet.creatorId === user.id,
);

const hasAccessToAssignFacetToUser = createSelector(
  (user) => user,
  (user, facet) => facet,
  (user, facet, stage) => stage,
  () => true,
);

export default {
  hasAccessToChangeStatusEntity,
  hasAccessToReassignEntity,
  hasAccessToRemoveEntity,
  hasAccessToRemoveFacet,
  hasAccessToChangeEntityProperty,
  hasAccessToAssignFacetToUser,
};
