/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { Button } from '@axis/xyz.admin.buttons.button';
import { ArticleSummary } from '@axis/xyz.admin.article-summary';
import { ContentSeparator } from '@axis/xyz.admin.content-separator';
import { CheckboxCircle } from '@axis/xyz.admin.form.checkbox-circle';
import { ButtonText } from '@axis/xyz.admin.buttons.button-text';
import { Loading } from '@axis/xyz.admin.loading';
import { Typography } from '@axis/xyz.admin.typography';
import { Alert } from '@axis/xyz.admin.alert';
import { useHistory, useParams } from 'react-router-dom';
import { STORAGE_ADMIN_FEED_REGULATIONS, STORAGE_ADMIN_FEED_REGULATION_ARTICLES } from '../../common/utils/storage';
import ConfirmModal from '../../components/ConfirmModal/ConfirmModal';
import ArticleModal from '../../components/Article/Article';
import {
  fetchSelectedRegulations, getRegulationsInfo, getRegulationTags, processRegulations,
} from './utils';
import { formatDateTime, parseDate } from '../../common/utils/date';
import css from './RegulationsFeedReview.module.css';
import useLocalStorage from '../../hooks/useLocalStorage';
import { REGULATION_FEED_TYPES } from '../../constants';

const RegulationSeparator = ({ index, mainText, secondaryText }) => {
  if (index !== 0) return <hr className={css.separator} />;
  return <ContentSeparator mainText={mainText} secondaryText={secondaryText} />;
};

const EndOfContent = ({
  isError, isLoading,
}) => {
  if (isError) {
    return (
      <Alert className={css.alert} type="error">
        Seems the connection was lost!
        <br />
        Refresh the page or come back later
      </Alert>
    );
  }
  if (isLoading) return <Loading className={css.loading} />;
  return (
    <Alert className={css.alert} type="success">
      You got to the end of the list.
    </Alert>
  );
};

const RegulationsFeedReview = () => {
  const history = useHistory();
  const { type } = useParams();

  const [selectedRegulations, setSelectedRegulations] = useLocalStorage(type === REGULATION_FEED_TYPES.REGULATION ? STORAGE_ADMIN_FEED_REGULATIONS : STORAGE_ADMIN_FEED_REGULATION_ARTICLES, []);
  const [modalDetails, setModalDetails] = useState({ open: false, regulation: null });

  const loadNextRef = useRef();

  const {
    data: regulations = [],
    isLoading,
    isError,
    isSuccess,
  } = useQuery(
    ['selectedRegulations', selectedRegulations],
    () => fetchSelectedRegulations({ ids: selectedRegulations }),
  );

  useEffect(() => {
    if (selectedRegulations.length === 0 || (regulations.length === 0 && !isSuccess)) return;
    const existingIds = regulations.map((regulation) => regulation.id);
    setSelectedRegulations(existingIds);
  }, [regulations]);

  const handleRegulationCheckboxClick = (regulation) => {
    setSelectedRegulations((currentRegulations) => {
      if (currentRegulations.includes(regulation.id)) {
        return currentRegulations.filter((e) => e !== regulation.id);
      }
      return [...currentRegulations, regulation.id];
    });
  };

  const handleRegulationModalConfirm = () => {
    setSelectedRegulations((currentRegulations) => {
      if (currentRegulations.includes(modalDetails.regulation.id)) return currentRegulations;
      return [...currentRegulations, modalDetails.regulation.id];
    });
    setModalDetails((currentDetails) => ({ ...currentDetails, open: false }));
  };

  const groupedRegulations = processRegulations({
    selectedRegulations: regulations,
    selectedIds: selectedRegulations,
  });

  return (
    <div className={css.container}>
      <section className={css.header}>
        <div className={css.actions}>
          <ButtonText
            data-cy="regulations-manually-create"
            label="Manually create"
            className={css.buttonSecondary}
            onClick={() => history.push('/analyst/feed/regulations/manually-create')}
          />
          <Button
            data-cy="regulations-create"
            label="Create"
            disabled={selectedRegulations.length === 0}
            badge={`${selectedRegulations.length}`}
            onClick={() => history.push(`/analyst/feed/regulations/create/${type}`)}
          />
        </div>
      </section>
      <section>
        <Typography
          className={css.title}
          tag="h1"
          type="title"
          size="xl"
        >
          Regulations to be reviewed
        </Typography>
        <Typography
          className={css.subtitle}
          tag="p"
          type="text"
          size="md"
        >
          List of regulations that you selected to be used
        </Typography>
        <hr className={css.titleSeparator} />
        {groupedRegulations.map((regulationsDate) => (
          <React.Fragment key={regulationsDate.date}>
            {regulationsDate.regulations.map((regulation, index) => (
              <React.Fragment key={regulation.id}>
                <RegulationSeparator
                  index={index}
                  mainText={regulationsDate.date}
                  secondaryText={getRegulationsInfo(regulationsDate.regulations.length)}
                />
                <div className={css.regulation}>
                  <ArticleSummary
                    title={regulation.title}
                    isUsed={regulation.isUsed}
                    summary={regulation.summary}
                    source={regulation.source}
                    favicon={regulation.favicon}
                    isTranslated={regulation.isTranslated}
                    date={formatDateTime(parseDate(regulation.date))}
                    image={regulation.image}
                    tags={getRegulationTags(regulation)}
                    onClick={() => setModalDetails({ open: true, regulation })}
                  />
                  <div className={css.input}>
                    <CheckboxCircle
                      id={regulation.id}
                      value={regulation.id}
                      checked={selectedRegulations.includes(regulation.id)}
                      onChange={() => handleRegulationCheckboxClick(regulation)}
                    />
                  </div>
                </div>
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
        <div className={css.end} ref={loadNextRef}>
          <EndOfContent
            isError={isError}
            isLoading={isLoading}
          />
        </div>
      </section>
      <ConfirmModal
        open={modalDetails.open}
        cancelLabel="Close"
        onOk={handleRegulationModalConfirm}
        onCancel={() => setModalDetails((currentDetails) => ({ ...currentDetails, open: false }))}
      >
        <ArticleModal article={modalDetails.regulation} />
      </ConfirmModal>
    </div>
  );
};

export default RegulationsFeedReview;
