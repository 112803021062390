import React from 'react';
import { FormItemWrapper } from '@axis/xyz.admin.form.form-item-wrapper';
import { ButtonIcon } from '@axis/xyz.admin.buttons.button-icon';
import { Typography } from '@axis/xyz.admin.typography';
import { TextInput } from '@axis/xyz.admin.form.text-input';
import styles from './Source.module.css';

const SourceURL = ({ source, onClick }) => (
  <div className={styles.source}>
    <a href={source} target="_blank" rel="noreferrer">
      <Typography className={styles.sourceText} help={source}>{source}</Typography>
    </a>
    <ButtonIcon
      icon="close"
      help="Remove source"
      className={styles.buttonRemove}
      onClick={() => onClick(source)}
    />
  </div>
);

const Source = ({
  sources = [],
  newSource,
  error,
  onNewSourceChange = () => {},
  onNewSourceSubmit = () => {},
  onSourceRemove = () => {},
}) => {
  const onKeyUp = (event) => {
    const ENTER_KEYCODE = 13;
    const { keyCode } = event;
    if (keyCode === ENTER_KEYCODE) onNewSourceSubmit();
  };

  return (
    <div className={styles.container}>
      {!sources.length && (
        <p className={styles.empty}>
          No sources indicated. Use the input below to add a new one.
        </p>
      )}
      {sources.map((source) => (
        <SourceURL key={source} source={source} onClick={onSourceRemove} />
      ))}
      <FormItemWrapper
        className={styles.inputContainer}
        errorMessage={error && 'Invalid url'}
      >
        <TextInput
          placeholder="Source URL"
          name="event-source"
          className={styles.input}
          value={newSource}
          onChange={(event) => onNewSourceChange(event.target.value)}
          onKeyUp={onKeyUp}
        />
        {newSource && (
          <ButtonIcon
            className={styles.inputClose}
            icon="close"
            onClick={() => onNewSourceChange('')}
          />
        )}
      </FormItemWrapper>
    </div>
  );
};

export default Source;
