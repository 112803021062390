import { get } from 'lodash';
import { convertDate, formatDateISO, parseDate } from '../../common/utils/date';
import { uploadFile } from '../EntityEditor/components/Field/mediaHelpers';
import client from '../../common/utils/http';

export const getActiveRegulationPage = ({ activeRegulation, regulations }) => {
  const index = regulations.findIndex((regulation) => regulation.id === activeRegulation.id);
  return index + 1;
};

export const getImage = async (data) => {
  const [localImage] = data.image || [];
  const uploadedImage = await uploadFile(localImage.formData);
  return uploadedImage.href;
};

const getAttachment = async (data) => {
  const attachments = data.attachments || [];
  const attachment = attachments[0];
  if (!attachment) return null;
  const file = await uploadFile(attachment.formData);
  return { key: file.key, name: attachment.name };
};

export const getDateInfo = (data) => {
  const { dateStart, dateEnd } = data;
  if (dateStart && !dateEnd) return { type: 'start', dateStart: formatDateISO(convertDate(dateStart)) };
  if (!dateStart && dateEnd) return { type: 'end', dateEnd: formatDateISO(convertDate(dateEnd)) };
  if (dateStart && dateEnd) return { type: 'range', dateStart: formatDateISO(convertDate(dateStart)), dateEnd: formatDateISO(convertDate(dateEnd)) };
  return { type: 'unknown' };
};

export const getIssuer = (data) => {
  const { issuer } = data;
  if (issuer.id) return { id: issuer.id, name: issuer.name };
  return { name: issuer.name };
};

export const processRegulationData = async (data) => ({
  issuerEntityId: data.issuerEntity.id,
  topicId: data.topic.id,
  summarizedName: data.summarizedName,
  legalName: data.legalName,
  date: getDateInfo(data),
  industryBriefIds: data.industryBriefs.map((b) => b.id),
  source: data.sources[0],
  attachment: await getAttachment(data),
});

export const createRegulation = async (data) => {
  const regulation = await processRegulationData(data);
  return client.post('/regulation', regulation);
};

export const transformRegulation = (regulation) => {
  const sourceEntity = get(regulation, 'entities.0', {});
  const regulationEntity = {
    id: get(sourceEntity, 'entityId', ''),
    name: get(sourceEntity, 'entityName', ''),
    topicName: get(sourceEntity, 'topicName', ''),
    topicId: get(sourceEntity, 'topicId', ''),
  };
  return {
    issuerEntity: regulationEntity,
    topic: { name: get(regulation, 'topicName', ''), id: get(regulation, 'topicId', '') },
    summarizedName: '',
    legalName: regulation.title,
    dateStart: parseDate(regulation.date).toJSDate(),
    dateEnd: null,
    industryBriefs: [],
    sources: [regulation.source],
    attachments: [],
  };
};
