export const getEnvironment = (url) => {
  switch (url) {
    case 'localhost:3000': return 'LOCAL';
    case 'localhost:3001': return 'LOCAL';
    case 'admin-dev.axis.xyz': return 'DEV';
    case 'admin-stage.axis.xyz': return 'STAGE';
    case 'admin.axis.xyz': return 'PRO';
    default: return '';
  }
};

export const isProduction = (env) => (env === 'STAGE' || env === 'PRO');

export const getAxisAppLocation = () => {
  switch (window.location.host) {
    case 'localhost:3000': return 'http://localhost:3001';
    case 'localhost:3001': return 'http://localhost:3000';
    case 'admin-dev.axis.xyz': return 'https://app-dev.axis.xyz';
    case 'admin-stage.axis.xyz': return 'https://app-stage.axis.xyz';
    case 'admin.axis.xyz': return 'https://app.axis.xyz';
    default: return '';
  }
};
