import React, { memo } from 'react';
import { Divider } from 'antd';
import styled from 'styled-components';
import moment from 'moment/moment';

import { DATE_FORMAT } from '../../../constants/common';

const CommentsLog = ({ comments }) => (
  <>
    {
      comments.map((comment) => (
        <Comment key={comment.id}>
          <CommentHeader>
            <div>
              <Col>
                <Row>
                  Rejected By
                  {' '}
                  <UserName>
                    {comment.rejector.name}
                  </UserName>
                </Row>
                <Row>
                  Last Assigned User
                  {' '}
                  <UserName>
                    {comment.user.name}
                  </UserName>
                </Row>
              </Col>
              <Details>
                {`Date: ${moment(comment.createdAt).format(DATE_FORMAT)}`}
              </Details>
            </div>
          </CommentHeader>
          <Text>{comment.comment}</Text>
          <Divider dashed />
        </Comment>
      ))
    }
  </>
);

export default memo(CommentsLog);

const CommentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 15px;
`;

const UserName = styled.span`
  font-weight: 700;
`;

const Details = styled.span`
  font-size: 15px;
  font-style: italic;
`;

const Comment = styled.div`
  margin-bottom: 10px;
  font-size: 17px;
`;

const Text = styled.div`
  font-style: italic;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div``;
