/* eslint-disable no-param-reassign,linebreak-style,no-plusplus */
import {
  all, put, takeEvery, takeLatest,
} from 'redux-saga/effects';
import uniqueId from 'lodash/uniqueId';
import { formatTemplate } from '../../../common/utils/lib';
import { createSchema } from '../utils';
import {
  CLEAR_FACET,
  FETCH_FACET_LOG_COUNT,
  LOAD_ENTITY,
  LOAD_TEMPLATE,
  SAVE,
  SEARCH,
  SET_ENTITY,
  USE_VIEW_MODE,
} from '../constants';

import loadDictionaries from './loadDictionaries';
import loadTemplate from './loadTemplate';
import loadEntity from './loadEntity';
import save from './save';
import search from './search';
import clearFacet from './clearFacet';
import fetchFacetLogCount from './fetchFacetLogCount';

function* prepareViewMode({ payload }) {
  const template = formatTemplate(payload);
  template.facets.forEach((f, fi) => {
    if (!f.id) {
      f.id = uniqueId('_viewFacetId');
    }
    f.order = fi;
    if (f.fields) {
      f.fields.forEach((ff, ffi) => {
        ff.name = ff.fieldType;
        if (!ff.id) {
          ff.id = uniqueId('_viewFieldId');
        }
        ff.order = ffi;
      });
    }
  });
  const entity = createSchema(template);
  const dictionaries = yield loadDictionaries([entity]);
  yield put({
    type: SET_ENTITY,
    payload: {
      dictionaries,
      template,
      viewMode: true,
      entity,
    },
  });
}

function* watchViewMode() {
  yield takeEvery(USE_VIEW_MODE, prepareViewMode);
}

function* watchLoadTemplate() {
  yield takeEvery(LOAD_TEMPLATE, loadTemplate);
}

function* watchLoadEntity() {
  yield takeEvery(LOAD_ENTITY, loadEntity);
}

function* watchSave() {
  yield takeEvery(SAVE, save);
}

function* watchSearch() {
  yield takeEvery(SEARCH, search);
}

function* watchClearFacet() {
  yield takeEvery(CLEAR_FACET, clearFacet);
}

function* watchFetchFacetLogCount() {
  yield takeLatest(FETCH_FACET_LOG_COUNT, fetchFacetLogCount);
}

export default function* rootSaga() {
  yield all([
    watchLoadTemplate(),
    watchLoadEntity(),
    watchSave(),
    watchViewMode(),
    watchSearch(),
    watchClearFacet(),
    watchFetchFacetLogCount(),
  ]);
}
