import client from '../../common/utils/http';
import {
  formatDateRelative, isSameDay, parseDate, startOfDay, parseDateIgnoreTZ, transformDateTZ, convertDate,
} from '../../common/utils/date';
import { REGULATION_FEED_TYPES } from '../../constants';

const PAGE_SIZE = 25;

export const getRegulationsInfo = (regulationsTotal) => {
  if (regulationsTotal === 1) return '1 regulation';
  return `${regulationsTotal} regulations`;
};

const getQueryParams = ({
  topicId, page, date, since, briefIds,
}) => {
  const params = {
    $limit: PAGE_SIZE,
    $skip: PAGE_SIZE * (page - 1),
  };
  if (topicId) params.topicId = topicId;
  if (since) params.since = since;
  if (briefIds) params.briefIds = briefIds;
  if (date) {
    const dateWithOffset = transformDateTZ(date);
    params.date = dateWithOffset;
  }
  return params;
};

const transformRegulation = (regulation) => ({
  ...regulation,
  actors: regulation.entities ? regulation.entities.filter((e) => e.entityType === 'Actor') : [],
  plots: regulation.entities ? regulation.entities.filter((e) => e.entityType === 'Plot') : [],
  contexts: regulation.entities ? regulation.entities.filter((e) => e.entityType === 'Context') : [],
  briefs: regulation.entities ? regulation.entities.filter((e) => e.entityType === 'Brief') : [],
  date: parseDateIgnoreTZ(regulation.date),
});

export const fetchRegulationsPage = ({
  topicId, pageParam = {}, briefIds, since, date,
}, type) => {
  const { page = 1 } = pageParam;
  const params = getQueryParams({
    topicId, page, briefIds, since, date,
  });
  return client.get(type === REGULATION_FEED_TYPES.REGULATION ? '/regulation-feeds' : '/regulation-articles', { params })
    .then((res) => res.data)
    .then(({
      total: totalRegulations,
      limit,
      skip,
      data: regulations,
    }) => ({
      hasMore: (limit + skip) < totalRegulations,
      total: regulations.length,
      regulations: regulations.map(transformRegulation),
    }));
};

export const processRegulations = (pages) => {
  const groupedPages = pages.reduce((groupedRegulations, currentPage) => {
    const groupedPage = currentPage.regulations.reduce((regulations, regulation) => {
      const regulationDate = parseDate(regulation.date);
      const EMPTY_DATE = { date: startOfDay(parseDate(regulationDate)), regulations: [] };
      const value = regulations.find((e) => isSameDay(e.date, regulationDate)) || EMPTY_DATE;
      value.regulations = [
        ...(value.regulations || []),
        regulation,
      ];
      return [
        ...regulations.filter((e) => !isSameDay(e.date, regulationDate)),
        value,
      ];
    }, groupedRegulations);
    return groupedPage;
  }, []);
  const pagesList = groupedPages
    .sort((page1, page2) => page2.date - page1.date)
    .map((page) => ({ ...page, date: formatDateRelative(parseDate(page.date)) }));
  return pagesList;
};

export const getRegulationTags = (regulation) => (regulation.entities ? regulation.entities.map((e) => e.entityName) : []);

export const getFilterCount = ({
  date, topicId, topicName, sectorBriefs, subsectorBriefs, industryBriefs,
}) => {
  let total = 0;
  if (date) total += 1;
  if (topicId && topicName) total += 1;
  if (sectorBriefs.length > 0) total += 1;
  if (subsectorBriefs.length > 0) total += 1;
  if (industryBriefs.length > 0) total += 1;
  return total;
};

export const getFilterDescription = ({
  date, topicId, topicName, sectorBriefs, subsectorBriefs, industryBriefs,
}, type) => {
  let description = type === REGULATION_FEED_TYPES.REGULATION ? 'Viewing all regulations ' : 'Viewing all regulation articles ';
  const fragments = [];
  if (date) fragments.push(`from ${formatDateRelative(convertDate(date))}`.toLowerCase());
  if (topicId && topicName) fragments.push(`of ${topicName}`);
  const briefList = [...sectorBriefs, ...subsectorBriefs, ...industryBriefs]
    .map((brief) => brief.briefName)
    .join(', ');
  if (briefList) fragments.push(`tagged to ${briefList}`);
  description += fragments.join(' ');
  return description;
};
