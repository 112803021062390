import get from 'lodash/get';
import { createSelector } from 'reselect';
import {
  ROLE_ADMIN,
} from '../common/Enums/Roles';
import {
  EDIT, UNASSIGNED, PUBLISHED, EXPIRED, NO_INFORMATION,
} from '../common/Enums/Stages';

const isUserExists = createSelector(
  (userId) => userId,
  (userId, users) => users,
  (userId, users = []) => !!users.find((user) => user.id === userId),
);

const getUsers = (state) => state.auth.users;

const getUser = (state) => state.auth.user;

const getUserProperty = (state, property) => get(state.auth.user, property, null);

const getRole = createSelector(
  (user) => user,
  (user) => user.role,
);

const getUserById = createSelector(
  (userId) => userId,
  (id, users) => users,
  (userId, users) => users.find((user) => user.id === userId),
);

const getUserByName = createSelector(
  (userName) => userName,
  (id, users) => users,
  (userName, users) => users.find((user) => user.name === userName),
);

const getUsersByStatus = createSelector(
  (users) => users,
  (users, status) => status,
  (users, status) => {
    switch ((status || '').toLowerCase()) {
      case null:
      case undefined:
      case EXPIRED:
      case PUBLISHED:
      case UNASSIGNED: return [];
      case NO_INFORMATION:
      case EDIT: return users.filter((user) => user.role === ROLE_ADMIN);
      default: return users;
    }
  },
);

export default {
  isUserExists,
  getUsers,
  getUser,
  getUserProperty,
  getRole,
  getUserById,
  getUserByName,
  getUsersByStatus,
};
