import React, { useEffect, useState } from 'react';
import {
  message, Input, Button, Spin, Row, Col,
} from 'antd';
import uniqueId from 'lodash/uniqueId';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams, Prompt, useHistory } from 'react-router-dom';
import client from '../../../../common/utils/http';
import {
  groupCategories, mergeDictionaryCategories, processDictionary, validateDictionary,
} from '../../utils';
import styles from './Editor.module.css';
import Category from '../Category/Category';
import CreateFromRaw from '../CreateFromRaw/CreateFromRaw';
import PreviewDictionary from '../PreviewDictionary/PreviewDictionary';

const Editor = () => {
  const { dictionaryIdByUrl } = useParams();
  const history = useHistory();

  const queryClient = useQueryClient();

  const [isRawVisible, setRawVisible] = useState(false);
  const [search, setSearch] = useState('');

  const [categories, setCategories] = useState([{
    key: ' ',
    items: [],
    id: uniqueId(),
  }]);

  const [dictionaryDetails, setDictionaryDetails] = useState({
    key: '',
    name: '',
  });

  const [itemType, setItemType] = useState({});

  const { isLoading, isError } = useQuery(['dictionary', dictionaryIdByUrl], () => {
    client.get(`/dictionaries/${dictionaryIdByUrl}`)
      .then(({ data }) => processDictionary(data))
      .then(({ categories: dictionaryCategories, name, key }) => {
        setDictionaryDetails({ name, key });
        setCategories(dictionaryCategories);
      });
  }, {
    enabled: !!dictionaryIdByUrl,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  useEffect(() => {
    document.leavingpage = false;
    return () => { document.leavingpage = false; };
  }, []);

  useEffect(() => {
    if (isError) message.error('The dictionary could not be loaded');
  }, [isError]);

  const dictionaryCreate = useMutation((dictionary) => client.post('/dictionaries', dictionary));
  const dictionaryUpdate = useMutation(({ id, dictionary }) => client.put(`/dictionaries/${id}`, dictionary));

  const handleDictionaryCreate = (dictionary) => {
    dictionaryCreate.mutate(dictionary, {
      onSuccess: ({ data }) => {
        queryClient.invalidateQueries('dictionaries');
        history.replace(`/admin/elements/dictionaries/editor/${data.id}`);
        message.success('The dictionary has been created');
      },
      onError: () => {
        message.error('There was an error creating the dictionary');
      },
    });
  };

  const handleDictionaryUpdate = ({ id, dictionary }) => {
    dictionaryUpdate.mutate(({ id, dictionary }), {
      onSuccess: () => {
        queryClient.invalidateQueries('dictionaries');
        message.success('The dictionary has been updated');
      },
      onError: () => {
        message.error('There was an error updating the dictionary');
      },
    });
  };

  const handleSave = () => {
    document.activeElement.blur();
    const { key, name } = dictionaryDetails;
    const { status, error, dictionary } = validateDictionary({ name, key, categories });
    if (status === 'ko') return message.error(error);
    if (dictionaryIdByUrl) {
      handleDictionaryUpdate({
        id: dictionaryIdByUrl,
        dictionary,
      });
    } else {
      handleDictionaryCreate(dictionary);
    }
    document.leavingpage = false;
    return null;
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearch(value);
    document.leavingpage = true;
  };

  const handleDictionaryKeyChange = (e) => {
    const value = e.target.value.replace(/[%&]/g, '');
    setDictionaryDetails((current) => ({ ...current, key: value }));
  };

  const handleDictionaryNameChange = (e) => {
    const { value } = e.target;
    setDictionaryDetails((current) => ({ ...current, name: value }));
  };

  const handleRawImport = (newCategories) => {
    const { mergedCategories, type } = mergeDictionaryCategories({
      itemType,
      newCategories,
      existingCategories: categories,
    });
    setCategories(mergedCategories);
    setItemType(type);
    setRawVisible(false);
  };

  const handleChangeCategory = (value, id) => {
    setCategories((current) => current.map((category) => {
      if (category.id !== id) return category;
      return { ...category, key: value };
    }));
    document.leavingpage = true;
  };

  const handleDeleteCategory = (id) => {
    setCategories((current) => current.filter((c) => c.id !== id));
    document.leavingpage = true;
  };

  const handleRemoveItem = (val, id) => {
    if (!val) return;
    setCategories((current) => current.map((category) => {
      if (category.id !== id) return category;
      return {
        ...category,
        items: category.items.filter((e) => e !== val),
      };
    }));
    document.leavingpage = true;
  };

  const handleAddItem = (value, id) => {
    if (!value) return;
    setCategories((current) => current.map((category) => {
      if (category.id !== id) return category;
      if (category.items.find((e) => e === value)) return category;
      return {
        ...category,
        items: [value, ...category.items],
      };
    }));
    setItemType((current) => ({
      ...current,
      [id]: '',
    }));
    document.leavingpage = true;
  };

  const handleAddSearchCategory = (key) => {
    if (!key && categories.find((cat) => cat.key === ' ')) {
      message.error('Available only one empty category');
      return;
    }
    if (categories.find((cat) => cat.key === key)) {
      message.error('Duplicate category');
      return;
    }
    setCategories((currentCategories) => ([
      ...currentCategories,
      { key, items: [], id: uniqueId() },
    ]));
    setSearch('');
    document.leavingpage = true;
  };

  const handleAddItemType = (e) => {
    const { name, value } = e.target;
    setItemType((current) => ({
      ...current,
      [name]: value,
    }));
    document.leavingpage = true;
  };

  const { categoryKeys, categoryValues } = groupCategories(categories);

  return (
    <>
      <Prompt
        when={document.leavingpage}
        message="You have unsaved changes, are you sure you want to leave?"
      />

      <Spin spinning={isLoading}>
        <Row className={styles.row} type="flex" justify="start">
          <Button type="primary" onClick={handleSave}>
            Save
          </Button>
          <Button type="default" onClick={() => setRawVisible(true)}>
            Create From Raw Data
          </Button>
        </Row>

        <Row className={styles.row} type="flex" justify="start" gutter={20}>
          <Col>
            <div>Dictionary Name</div>
            <Input
              name="key"
              placeholder="Name"
              autoComplete="off"
              maxLength={254}
              onChange={handleDictionaryKeyChange}
              value={dictionaryDetails.key || ''}
              className={styles.input}
            />
          </Col>

          <Col>
            <PreviewDictionary
              name={dictionaryDetails.key}
              description={dictionaryDetails.name}
              values={categoryValues}
              keys={categoryKeys}
            />
          </Col>
        </Row>

        <Row className={styles.row}>
          <div>Dictionary Description</div>
          <Input
            name="name"
            autoComplete="off"
            placeholder="Description"
            maxLength={254}
            onChange={handleDictionaryNameChange}
            value={dictionaryDetails.name || ''}
            className={styles.input}
          />
        </Row>

        <Row className={styles.row}>
          <div>Add New Category:</div>
          <Input.Search
            name="search"
            enterButton="Add"
            autoComplete="off"
            placeholder="New Category"
            maxLength={254}
            onChange={handleSearchChange}
            value={search || ''}
            onSearch={handleAddSearchCategory}
            className={styles.input}
          />
        </Row>

        <Row className={styles.row} type="flex">
          {categories.map((category) => (
            <Category
              key={category.id}
              category={category}
              onChangeCategory={handleChangeCategory}
              onRemoveCategory={handleDeleteCategory}
              onRemoveItem={handleRemoveItem}
              onAddItem={handleAddItem}
              onTypeInner={handleAddItemType}
              itemType={itemType}
            />
          ))}
        </Row>
        <CreateFromRaw
          visible={isRawVisible}
          onConfirm={handleRawImport}
          onCancel={() => setRawVisible(false)}
        />
      </Spin>
    </>
  );
};

export default Editor;
