import React, { useEffect, useState } from 'react';
import { Typography } from '@axis/xyz.admin.typography';
import { Button } from '@axis/xyz.admin.buttons.button';
import { TextInput } from '@axis/xyz.admin.form.text-input';
import { FormItemWrapper } from '@axis/xyz.admin.form.form-item-wrapper';
import isURL from 'validator/lib/isURL';
import { DateInput } from '@axis/xyz.admin.form.date-input';
import SearchSelect from '../../components/Form/SearchSelect/SearchSelect';
import Source from '../../components/Form/Source/Source';
import { Regulations as FieldHint } from '../../components/Form/FieldHint/FieldHint';
import AttachmentField from '../../components/Form/AttachmentField/AttachmentField';
import getEntityOptionLabel from '../../common/utils/getEntityOptionLabel';
import css from './RegulationForm.module.css';
import { getFormErrors } from './utils';

const FIND_INDUSTRY_BRIEF_QUERY = '/briefs?type[]=industry&$limit=100&name=';
const FIND_ENTITIES_QUERY = '/entity-infos?type[$ne]=Brief&$limit=50&name[$iLike]=';
const FIND_COUNTRIES_QUERY = '/countries?$limit=50&name[$iLike]=';

const RegulationForm = ({
  regulation = {},
  onChange,
  onSubmit,
  buttonLabel = 'Create facet',
  submitEnabled = true,
  title = 'Regulations',
}) => {
  const [formErrors, setFormErrors] = useState([]);

  const [newSource, setNewSource] = useState('');
  const [newSourceError, setNewSourceError] = useState(false);

  useEffect(() => setFormErrors([]), [regulation.regulationId]);

  useEffect(() => {
    setNewSourceError(false);
  }, [newSource]);

  const handleSubmitClick = () => {
    const errors = getFormErrors(regulation);
    if (errors.length === 0) {
      onSubmit();
      setNewSource('');
    } else {
      setFormErrors(errors);
    }
  };

  const handleInputChange = (field, value) => {
    if (formErrors.find((e) => e.field === field)) {
      setFormErrors((errors) => errors.filter((e) => e.field !== field));
    }
    onChange(field, value);
  };

  const getErrorMessage = (field) => {
    const error = formErrors.find((e) => e.field === field);
    if (!error) return null;
    return error.message;
  };

  const handleNewSourceSubmit = () => {
    if (!isURL(newSource)) {
      setNewSourceError(true);
    } else {
      handleInputChange('sources', [...(regulation.sources || []), newSource]);
      setNewSource('');
    }
  };

  const handleSourceRemove = (source) => {
    handleInputChange('sources', (regulation.sources || []).filter((s) => s !== source));
  };

  return (
    <div className={css.container}>
      <div className={`${css.row} ${css.titleContainer}`}>
        <Typography
          type="title"
          tag="h2"
          size="xl"
          className={css.title}
        >
          {title}
        </Typography>
      </div>

      <FormItemWrapper
        className={css.input}
        isRequired
        label="Topic"
        helpMessage={<FieldHint.Topic />}
        helpMessageTitle="Regulation Topic"
        errorMessage={getErrorMessage('topic')}
      >
        <SearchSelect
          name="regulation-topic"
          value={regulation.topic || {}}
          onChange={(value) => handleInputChange('topic', value)}
          searchUrl={FIND_COUNTRIES_QUERY}
        />
      </FormItemWrapper>

      <FormItemWrapper
        className={css.input}
        isRequired
        label="Issuer Entity"
        helpMessage={<FieldHint.IssuerEntity />}
        helpMessageTitle="Issuer Entity"
        errorMessage={getErrorMessage('issuerEntity')}
      >
        <SearchSelect
          name="regulation-issuer-entity"
          value={regulation.issuerEntity || {}}
          onChange={(value) => handleInputChange('issuerEntity', value)}
          getOptionLabel={getEntityOptionLabel}
          searchUrl={FIND_ENTITIES_QUERY}
        />
      </FormItemWrapper>

      <FormItemWrapper
        className={css.input}
        isRequired
        label="Summarized Name"
        helpMessage={<FieldHint.SummarizedName />}
        helpMessageTitle="Summarized Name"
        errorMessage={getErrorMessage('summarizedName')}
        maxLength={`${250 - (regulation.summarizedName || '').length}`}
      >
        <TextInput
          name="regulation-summarized-name"
          value={regulation.summarizedName || ''}
          onChange={(e) => handleInputChange('summarizedName', e.target.value)}
          maxLength={250}
        />
      </FormItemWrapper>

      <FormItemWrapper
        className={css.input}
        isRequired
        label="Legal Name"
        helpMessage={<FieldHint.LegalName />}
        helpMessageTitle="Regulation Legal Name"
        errorMessage={getErrorMessage('legalName')}
        maxLength={`${250 - (regulation.legalName || '').length}`}
      >
        <TextInput
          name="regulation-legal-name"
          value={regulation.legalName || ''}
          onChange={(e) => handleInputChange('legalName', e.target.value)}
          maxLength={250}
        />
      </FormItemWrapper>

      <FormItemWrapper
        className={css.input}
        label="Regulation date"
        helpMessage={<FieldHint.Date />}
        helpMessageTitle="Regulation Date(s)"
        errorMessage={getErrorMessage('date')}
      >
        <div className={css.dates}>
          <DateInput
            name="regulation-date"
            placeholder="Start date"
            value={regulation.dateStart}
            onChange={(date) => handleInputChange('dateStart', date)}
            onClear={() => handleInputChange('dateStart', null)}
            valuePosition="right"
            closeOnValueSelect
          />
          <DateInput
            name="regulation-date"
            placeholder="End date"
            value={regulation.dateEnd}
            onChange={(date) => handleInputChange('dateEnd', date)}
            onClear={() => handleInputChange('dateEnd', null)}
            valuePosition="right"
            styles={{ right: 4 }}
            closeOnValueSelect
          />
        </div>
      </FormItemWrapper>

      <FormItemWrapper
        className={css.input}
        label="Industry briefs"
        helpMessage={<FieldHint.IndustryBriefs />}
        helpMessageTitle="Industry briefs"
        errorMessage={getErrorMessage('industryBriefs')}
      >
        <SearchSelect
          name="regulation-industry-briefs"
          isMulti
          value={regulation.industryBriefs || []}
          onChange={(value) => handleInputChange('industryBriefs', value)}
          searchUrl={FIND_INDUSTRY_BRIEF_QUERY}
          getOptionLabel={getEntityOptionLabel}
        />
      </FormItemWrapper>

      <FormItemWrapper
        className={css.input}
        label="Regulation source"
        helpMessage={<FieldHint.Source />}
        helpMessageTitle="Source"
        errorMessage={getErrorMessage('source')}
      >
        <Source
          sources={regulation.sources || []}
          newSource={newSource}
          error={newSourceError}
          onNewSourceChange={setNewSource}
          onNewSourceSubmit={handleNewSourceSubmit}
          onSourceRemove={handleSourceRemove}
        />
      </FormItemWrapper>

      <FormItemWrapper
        className={css.input}
        label="Attachment"
        isRequired
        helpMessage={<FieldHint.Attachments />}
        helpMessageTitle="Attachment"
        errorMessage={getErrorMessage('attachments')}
      >
        <AttachmentField
          values={regulation.attachments}
          onChange={(attachments) => handleInputChange('attachments', attachments)}
        />
      </FormItemWrapper>

      <Button
        className={css.button}
        disabled={!submitEnabled || formErrors.length > 0}
        onClick={handleSubmitClick}
        label={buttonLabel}
        type="button"
      />
    </div>
  );
};

export default RegulationForm;
