import React, {
  useState, useRef, useEffect, useMemo,
} from 'react';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import styled from 'styled-components';
import {
  Input, Button, message, Popconfirm, Collapse, Row, Modal,
} from 'antd';

import * as strings from '../../../../../common/Strings';
import {
  UPLOAD_IMAGE,
  VIEW_IMAGE,
  UPLOAD_BY_URL,
} from '../../../constants';

import Description from '../common/Description';
import Info from '../common/Info';
import withRemoveButton from '../HOC/withButtonRemove';
import { uploadFile } from '../mediaHelpers';
import client from '../../../../../common/utils/http';

import Crop from './Crop';
import useDebounceSave from '../useDebounceSave';
import { REGULATION } from '../../../../../constants/facetTypes';
import uploadFileHandler, { onLoadEndHandler } from '../../../../../common/utils/uploadFileHandler';

const { Panel } = Collapse;
const { TextArea } = Input;

const UPLOAD_DOC = 'Upload Doc';
const VIEW_ARCHIVE_URL = 'View Archive Url';
const VIEW_DOCUMENT = 'View Document';

export const urlToData = (url) => client
  .get(`/proxy-download?url=${url}`)
  .then((data) => {
    if (!get(data, 'data.data', '')) {
      throw new Error();
    }
    return `data:image/;base64,${get(data, 'data.data', '')}`;
  });

function Media(props) {
  const {
    fieldType: {
      rules: {
        Field: fieldTitle,
        'Image-Detail': enableImageDetail,
        'Image-Provenance': enableImageProvenance,
      },
      name: fieldName,
    },
    fieldSet,
    actions,
    options,
    readonly,
    facetType,
  } = props;
  const { current: debounceInput } = useRef(debounce((fields) => actions.changeFieldSet(fields), 500));
  const debounceSave = useDebounceSave({ saveField: actions.saveField, fieldSet });

  const [imageLabelValue, setLabel] = useState(get(fieldSet, 'fields.imageLabel.value', ''));
  const [imageDetailValue, setDetail] = useState(get(fieldSet, 'fields.imageDetail.value', ''));
  const [fieldSourceUrl, setSourceValue] = useState(get(fieldSet, 'fields.sourceUrl.value', ''));

  const [state, setState] = useState({
    src: null,
    height: null,
    weight: null,
    modalCrop: false,
    loading: false,
  });
  const [loading, setLoading] = useState(false);

  const {
    fields: {
      imageDetail,
      imageLabel,
      key,
      urlInput,
      sourceKey,
      sourceUrl,
    },
    rules: {
      Chars: chars,
      'Detail-Chars': detailChars,
      'Image-Type': imageType,
      Description: description,
      Info: info,
      Label: label,
    },
  } = fieldSet;

  useEffect(() => {
    if (!imageLabel.value) {
      setLabel(imageLabel.value);
    }
    if (!imageDetail.value) {
      setDetail(imageDetail.value);
    }
  }, [imageLabel.value, imageDetail.value]);

  useEffect(() => {
    if (!sourceUrl) {
      setSourceValue(sourceUrl.value);
    }
  }, [sourceUrl.value]);

  const onChangeFieldDetail = (value) => {
    setDetail(value);
    const fields = { [imageDetail.name]: value };
    debounceSave(fields, () => debounceInput(fields));
  };

  const onChangeFieldLabel = (value) => {
    setLabel(value);
    const fields = { [imageLabel.name]: value };
    debounceSave(fields, () => debounceInput(fields));
  };

  const onChangeUrlProvenance = (value) => {
    const fields = {
      [sourceUrl.name]: value,
      [sourceKey.name]: { key: '', href: '' },
    };
    setSourceValue(value);
    debounceSave({ ...fields, [sourceKey.name]: '' }, () => debounceInput(fields));
  };

  const uploadByURL = () => {
    if (!options.viewMode) {
      setState((obj) => ({ ...obj, loading: true }));
      urlToData(urlInput.value)
        .then((res) => {
          setState((obj) => ({
            ...obj,
            loading: false,
            modalCrop: true,
            src: res,
          }));
        }).catch(() => {
          setState((obj) => ({
            ...obj, loading: false, modalCrop: false, src: null,
          }));
          message.error(
            'Invalid URL, try to upload image from your PC or use other URL',
            5,
          );
        });
    }
  };

  const uploadImage = () => {
    const allowedTypes = '.png|.jpg|.jpeg|.PNG|.JPG|.JPEG';
    const existingInput = document.querySelector('input#file-input');
    if (existingInput) document.body.removeChild(existingInput);
    const x = document.createElement('INPUT');
    x.setAttribute('id', 'file-input');
    x.setAttribute('type', 'file');
    x.addEventListener('change', (ev) => {
      const file = ev.target.files[0] || false;
      if (!file || file.size > 21971520) { // 21mb
        message.error(strings.aErrorLargeFile);
      }
      const ext = /[^.]+$/.exec(file.name);
      if (allowedTypes.includes(ext && ext[0])) {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
          setState((obj) => ({
            ...obj,
            loading: false,
            modalCrop: true,
            src: reader.result,
          }));
        });
        reader.readAsDataURL(ev.target.files[0]);
      }
      document.body.removeChild(document.querySelector('#file-input'));
      x.remove();
      return null;
    });
    document.body.appendChild(x);
    x.click();
  };

  const uploadDoc = () => {
    uploadFileHandler(({ formData }) => {
      if (!options.viewMode) {
        setLoading(true);
        uploadFile(formData)
          .then((data) => {
            const changes = {
              [sourceKey.name]: { key: data.key, href: data.href },
            };
            debounceSave({ [sourceKey.name]: data.key }, () => debounceInput(changes));
          })
          .catch((err) => {
            console.error(err);
            message.error(strings.aErrorUploadFailed);
          })
          .finally(() => setLoading(false));
      }
    });
  };

  const onSaveCroppedImage = (image) => {
    const reader = new FileReader();
    let filename = image.name;
    let file = image;

    if (imageType === 'square') {
      filename = image[1].blob.name;
      file = image[1].blob;
      reader.readAsDataURL(image[1].blob);
    } else {
      reader.readAsDataURL(image);
    }

    const onChange = ({ formData }) => {
      if (!options.viewMode) {
        setState((obj) => ({ ...obj, loading: true }));
        uploadFile(formData)
          .then((data) => {
            const fields = {
              [key.name]: { key: data.key, href: data.href },
            };
            debounceSave(
              {
                [key.name]: data.key,
              },
              () => debounceInput(fields),
            );
          })
          .catch((err) => {
            console.error(err);
            message.error(strings.aErrorUploadFailed);
          })
          .finally(() => {
            setState({
              srcFromFile: null,
              srcFromUrl: null,
              height: null,
              weight: null,
              modalCrop: false,
              loading: false,
            });
          });
      }
    };

    reader.onloadend = () => onLoadEndHandler({
      file, filename, onChange, reader,
    });
  };

  const onRemoveImage = () => {
    setState((prev) => ({
      ...prev,
      srcFromFile: null,
      srcFromUrl: null,
    }));
    const changes = {
      [key.name]: {
        key: '',
        href: '',
      },
      [urlInput.name]: '',
    };
    debounceSave({ ...changes, [key.name]: '' }, () => debounceInput(changes));
  };
  const onRemoveDoc = () => {
    const changes = {
      [sourceKey.name]: {
        key: '',
        href: '',
      },
      [sourceUrl.name]: '',
    };
    debounceSave({ ...changes, [sourceKey.name]: '' }, () => debounceInput(changes));
  };

  const viewDoc = (href) => {
    const win = window.open(href || key.href, '_blank');
    win.focus();
  };

  const imageSrc = key.href;

  const onClick = useMemo(() => {
    if (sourceKey.href) return () => viewDoc(sourceKey.href);
    if (sourceUrl.value) return uploadByURL;
    return uploadDoc;
  }, [sourceKey.href, sourceUrl.value]);

  const fieldLabel = facetType.name === REGULATION ? 'Image Credit' : 'Image Label';

  return (
    <div className="facet-type-field">
      <Collapse
        bordered={false}
        defaultActiveKey={!fieldSet.filled ? fieldSet.key : null}
      >
        <Panel
          key={fieldSet.key}
          header={fieldTitle || fieldName}
        >
          <Row type="flex" justify="space-between">
            <div className="label-text">{label}</div>
            <Info info={info} />
          </Row>

          <div className="ant-row-flex">
            <Button
              data-cy="media-browse-image-button"
              loading={state.loading}
              icon={(imageSrc) ? '' : 'upload'}
              // eslint-disable-next-line no-nested-ternary
              onClick={imageSrc ? () => viewDoc(imageSrc) : urlInput.value ? uploadByURL : uploadImage}
              disabled={readonly}
            >
              {imageSrc && VIEW_IMAGE}
              {!imageSrc && urlInput.value && UPLOAD_BY_URL}
              {!imageSrc && !urlInput.value && UPLOAD_IMAGE}
            </Button>

            {
              imageSrc
                ? (
                  <div className="ant-row-flex">
                    <Popconfirm
                      placement="topRight"
                      title="Are you sure?"
                      onConfirm={onRemoveImage}
                      onCancel={() => {
                      }}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        className="mrg-left-10"
                        type="delete"
                        icon="close"
                      />
                    </Popconfirm>
                  </div>
                ) : (
                  <span />
                )
            }
          </div>

          <Row style={{ margin: '10px 0' }}>
            <span className="label-text">
              You can load following types of images:
              {' '}
              <HighlightText>.png</HighlightText>
              {' | '}
              <HighlightText>.jpg</HighlightText>
              {' | '}
              <HighlightText>.jpeg</HighlightText>
            </span>
          </Row>

          <Description
            value={description}
            entityKey={options.entityKey}
          />

          {
            imageSrc && key.value && !key.value.includes('.pdf') && (
              <div>
                <img style={{ marginTop: 20, height: 100 }} alt="img" src={imageSrc} />
              </div>
            )
          }

          <div className="mrg-top-10">
            <span className="label-text">{fieldLabel}</span>
            <Input
              maxLength={chars}
              autoComplete="off"
              value={imageLabelValue}
              onChange={({ target: { value } }) => onChangeFieldLabel(value)}
              disabled={readonly}
            />
          </div>

          {enableImageDetail ? (
            <div className="mrg-top-10">
              <span className="label-text">Image Detail</span>
              <TextArea
                autoComplete="off"
                value={imageDetailValue}
                type="text"
                rows={5}
                maxLength={detailChars}
                onChange={({ target: { value: v } }) => onChangeFieldDetail(v)}
                disabled={readonly}
              />
            </div>
          )
            : null}

          {enableImageProvenance ? (
            <div className="mrg-top-10">
              <span className="label-text">Provenance</span>

              <div className="ant-row-flex">
                {sourceUrl.value ? null
                  : (
                    <Button
                      loading={loading}
                      style={{ width: 114, marginRight: 10 }}
                      onClick={onClick}
                      disabled={readonly}
                    >
                      {sourceKey.href && sourceUrl.value && VIEW_ARCHIVE_URL}
                      {sourceKey.href && !sourceUrl.value && VIEW_DOCUMENT}
                      {!sourceKey.href && sourceUrl.value && UPLOAD_BY_URL}
                      {!sourceKey.href && !sourceUrl.value && UPLOAD_DOC}
                    </Button>
                  )}

                <Input
                  autoComplete="off"
                  style={{ flex: 1 }}
                  type="text"
                  placeholder="Source URL"
                  disabled={!!sourceKey.href || readonly}
                  value={fieldSourceUrl}
                  onChange={(e) => onChangeUrlProvenance(e.target.value)}
                />
                {!sourceKey.href
                  ? (
                    <span />
                  ) : (
                    <div className="ant-row-flex">
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure?"
                        onConfirm={onRemoveDoc}
                        onCancel={() => {
                        }}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button
                          className="mrg-left-10"
                          type="delete"
                          icon="close"
                        />
                      </Popconfirm>
                    </div>
                  )}
              </div>
            </div>
          )
            : null}

          {
            state.modalCrop && (
              <Modal
                footer={null}
                visible={state.modalCrop}
                title="Crop"
                width={1000}
                centered
                maskClosable={false}
                onCancel={() => setState((obj) => ({ ...obj, modalCrop: false, src: null }))}
              >
                <Crop
                  imageType={imageType}
                  src={state.src}
                  fromUrl={!!state.srcFromUrl}
                  naturalWidth={state.width}
                  naturalHeight={state.height}
                  onOk={(image) => onSaveCroppedImage(image)}
                  onCancel={() => setState((obj) => ({ ...obj, modalCrop: false, src: null }))}
                  isLoading={state.loading}
                />
              </Modal>
            )
          }
        </Panel>
      </Collapse>
    </div>
  );
}

export default withRemoveButton(Media);

const HighlightText = styled.span`
  color: #502DB3;
`;
