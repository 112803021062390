import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTE_ANALYST_ENTITIES_EDITOR } from '../../../../constants';

const formatter = ({ row: { name, ...rest } }) => (
  <div title={name}>
    <Link
      to={`${ROUTE_ANALYST_ENTITIES_EDITOR}/${rest.entityId}`}
      target="_blank"
      data-testid="facetItemName"
    >
      {name}
    </Link>
  </div>
);

const nameColumn = {
  key: 'name',
  name: 'Name',
  filterable: true,
  sortable: true,
  resizable: true,
  width: 150,
  formatter,
  frozen: true,
};

export default nameColumn;
