import React from 'react';
import classnames from 'classnames';
import { Button } from 'antd';
import { get } from 'lodash';
import LinkSet from '../Links';
import Field from '../Field';
import { RELATIONSHIP } from '../../../../common/Enums/fieldTypeNames';
import { ADMIN_BRIEF_TAG, ANALYST_BRIEF_TAG, BRIEF_RELATIONSHIP_ID } from '../../../../common/Enums/TagPermission';
import { ROLE_ADMIN } from '../../../../common/Enums/Roles';

const Wrapper = ({
  data, options, facetType, specialFacet, actions, allowDuplicate, readonly, entityId, userRole,
}) => {
  const highlightLink = data.fieldSets.length === 0 && data.fieldType.required;
  const field = get(data, 'fieldType.rules.Field', '').trim();
  return (
    <>
      {data.fieldType.name === RELATIONSHIP
        ? (
          data.fieldSets.map((linkSet) => (
            <LinkSet
              entityId={entityId}
              key={linkSet.key}
              link={linkSet}
              options={options}
              specialFacet={specialFacet}
              facetType={facetType}
              wrapperKey={data.key}
              count={data.fieldSets.length}
              fieldType={data.fieldType}
              readonly={readonly}
              removeField={() => actions.removeLinkSet(linkSet.key)}
            />
          ))
        )
        : (
          data.fieldSets.map((fieldSet) => (
            <Field
              facetType={facetType}
              wrapperKey={data.key}
              key={fieldSet.key}
              fieldSet={fieldSet}
              options={options}
              count={data.fieldSets.length}
              fieldType={data.fieldType}
              removeField={() => actions.removeFieldSet(fieldSet)}
              readonly={readonly}
              entityId={entityId}
            />
          ))
        )}
      {
        (data.fieldType.duplicate || data.fieldSets.length === 0)
        && allowDuplicate
        && (
          !BRIEF_RELATIONSHIP_ID.includes(data.fieldType.facetTypeId)
          || (
            BRIEF_RELATIONSHIP_ID.includes(data.fieldType.facetTypeId)
            && userRole === ROLE_ADMIN ? ADMIN_BRIEF_TAG.includes(field) : ANALYST_BRIEF_TAG.includes(field)
          )
        )
        && (
          <Button
            key={data.fieldType.id}
            type="link"
            className={classnames('button-like-link', 'bold-button', { 'btn-required-link': highlightLink })}
            onClick={() => (data.fieldType.name === RELATIONSHIP
              ? actions.addLinkSet(data.fieldType)
              : actions.addFieldSet(data.fieldType))}
          >
            <b>{`+ Add ${data.fieldType.rules.Field || data.fieldType.name}`}</b>
          </Button>
        )
}
    </>
  );
};

export default Wrapper;
