import React, { useEffect, useState } from 'react';
import { FormItemWrapper } from '@axis/xyz.admin.form.form-item-wrapper';
import { TextInput } from '@axis/xyz.admin.form.text-input';
import { useMutation, useQueryClient } from 'react-query';
import { Typography } from '@axis/xyz.admin.typography';
import { notification } from 'antd';
import { get } from 'lodash';
import { Button } from '@axis/xyz.admin.buttons.button';
import { DateInput } from '@axis/xyz.admin.form.date-input';
import { Icon } from '@axis/xyz.assets.icon';
import moment from 'moment';
import { ButtonText } from '@axis/xyz.admin.buttons.button-text';
import { Loading } from '@axis/xyz.admin.loading';
import { Select } from '@axis/xyz.admin.form.select';
import css from './EntityEducation.module.css';
import { deleteEntityEducation, getEntityEducations, saveEntityEducation } from './utils';
import Modal from '../Modal/Modal';
import SearchSelect from '../Form/SearchSelect/SearchSelect';

const emptyEducation = {
  id: null,
  degree: '',
  specialty: '',
  institution: '',
  source: '',
  startDate: null,
  endDate: null,
  institutionEntity: null,
};

const institutionSources = ['Axis', 'Non-Axis'];
const searchEntitiesQuery = '/entity-infos?type[$ne]=Brief&$limit=50&name[$iLike]=';

const EntityEducation = ({ entityId }) => {
  const [educations, setEducations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [educationObject, setEducationObject] = useState(emptyEducation);
  const [educationToDelete, setEducationToDelete] = useState(null);
  const [institutionSource, setInstitutionSource] = useState(institutionSources[0]);
  const [removeEducationModalOpen, setRemoveEducationModalOpen] = useState(false);
  const queryClient = useQueryClient();
  const mutateEducation = useMutation(saveEntityEducation);
  const deleteEducation = useMutation(deleteEntityEducation);

  const requiredFields = ['degree', 'source', ...(institutionSource === 'Axis' ? ['institutionEntity'] : ['institution'])];

  useEffect(() => {
    setLoading(true);
    queryClient
      .fetchQuery(['entity-educations', entityId], () => getEntityEducations(entityId))
      .then(setEducations)
      .catch((error) => {
        const text = get(error, 'response.data.message', 'Could not fetch entity roles');
        notification.error({ message: text });
        setEducations([]);
      })
      .finally(() => {
        setLoading(false);
      });
    return () => {};
  }, []);

  const handleSaveEducation = () => {
    const errors = [];
    Object.keys(educationObject).forEach((key) => {
      if (requiredFields.includes(key) && !educationObject[key]) {
        errors.push(key);
      }
    });

    if (errors.length) {
      notification.error(`The following fields are empty: ${errors.join(', ')}`);
      return;
    }

    educationObject.entityId = entityId;
    educationObject.institutionEntityId = educationObject.institutionEntity ? educationObject.institutionEntity.id : null;
    educationObject.institutionEntity = null;

    setLoading(true);
    mutateEducation
      .mutateAsync(educationObject)
      .then((data) => {
        setEducations([...educations, ...[data]]);
        setEducationObject(emptyEducation);
        queryClient.invalidateQueries(['entity-educations', entityId]);
        notification.success({ message: 'Entity education saved successfully!' });
      })
      .catch((error) => {
        const text = get(error, 'response.data.message', 'Could not save entity education');
        notification.error({ message: text });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleInputChange = (field, value) => setEducationObject({ ...educationObject, [field]: value });

  const handleEditEducation = (education) => {
    setEducations(educations.filter((r) => r.id !== education.id));
    setEducationObject({
      ...education,
      startDate: education.startDate ? new Date(education.startDate) : null,
      endDate: education.endDate ? new Date(education.endDate) : null,
    });
    setInstitutionSource(education.institutionEntity ? 'Axis' : 'Non-Axis');
  };

  const handleDeleteEducation = (education) => {
    setEducationToDelete(education);
    setRemoveEducationModalOpen(true);
  };

  const handleDeleteConfirmed = () => {
    setRemoveEducationModalOpen(false);
    setLoading(true);
    deleteEducation
      .mutateAsync(educationToDelete)
      .then(() => {
        setEducations(educations.filter((e) => e.id !== educationToDelete.id));
        queryClient.invalidateQueries(['entity-educations', entityId]);
        notification.success({ message: 'Entity education deleted successfully!' });
      })
      .catch((error) => {
        const text = get(error, 'response.data.message', 'Could not delete entity education');
        notification.error({ message: text });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDeleteCancelled = () => {
    setEducationToDelete(null);
    setRemoveEducationModalOpen(false);
  };

  return (
    <>
      <div className={css.card}>
        {educations.map((education) => (
          <div className={css.card} key={education.id}>
            <div className={css.icons}>
              <Icon
                className={css.icon}
                type="edit"
                onClick={() => handleEditEducation(education)}
              />
              <Icon
                className={css.icon}
                type="trash"
                onClick={() => handleDeleteEducation(education)}
              />
            </div>
            <div className={css.row}>
              <div className={css.entry}>
                <Typography size="sm" type="title" className={css.subtitle}>Education Degree</Typography>
                <Typography>{education.degree || '<Empty>'}</Typography>
              </div>
              <div style={{ marginLeft: '10px' }} />
              <div className={css.bigEntry}>
                <Typography size="sm" type="title" className={css.subtitle}>Education Specialty</Typography>
                <Typography>{education.specialty || '<Empty>'}</Typography>
              </div>
            </div>
            <div className={css.row}>
              <div className={css.entry}>
                <Typography size="sm" type="title" className={css.subtitle}>Axis Institution</Typography>
                <Typography>{education.institutionEntity ? 'Yes' : 'No'}</Typography>
              </div>
              <div style={{ marginLeft: '10px' }} />
              <div className={css.bigEntry}>
                <Typography size="sm" type="title" className={css.subtitle}>Institution</Typography>
                <Typography>{education.institutionEntity ? education.institutionEntity.name : education.institution || '<Empty>'}</Typography>
              </div>
            </div>
            <div className={css.row}>
              <div className={css.entry}>
                <Typography size="sm" type="title" className={css.subtitle}>Start Date</Typography>
                <Typography>{education.startDate ? moment(education.startDate).format('DD MMM, YYYY') : '<Empty>'}</Typography>
              </div>
              <div className={css.entry}>
                <Typography size="sm" type="title" className={css.subtitle}>End Date</Typography>
                <Typography>{education.endDate ? moment(education.endDate).format('DD MMM, YYYY') : '<Empty>'}</Typography>
              </div>
              <div className={css.entry}>
                <Typography size="sm" type="title" className={css.subtitle}>Source</Typography>
                <Typography>{education.source || '<Empty>'}</Typography>
              </div>
            </div>
          </div>
        ))}
        <div>
          <div>
            <div className={css.row}>
              <FormItemWrapper
                className={css.input}
                isRequired
                label="Educational Degree"
                helpMessage=""
                helpMessageTitle="Educational Degree"
                errorMessage={educationObject.degree ? '' : 'An educational degree is required'}
                maxLength={`${300 - (educationObject.degree || '').length}`}
              >
                <TextInput
                  name="education-degree"
                  value={educationObject.degree || ''}
                  onChange={(e) => handleInputChange('degree', e.target.value)}
                  maxLength={300}
                />
              </FormItemWrapper>
              <div style={{ marginLeft: '10px', marginRight: '10px' }} />
              <FormItemWrapper
                className={css.input}
                label="Specialty"
                helpMessage=""
                helpMessageTitle="Specialty"
                maxLength={`${300 - (educationObject.specialty || '').length}`}
              >
                <TextInput
                  name="specialty"
                  value={educationObject.specialty || ''}
                  onChange={(e) => handleInputChange('specialty', e.target.value)}
                  maxLength={300}
                />
              </FormItemWrapper>
            </div>
          </div>
          <div>
            <div className={css.row}>
              <FormItemWrapper
                className={css.input}
                label="Institution Source"
                helpMessage=""
                helpMessageTitle="Institution Source"
              >
                <Select
                  className={css.select}
                  placeholder="Select Institution Source"
                  name="institution-source"
                  getOptionLabel={(option) => option}
                  getOptionValue={(option) => option}
                  filterOption={(options) => options}
                  onChange={(value) => setInstitutionSource(value)}
                  value={institutionSource}
                  options={institutionSources}
                />
              </FormItemWrapper>
              <div style={{ marginLeft: '10px', marginRight: '10px' }} />
              {institutionSource === 'Axis' ? (
                <FormItemWrapper
                  className={css.input}
                  isRequired={institutionSource === 'Axis'}
                  label="Axis Entity"
                  helpMessageTitle="Entity"
                  errorMessage={(institutionSource === 'Axis' && !educationObject.institutionEntity) ? 'An Axis entity is required' : ''}
                >
                  <SearchSelect
                    name="education-institution"
                    value={educationObject.institutionEntity}
                    onChange={(value) => handleInputChange('institutionEntity', value)}
                    getOptionLabel={(entity) => (entity ? entity.name : null)}
                    searchUrl={searchEntitiesQuery}
                  />
                </FormItemWrapper>
              ) : (
                <FormItemWrapper
                  className={css.input}
                  label="Non-Axis Organization"
                  isRequired={institutionSource === 'Non-Axis'}
                  helpMessage=""
                  helpMessageTitle="Non-Axis Organization"
                  errorMessage={(institutionSource === 'Non-Axis' && !educationObject.institution) ? 'An institution is required' : ''}
                >
                  <TextInput
                    name="non-axis-institution"
                    value={educationObject.institution || ''}
                    onChange={(e) => handleInputChange('institution', e.target.value)}
                    maxLength={300}
                  />
                </FormItemWrapper>
              )}
            </div>
          </div>
          <div>
            <div className={css.row}>
              <div className={css.dates}>
                <FormItemWrapper
                  className={css.input}
                  label="Start Date"
                  helpMessage=""
                  helpMessageTitle="Start Date"
                >
                  <DateInput
                    name="education-start-date"
                    placeholder="Start date"
                    value={educationObject.startDate}
                    onChange={(date) => handleInputChange('startDate', date)}
                    onClear={() => handleInputChange('startDate', null)}
                    valuePosition="right"
                    closeOnValueSelect
                  />
                </FormItemWrapper>
                <div style={{ marginLeft: '10px', marginRight: '10px' }} />
                <FormItemWrapper
                  className={css.input}
                  label="End Date"
                  helpMessage=""
                  helpMessageTitle="End Date"
                >
                  <DateInput
                    name="education-end-date"
                    placeholder="End date"
                    value={educationObject.endDate}
                    onChange={(date) => handleInputChange('endDate', date)}
                    onClear={() => handleInputChange('endDate', null)}
                    valuePosition="right"
                    closeOnValueSelect
                  />
                </FormItemWrapper>
              </div>
              <div style={{ marginLeft: '10px', marginRight: '10px' }} />
              <div className={css.source}>
                <FormItemWrapper
                  className={css.input}
                  isRequired
                  label="Source"
                  helpMessage=""
                  helpMessageTitle="Source"
                  errorMessage={educationObject.source ? '' : 'Role source is required'}
                  maxLength={`${300 - (educationObject.source || '').length}`}
                >
                  <TextInput
                    name="source"
                    value={educationObject.source || ''}
                    onChange={(e) => handleInputChange('source', e.target.value)}
                    maxLength={500}
                  />
                </FormItemWrapper>
              </div>
            </div>
          </div>
          <Button
            className={css.save}
            disabled={loading || !educationObject.degree || !educationObject.source}
            onClick={handleSaveEducation}
            label="Save Education"
            type="button"
          />
        </div>
        <Modal
          className={css.modal}
          width={500}
          open={removeEducationModalOpen}
          maskStyle={{ backgroundColor: 'var(--color-bg-modal)' }}
        >
          <div className={css.content}>
            <Typography>Are you sure you want to delete the education</Typography>
            <Typography type="title" size="sm">
              {removeEducationModalOpen ? educationToDelete.degree : null}
            </Typography>
            <div className={css.buttons}>
              <ButtonText
                disabled={loading}
                color="secondary"
                className={css.buttonText}
                label="Delete"
                onClick={() => handleDeleteConfirmed()}
              />
              <Button
                label="Cancel"
                disabled={loading}
                onClick={() => handleDeleteCancelled()}
              />
            </div>
          </div>
        </Modal>
        <div className={css.loaderContainer}>
          {loading && (<Loading className={css.loader} />)}
        </div>
      </div>
    </>
  );
};

export default EntityEducation;
