import orderBy from 'lodash/orderBy';
import get from 'lodash/get';
import lowerCase from 'lodash/lowerCase';
import { message } from 'antd';
import entityTypes from '../../../../common/models/EntityTypes';
import client from '../../../../common/utils/http';
import { catchError } from '../../../../saga/sagas';

const searchEntities = async (searchString, types = '*', briefValue = '', topicId) => {
  try {
    let value = searchString;
    if (value.length < 2) {
      return [];
    }
    const allowed = types.split(',');
    let queryType = '';
    let i = 0;
    allowed.forEach((q) => {
      const param = q.trim();
      if (param === '*') return null;
      if (param.split(':').length === 2) {
        i += 1;
        const params = param.split(':');
        queryType += `&$or[${i}][$and][type]=${params[0]}&$or[${i}][$and][subType]=${params[1]}`;
        i += 1;
      } else {
        queryType += `&$or[${i}][type]=${param}`;
      }
      return null;
    });
    if (value.split(':').length === 2 && queryType === '') {
      if (entityTypes[lowerCase(value.split(':')[0])]) {
        const category = value.split(':');
        queryType = `&$or[1][$and][type]=${category[0]}&$or[1][$and][subType]=${category[1].split(',')[0]}`;
        value = category[1].split(',').length > 2 ? category[1].split(',')[1] : '';
      }
    }
    let briefTypeQuery = '';
    if (briefValue) {
      if (briefValue === 'Topic') {
        briefTypeQuery = '&briefType=Country';
      } else {
        briefTypeQuery = `&briefType=${briefValue}`;
      }
    }
    if (topicId) {
      briefTypeQuery += `&topicId=${topicId}`;
    }
    const { data } = await client
      .get(`/entity-infos?name[%24iLike]=%25${value}%25${queryType}&%24limit=1000${briefTypeQuery}`)
      .catch(catchError);
    return orderBy(data.data, [(e) => `${e.type} ${e.subType}`, 'name'], ['asc', 'asc']);
  } catch (e) {
    message.error(e.message);
    return [];
  }
};

const getTopics = async (id) => {
  const { data } = await client
    .get(`/entity-infos?id=${id}`)
    .catch(catchError);
  return get(data, 'data[0].topics', null);
};

export default {
  searchEntities,
  getTopics,
};
