import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Input, Button, message, Spin,
} from 'antd';
import { useMutation } from 'react-query';
import styles from './ResetPassword.module.css';
import client from '../../common/utils/http';
import { getResetToken, verifyNewPassword } from './utils';

const ResetPassword = () => {
  const history = useHistory();
  const location = useLocation();

  const [newPassword, setNewPassword] = useState('');
  const [repassword, setRepassword] = useState('');

  const [isLoading, setLoading] = useState(false);

  const passwordReset = useMutation(({ password, resetToken }) => {
    client.post('/reset-pwd/', { password, resetToken });
  });

  const handleResetPassword = () => {
    setLoading(true);
    const { status, error } = verifyNewPassword({ password: newPassword, repassword });
    if (status === 'ko') return message.error(error);
    const resetToken = getResetToken(location);
    return passwordReset.mutate({ password: newPassword, resetToken }, {
      onSuccess: () => {
        message.success('Your password has been reset');
        setTimeout(() => history.push('/auth'), 3000);
      },
      onError: () => {
        message.error('There was an error reseting the password');
      },
      onSettled: () => setLoading(false),
    });
  };

  return (
    <div className={styles.main}>
      <Spin className={styles.spin} size="large" spinning={isLoading}>
        <div className={styles.form}>
          <div className={styles.formColumn}>
            <span>New Password</span>
          </div>
          <div className={styles.formColumn}>
            <Input
              autoComplete="off"
              type="password"
              value={newPassword}
              style={{ width: '100%' }}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <div className={styles.formColumn}>
            <span>Confirm Password</span>
          </div>
          <div className={styles.formColumn}>
            <Input
              autoComplete="off"
              type="password"
              value={repassword}
              style={{ width: '100%' }}
              onChange={(e) => setRepassword(e.target.value)}
            />
          </div>
          <div className={styles.actions}>
            <Button type="default" onClick={() => history.push('/auth')}>Back</Button>
            <Button type="primary" onClick={handleResetPassword}>Reset</Button>
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default ResetPassword;
